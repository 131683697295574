import { GlobalStateContext } from '@/utils/context/GlobalStateContext';
import { IOrder } from '@/api/omni/Order/order.interface';
import { Icon } from '@iconify/react';
import { useContext, useEffect, useState } from 'react';
import Nav from '@/components/Nav/Nav';
import OrderLayout from '@/components/order/OrderLayout';
import OrderList from '@/components/order/OrderList';
import OrderModal from '@/components/order/OrderModal';

const OrderPage = () => {
  const { orders, isLoadingOrders } = useContext(GlobalStateContext);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState<any[]>();
  const [option, setOption] = useState<boolean>(false);

  const [orderInModal, setOrderInModal] = useState<IOrder | undefined>(
    undefined,
  );
  const [isOrderDetailModalOpen, setIsOrderDetailModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    const order: any = [];
    if (orders.length > 15) {
      for (let i = 0; i <= orders.length / 15; i += 1) {
        order.push(<OrderLayout page={page} pages={i} setPage={setPage} />);
      }
    }
    setPages(order);
  }, [orders.length, page]);

  useEffect(() => {
    if (orderInModal) {
      setIsOrderDetailModalOpen(true);
    }
  }, [orderInModal]);

  useEffect(() => {
    if (!isOrderDetailModalOpen) {
      setOrderInModal(undefined);
    }
  }, [isOrderDetailModalOpen]);

  useEffect(() => {
    const con = document.querySelector('.order-con') as HTMLDivElement;
    con.scrollTo(0, 0);
  }, [page]);

  useEffect(() => {
    window.onclick = function (event) {
      if (!event.target.matches('.btn-order-option') && option) {
        document.querySelectorAll<HTMLDivElement>('.e').forEach((_) => {
          setOption(false);
        });
      }
    };
  });

  return (
    <>
      <div className="e fixed z-[49] hidden h-full w-full"></div>
      <Nav title="Order" />
      <OrderModal
        order={orderInModal}
        isOpen={isOrderDetailModalOpen}
        closeModal={() => {
          setIsOrderDetailModalOpen(false);
        }}
      />
      <section className="order-con h-full min-h-[250px] w-full overflow-y-auto scroll-smooth px-6 pb-10 md:flex lg:min-h-[560px]">
        <div className="mt-6 flex h-fit flex-1 flex-col justify-between gap-5 rounded-2xl bg-white p-4 max-md:mt-0">
          <OrderList
            orders={orders}
            isLoading={isLoadingOrders}
            end={orders.length}
            openModal={setOrderInModal}
          />
          {orders !== undefined && page > 0 && (
            <ul className="order-wrapper flex w-full justify-center gap-2">
              <li
                className="theme bg cursor-pointer rounded-md p-2 px-3 text-white md:hidden"
                onClick={() => page > 0 && setPage(page - 1)}
              >
                <Icon icon="akar-icons:chevron-left" />
              </li>
              {pages}
              <li
                className="theme bg cursor-pointer rounded-md p-2 px-3 text-white md:hidden"
                onClick={() => setPage(page + 1)}
              >
                <Icon icon="akar-icons:chevron-right" />
              </li>
            </ul>
          )}
        </div>
      </section>
    </>
  );
};

export default OrderPage;
