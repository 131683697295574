import { AddWalletIcon } from './AddWalletIcon';
import { CryptoWalletType } from '@/api/omni/CryptoWallet/cryptoWallet.interface';
import { GlobalStateContext } from '@/utils/context/GlobalStateContext';
import { ReactNode, useContext, useState } from 'react';
import { Web3Functions } from '../web3/Web3';
import { motion } from 'framer-motion';
import config, { DeploymentEnvironment } from '@/config';

interface IAddWallerBtnProps {
  onClick?: () => void;
  children?: ReactNode;
  isHidden?: boolean;
}
const AddWalletBtn = (props: IAddWallerBtnProps) => {
  const { onClick, children, isHidden = false } = props;
  if (isHidden) {
    return null;
  }
  return (
    <button
      onClick={onClick}
      className="min-w-[250px] flex-1 rounded-lg border-2 border-[#93278f] p-3 py-5 font-medium text-[#93278f] duration-150 hover:bg-[#93278f] hover:text-white"
    >
      {children}
    </button>
  );
};
interface IAddWalletProps {
  isManagedWallet?: boolean;
}
interface IAddingWalletProps {
  isOpen?: boolean;
  onDisable?: () => void;
  walletType?: 'ethereum' | 'managed';
}
function AddingWallet({
  isOpen = false,
  onDisable,
  walletType,
}: IAddingWalletProps) {
  const { getProvider, isManagedWallet, addCryptoWallet } =
    useContext(GlobalStateContext);

  const createEthereumAddress = async (
    address: string,
    message: string,
    signature: string,
  ) => {
    await addCryptoWallet(CryptoWalletType.ASSOCIATED)(
      address,
      message,
      signature,
    );
  };

  const handleAddManagedWallet = async () => {
    // await createManagedWallet(instance)({ eco: BlockchainEco.ETHEREUM });
    // await updateCryptoWallet();
    await addCryptoWallet(CryptoWalletType.MANAGED)();
  };
  const handleAddWalletClick = async () => {
    try {
      const chainId = config.env === DeploymentEnvironment.PRODUCTION ? 137 : 5;
      const useWeb3Function = new Web3Functions(
        chainId,
        await getProvider(chainId),
      );
      const result = await useWeb3Function.signMessage();
      if (result) {
        const { address, signature, message } = result;
        await createEthereumAddress(address, message, signature);
      }
    } catch (e) {
      console.error({ e });
      if (e instanceof Error) {
        if (e.message === 'No Binance Chain Wallet found') {
          // setCustomAddWalletErrorMessage(e.message);
        }
      }
    }
  };

  return (
    <>
      {isOpen && (
        <div
          onClick={() => {
            if (onDisable !== undefined) {
              onDisable();
            }
          }}
          className="fixed left-0 top-0 z-[700] h-full w-full bg-black/30"
        >
          <div className="absolute left-1/2 top-1/2 z-[701] flex min-w-[600px] -translate-x-1/2 -translate-y-1/2 transform flex-col gap-5 rounded-xl bg-white p-6">
            <h2 className="text-xl font-medium">Select Wallet Type</h2>
            <div className="flex flex-1 flex-wrap gap-5">
              <AddWalletBtn
                onClick={() => {
                  handleAddWalletClick();
                  if (onDisable !== undefined) {
                    onDisable();
                  }
                }}
              >
                Add Ethereum Wallet
              </AddWalletBtn>
              <AddWalletBtn
                onClick={() => {
                  handleAddManagedWallet();
                  if (onDisable !== undefined) {
                    onDisable();
                  }
                }}
                isHidden={!(walletType === 'ethereum' && !isManagedWallet)}
              >
                Create Managed Wallet
              </AddWalletBtn>

              {/*{walletType === 'ethereum' && (*/}
              {/*  <div className="text-red-700">Caution: Managed Wallet </div>*/}
              {/*)}*/}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

function AddWallet(props: IAddWalletProps) {
  const { isManagedWallet } = props;
  // const navigate = useNavigate();
  const [isOpenWallet, setIsOpenWallet] = useState<boolean>(false);

  const handleOnClickAddWallet = (_) => {
    setIsOpenWallet(true);
  };

  return (
    <>
      <AddingWallet
        isOpen={isOpenWallet}
        onDisable={() => setIsOpenWallet(false)}
        walletType={isManagedWallet ? 'managed' : 'ethereum'}
      />
      <motion.button
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ ease: 'easeOut', duration: 0.3 }}
        viewport={{ once: true }}
        className="box-shadow gradient-box gradient-box-rounded-2xl flex max-h-[150px] w-full max-w-[280px] shrink-0 flex-col items-center justify-center gap-2 rounded-2xl bg-white px-7 text-xl font-medium text-blue-500 duration-200"
        onClick={handleOnClickAddWallet}
      >
        <AddWalletIcon />
        <p className="bg-gradient-to-br from-purple-500 to-blue-500 bg-clip-text text-transparent">
          + Add Wallet
        </p>
      </motion.button>
    </>
  );
}

export { AddWallet };
