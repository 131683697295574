import { DateTime } from 'luxon';

interface IProfileDateTime {
  title: string;
  datetime?: string;
}
const ProfileDateTime = (props: IProfileDateTime) => {
  const { title, datetime = '1970-1-1' } = props;

  const handleGetDate = (date: string) => {
    const d = DateTime.fromISO(date);
    return d.toLocaleString(DateTime.DATE_FULL, { locale: 'en-GB' });
  };

  const handleGetTime = (date: string) => {
    const d = DateTime.fromISO(date);
    return d.toLocaleString(DateTime.TIME_24_WITH_SHORT_OFFSET);
  };

  return (
    <div className="col-span-3">
      <div className=" text-xl font-semibold text-slate-500">{title}</div>
      <div className="text-lg font-semibold">{handleGetDate(datetime)}</div>
      <div className="text-sm font-semibold text-slate-500">
        {handleGetTime(datetime)}
      </div>
    </div>
  );
};

export { ProfileDateTime };
