const copyToClipboard = async (
  text: string,
  setCopyText?: (any) => void,
): Promise<void> => {
  try {
    await navigator.clipboard.writeText(text);
    if (setCopyText) setCopyText('Copied');
    setTimeout(() => {
      if (setCopyText) setCopyText('Copy to Clipboard');
    }, 1000);
    /* ✅ Copied successfully */
  } catch (e) {
    /* ❌ Failed to copy (insufficient permissions) */
  }
};

export { copyToClipboard };
