export enum DomainStatus {
  Active,
  MissingDeposit,
  Expired,
  Loading,
}

interface DomainStatusBarProps {
  status: DomainStatus;
}

const render = (color: string, name: string) => {
  return (
    <div className="flex shrink-0 items-center justify-between gap-2 font-medium text-green-600">
      <div className="aspect-square h-3 rounded-full bg-green-600"></div>
      {name}
    </div>
  );
};
const DomainStatusBar = (props: DomainStatusBarProps) => {
  switch (props.status) {
    case DomainStatus.Active:
      return render('', 'Active');
    case DomainStatus.MissingDeposit:
      return render('', 'MissingDeposit');
    case DomainStatus.Expired:
      return render('', 'Expired');
    default:
      return render('', '');
  }
};

export default DomainStatusBar;
