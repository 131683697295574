import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface IScrollToTopProps {
  children?: ReactNode | ReactNode[];
}
const ScrollToTop = (props: IScrollToTopProps) => {
  const { children } = props;
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return <>{children}</>;
};

export default ScrollToTop;
