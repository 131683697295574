import { AxiosInstance } from 'axios';
import { errorHandler } from '../../errorHandler';
import config from '@/config';

interface ICheckout {
  id: string;
  status: string;
  url: string;
}
interface ICheckoutInput {
  country: string;
  rememberCountrySetting: boolean;
}
export const createCheckoutPayment =
  (instance: AxiosInstance) =>
  async (orderId: string, input: ICheckoutInput): Promise<ICheckout> => {
    try {
      const res = await instance.post<ICheckout>(
        `${config.api.router.payment.checkout}${orderId}/checkout`,
        input,
      );
      console.log(res.data);
      return res.data;
    } catch (e) {
      throw errorHandler(e);
    }
  };
