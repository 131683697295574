import { AxiosContext } from '@/utils/axios/AxiosProvider';
import { AxiosInstance } from 'axios';
import {
  Bnb,
  Btc,
  Busd,
  Eth,
  Matic,
  Trx,
  Tusd,
  Usdc,
  Usdt,
} from '@/components/common/PaymentIcon';
import {
  IPayment,
  IPaymentMain,
} from '@/components/checkout/payments/paymentProvider.interface';
import { createCoingatePayment } from '@/api/omni/Payment-gateway/coingate';
import { createMixPayment } from '@/api/omni/Payment-gateway/mixpay';
import CoingateSvg from '@/static/payment/coingate_icon.svg';
import MixpaySvg from '@/static/payment/mixpay.svg';
import PaymentCard from '@/components/checkout/payments/paymentCard';
import React, { useContext } from 'react';

const IconList = [
  { icon: <Btc className="h-4 w-4" />, name: 'BTC' },
  { icon: <Trx className="h-4 w-4" />, name: 'TRX' },
  { icon: <Usdt className="h-4 w-4" />, name: 'USDT' },
  { icon: <Usdc className="h-4 w-4" />, name: 'USDC' },
  { icon: <Tusd className="h-4 w-4" />, name: 'TUSD' },
  { icon: <Matic className="h-4 w-4" />, name: 'MATIC' },
  { icon: <Busd className="h-4 w-4" />, name: 'BUSD' },
  { icon: <Eth className="h-4 w-4" />, name: 'ETH' },
  { icon: <Bnb className="h-4 w-4" />, name: 'BNB' },
];

const renderCryptoList = () => {
  return (
    <>
      <div className="flex w-full flex-wrap gap-1">
        {IconList.map((icon) => (
          <div className="flex flex-row items-center gap-1 rounded-md bg-white p-2">
            <div>{icon.icon}</div>
            <p className="font-light">{icon.name}</p>
          </div>
        ))}
      </div>
    </>
  );
};
const handleCreateCoingate =
  (instance: AxiosInstance) => async (orderId: string) => {
    // const link = await createCoingatePay(accessToken, order?.id);
    const link = await createCoingatePayment(instance)(orderId);
    window.location.href = link.url;
  };

const handleCreateMixPay = (instance) => async (orderId: string) => {
  // const link = await createCoingatePay(accessToken, order?.id);
  const link = await createMixPayment(instance)(orderId);
  window.location.href = link.url;
};

const CryptoPaymet: IPayment = {
  Coingate: {
    header: 'Coingate',
    point: [
      'Powered By Checkout.com',
      'Use most of the fundemental sryptocurrensies for payment',
    ],
    disable: false,
    icon: <img src={CoingateSvg} alt="coingate" />,
    onCreate: handleCreateCoingate,
  },
  MixPay: {
    header: 'MixPay',
    point: [
      'Powered By Checkout.com',
      'Use most of the fundemental sryptocurrensies for payment',
    ],
    disable: false,
    icon: <img src={MixpaySvg} alt="mixpay" />,
    onCreate: handleCreateMixPay,
  },
};
const CryptoPayment = (props: IPaymentMain) => {
  const { instance } = useContext(AxiosContext);

  if (!props.isShow) {
    return <></>;
  }
  return (
    <div className="flex h-full  flex-col  justify-center gap-8 lg:flex-row">
      {Object.values(CryptoPaymet).map((payment) => (
        <PaymentCard
          amount={props.order.total}
          name={payment.header}
          point={payment.point}
          isSelected={true}
          icon={payment.icon}
          children={renderCryptoList()}
          onCreate={payment.onCreate(instance)}
          orderId={props.order.id}
          disable={payment.disable}
        ></PaymentCard>
      ))}
    </div>
  );
};

export { CryptoPayment };
