import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';
import { ICryptoWallet } from '@/api/omni/CryptoWallet/cryptoWallet.interface';
import { IDomain } from '@/api/omni/Domain/domain.interface';
import i18nIsoCountries from 'i18n-iso-countries';

i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));

interface IMyDomainProviderProps {
  children: ReactNode;
  domain?: IDomain;
}

export enum SelectDomainState {
  None,
  Transfer,
  SwapChain,
  Mortgage,
}
interface IMyDomainContext {
  domain?: IDomain;
  displayView: 'grid' | 'list';
  setDisplayView: Dispatch<SetStateAction<'grid' | 'list'>>;
  selectedWallet: ICryptoWallet | undefined;
  setSelectedWallet: Dispatch<SetStateAction<ICryptoWallet | undefined>>;
  selectedBlockchainId: number | undefined;
  setSelectedBlockchainId: Dispatch<SetStateAction<number | undefined>>;
  selectedDomain: IDomain | undefined;
  setSelectedDomain: Dispatch<SetStateAction<IDomain | undefined>>;
  selectDomainState: SetStateAction<SelectDomainState>;
  setSelectDomainState: Dispatch<SetStateAction<SelectDomainState>>;
  searchDomain: string;
  setSearchDomain: Dispatch<SetStateAction<string>>;
  ethereumAddresses: ICryptoWallet[] | undefined;
  setEthereumAddresses: Dispatch<SetStateAction<ICryptoWallet[] | undefined>>;
  isOpenTransfer: boolean;
  setIsOpenTransfer: Dispatch<SetStateAction<boolean>>;
  isOpenMortgage: boolean;
  setIsOpenMortgage: Dispatch<SetStateAction<boolean>>;
  isDuplicatedBindingEthereumAddress: boolean | undefined;
  setIsDuplicatedBindingEthereumAddress: Dispatch<
    SetStateAction<boolean | undefined>
  >;
  customAddWalletErrorMessage: string | undefined;
  setCustomAddWalletErrorMessage: Dispatch<SetStateAction<string | undefined>>;
  showNav: boolean;
  setShowNav: Dispatch<SetStateAction<boolean>>;
  isRevealOptions: boolean;
  setIsRevealOptions: Dispatch<SetStateAction<boolean>>;
  privateKey: string;
  setPrivateKey: Dispatch<SetStateAction<string>>;
}
const MyDomainContext = createContext<IMyDomainContext>({
  displayView: 'grid',
  setDisplayView: () => {},
  selectedWallet: undefined,
  setSelectedWallet: () => {},
  selectedBlockchainId: undefined,
  setSelectedBlockchainId: () => {},
  selectedDomain: undefined,
  setSelectedDomain: () => {},
  selectDomainState: SelectDomainState.None,
  setSelectDomainState: () => {},
  searchDomain: '',
  setSearchDomain: () => {},
  ethereumAddresses: undefined,
  setEthereumAddresses: () => {},
  isOpenTransfer: false,
  setIsOpenTransfer: () => {},
  isOpenMortgage: false,
  setIsOpenMortgage: () => {},
  isDuplicatedBindingEthereumAddress: undefined,
  setIsDuplicatedBindingEthereumAddress: () => {},
  customAddWalletErrorMessage: undefined,
  setCustomAddWalletErrorMessage: () => {},
  showNav: false,
  setShowNav: () => {},
  isRevealOptions: false,
  setIsRevealOptions: () => {},
  privateKey: '',
  setPrivateKey: () => {},
});
function MyDomainProvider(props: IMyDomainProviderProps) {
  const { children, domain } = props;
  const [displayView, setDisplayView] = useState<'grid' | 'list'>('grid');
  const [selectedWallet, setSelectedWallet] = useState<ICryptoWallet>();
  const [selectedBlockchainId, setSelectedBlockchainId] = useState<number>();
  const [selectedDomain, setSelectedDomain] = useState<IDomain>();
  const [selectDomainState, setSelectDomainState] = useState<SelectDomainState>(
    SelectDomainState.None,
  );
  const [searchDomain, setSearchDomain] = useState<string>('');
  const [ethereumAddresses, setEthereumAddresses] = useState<
    ICryptoWallet[] | undefined
  >(undefined);
  const [isOpenTransfer, setIsOpenTransfer] = useState<boolean>(false);
  const [isOpenMortgage, setIsOpenMortgage] = useState<boolean>(false);
  const [
    isDuplicatedBindingEthereumAddress,
    setIsDuplicatedBindingEthereumAddress,
  ] = useState<boolean | undefined>(undefined);
  const [customAddWalletErrorMessage, setCustomAddWalletErrorMessage] =
    useState<string | undefined>(undefined);
  const [showNav, setShowNav] = useState(true);
  const [isRevealOptions, setIsRevealOptions] = useState(false);
  const [privateKey, setPrivateKey] = useState<string>('');

  const context = useMemo<IMyDomainContext>(
    () => ({
      domain,
      displayView,
      setDisplayView,
      selectedWallet,
      setSelectedWallet,
      selectedBlockchainId,
      setSelectedBlockchainId,
      selectedDomain,
      setSelectedDomain,
      selectDomainState,
      setSelectDomainState,
      searchDomain,
      setSearchDomain,
      ethereumAddresses,
      setEthereumAddresses,
      isOpenTransfer,
      setIsOpenMortgage,
      isOpenMortgage,
      setIsOpenTransfer,
      isDuplicatedBindingEthereumAddress,
      setIsDuplicatedBindingEthereumAddress,
      customAddWalletErrorMessage,
      setCustomAddWalletErrorMessage,
      showNav,
      setShowNav,
      isRevealOptions,
      setIsRevealOptions,
      privateKey,
      setPrivateKey,
    }),
    [
      domain,
      displayView,
      selectedWallet,
      selectedBlockchainId,
      selectedDomain,
      selectDomainState,
      searchDomain,
      ethereumAddresses,
      isOpenMortgage,
      isOpenTransfer,
      isDuplicatedBindingEthereumAddress,
      customAddWalletErrorMessage,
      showNav,
      isRevealOptions,
      privateKey,
    ],
  );
  return (
    <MyDomainContext.Provider value={context}>
      {children}
    </MyDomainContext.Provider>
  );
}

const useMyDomain = () => {
  if (MyDomainContext === undefined) {
    throw new Error('useMyDomain must be used within a MyDomainProvider');
  }
  return useContext(MyDomainContext);
};
export { MyDomainContext, MyDomainProvider, useMyDomain };
