export enum DeploymentEnvironment {
  DEVELOPMENT = 'development',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export interface IConfig {
  env: string;
  node_endpoint: string;
  auth0_domain: string;
  auth0_client_id: string;
  auth0_default_domain: string;
  auth0: {
    defaultDomain: string;
    domain: string;
    clientId: string;
    connection: string;
    url: string;
    realm: string;
    client: string;
  };
  api: {
    endpoint: string;
    resolverGdn: {
      endPoint: string;
      router: {
        allRecords: string;
        domains: string;
      };
    };
    endpointV2: string;
    router: {
      blockchain: {
        get: string;
        list: string;
      };
      cart: {
        addDomain: string;
        applyPromoCode: string;
        deletePromoCode: string;
        getCart: string;
        RemoveCartProduct: string;
        updateCartAmount: string;
      };
      cryptoWallet: {
        create: string;
        list: string;
        remove: string;
        revealManagedWalletPrivateKey: string;
        setDefaultWallet: string;
      };
      domain: {
        detail: string;
        list: string;
        search: string;
      };
      invoice: {
        get: string;
      };
      order: {
        cancelOrder: string;
        createOrder: string;
        getOrder: string;
        getPayment: string;
        listOrder: string;
      };
      premiumDomain: {
        list: string;
        lookup: string;
        balance: string;
      };
      payment: {
        checkout: string;
        coingate: string;
        mixpay: string;
      };
      product: {
        list: string;
      };
      receipt: {
        get: string;
      };
      tld: {
        list: string;
        get: string;
      };
      user: {
        get: string;
      };
    };
  };
  sentry: {
    dsn: string;
  };
  web3: {
    portis: {
      id: string;
    };
    blockchain: {
      infura: {
        id: string;
      };
      node: {
        [chainid: number]: {
          endpoint: string;
        };
      };
      network: string;
    };
  };
}

const config: IConfig = {
  env: process.env.REACT_APP_ENVIRONMENT || DeploymentEnvironment.DEVELOPMENT,
  node_endpoint: process.env.REACT_APP_API_ENDPOINT || '',
  auth0_domain: process.env.REACT_APP_AUTH0_DOMAIN || '',
  auth0_default_domain: process.env.REACT_APP_AUTH0_DEFAULT_DOMAIN || '',
  auth0_client_id: process.env.REACT_APP_AUTH0_CLIENT_ID || '',
  api: {
    endpointV2: 'http://localhost:8080',
    endpoint: process.env.REACT_APP_API_ENDPOINT || 'http://localhost:3001',
    resolverGdn: {
      endPoint:
        process.env.REACT_APP_API_RESOLVER_GDN || 'http://localhost:8080',
      router: {
        allRecords: '/record',
        domains: '/domain',
      },
    },
    router: {
      blockchain: {
        get: '/v2/public/chains/',
        list: '/v2/public/chains',
      },
      cart: {
        addDomain: '/v2/carts/domains',
        applyPromoCode: '/v2/carts/code',
        deletePromoCode: '/v2/carts/code/',
        getCart: '/v2/carts',
        RemoveCartProduct: '/v2/carts/domains/',
        updateCartAmount: '/v2/carts/domains/',
      },
      cryptoWallet: {
        create: '/v2/wallets',
        list: '/v2/wallets',
        remove: '/v2/wallets/',
        revealManagedWalletPrivateKey: '/v2/user/managed-wallet/reveal/',
        setDefaultWallet: '/v2/wallets/setDefault',
      },
      domain: {
        detail: '/v2/public/domains/',
        list: '/v2/domains/domains',
        search: '/v2/public/domains/search',
      },
      invoice: {
        get: '/v2/invoice/download/',
      },
      order: {
        cancelOrder: '/v2/orders/',
        createOrder: '/v2/orders',
        getOrder: '/v2/orders/',
        getPayment: '/v2/payment/result/',
        listOrder: '/v2/orders',
      },
      premiumDomain: {
        list: '/v2/public/premium/domains',
        lookup: '/v2/premium/lookup/',
        balance: '/v2/premium/balance/',
      },
      payment: {
        checkout: '/v2/payment/',
        coingate: '/v2/payment/',
        mixpay: '/v2/payment/',
      },
      product: {
        list: '/v2/public/products',
      },
      receipt: {
        get: '/v2/receipt/download/',
      },
      tld: {
        get: '/v2/public/tlds/',
        list: '/v2/public/tlds',
      },
      user: {
        get: '/v2/user',
      },
    },
  },
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN || '',
  },
  auth0: {
    defaultDomain: process.env.REACT_APP_AUTH0_DEFAULT_DOMAIN || 'UNKNOWN_',
    domain: process.env.REACT_APP_AUTH0_DOMAIN || 'UNKNOWN_',
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID || 'UNKNOWN_',
    connection: process.env.REACT_APP_AUTH0_CONNECTION || 'UNKNOWN_',
    url: process.env.REACT_APP_AUTH0_URL || 'UNKNOWN_KEYCLOAK_URL',
    realm: process.env.REACT_APP_AUTH0_REALM || 'UNKNOWN_UNKNOWN_REALM_URL',
    client: process.env.REACT_APP_AUTH0_CLIENT || 'UNKNOWN_CLIENT',
  },
  // contractAddress: {
  //   43113: {
  //     //AVALANCHE_FUJI
  //     publicResolver: '0x044FB12f7E570c059e4E202e35EA1b68ee1CB8B4',
  //     baseRegistrar: '0x5C2BcA98a580048Faf90C24A68cBa9A6885F19a3',
  //     classicalRegistrarController:
  //       '0x7f26e323a3a3b0447f0Bce036A5f08e92BE317cb',
  //     universalRegistrarController:
  //       '0x169600Bf248295B4dFb2a1405c46ea14619D4D02',
  //     omniRegistrarController: '0xc200687683013e24B7b7E1ac956A6a02F6b6BCd7',
  //     defaultWrapper: '',
  //   },
  //   80001: {
  //     //POLYGON_MUMBAI
  //     publicResolver: '0x44E03aEFE8217445433F2593B62Afd970aBC395C',
  //     baseRegistrar: '0xEFD5ddD8531F5b96c3c29e682C6C471224Eb86ED',
  //     classicalRegistrarController:
  //       '0x2ffbd22Ca04291EeDD34F76f7CFCbecCCA4fCBBB',
  //     universalRegistrarController:
  //       '0xf841015c42679528c0A6cE9a52e0898810fF4832',
  //     omniRegistrarController: '0x1491828F30Eb971F7f09722EB14d7AED8Ae0c417',
  //     defaultWrapper: '',
  //   },
  //   97: {
  //     //BNB_CHAIN_TESTNET
  //     publicResolver: '0x8646Af70792c4639b71d6D1CA96211e34c2e9729',
  //     baseRegistrar: '0xfbbDe69Ad509d24c007b2b1406B9dBbd04384578',
  //     classicalRegistrarController:
  //       '0x44c0119CB112834A36DD880752DA928FB22c3c31',
  //     universalRegistrarController:
  //       '0x2f9D45f3B48Ec664A4eD8e0A9C5db1c584796b06',
  //     omniRegistrarController: '0xE23AeDF7d90841CcfB0247a536331784616f0C69',
  //     defaultWrapper: '',
  //   },
  //   5: {
  //     //GOERLI
  //     publicResolver: '0xe90EAE12B9A9CC3AB8BAF9dB06422D6c5922213f',
  //     baseRegistrar: '0x4B09eEdF277b43223f35B68a997A775E64a53084',
  //     classicalRegistrarController:
  //       '0xFf1f612EF6842AcD9bE11D191e428935d1c24C0B',
  //     universalRegistrarController:
  //       '0x24278bF7bE752DaB0bcd0c9fed5939d0215552B4',
  //     omniRegistrarController: '0x505b73dE396773bE88C2c00480E7d21525CE482E',
  //     defaultWrapper: '',
  //   },
  //   4002: {
  //     //FANTOM_TESTNET
  //     publicResolver: '0x1B4F58E0FC9bA1c2aA870B4e592f0F38987f0C97',
  //     baseRegistrar: '0x1bF7c57a0BeeD5add701C226D6f83a231673c6ae',
  //     classicalRegistrarController:
  //       '0x26453B652FA079eCaF5D78bE1218173CC2Ae7698',
  //     universalRegistrarController:
  //       '0x0cF919C51Fb6DEE63AE620a20c8e0B7DF6DFA8ff',
  //     omniRegistrarController: '0xF0bf6d9EBd7F10788Eb529100Bbc1b44838eED98',
  //     defaultWrapper: '',
  //   },
  //   420: {
  //     //OPTIMISM_GOERLI
  //     publicResolver: '',
  //     baseRegistrar: '',
  //     classicalRegistrarController: '',
  //     universalRegistrarController: '',
  //     omniRegistrarController: '',
  //     defaultWrapper: '',
  //   },
  //   421613: {
  //     //ARBITRUM_GOERLI
  //     publicResolver: '',
  //     baseRegistrar: '',
  //     classicalRegistrarController: '',
  //     universalRegistrarController: '',
  //     omniRegistrarController: '',
  //     defaultWrapper: '',
  //   },
  //   4690: {
  //     //IOTEX_TESTNET
  //     publicResolver: '0x704894387CDCc9068367D49b9133d29083a0bfbC',
  //     baseRegistrar: '0x74d926b03576717a7B2E5C2294549D30D9Ce4DF2',
  //     classicalRegistrarController:
  //       '0xBaC438DA4aecB2bFDc046bAC50ba5e1fb0Dd5317',
  //     universalRegistrarController:
  //       '0x3042D63c7f27850191BF87a88f3f81CFbC2b3D4e',
  //     omniRegistrarController: '0xb848963623E78196b66601f07fD2bB8bDe51D871',
  //     defaultWrapper: '',
  //   },
  //   44787: {
  //     //CELO_ALFAJORES
  //     publicResolver: '0x02B9E903C682139458Daef1a08f6BB92d7a48a9B',
  //     baseRegistrar: '0xF694D02F7265384fe049EB8565f76460dc48A5cF',
  //     classicalRegistrarController:
  //       '0x7197e1001b8bFCA77e75A1a37dC99C9a96D97F5e',
  //     universalRegistrarController:
  //       '0x3eC9c84A26C75cF031e32508F9EF5ebaD4C6acEF',
  //     omniRegistrarController: '0x704894387CDCc9068367D49b9133d29083a0bfbC',
  //     defaultWrapper: '',
  //   },
  //   65: {
  //     //OKC_TESTNET
  //     publicResolver: '0x02B9E903C682139458Daef1a08f6BB92d7a48a9B',
  //     baseRegistrar: '0xF694D02F7265384fe049EB8565f76460dc48A5cF',
  //     classicalRegistrarController:
  //       '0x7197e1001b8bFCA77e75A1a37dC99C9a96D97F5e',
  //     universalRegistrarController:
  //       '0x3eC9c84A26C75cF031e32508F9EF5ebaD4C6acEF',
  //     omniRegistrarController: '0x704894387CDCc9068367D49b9133d29083a0bfbC',
  //     defaultWrapper: '',
  //   },
  //   10200: {
  //     //GNOSIS_CHIADO
  //     publicResolver: '0x0A1CaE4B150C42153A78DeFf3E83158080895633',
  //     baseRegistrar: '0xA6b4930d2F9c0676B99A30c5396311Af8d050Bb0',
  //     classicalRegistrarController:
  //       '0xb45adB0bA915D909E51cD025426970A8a1a4Cd1e',
  //     universalRegistrarController:
  //       '0x206501c0E40DF993b811dcbd3EF4BFb8075Afa8B',
  //     omniRegistrarController: '0xFa365f8b4F80A06ae2C872792A19744aFA917724',
  //     defaultWrapper: '',
  //   },
  //   1666700000: {
  //     //HARMONEY_TESTNET
  //     publicResolver: '0x02B9E903C682139458Daef1a08f6BB92d7a48a9B',
  //     baseRegistrar: '0xF694D02F7265384fe049EB8565f76460dc48A5cF',
  //     classicalRegistrarController:
  //       '0x7197e1001b8bFCA77e75A1a37dC99C9a96D97F5e',
  //     universalRegistrarController:
  //       '0x3eC9c84A26C75cF031e32508F9EF5ebaD4C6acEF',
  //     omniRegistrarController: '0x704894387CDCc9068367D49b9133d29083a0bfbC',
  //     defaultWrapper: '',
  //   },
  //   1287: {
  //     //MOONBASE_ALPHA
  //     publicResolver: '0x02B9E903C682139458Daef1a08f6BB92d7a48a9B',
  //     baseRegistrar: '0xF694D02F7265384fe049EB8565f76460dc48A5cF',
  //     classicalRegistrarController:
  //       '0x7197e1001b8bFCA77e75A1a37dC99C9a96D97F5e',
  //     universalRegistrarController:
  //       '0x3eC9c84A26C75cF031e32508F9EF5ebaD4C6acEF',
  //     omniRegistrarController: '0x704894387CDCc9068367D49b9133d29083a0bfbC',
  //     defaultWrapper: '',
  //   },
  //   280: {
  //     //ZKSYNC_ERA_TESTNET
  //     publicResolver: '',
  //     baseRegistrar: '',
  //     classicalRegistrarController: '',
  //     universalRegistrarController: '',
  //     omniRegistrarController: '',
  //     defaultWrapper: '',
  //   },
  //   1442: {
  //     //POLYGON_ZKEVM_TESTNET
  //     publicResolver: '',
  //     baseRegistrar: '',
  //     classicalRegistrarController: '',
  //     universalRegistrarController: '',
  //     omniRegistrarController: '',
  //     defaultWrapper: '',
  //   },
  // },
  web3: {
    portis: {
      id: process.env.REACT_APP_PORTIS_ID || 'UNKNOWN',
    },
    blockchain: {
      infura: {
        id: process.env.REACT_APP_INFURA_ID || '',
      },
      node: {
        1: {
          endpoint:
            process.env.REACT_APP_ETHEREUM_ENDPOINT || 'https://1rpc.io/eth',
        },
        5: {
          // GOERLI
          endpoint:
            process.env.REACT_APP_GOERL_ENDPOINT ||
            'https://eth-goerli.api.onfinality.io/public',
        },
        56: {
          endpoint:
            process.env.REACT_APP_BNB_ENDPOINT ||
            'https://bsc-dataseed1.binance.org',
        },
        97: {
          // BNB_CHAIN_TESTNET
          endpoint:
            process.env.REACT_APP_BNB_TEST_ENDPOINT ||
            'https://data-seed-prebsc-1-s1.binance.org:8545',
        },
        43114: {
          // AVALANCHE_FUJI
          endpoint:
            process.env.REACT_APP_FUJI_ENDPOINT ||
            'https://avalanche-c-chain.publicnode.com',
        },
        43113: {
          // AVALANCHE_FUJI
          endpoint:
            process.env.REACT_APP_FUJI_TEST_ENDPOINT ||
            'https://ava-testnet.public.blastapi.io/ext/bc/C/rpc',
        },
        137: {
          // Polygon
          endpoint:
            process.env.REACT_APP_POLYGON_MAINNET_ENDPOINT ||
            'https://polygon.llamarpc.com',
        },
        80001: {
          // POLYGON_MUMBAI
          endpoint:
            process.env.REACT_APP_MUMBAI_ENDPOINT ||
            'https://rpc-mumbai.maticvigil.com',
        },
        42161: {
          //ARBITRUM MAINNET
          endpoint:
            process.env.REACT_APP_ARBITRUM_ENDPOINT ||
            'https://endpoints.omniatech.io/v1/arbitrum/one/public',
        },
        421613: {
          // ARBITRUM_GOERLI
          endpoint:
            process.env.REACT_APP_ARBITRUM_TEST_ENDPOINT ||
            'https://endpoints.omniatech.io/v1/arbitrum/goerli/public',
        },
        10: {
          // OPTIMISM
          endpoint:
            process.env.REACT_APP_OPTIMISM_ENDPOINT ||
            'https://endpoints.omniatech.io/v1/op/mainnet/public',
        },
        420: {
          // OPTIMISM_GOERLI
          endpoint:
            process.env.REACT_APP_OPTIMISM_GOERLI_ENDPOINT ||
            'https://endpoints.omniatech.io/v1/op/goerli/public',
        },
        250: {
          // Fantom Opera
          endpoint:
            process.env.REACT_APP_FANTOM_ENDPOINT ||
            'https://fantom.publicnode.com',
        },
        4002: {
          // FANTOM_TESTNET
          endpoint:
            process.env.REACT_APP_FANTOM_TEST_ENDPOINT ||
            'https://rpc.testnet.fantom.network',
        },
        4689: {
          // IOTEX
          endpoint:
            process.env.REACT_APP_IOTEX_ENDPOINT ||
            'https://babel-api.mainnet.iotex.one',
        },
        4690: {
          // IOTEX_TESTNET
          endpoint:
            process.env.REACT_APP_IOTEX_TEST_ENDPOINT ||
            'https://babel-api.testnet.iotex.io',
        },
        65: {
          // OKC_TESTNET
          endpoint:
            process.env.REACT_APP_OKC_TEST_ENDPOINT ||
            'https://exchaintestrpc.okex.org',
        },
        66: {
          // OKC
          endpoint:
            process.env.REACT_APP_OKC_ENDPOINT || 'https://exchainrpc.okex.org',
        },
        322: {
          //KCC TESTNET
          endpoint:
            process.env.REACT_APP_KCC_TEST_ENDPOINT ||
            'https://rpc-testnet.kcc.network',
        },
        321: {
          //KCC
          endpoint:
            process.env.REACT_APP_KCC_ENDPOINT ||
            'https://rpc-mainnet.kcc.network',
        },
        100: {
          //GNOSIS
          endpoint:
            process.env.REACT_APP_GNOSIS_ENDPOINT ||
            'https://gnosischain-rpc.gateway.pokt.network',
        },
        10200: {
          // GNOSIS_CHIADO
          endpoint:
            process.env.REACT_APP_CHIADO_TEST_ENDPOINT ||
            'https://rpc.chiadochain.net',
        },
        1284: {
          // MOONBEAM
          endpoint:
            process.env.REACT_APP_MOONBEAM_ENDPOINT ||
            'https://rpc.api.moonbeam.network',
        },
        1287: {
          // MOONBASE_ALPHA
          endpoint:
            process.env.REACT_APP_MOONBASE_TEST_ENDPOINT ||
            'https://rpc.api.moonbase.moonbeam.network',
        },
        1285: {
          // MOONRIVER
          endpoint:
            process.env.REACT_APP_MOONBASE_ENDPOINT ||
            'https://moonriver.public.blastapi.io',
        },
        42220: {
          // CELO
          endpoint:
            process.env.REACT_APP_CELO_ENDPOINT || 'https://forno.celo.org',
        },
        44787: {
          // CELO_ALFAJORES
          endpoint:
            process.env.REACT_APP_CELO_TEST_ENDPOINT ||
            'https://alfajores-forno.celo-testnet.org',
        },
        324: {
          // ZKSYNC_ERA
          endpoint:
            process.env.REACT_APP_ZKSYNC_ERA_ENDPOINT ||
            'https://mainnet.era.zksync.io',
        },
        280: {
          // ZKSYNC_ERA_TESTNET
          endpoint:
            process.env.REACT_APP_ZKSYNC_ERA_TESTNET_ENDPOINT ||
            'https://testnet.era.zksync.dev',
        },
        1101: {
          // ZKSYNC_ERA
          endpoint:
            process.env.REACT_APP_ZKSYNC_ERA_ENDPOINT ||
            'https://zkevm-rpc.com',
        },
        1442: {
          // POLYGON_ZKEVM_TESTNET
          endpoint:
            process.env.REACT_APP_POLYGON_ZKEVM_TESTNET_ENDPOINT ||
            'https://rpc.public.zkevm-test.net',
        },
        1666600000: {
          // HARMONEY
          endpoint:
            process.env.REACT_APP_HARMONEY_ENDPOINT || 'https://1rpc.io/one',
        },
        1666700000: {
          // HARMONEY_TESTNET
          endpoint:
            process.env.REACT_APP_HARMONY_TEST_ENDPOINT ||
            'https://api.s0.b.hmny.io',
        },
        114: {
          endpoint:
            process.env.REACT_APP_COTON_2 ||
            'https://coston2-api.flare.network/ext/C/rpc',
        },
      },
      network: process.env.REACT_APP_BLOCKCHAIN_NETWORK || 'mainnet',
    },
  },
};
export default config;
