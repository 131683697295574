/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  IContentHashResolver,
  IContentHashResolverInterface,
} from "../IContentHashResolver";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "node",
        type: "bytes32",
      },
      {
        indexed: false,
        internalType: "bytes",
        name: "hash",
        type: "bytes",
      },
    ],
    name: "SetContentHash",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "bytes",
        name: "host",
        type: "bytes",
      },
      {
        internalType: "bytes",
        name: "name",
        type: "bytes",
      },
      {
        internalType: "bytes",
        name: "tld",
        type: "bytes",
      },
    ],
    name: "getContentHash",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes",
        name: "host",
        type: "bytes",
      },
      {
        internalType: "bytes",
        name: "name",
        type: "bytes",
      },
      {
        internalType: "bytes",
        name: "tld",
        type: "bytes",
      },
      {
        internalType: "enum IContentHashResolver.ContentHashProtocol",
        name: "type_",
        type: "uint8",
      },
    ],
    name: "getTypedContentHash",
    outputs: [
      {
        internalType: "bytes",
        name: "",
        type: "bytes",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "host",
        type: "string",
      },
      {
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        internalType: "string",
        name: "tld",
        type: "string",
      },
      {
        internalType: "bytes",
        name: "hash",
        type: "bytes",
      },
    ],
    name: "setContentHash",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "string",
        name: "host",
        type: "string",
      },
      {
        internalType: "string",
        name: "name",
        type: "string",
      },
      {
        internalType: "string",
        name: "tld",
        type: "string",
      },
      {
        internalType: "enum IContentHashResolver.ContentHashProtocol",
        name: "type_",
        type: "uint8",
      },
      {
        internalType: "bytes",
        name: "hash",
        type: "bytes",
      },
    ],
    name: "setTypedContentHash",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
];

export class IContentHashResolver__factory {
  static readonly abi = _abi;
  static createInterface(): IContentHashResolverInterface {
    return new utils.Interface(_abi) as IContentHashResolverInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IContentHashResolver {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as IContentHashResolver;
  }
}
