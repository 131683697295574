import { IAllRecords } from '@/api/resolver/interface';
import { IRecord, ProfileRecordTable } from './ProfileRecordTable';
import { JSX, useMemo } from 'react';
import { SectionLayout } from '../common/SectionLayout';
import { useMyDomain } from '@/utils/context';

import { Web3Functions } from '../web3/Web3';
import { formatsByName } from '@ensdomains/address-encoder';

interface IProfileTabRecords {
  fqdn?: string;
  useWeb3?: Web3Functions;
  allRecords?: IAllRecords | undefined;
  emit?: (component: JSX.Element) => void;
  borderless?: boolean;
}

const ProfileTabRecords = (props: IProfileTabRecords) => {
  const { allRecords, borderless = false } = props;

  const { domain } = useMyDomain();
  if (domain === undefined) throw new Error('Domain Not Found');

  const coins = Object.values(formatsByName)
    .filter((c) => !c.name.match(/_LEGACY/))
    .reduce((acc, val) => {
      return { ...acc, [val.coinType.toString()]: val.name };
    }, {});

  const text: string = useMemo<string>(() => {
    if (!allRecords) return '';
    if (typeof allRecords?.textRecord === 'object') {
      return allRecords.textRecord.text;
    }
    return '';
  }, [allRecords]);

  const address: string = useMemo<string>(() => {
    if (!allRecords) return '0x0000000000000000000000000000000000000000';
    return allRecords.address;
  }, [allRecords]);

  //Get TypedText
  const textRecords: IRecord[] = useMemo<IRecord[]>(() => {
    const _records: IRecord[] = [];
    if (allRecords && allRecords.typedtextRecord) {
      console.log(Object.entries(allRecords.typedtextRecord));
      Object.entries(allRecords.typedtextRecord)[0].forEach(
        (val: any, index: number) => {
          if (val == '0') {
            return;
          }
          console.log(val);
          _records.push({ name: val.typed, value: val.text });
        },
      );
    }
    return _records;
  }, [allRecords]);

  //Get TypedAddress
  const addressRecords: IRecord[] = useMemo<IRecord[]>(() => {
    const _records: IRecord[] = [];
    if (allRecords && allRecords.typedAddresses) {
      Object.entries(allRecords.typedAddresses).forEach(([key]) => {
        _records.push({
          name: formatsByName[coins[key]].name,
          value: allRecords.typedAddresses[key],
        });
      });
    }
    return _records;
  }, [allRecords, coins]);

  return (
    <>
      <SectionLayout className="bg-white" borderless={borderless}>
        <div className="space-y-4">
          <ProfileRecordTable
            title="Text"
            records={[
              ...textRecords,
              {
                name: 'Default Text',
                value: text!,
              },
            ]}
          />
          <ProfileRecordTable
            title="Address"
            records={[
              ...addressRecords,
              {
                name: 'Default Address',
                value: address!,
              },
            ]}
          />
        </div>
      </SectionLayout>
    </>
  );
};

export { ProfileTabRecords };
