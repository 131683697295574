import { ReactNode } from 'react';

interface IAttributeProps {
  title: string;
  children: ReactNode;
  color?: string;
}
function Attribute(props: IAttributeProps) {
  const { title, children, color } = props;
  return (
    <div className=" grid w-full grid-cols-12 space-x-4">
      <span className={`col-span-1 font-semibold ${color}`}>{title}</span>
      <div className="col-span-11  truncate text-left">{children}</div>
    </div>
  );
}

export { Attribute };
