import { Attribute } from '@/components/CrossChain/Attribute';
import {
  CrossChainContext,
  MyDomainContext,
  SwappingState,
} from '@/utils/context';
import { StepTitle } from '@/components/CrossChain/StepTitle';
import { useContext, useState } from 'react';

function Step5() {
  const { selectedDomain: domain } = useContext(MyDomainContext);
  const [loading, setLoading] = useState(false);
  const {
    selectBlockChain,
    swappingState,
    step2Payload,
    setSwappingState,
    setSnackState,
    setIsOpenSnackbar,
    setSnackMessage,
    step4tx,
  } = useContext(CrossChainContext);

  if (
    !domain ||
    !selectBlockChain ||
    swappingState !== SwappingState.Done ||
    !step4tx
  ) {
    return <></>;
  }
  const textColor = 'text-[#93278f]';
  return (
    <>
      <StepTitle step={5} title="Cross Chain Complete" description={''} />
      <div className="w-full border"></div>
      <div className="flex flex-col space-y-4">
        <Attribute title="From" color={textColor}>
          {domain.blockchains[0].name}
        </Attribute>
        <Attribute title="To" color={textColor}>
          {selectBlockChain.name}
        </Attribute>
        <Attribute title="Tx" color={textColor}>
          {step4tx}
        </Attribute>
      </div>
    </>
  );
}

export { Step5 };
