import { AxiosInstance } from 'axios';
import config from '@/config';

export enum DomainSearchType {
  UNAVAILABLE = 'unavailable',
  AVAILABLE = 'available',
  TLDNOTFOUND = 'tldnotfound',
}

export interface ISearchDomain {
  name: string;
  tld_id: string;
  price: number;
  available?: DomainSearchType;
}
export interface IOutSearchDomain {
  recommend: ISearchDomain[];
}
export interface ISearchPremiumDomain {
  name: string;
  tld_id: string;
  price: number;
  available?: DomainSearchType;
  token: {
    deposit: number;
    bonus: number;
  };
}
export interface IOutSearchPremiumDomain {
  recommend: ISearchPremiumDomain[];
}
// "token": {
//   "deposit": 100,
//     "bonus": 100
// }
export const searchDomain =
  (instance: AxiosInstance) =>
  async (domain: string): Promise<IOutSearchDomain> => {
    const result = await instance.post<IOutSearchDomain>(
      config.api.router.domain.search,
      {
        domain,
      },
    );

    return result.data;
  };
