/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type {
  IRouterProtocolV1Provider,
  IRouterProtocolV1ProviderInterface,
} from "../IRouterProtocolV1Provider";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "ref",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes",
        name: "sender",
        type: "bytes",
      },
      {
        indexed: true,
        internalType: "bytes",
        name: "srcChainId",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "bytes",
        name: "payload",
        type: "bytes",
      },
    ],
    name: "MessageReceived",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes",
        name: "sender",
        type: "bytes",
      },
      {
        indexed: true,
        internalType: "bytes",
        name: "receiver",
        type: "bytes",
      },
      {
        indexed: true,
        internalType: "bytes",
        name: "dstChainId",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "bytes",
        name: "payload",
        type: "bytes",
      },
    ],
    name: "MessageSent",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "user",
        type: "address",
      },
    ],
    name: "getFeeToken",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getHandler",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes",
        name: "_payload",
        type: "bytes",
      },
    ],
    name: "receive_",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint8",
        name: "srcChainID",
        type: "uint8",
      },
      {
        internalType: "address",
        name: "srcAddress",
        type: "address",
      },
      {
        internalType: "bytes",
        name: "data",
        type: "bytes",
      },
    ],
    name: "routerReply",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address payable",
        name: "_sender",
        type: "address",
      },
      {
        internalType: "enum Chain",
        name: "_dstChain",
        type: "uint8",
      },
      {
        internalType: "bytes",
        name: "_payload",
        type: "bytes",
      },
    ],
    name: "send_",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_address",
        type: "address",
      },
    ],
    name: "setFeeToken",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address",
        name: "_handler",
        type: "address",
      },
    ],
    name: "setHandler",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class IRouterProtocolV1Provider__factory {
  static readonly abi = _abi;
  static createInterface(): IRouterProtocolV1ProviderInterface {
    return new utils.Interface(_abi) as IRouterProtocolV1ProviderInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IRouterProtocolV1Provider {
    return new Contract(
      address,
      _abi,
      signerOrProvider
    ) as IRouterProtocolV1Provider;
  }
}
