import { AxiosInstance } from 'axios';
import { ICart } from './cart.interface';
import { errorHandler } from '../../errorHandler';
import config from '@/config';

export const removeCartProduct =
  (instance: AxiosInstance) =>
  async (productId: string): Promise<ICart> => {
    try {
      const response = await instance.delete(
        `${config.api.router.cart.RemoveCartProduct}${productId}`,
      );
      return response.data;
    } catch (e) {
      throw errorHandler(e);
    }
  };
