import { DomainItems } from './DomainItems';
import { FilterBlockchain } from './FilterBlockchain';
import { Icon } from '@iconify/react';
import { MyDomainContext } from '@/utils/context';
import { SearchFilter } from './SearchFilter';
import { Tooltip } from '@mui/material';
import { useContext } from 'react';

interface IDisplayViewSelectedProps {
  isSelected: boolean;
  icon: string;
  title: string;
  onClick?: () => void;
}
const DisplayViewSelected = ({
  isSelected,
  title,
  icon,
  onClick,
}: IDisplayViewSelectedProps) => {
  return (
    <Tooltip title={title} placement={'top'} arrow>
      <div onClick={onClick}>
        <Icon
          icon={icon}
          className={`h-8 w-auto cursor-pointer content-center border p-1 font-bold hover:bg-purple-400 ${
            isSelected ? 'bg-edns text-white' : 'text-edns'
          }`}
        />
      </div>
    </Tooltip>
  );
};
interface IDomainListProps {
  title?: string;
  hiddenSearch?: boolean;
  count?: number;
}
function DomainList(props: IDomainListProps) {
  const { title = 'Domains', hiddenSearch = false, count = 0 } = props;
  const { displayView, setDisplayView } = useContext(MyDomainContext);
  const viewClass = {
    grid: 'grid w-full grid-cols-1 md:grid-cols-2 gap-4 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5',
    list: 'flex flex-col gap-2',
  };
  return (
    <section className="mb-10 flex w-full flex-1 flex-col gap-5 overflow-hidden rounded-3xl border bg-white p-6">
      <div className="flex justify-between">
        <div>
          <div className="flex space-x-4">
            <span className="text-2xl font-semibold">{title}</span>

            {!hiddenSearch && <SearchFilter />}
          </div>
        </div>
        <div className="flex items-center gap-2">
          <div className="flex">
            <DisplayViewSelected
              isSelected={displayView === 'grid'}
              title={'Grid View'}
              icon={'ion:grid'}
              onClick={() => setDisplayView('grid')}
            />

            <DisplayViewSelected
              isSelected={displayView === 'list'}
              title={'List View'}
              icon={'ph:list-fill'}
              onClick={() => setDisplayView('list')}
            />
          </div>
        </div>
      </div>
      <FilterBlockchain />
      <div className={viewClass[displayView]}>
        <DomainItems count={count} />
      </div>
    </section>
  );
}

export { DomainList };
