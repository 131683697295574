import { Button } from '@/components/CheckoutRedirect/PaymentResult';
import { Icon } from '@iconify/react';
import { useLocation } from 'react-router-dom';
import Logo from '@/components/common/Logo';

const NotFoundPage = () => {
  const { state } = useLocation();
  const message = (state as any).message || 'Order Not Found';

  return (
    <div className="w-full overflow-y-auto scroll-smooth">
      <div className="mx-auto w-auto p-6 lg:w-[35rem]">
        <div className="relative flex flex-col items-center space-y-4 rounded-xl bg-white py-8 shadow-md">
          <Logo />

          <Icon
            icon="ph:question-fill"
            className="mx-auto h-20 w-auto text-red-500"
          />
          <div className="mt-5 px-8 text-xl font-semibold text-red-500">
            {message}
          </div>
          <div className="flex items-center space-x-8 py-2">
            <Button url="/" text="Back" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
