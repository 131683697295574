import { AxiosInstance } from 'axios';
import { IBlockchain } from './blockchain.interface';
import config from '@/config';

interface IListBlockChain {
  count: number;
  limit: number;
  skip: number;
  data: IBlockchain[];
}
export const listBlockChain =
  (instance: AxiosInstance) => async (): Promise<IListBlockChain> => {
    const blockchains = await instance.get<IListBlockChain>(
      `${config.api.router.blockchain.list}`,
    );
    return blockchains.data;
  };
