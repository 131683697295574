import { AxiosInstance } from 'axios';
import { errorHandler } from '@/api/errorHandler';
import config from '@/config';

interface IMixPay {
  id: string;
  status: string;
  url: string;
}
export const createMixPayment =
  (instance: AxiosInstance) =>
  async (orderId: string): Promise<IMixPay> => {
    try {
      const res = await instance.post<IMixPay>(
        `${config.api.router.payment.mixpay}${orderId}/mixpay`,
        {},
      );
      return res.data;
    } catch (e) {
      throw errorHandler(e);
    }
  };
