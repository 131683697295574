import { IPaymentOrder } from '@/api/omni/Order/order.interface';
import { Icon } from '@iconify/react';
import {
  PaymentResult,
  PaymentResultSkeleton,
} from '@/components/CheckoutRedirect/PaymentResult';
import { getPayment } from '@/api/omni/Order/getPayment';
import { useAxios } from '@/utils/axios/AxiosProvider';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const FailPage = () => {
  const params = useParams();
  const { instance } = useAxios();

  const [paymentOrder, setPaymentOrder] = useState<IPaymentOrder | undefined>();
  const navigate = useNavigate();

  useEffect(() => {
    async function exec() {
      if (params.id) {
        const orderId = params.id;
        try {
          const _order = await getPayment(instance)({ orderId });
          setPaymentOrder(_order);
          console.log('Order', _order);
        } catch (err: any) {
          // redirect to login page
          navigate('/payment/404', {
            state: {
              message: err.message,
            },
          });
        }
      }
    }
    exec();
  }, [navigate, params.id]);

  return (
    <>
      {paymentOrder ? (
        <PaymentResult order={paymentOrder}>
          <Icon
            icon="material-symbols:error"
            className="mx-auto h-20 w-auto text-orange-500"
          />
          <div className="mt-5 text-xl font-semibold text-orange-500">
            Payment Failed
          </div>
        </PaymentResult>
      ) : (
        <PaymentResultSkeleton />
      )}
    </>
  );
};

export default FailPage;
