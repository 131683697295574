function HomeLogo() {
  return (
    <div className="mb-[calc(1vw+3rem)] flex gap-[3vw] md:max-w-[calc(100vw-332px-5rem)]">
      <svg
        className="home-logo z-[80] !w-[70dvw] md:!w-[calc(20vw+2rem)] "
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 250.333 80.085"
      >
        <defs>
          <clipPath id="clip-path">
            <rect
              id="Rectangle_331"
              data-name="Rectangle 331"
              width="250.333"
              height="73.085"
              fill="none"
            />
          </clipPath>
        </defs>
        <g id="Group_413" data-name="Group 413" clipPath="url(#clip-path)">
          <path
            id="Path_120"
            data-name="Path 120"
            d="M52.039,63.5H26.007a5.1,5.1,0,0,1-5.09-5.09V55.915a5.1,5.1,0,0,1,5.09-5.09H52.039a5.1,5.1,0,0,1,5.09,5.09v2.494a5.1,5.1,0,0,1-5.09,5.09"
            transform="translate(-4.151 -10.085)"
            className="main fill"
          />
          <path
            id="Path_121"
            data-name="Path 121"
            d="M46.806,25.186l26.027-.513a5.1,5.1,0,0,1,5.189,4.989l.05,2.494a5.106,5.106,0,0,1-4.989,5.189l-26.028.513a5.106,5.106,0,0,1-5.19-4.989l-.049-2.494a5.106,5.106,0,0,1,4.99-5.189"
            transform="matrix(1, 0.017, -0.017, 1, -7.743, -5.937)"
            className="theme fill"
          />
          <path
            id="Path_122"
            data-name="Path 122"
            d="M51.662,12.674H25.63a5.1,5.1,0,0,1-5.09-5.09V5.09A5.1,5.1,0,0,1,25.63,0H51.662a5.1,5.1,0,0,1,5.09,5.09V7.584a5.1,5.1,0,0,1-5.09,5.09"
            transform="translate(-4.076 0)"
            className="main fill"
          />
          <path
            id="Path_123"
            data-name="Path 123"
            d="M17.519,56.092,1.352,35.688a5.105,5.105,0,0,1,.828-7.151l1.955-1.549a5.106,5.106,0,0,1,7.151.829l16.167,20.4a5.105,5.105,0,0,1-.828,7.151l-1.955,1.549a5.1,5.1,0,0,1-7.151-.829"
            transform="translate(-0.051 -5.138)"
            className="main fill"
          />
          <path
            id="Path_124"
            data-name="Path 124"
            d="M26.528,10.235,11.219,31.291a5.1,5.1,0,0,1-7.11,1.124L2.091,30.948A5.105,5.105,0,0,1,.967,23.837L16.276,2.781a5.105,5.105,0,0,1,7.111-1.123L25.4,3.124a5.105,5.105,0,0,1,1.124,7.111"
            transform="translate(0 -0.137)"
            className="main fill"
          />
          <path
            id="Path_125"
            data-name="Path 125"
            d="M48.937,75.24l26.028-.513a5.106,5.106,0,0,1,5.189,4.989l.048,2.494A5.105,5.105,0,0,1,75.213,87.4l-26.027.513A5.106,5.106,0,0,1,44,82.923l-.049-2.494a5.106,5.106,0,0,1,4.99-5.189"
            transform="matrix(1, 0.017, -0.017, 1, -7.292, -15.899)"
            className="theme fill"
          />
          <path
            id="Path_126"
            data-name="Path 126"
            d="M83.338,27.176,99.9,47.258a5.1,5.1,0,0,1-.688,7.165L97.292,56.01a5.105,5.105,0,0,1-7.166-.687L73.56,35.242a5.106,5.106,0,0,1,.688-7.166l1.924-1.587a5.106,5.106,0,0,1,7.166.688"
            transform="translate(-14.367 -5.026)"
            className="theme fill"
          />
          <path
            id="Path_127"
            data-name="Path 127"
            d="M75.524,72.992,90.414,51.639A5.106,5.106,0,0,1,97.5,50.376L99.547,51.8a5.106,5.106,0,0,1,1.263,7.087L85.92,80.242a5.1,5.1,0,0,1-7.087,1.263l-2.046-1.427a5.1,5.1,0,0,1-1.263-7.087"
            transform="translate(-14.806 -9.815)"
            className="theme fill"
          />
          <path
            id="Path_128"
            data-name="Path 128"
            d="M78.871,12.22h-7.61a2.54,2.54,0,0,1-2.532-2.532v-6.4A2.54,2.54,0,0,1,71.261.753h7.61A2.539,2.539,0,0,1,81.4,3.285v6.4a2.54,2.54,0,0,1-2.532,2.532"
            transform="translate(-13.638 -0.149)"
            className="theme fill"
          />
          <path
            id="Path_129"
            data-name="Path 129"
            d="M34.447,88.269h-7.61a2.54,2.54,0,0,1-2.532-2.532v-6.4A2.54,2.54,0,0,1,26.837,76.8h7.61a2.539,2.539,0,0,1,2.532,2.532v6.4a2.54,2.54,0,0,1-2.532,2.532"
            transform="translate(-4.823 -15.24)"
            className="main fill"
          />
          <path
            id="Path_130"
            data-name="Path 130"
            d="M123.978,58.2h8.881a27.39,27.39,0,0,1,6.13.666,14.591,14.591,0,0,1,5.067,2.157,10.806,10.806,0,0,1,3.436,3.876,12.16,12.16,0,0,1,1.272,5.787A11.067,11.067,0,0,1,147.53,76a11.64,11.64,0,0,1-3.322,3.858,15.116,15.116,0,0,1-4.821,2.368,20.143,20.143,0,0,1-5.732.806h-9.678ZM129.9,77.985h3.075a17.838,17.838,0,0,0,3.814-.386,8.5,8.5,0,0,0,3.056-1.28,6.251,6.251,0,0,0,2.03-2.35,7.773,7.773,0,0,0,.741-3.56,6.307,6.307,0,0,0-2.734-5.4,9.05,9.05,0,0,0-2.942-1.315,14.056,14.056,0,0,0-3.549-.438H129.9Z"
            transform="translate(-24.601 -11.549)"
            className="main fill"
          />
          <path
            id="Path_131"
            data-name="Path 131"
            d="M158.166,76.336a8.35,8.35,0,0,1,.8-3.682,8.521,8.521,0,0,1,2.183-2.823,9.8,9.8,0,0,1,3.264-1.807,13.008,13.008,0,0,1,8.008,0,9.806,9.806,0,0,1,3.264,1.807,8.52,8.52,0,0,1,2.183,2.823,8.908,8.908,0,0,1,0,7.365,8.515,8.515,0,0,1-2.183,2.824,9.818,9.818,0,0,1-3.264,1.806,13.008,13.008,0,0,1-8.008,0,9.81,9.81,0,0,1-3.264-1.806,8.515,8.515,0,0,1-2.183-2.824,8.345,8.345,0,0,1-.8-3.682m5.694,0a4.214,4.214,0,0,0,1.2,3.121,5.328,5.328,0,0,0,6.72,0,4.673,4.673,0,0,0,0-6.243,5.328,5.328,0,0,0-6.72,0,4.217,4.217,0,0,0-1.2,3.121"
            transform="translate(-31.386 -13.373)"
            className="main fill"
          />
          <path
            id="Path_132"
            data-name="Path 132"
            d="M187.9,67.813h5.466v2.315h.076a4.288,4.288,0,0,1,.759-.982,5.474,5.474,0,0,1,1.177-.877,7.013,7.013,0,0,1,3.455-.877,7.689,7.689,0,0,1,3.435.737,4.821,4.821,0,0,1,2.259,2.315,5.9,5.9,0,0,1,2.391-2.35,7.683,7.683,0,0,1,3.455-.7,7.55,7.55,0,0,1,3.131.579,5.038,5.038,0,0,1,2.012,1.578,6.412,6.412,0,0,1,1.063,2.35,12.439,12.439,0,0,1,.323,2.894V84.858h-5.694V74.933a3.729,3.729,0,0,0-.551-2.052,2.141,2.141,0,0,0-1.955-.859,4.028,4.028,0,0,0-1.651.3,2.67,2.67,0,0,0-1.063.824,3.312,3.312,0,0,0-.57,1.228,6.205,6.205,0,0,0-.17,1.472v9.014h-5.694V75.845q0-.456-.038-1.122a4.181,4.181,0,0,0-.266-1.262,2.412,2.412,0,0,0-.74-1.017,2.314,2.314,0,0,0-1.5-.421,3.756,3.756,0,0,0-1.784.368,2.614,2.614,0,0,0-1.044.982,3.779,3.779,0,0,0-.475,1.386,11,11,0,0,0-.115,1.613v8.488H187.9Z"
            transform="translate(-37.287 -13.373)"
            className="main fill"
          />
          <path
            id="Path_133"
            data-name="Path 133"
            d="M240.455,82.72h-.076a5.274,5.274,0,0,1-2.524,1.964,9.5,9.5,0,0,1-5.807.263,6.462,6.462,0,0,1-2.107-1A4.93,4.93,0,0,1,228.5,82.3a4.718,4.718,0,0,1-.531-2.28,4.9,4.9,0,0,1,.588-2.49,5.005,5.005,0,0,1,1.594-1.683,8.2,8.2,0,0,1,2.3-1.035,17.871,17.871,0,0,1,2.676-.543,27.563,27.563,0,0,1,2.772-.211q1.384-.035,2.562-.035a2.653,2.653,0,0,0-1.082-2.227,4.107,4.107,0,0,0-2.562-.825,5.955,5.955,0,0,0-2.563.544A6.971,6.971,0,0,0,232.18,73l-3.036-2.876a10.655,10.655,0,0,1,3.72-2.051,14.28,14.28,0,0,1,4.4-.685,12.361,12.361,0,0,1,4.118.58,6.005,6.005,0,0,1,2.582,1.7,6.334,6.334,0,0,1,1.347,2.753,16.734,16.734,0,0,1,.38,3.771v8.663h-5.238Zm-1.4-5.331c-.431,0-.967.018-1.614.052a8.8,8.8,0,0,0-1.86.3,4.257,4.257,0,0,0-1.518.736,1.642,1.642,0,0,0-.627,1.368,1.489,1.489,0,0,0,.874,1.4,3.883,3.883,0,0,0,1.822.456,6.156,6.156,0,0,0,1.613-.211,5,5,0,0,0,1.386-.6,2.98,2.98,0,0,0,.967-.982,2.657,2.657,0,0,0,.361-1.4V77.388Z"
            transform="translate(-45.236 -13.373)"
            className="main fill"
          />
          <path
            id="Path_134"
            data-name="Path 134"
            d="M254.059,60.468a2.833,2.833,0,0,1,.968-2.157,3.5,3.5,0,0,1,4.669,0,2.888,2.888,0,0,1,0,4.314,3.494,3.494,0,0,1-4.669,0,2.833,2.833,0,0,1-.968-2.157m.456,5.366h5.694V82.88h-5.694Z"
            transform="translate(-50.414 -11.393)"
            className="main fill"
          />
          <path
            id="Path_135"
            data-name="Path 135"
            d="M266.845,67.813h5.466v2.315h.076a4.287,4.287,0,0,1,.759-.982,5.474,5.474,0,0,1,1.177-.877,7.012,7.012,0,0,1,3.455-.877,8.409,8.409,0,0,1,3.492.614,4.94,4.94,0,0,1,2.069,1.7,6.565,6.565,0,0,1,1.006,2.56,18.08,18.08,0,0,1,.265,3.192v9.4h-5.694V76.511q0-.736-.057-1.525a4.5,4.5,0,0,0-.341-1.456,2.537,2.537,0,0,0-.874-1.087,2.877,2.877,0,0,0-1.688-.421,3.756,3.756,0,0,0-1.784.368,2.614,2.614,0,0,0-1.044.982,3.779,3.779,0,0,0-.475,1.386,11.007,11.007,0,0,0-.115,1.613v8.488h-5.694Z"
            transform="translate(-52.951 -13.373)"
            className="main fill"
          />
          <path
            id="Path_136"
            data-name="Path 136"
            d="M304.192,72.829a4.168,4.168,0,0,0-3.3-1.438,3.308,3.308,0,0,0-1.4.316,1.105,1.105,0,0,0-.683,1.087.983.983,0,0,0,.7.93,9.8,9.8,0,0,0,1.784.526q1.082.228,2.315.491a9.148,9.148,0,0,1,2.316.824A5.051,5.051,0,0,1,307.7,77.09a4.29,4.29,0,0,1,.7,2.578,4.5,4.5,0,0,1-.778,2.718,5.792,5.792,0,0,1-1.993,1.719,9.038,9.038,0,0,1-2.732.912,17.535,17.535,0,0,1-3,.263,15.656,15.656,0,0,1-3.909-.508,7.446,7.446,0,0,1-3.34-1.807l3.454-3.542a5.624,5.624,0,0,0,1.765,1.385,5.08,5.08,0,0,0,2.259.474,5.673,5.673,0,0,0,1.784-.263.985.985,0,0,0,.8-.965,1.068,1.068,0,0,0-.7-1,8.142,8.142,0,0,0-1.784-.562q-1.082-.227-2.316-.491a10.055,10.055,0,0,1-2.315-.79,4.721,4.721,0,0,1-1.784-1.49,4.287,4.287,0,0,1-.7-2.578,4.886,4.886,0,0,1,.664-2.6,5.58,5.58,0,0,1,1.747-1.789,7.868,7.868,0,0,1,2.486-1.034,12.285,12.285,0,0,1,2.846-.333,14.394,14.394,0,0,1,3.682.491,6.962,6.962,0,0,1,3.15,1.754Z"
            transform="translate(-58.072 -13.373)"
            className="main fill"
          />
          <path
            id="Path_137"
            data-name="Path 137"
            d="M124.06,2.509h24.676V9.9H132.063v6.772h15.75v7.388h-15.75v7.387h17.6v7.387h-25.6Z"
            transform="translate(-24.618 -0.498)"
            className="main fill"
          />
          <path
            id="Path_138"
            data-name="Path 138"
            d="M164,2.509h12a34.288,34.288,0,0,1,8.285.975,18.972,18.972,0,0,1,6.849,3.155,15.463,15.463,0,0,1,4.643,5.669,19.015,19.015,0,0,1,1.719,8.465,17.275,17.275,0,0,1-1.667,7.772,16.714,16.714,0,0,1-4.489,5.643,19.842,19.842,0,0,1-6.516,3.463,25.288,25.288,0,0,1-7.746,1.18H164Zm8,28.934h4.155a22.344,22.344,0,0,0,5.156-.564,11.027,11.027,0,0,0,4.13-1.872,8.974,8.974,0,0,0,2.744-3.437,12.176,12.176,0,0,0,1-5.207,10.144,10.144,0,0,0-1-4.643,9.279,9.279,0,0,0-2.692-3.258,11.786,11.786,0,0,0-3.977-1.924,17.639,17.639,0,0,0-4.8-.642H172Z"
            transform="translate(-32.543 -0.498)"
            className="main fill"
          />
          <path
            id="Path_139"
            data-name="Path 139"
            d="M212.574,2.509h10.876L238.892,27.75h.1V2.509h8V38.831H236.532L220.679,12.975h-.1V38.831h-8Z"
            transform="translate(-42.182 -0.498)"
            className="main fill"
          />
          <path
            id="Path_140"
            data-name="Path 140"
            d="M282.016,11.156a5.705,5.705,0,0,0-2.642-1.821,9.486,9.486,0,0,0-3.156-.59,8.289,8.289,0,0,0-1.795.205,6.893,6.893,0,0,0-1.744.641,3.8,3.8,0,0,0-1.334,1.154,2.922,2.922,0,0,0-.514,1.744A2.89,2.89,0,0,0,272.063,15a11.653,11.653,0,0,0,3.1,1.488q1.872.616,4.027,1.231a16.623,16.623,0,0,1,4.027,1.744,9.547,9.547,0,0,1,3.1,3.028,9.224,9.224,0,0,1,1.231,5.079,11.681,11.681,0,0,1-1.129,5.284,10.718,10.718,0,0,1-3.052,3.745,12.975,12.975,0,0,1-4.463,2.206,20.471,20.471,0,0,1-11.928-.359,16.449,16.449,0,0,1-5.643-3.488l5.694-6.259a8.3,8.3,0,0,0,3.1,2.539,8.861,8.861,0,0,0,3.874.9,8.772,8.772,0,0,0,1.975-.231,6.44,6.44,0,0,0,1.8-.693,3.875,3.875,0,0,0,1.283-1.18,2.943,2.943,0,0,0,.487-1.693,3.072,3.072,0,0,0-1.258-2.591,11.621,11.621,0,0,0-3.154-1.617q-1.9-.665-4.1-1.334a18.391,18.391,0,0,1-4.1-1.795,9.9,9.9,0,0,1-3.155-2.976,8.514,8.514,0,0,1-1.257-4.874,10.911,10.911,0,0,1,1.154-5.13,11.149,11.149,0,0,1,3.078-3.694A13.4,13.4,0,0,1,271.191,2.1a18.187,18.187,0,0,1,5.181-.744,20.386,20.386,0,0,1,5.952.872A13.741,13.741,0,0,1,287.5,5.154Z"
            transform="translate(-51.859 -0.269)"
            className="main fill"
          />
          <path
            id="Path_141"
            data-name="Path 141"
            d="M306.288,45.2h-5.25a1.907,1.907,0,0,1-1.9-1.9v-5.25a1.906,1.906,0,0,1,1.9-1.9h5.25a1.906,1.906,0,0,1,1.9,1.9v5.25a1.907,1.907,0,0,1-1.9,1.9"
            transform="translate(-59.359 -7.172)"
            className="main fill"
          />
        </g>
      </svg>
      <svg
        className="hidden h-auto w-[calc(24vw+2rem)] md:block"
        viewBox="0 0 509 368"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_70_113170)">
          <path
            d="M471.3 38.2124V47.6287C471.3 48.0666 471.081 48.5046 470.641 48.8331L454.594 58.1398C453.165 59.0158 451.297 59.0158 449.868 58.1398L433.93 48.8331C433.491 48.6141 433.271 48.0666 433.271 47.6287H433.161V38.2124H471.3Z"
            fill="url(#paint0_linear_70_113170)"
          />
          <path
            d="M454.594 48.6141L470.641 39.3074C471.52 38.7599 471.52 37.446 470.641 36.8986L454.484 27.5918C453.165 26.8254 451.516 26.8254 450.197 27.5918L433.93 37.008C433.051 37.5555 433.051 38.7599 433.93 39.3074L449.868 48.6141C451.296 49.49 453.055 49.49 454.594 48.6141Z"
            fill="#F0F0FC"
          />
          <path
            d="M456.572 51.2419C456.243 51.4609 456.023 51.8988 456.023 52.3368C456.023 52.7748 456.243 52.9938 456.572 52.7748L466.904 46.7528V45.2199L456.572 51.2419Z"
            fill="#212D60"
          />
          <path
            d="M468.333 44.4534L466.904 45.3294V46.8622L468.333 45.9863C468.663 45.7673 468.882 45.3294 468.882 44.8914C468.882 44.4534 468.663 44.2344 468.333 44.4534Z"
            fill="#212D60"
          />
          <path
            d="M450.197 45.5483L438.767 38.9789C438.107 38.6504 438.107 37.665 438.767 37.3365L450.307 30.6575C451.296 30.1101 452.396 30.1101 453.385 30.6575L465.035 37.446C465.695 37.7745 465.695 38.7599 465.035 39.0884L453.605 45.6578C452.615 46.2053 451.296 46.2053 450.197 45.5483Z"
            fill="#212D60"
          />
          <path
            d="M438.766 38.2124L450.307 31.5335C451.296 30.986 452.395 30.986 453.385 31.5335L465.035 38.2124C465.145 38.3219 465.255 38.4314 465.365 38.5409C465.585 38.1029 465.475 37.5555 465.035 37.227L453.385 30.548C452.395 30.0006 451.296 30.0006 450.307 30.548L438.766 37.227C438.327 37.5555 438.107 38.1029 438.437 38.5409C438.546 38.4314 438.656 38.3219 438.766 38.2124Z"
            fill="url(#paint1_linear_70_113170)"
          />
          <path
            d="M456.023 35.6941C454.154 34.7087 451.626 34.4897 449.428 35.0372V36.3511C450.967 35.6941 453.165 35.6941 454.704 36.5701C456.242 37.446 456.242 38.9789 454.704 39.8548C453.165 40.7307 450.637 40.7307 448.988 39.8548L447.559 40.6212C449.868 41.9351 453.714 41.9351 455.913 40.6212C458.331 39.1978 458.331 37.008 456.023 35.6941Z"
            fill="url(#paint2_linear_70_113170)"
          />
          <path
            d="M441.954 51.8988L440.855 51.2419C440.635 51.1324 440.525 50.8039 440.525 50.5849C440.525 50.366 440.635 50.2565 440.855 50.366L441.954 51.0229C442.174 51.1324 442.284 51.4609 442.284 51.6798C442.284 51.8988 442.174 52.0083 441.954 51.8988Z"
            fill="url(#paint3_linear_70_113170)"
          />
          <path
            d="M372.38 90.2207C372.27 90.2207 372.16 90.2208 372.16 90.1113C372.05 90.0018 372.16 89.7828 372.27 89.7828L441.404 51.0229C441.514 50.9134 441.734 51.0229 441.734 51.1324C441.844 51.2419 441.734 51.4609 441.624 51.4609L372.49 90.2207H372.38Z"
            fill="url(#paint4_linear_70_113170)"
          />
          <path
            opacity="0.43"
            d="M507.022 205.187L475.257 187.011C472.07 185.259 468.223 185.259 465.145 187.011L434.15 204.639V215.807L460.199 231.136H475.587L507.131 211.975C509.659 210.442 509.659 206.719 507.022 205.187Z"
            fill="#0C25BA"
          />
          <path
            d="M427.226 158.872V211.866C427.226 212.632 427.665 213.508 428.435 213.946L456.133 230.041C458.66 231.464 461.738 231.464 464.376 230.041L491.964 214.055C492.733 213.617 493.173 212.741 493.063 211.975L493.283 159.2L427.226 158.872Z"
            fill="url(#paint5_linear_70_113170)"
          />
          <path
            d="M456.133 176.938L428.435 160.843C426.896 159.967 426.896 157.667 428.435 156.791L456.353 140.587C458.661 139.273 461.518 139.273 463.827 140.587L492.074 156.901C493.613 157.777 493.832 159.857 492.294 160.733L464.486 176.938C461.848 178.361 458.661 178.361 456.133 176.938Z"
            fill="#F0F0FC"
          />
          <path
            d="M431.732 207.157V167.303C431.732 167.193 431.732 167.084 431.732 167.084C431.073 166.865 430.303 167.303 430.303 168.069V207.924C430.303 208.581 430.633 209.128 431.183 209.457L455.363 223.472C455.913 223.8 456.572 223.362 456.572 222.815V222.596L432.611 208.69C432.062 208.252 431.732 207.705 431.732 207.157Z"
            fill="url(#paint6_linear_70_113170)"
          />
          <path
            d="M455.363 180.661L431.952 167.084C431.842 167.084 431.842 166.974 431.732 166.974C431.732 167.084 431.732 167.084 431.732 167.193V207.048C431.732 207.705 432.062 208.252 432.611 208.581L456.572 222.486V182.741C456.462 181.865 456.023 181.098 455.363 180.661Z"
            fill="#212D60"
          />
          <path
            d="M439.206 205.953V208.909L436.568 207.376L436.678 204.42L439.206 205.953Z"
            fill="#FF7364"
          />
          <path
            d="M439.316 199.931V202.887L436.678 201.354V198.398L439.316 199.931Z"
            fill="#5A3B72"
          />
          <path
            d="M439.426 193.909L439.316 196.865L436.788 195.332V192.376L439.426 193.909Z"
            fill="#5A3B72"
          />
          <path
            d="M439.426 187.887V190.843L436.788 189.31V186.354L439.426 187.887Z"
            fill="#ED3BEF"
          />
          <path
            opacity="0.2"
            d="M439.536 181.865L439.426 184.821L436.898 183.288V180.332L439.536 181.865Z"
            fill="#9FAFFC"
          />
          <path
            opacity="0.2"
            d="M439.536 175.843V178.799L436.898 177.266L437.008 174.31L439.536 175.843Z"
            fill="#9FAFFC"
          />
          <path
            opacity="0.2"
            d="M443.273 208.362L443.163 211.318L440.635 209.785V206.829L443.273 208.362Z"
            fill="#9FAFFC"
          />
          <path
            d="M443.273 202.34V205.296L440.635 203.763L440.745 200.807L443.273 202.34Z"
            fill="#FF7364"
          />
          <path
            d="M443.383 196.318V199.274L440.745 197.741V194.785L443.383 196.318Z"
            fill="#FFD33F"
          />
          <path
            d="M443.383 190.296V193.252L440.745 191.719L440.855 188.763L443.383 190.296Z"
            fill="#5A3B72"
          />
          <path
            opacity="0.2"
            d="M443.493 184.274V187.23L440.855 185.697V182.741L443.493 184.274Z"
            fill="#9FAFFC"
          />
          <path
            d="M447.34 210.771L447.23 213.727L444.702 212.194V209.238L447.34 210.771Z"
            fill="#5A3B72"
          />
          <path
            d="M447.34 204.749V207.705L444.702 206.172L444.812 203.216L447.34 204.749Z"
            fill="#5A3B72"
          />
          <path
            opacity="0.2"
            d="M447.45 198.727V201.683L444.812 200.15V197.194L447.45 198.727Z"
            fill="#9FAFFC"
          />
          <path
            d="M447.45 192.705V195.661L444.812 194.128L444.922 191.172L447.45 192.705Z"
            fill="#FD256F"
          />
          <path
            d="M447.56 186.683V189.639L444.922 188.106V185.15L447.56 186.683Z"
            fill="#5A3B72"
          />
          <path
            d="M451.077 212.96V216.026L448.439 214.384V211.428L451.077 212.96Z"
            fill="#ED3BEF"
          />
          <path
            d="M451.187 206.938L451.077 210.004L448.549 208.471V205.406L451.187 206.938Z"
            fill="#FF7364"
          />
          <path
            d="M451.187 200.916V203.982L448.549 202.449L448.659 199.384L451.187 200.916Z"
            fill="#5A3B72"
          />
          <path
            d="M451.297 195.004V197.96L448.659 196.427V193.362L451.297 195.004Z"
            fill="#5A3B72"
          />
          <path
            d="M451.406 188.982L451.297 191.938L448.769 190.405V187.34L451.406 188.982Z"
            fill="#5A3B72"
          />
          <path
            d="M451.406 182.96V185.916L448.769 184.383V181.318L451.406 182.96Z"
            fill="#FFD33F"
          />
          <path
            d="M479.214 219.311L480.313 218.654C480.533 218.545 480.643 218.216 480.643 217.997C480.643 217.778 480.533 217.669 480.313 217.778L479.214 218.435C478.994 218.545 478.884 218.873 478.884 219.092C478.884 219.311 478.994 219.42 479.214 219.311Z"
            fill="url(#paint7_linear_70_113170)"
          />
          <path
            d="M403.485 289.057L403.265 288.728L500.207 230.698L479.654 218.763L479.873 218.326L501.086 230.589L500.757 230.808L403.485 289.057Z"
            fill="url(#paint8_linear_70_113170)"
          />
          <path
            opacity="0.43"
            d="M442.064 136.864L425.687 127.338C424.038 126.353 422.06 126.353 420.411 127.338L399.418 139.492L417.883 150.441H425.797L442.064 140.368C443.383 139.601 443.383 137.63 442.064 136.864Z"
            fill="#0C25BA"
          />
          <path
            d="M437.448 128.214V138.506C437.448 139.054 437.228 139.492 436.678 139.82L419.202 149.893C417.554 150.769 415.685 150.769 414.036 149.893L396.67 139.82C396.121 139.492 395.901 139.054 396.011 138.506H395.901V128.214H437.448Z"
            fill="url(#paint9_linear_70_113170)"
          />
          <path
            d="M419.202 139.492L436.678 129.419C437.667 128.871 437.667 127.448 436.678 126.791L419.092 116.608C417.664 115.732 415.905 115.732 414.366 116.608L396.561 126.9C395.571 127.448 395.571 128.871 396.561 129.419L413.927 139.492C415.685 140.368 417.664 140.368 419.202 139.492Z"
            fill="#F0F0FC"
          />
          <path
            d="M421.4 142.339C421.071 142.558 420.741 143.105 420.741 143.543C420.741 143.981 420.961 144.2 421.4 143.981L432.611 137.411V135.769L421.4 142.339Z"
            fill="#212D60"
          />
          <path
            d="M434.26 134.893L432.721 135.769V137.411L434.37 136.536C434.7 136.317 435.03 135.769 435.03 135.331C434.92 134.893 434.59 134.784 434.26 134.893Z"
            fill="#212D60"
          />
          <path
            d="M437.448 114.856V125.148C437.448 125.696 437.228 126.134 436.678 126.462L419.202 136.536C417.554 137.412 415.685 137.412 414.036 136.536L396.67 126.462C396.121 126.134 395.901 125.696 396.011 125.148H395.901V114.856H437.448Z"
            fill="url(#paint10_linear_70_113170)"
          />
          <path
            d="M419.202 126.134L436.678 116.061C437.667 115.513 437.667 114.09 436.678 113.433L419.092 103.25C417.664 102.374 415.905 102.374 414.366 103.25L396.561 113.542C395.571 114.09 395.571 115.513 396.561 116.061L413.927 126.134C415.685 127.01 417.664 127.01 419.202 126.134Z"
            fill="#F0F0FC"
          />
          <path
            d="M421.4 128.981C421.071 129.2 420.741 129.747 420.741 130.185C420.741 130.623 420.961 130.842 421.4 130.623L432.611 124.054V122.411L421.4 128.981Z"
            fill="#212D60"
          />
          <path
            d="M434.26 121.535L432.721 122.411V124.054L434.37 123.178C434.7 122.959 435.03 122.411 435.03 121.973C434.92 121.535 434.59 121.316 434.26 121.535Z"
            fill="#212D60"
          />
          <path
            d="M437.448 101.389V111.681C437.448 112.228 437.228 112.666 436.678 112.995L419.202 123.068C417.554 123.944 415.685 123.944 414.036 123.068L396.67 112.995C396.121 112.666 395.901 112.228 396.011 111.681H395.901V101.389H437.448Z"
            fill="url(#paint11_linear_70_113170)"
          />
          <path
            d="M419.202 112.666L436.678 102.593C437.667 102.046 437.667 100.622 436.678 99.9655L419.092 89.7828C417.664 88.9069 415.905 88.9069 414.366 89.7828L396.561 100.075C395.571 100.622 395.571 102.046 396.561 102.593L413.927 112.666C415.685 113.652 417.664 113.652 419.202 112.666Z"
            fill="#F0F0FC"
          />
          <path
            d="M421.4 115.623C421.071 115.842 420.741 116.389 420.741 116.827C420.741 117.265 420.961 117.484 421.4 117.265L432.611 110.696V109.053L421.4 115.623Z"
            fill="#212D60"
          />
          <path
            d="M434.26 108.177L432.721 109.053V110.696L434.37 109.82C434.7 109.601 435.03 109.053 435.03 108.615C434.92 108.177 434.59 107.958 434.26 108.177Z"
            fill="#212D60"
          />
          <path
            d="M437.448 87.9214V98.2136C437.448 98.7611 437.228 99.199 436.678 99.5275L419.202 109.601C417.554 110.477 415.685 110.477 414.036 109.601L396.78 99.5275C396.231 99.199 396.011 98.7611 396.121 98.2136H396.011V87.9214H437.448Z"
            fill="url(#paint12_linear_70_113170)"
          />
          <path
            d="M419.202 99.1991L436.678 89.1259C437.667 88.5784 437.667 87.155 436.678 86.4981L419.092 76.3154C417.664 75.4395 415.905 75.4395 414.366 76.3154L396.561 86.6076C395.571 87.155 395.571 88.5784 396.561 89.1259L413.927 99.1991C415.685 100.075 417.664 100.075 419.202 99.1991Z"
            fill="#F0F0FC"
          />
          <path
            d="M421.4 102.046C421.071 102.265 420.741 102.812 420.741 103.25C420.741 103.688 420.961 103.907 421.4 103.688L432.611 97.1187V95.4764L421.4 102.046Z"
            fill="#212D60"
          />
          <path
            d="M434.26 94.6004L432.721 95.4764V97.1187L434.37 96.2428C434.7 96.0238 435.03 95.4764 435.03 95.0384C434.92 94.7099 434.59 94.491 434.26 94.6004Z"
            fill="#212D60"
          />
          <path
            d="M413.267 103.579C413.267 104.017 413.597 104.564 413.927 104.783C414.366 105.002 414.586 104.783 414.586 104.345C414.586 103.907 414.256 103.36 413.927 103.141C413.597 102.922 413.267 103.141 413.267 103.579Z"
            fill="#212D60"
          />
          <path
            d="M410.739 102.155C410.739 102.593 411.069 103.141 411.399 103.36C411.838 103.579 412.058 103.36 412.058 102.922C412.058 102.484 411.728 101.936 411.399 101.717C411.069 101.498 410.739 101.608 410.739 102.155Z"
            fill="#212D60"
          />
          <path
            d="M408.321 100.622C408.321 101.06 408.651 101.608 408.981 101.827C409.42 102.046 409.64 101.827 409.64 101.389C409.64 100.951 409.31 100.403 408.981 100.185C408.651 100.075 408.321 100.185 408.321 100.622Z"
            fill="#212D60"
          />
          <path
            d="M405.793 99.1991C405.793 99.637 406.123 100.184 406.453 100.403C406.892 100.622 407.112 100.403 407.112 99.9655C407.112 99.5276 406.782 98.9801 406.453 98.7611C406.123 98.6516 405.793 98.7611 405.793 99.1991Z"
            fill="#212D60"
          />
          <path
            d="M403.375 97.7756C403.375 98.2136 403.705 98.7611 404.034 98.98C404.474 99.199 404.694 98.98 404.694 98.5421C404.694 98.1041 404.364 97.5567 404.034 97.3377C403.595 97.1187 403.375 97.3377 403.375 97.7756Z"
            fill="url(#paint13_linear_70_113170)"
          />
          <path
            d="M400.847 96.3523C400.847 96.7903 401.177 97.3377 401.506 97.5567C401.946 97.7757 402.166 97.5567 402.166 97.1187C402.166 96.6808 401.836 96.1333 401.506 95.9143C401.177 95.6954 400.847 95.9143 400.847 96.3523Z"
            fill="url(#paint14_linear_70_113170)"
          />
          <path
            d="M398.319 94.9289C398.319 95.3669 398.649 95.9143 398.979 96.1333C399.418 96.3523 399.638 96.1333 399.638 95.6953C399.638 95.2574 399.308 94.7099 398.979 94.4909C398.649 94.272 398.319 94.4909 398.319 94.9289Z"
            fill="url(#paint15_linear_70_113170)"
          />
          <path
            d="M413.597 117.484C413.597 117.922 413.926 118.47 414.256 118.689C414.586 118.907 414.916 118.689 414.916 118.251C414.916 117.813 414.586 117.265 414.256 117.046C413.926 116.937 413.597 117.046 413.597 117.484Z"
            fill="#212D60"
          />
          <path
            d="M411.179 116.061C411.179 116.499 411.508 117.046 411.838 117.265C412.278 117.484 412.498 117.265 412.498 116.827C412.498 116.389 412.168 115.842 411.838 115.623C411.508 115.513 411.179 115.623 411.179 116.061Z"
            fill="url(#paint16_linear_70_113170)"
          />
          <path
            d="M408.651 114.637C408.651 115.075 408.98 115.623 409.31 115.842C409.75 116.061 409.97 115.842 409.97 115.404C409.97 114.966 409.64 114.418 409.31 114.199C408.98 113.98 408.651 114.199 408.651 114.637Z"
            fill="url(#paint17_linear_70_113170)"
          />
          <path
            d="M406.123 113.214C406.123 113.652 406.453 114.199 406.782 114.418C407.222 114.637 407.442 114.418 407.442 113.98C407.442 113.542 407.112 112.995 406.782 112.776C406.453 112.557 406.123 112.776 406.123 113.214Z"
            fill="url(#paint18_linear_70_113170)"
          />
          <path
            d="M403.705 111.791C403.705 112.229 404.034 112.776 404.364 112.995C404.804 113.214 405.024 112.995 405.024 112.557C405.024 112.119 404.694 111.572 404.364 111.353C404.034 111.134 403.705 111.353 403.705 111.791Z"
            fill="url(#paint19_linear_70_113170)"
          />
          <path
            d="M401.177 110.367C401.177 110.805 401.506 111.353 401.836 111.572C402.276 111.791 402.496 111.572 402.496 111.134C402.496 110.696 402.166 110.148 401.836 109.929C401.506 109.71 401.177 109.929 401.177 110.367Z"
            fill="url(#paint20_linear_70_113170)"
          />
          <path
            d="M398.759 108.944C398.759 109.382 399.088 109.929 399.418 110.148C399.858 110.367 400.078 110.148 400.078 109.71C400.078 109.272 399.748 108.725 399.418 108.506C399.088 108.287 398.759 108.396 398.759 108.944Z"
            fill="#212D60"
          />
          <path
            d="M413.047 130.952C413.047 131.389 413.377 131.937 413.707 132.156C414.146 132.375 414.366 132.156 414.366 131.718C414.366 131.28 414.036 130.733 413.707 130.514C413.377 130.404 413.047 130.514 413.047 130.952Z"
            fill="url(#paint21_linear_70_113170)"
          />
          <path
            d="M410.629 129.528C410.629 129.966 410.959 130.514 411.289 130.733C411.728 130.952 411.948 130.733 411.948 130.295C411.948 129.857 411.618 129.309 411.289 129.09C410.959 128.981 410.629 129.09 410.629 129.528Z"
            fill="#212D60"
          />
          <path
            d="M408.101 128.105C408.101 128.543 408.431 129.09 408.761 129.309C409.2 129.528 409.42 129.309 409.42 128.871C409.42 128.433 409.09 127.886 408.761 127.667C408.431 127.448 408.101 127.667 408.101 128.105Z"
            fill="#212D60"
          />
          <path
            d="M405.683 126.681C405.683 127.119 406.013 127.667 406.343 127.886C406.782 128.105 407.002 127.886 407.002 127.448C407.002 127.01 406.672 126.462 406.343 126.243C405.903 126.024 405.683 126.243 405.683 126.681Z"
            fill="url(#paint22_linear_70_113170)"
          />
          <path
            d="M403.155 125.258C403.155 125.696 403.485 126.243 403.815 126.462C404.254 126.681 404.474 126.462 404.474 126.024C404.474 125.586 404.144 125.039 403.815 124.82C403.485 124.601 403.155 124.82 403.155 125.258Z"
            fill="url(#paint23_linear_70_113170)"
          />
          <path
            d="M400.627 123.835C400.627 124.273 400.957 124.82 401.287 125.039C401.726 125.258 401.946 125.039 401.946 124.601C401.946 124.163 401.616 123.616 401.287 123.397C400.957 123.178 400.627 123.397 400.627 123.835Z"
            fill="url(#paint24_linear_70_113170)"
          />
          <path
            d="M398.209 122.411C398.209 122.849 398.539 123.397 398.869 123.616C399.308 123.835 399.528 123.616 399.528 123.178C399.528 122.74 399.198 122.192 398.869 121.973C398.539 121.754 398.209 121.864 398.209 122.411Z"
            fill="url(#paint25_linear_70_113170)"
          />
          <path
            d="M412.937 143.543C412.937 143.981 413.267 144.528 413.597 144.747C414.036 144.966 414.256 144.747 414.256 144.309C414.256 143.871 413.927 143.324 413.597 143.105C413.267 142.996 412.937 143.105 412.937 143.543Z"
            fill="#212D60"
          />
          <path
            d="M410.409 142.12C410.409 142.558 410.739 143.105 411.069 143.324C411.508 143.543 411.728 143.324 411.728 142.886C411.728 142.448 411.399 141.901 411.069 141.682C410.739 141.572 410.409 141.682 410.409 142.12Z"
            fill="#212D60"
          />
          <path
            d="M407.881 140.696C407.881 141.134 408.211 141.682 408.541 141.901C408.981 142.12 409.2 141.901 409.2 141.463C409.2 141.025 408.871 140.477 408.541 140.258C408.211 140.039 407.881 140.258 407.881 140.696Z"
            fill="url(#paint26_linear_70_113170)"
          />
          <path
            d="M405.463 139.273C405.463 139.711 405.793 140.258 406.123 140.477C406.563 140.696 406.782 140.477 406.782 140.039C406.782 139.601 406.453 139.054 406.123 138.835C405.793 138.616 405.463 138.835 405.463 139.273Z"
            fill="url(#paint27_linear_70_113170)"
          />
          <path
            d="M402.935 137.849C402.935 138.287 403.265 138.835 403.595 139.054C404.035 139.273 404.254 139.054 404.254 138.616C404.254 138.178 403.925 137.63 403.595 137.411C403.265 137.192 402.935 137.411 402.935 137.849Z"
            fill="url(#paint28_linear_70_113170)"
          />
          <path
            d="M400.517 136.426C400.517 136.864 400.847 137.412 401.177 137.63C401.616 137.849 401.836 137.631 401.836 137.193C401.836 136.755 401.507 136.207 401.177 135.988C400.847 135.769 400.517 135.988 400.517 136.426Z"
            fill="#212D60"
          />
          <path
            d="M397.989 135.003C397.989 135.441 398.319 135.988 398.649 136.207C399.089 136.426 399.308 136.207 399.308 135.769C399.308 135.331 398.979 134.784 398.649 134.565C398.319 134.346 397.989 134.455 397.989 135.003Z"
            fill="#212D60"
          />
          <path
            d="M428.215 143.433L429.314 142.777C429.534 142.667 429.644 142.339 429.644 142.12C429.644 141.901 429.534 141.791 429.314 141.901L428.215 142.558C427.995 142.667 427.885 142.996 427.885 143.215C427.885 143.433 428.105 143.543 428.215 143.433Z"
            fill="url(#paint29_linear_70_113170)"
          />
          <path
            d="M416.565 158.543H416.455L378.315 136.317L355.234 149.346C355.124 149.455 355.014 149.346 354.904 149.237C354.794 149.127 354.904 149.018 355.014 148.908L378.205 135.769C378.315 135.769 378.425 135.769 378.425 135.769L416.565 157.996L435.799 146.937L428.655 142.886C428.545 142.777 428.545 142.667 428.545 142.558C428.655 142.448 428.765 142.448 428.875 142.448L436.349 146.718C436.458 146.718 436.458 146.828 436.458 146.937C436.458 147.047 436.458 147.156 436.349 147.156L416.565 158.543Z"
            fill="url(#paint30_linear_70_113170)"
          />
          <path
            opacity="0.43"
            d="M382.492 102.703L366.115 93.0675C364.466 92.0821 362.488 92.0821 360.839 93.0675L339.846 105.221L358.311 116.17H366.225L382.492 106.097C383.921 105.331 383.811 103.469 382.492 102.703Z"
            fill="#0C25BA"
          />
          <path
            d="M377.876 93.9434V104.236C377.876 104.783 377.656 105.221 377.106 105.549L359.63 115.623C357.982 116.499 356.113 116.499 354.464 115.623L337.098 105.549C336.549 105.221 336.329 104.783 336.439 104.236H336.329V93.9434H377.876Z"
            fill="url(#paint31_linear_70_113170)"
          />
          <path
            d="M359.74 105.331L377.216 95.2573C378.205 94.7099 378.205 93.2865 377.216 92.6295L359.63 82.4469C358.202 81.5709 356.443 81.5709 354.904 82.4469L337.099 92.739C336.109 93.2865 336.109 94.7099 337.099 95.2573L354.465 105.331C356.113 106.206 358.092 106.206 359.74 105.331Z"
            fill="#F0F0FC"
          />
          <path
            d="M361.938 108.177C361.609 108.396 361.279 108.944 361.279 109.382C361.279 109.82 361.609 110.039 361.938 109.82L373.149 103.25V101.608L361.938 108.177Z"
            fill="#212D60"
          />
          <path
            d="M374.688 100.732L373.149 101.608V103.25L374.798 102.374C375.128 102.155 375.458 101.608 375.458 101.17C375.348 100.732 375.018 100.513 374.688 100.732Z"
            fill="#212D60"
          />
          <path
            d="M377.876 80.5855V90.8777C377.876 91.4251 377.656 91.8631 377.106 92.1916L359.63 102.265C357.982 103.141 356.113 103.141 354.464 102.265L337.098 92.1916C336.549 91.8631 336.329 91.4251 336.439 90.8777H336.329V80.5855H377.876Z"
            fill="url(#paint32_linear_70_113170)"
          />
          <path
            d="M359.74 91.8631L377.216 81.7899C378.205 81.2425 378.205 79.8191 377.216 79.1621L359.63 68.9795C358.202 68.1035 356.443 68.1035 354.904 68.9795L337.099 79.2716C336.109 79.8191 336.109 81.2425 337.099 81.7899L354.465 91.8631C356.113 92.8485 358.092 92.8485 359.74 91.8631Z"
            fill="#F0F0FC"
          />
          <path
            d="M361.938 94.8194C361.609 95.0383 361.279 95.5858 361.279 96.0238C361.279 96.4617 361.609 96.6807 361.938 96.4617L373.149 89.8922V88.2499L361.938 94.8194Z"
            fill="#212D60"
          />
          <path
            d="M374.688 87.374L373.149 88.2499V89.8923L374.798 89.0164C375.128 88.7974 375.458 88.2499 375.458 87.812C375.348 87.374 375.018 87.155 374.688 87.374Z"
            fill="#212D60"
          />
          <path
            d="M377.876 67.2276V77.5198C377.876 78.0672 377.656 78.5052 377.106 78.8337L359.63 88.9069C357.982 89.7828 356.113 89.7828 354.464 88.9069L337.208 78.8337C336.659 78.5052 336.439 78.0672 336.549 77.5198H336.439V67.2276H377.876Z"
            fill="url(#paint33_linear_70_113170)"
          />
          <path
            d="M359.74 78.5052L377.216 68.432C378.205 67.8846 378.205 66.4612 377.216 65.8042L359.63 55.6215C358.201 54.7456 356.443 54.7456 354.904 55.6215L337.098 65.9137C336.109 66.4612 336.109 67.8846 337.098 68.432L354.464 78.5052C356.113 79.3811 358.092 79.3811 359.74 78.5052Z"
            fill="#F0F0FC"
          />
          <path
            d="M361.938 81.352C361.609 81.571 361.279 82.1184 361.279 82.5564C361.279 82.9944 361.609 83.2134 361.938 82.9944L373.149 76.4249V74.7825L361.938 81.352Z"
            fill="#212D60"
          />
          <path
            d="M374.688 73.9066L373.149 74.7825V76.4249L374.798 75.549C375.128 75.33 375.458 74.7825 375.458 74.3446C375.348 74.0161 375.018 73.7971 374.688 73.9066Z"
            fill="#212D60"
          />
          <path
            d="M377.876 53.7602V64.0524C377.876 64.5998 377.656 65.0378 377.106 65.3663L359.63 75.4395C357.982 76.3154 356.113 76.3154 354.464 75.4395L337.098 65.3663C336.549 65.0378 336.329 64.5998 336.439 64.0524H336.329V53.7602H377.876Z"
            fill="url(#paint34_linear_70_113170)"
          />
          <path
            d="M359.74 65.0378L377.216 54.9646C378.206 54.4171 378.206 52.9937 377.216 52.3368L359.631 42.1541C358.202 41.2782 356.443 41.2782 354.904 42.1541L337.099 52.4463C336.11 52.9937 336.11 54.4171 337.099 54.9646L354.465 65.0378C356.113 65.9137 358.092 65.9137 359.74 65.0378Z"
            fill="#F0F0FC"
          />
          <path
            d="M361.938 67.8845C361.609 68.1035 361.279 68.651 361.279 69.0889C361.279 69.5269 361.609 69.7459 361.938 69.5269L373.149 62.9574V61.3151L361.938 67.8845Z"
            fill="#212D60"
          />
          <path
            d="M374.688 60.4391L373.149 61.315V62.9574L374.798 62.0815C375.128 61.8625 375.458 61.315 375.458 60.8771C375.348 60.4391 375.018 60.2201 374.688 60.4391Z"
            fill="#212D60"
          />
          <path
            d="M353.695 69.3079C353.695 69.7459 354.025 70.2933 354.355 70.5123C354.794 70.7313 355.014 70.5123 355.014 70.0743C355.014 69.6364 354.684 69.0889 354.355 68.8699C354.025 68.7604 353.695 68.8699 353.695 69.3079Z"
            fill="#212D60"
          />
          <path
            d="M351.277 67.8845C351.277 68.3225 351.607 68.87 351.937 69.0889C352.376 69.3079 352.596 69.0889 352.596 68.651C352.596 68.213 352.266 67.6655 351.937 67.4466C351.607 67.2276 351.277 67.4466 351.277 67.8845Z"
            fill="#212D60"
          />
          <path
            d="M348.749 66.4611C348.749 66.8991 349.079 67.4465 349.409 67.6655C349.848 67.8845 350.068 67.6655 350.068 67.2276C350.068 66.7896 349.738 66.2421 349.409 66.0232C349.079 65.8042 348.749 66.0232 348.749 66.4611Z"
            fill="#212D60"
          />
          <path
            d="M346.331 65.0378C346.331 65.4758 346.661 66.0232 346.991 66.2422C347.43 66.4612 347.65 66.2422 347.65 65.8042C347.65 65.3663 347.32 64.8188 346.991 64.5998C346.551 64.3808 346.331 64.5998 346.331 65.0378Z"
            fill="#212D60"
          />
          <path
            d="M343.803 63.6144C343.803 64.0524 344.133 64.5998 344.463 64.8188C344.902 65.0378 345.122 64.8188 345.122 64.3808C345.122 63.9429 344.792 63.3954 344.463 63.1764C344.133 62.9574 343.803 63.1764 343.803 63.6144Z"
            fill="url(#paint35_linear_70_113170)"
          />
          <path
            d="M341.275 62.191C341.275 62.6289 341.605 63.1764 341.935 63.3954C342.374 63.6144 342.594 63.3954 342.594 62.9574C342.594 62.5195 342.264 61.972 341.935 61.753C341.605 61.534 341.275 61.753 341.275 62.191Z"
            fill="url(#paint36_linear_70_113170)"
          />
          <path
            d="M338.857 60.6581C338.857 61.0961 339.187 61.6435 339.517 61.8625C339.956 62.0815 340.176 61.8625 340.176 61.4246C340.176 60.9866 339.846 60.4391 339.517 60.2202C339.187 60.1107 338.857 60.2202 338.857 60.6581Z"
            fill="url(#paint37_linear_70_113170)"
          />
          <path
            d="M354.135 83.3228C354.135 83.7607 354.464 84.3082 354.794 84.5272C355.234 84.7461 355.454 84.5272 355.454 84.0892C355.454 83.6512 355.124 83.1038 354.794 82.8848C354.464 82.6658 354.135 82.8848 354.135 83.3228Z"
            fill="#212D60"
          />
          <path
            d="M351.607 81.8994C351.607 82.3374 351.936 82.8848 352.266 83.1038C352.706 83.3228 352.926 83.1038 352.926 82.6659C352.926 82.2279 352.596 81.6804 352.266 81.4615C351.936 81.2425 351.607 81.4615 351.607 81.8994Z"
            fill="url(#paint38_linear_70_113170)"
          />
          <path
            d="M349.079 80.476C349.079 80.914 349.409 81.4614 349.738 81.6804C350.178 81.8994 350.398 81.6804 350.398 81.2425C350.398 80.8045 350.068 80.257 349.738 80.0381C349.409 79.8191 349.079 80.0381 349.079 80.476Z"
            fill="url(#paint39_linear_70_113170)"
          />
          <path
            d="M346.661 79.0526C346.661 79.4906 346.991 80.038 347.32 80.257C347.76 80.476 347.98 80.257 347.98 79.8191C347.98 79.3811 347.65 78.8336 347.32 78.6147C346.991 78.3957 346.661 78.6146 346.661 79.0526Z"
            fill="url(#paint40_linear_70_113170)"
          />
          <path
            d="M344.133 77.5198C344.133 77.9577 344.463 78.5052 344.792 78.7242C345.232 78.9431 345.452 78.7242 345.452 78.2862C345.452 77.8482 345.122 77.3008 344.792 77.0818C344.463 76.9723 344.133 77.0818 344.133 77.5198Z"
            fill="url(#paint41_linear_70_113170)"
          />
          <path
            d="M341.715 76.0964C341.715 76.5343 342.044 77.0818 342.374 77.3008C342.814 77.5197 343.034 77.3008 343.034 76.8628C343.034 76.4248 342.704 75.8774 342.374 75.6584C342.044 75.5489 341.715 75.6584 341.715 76.0964Z"
            fill="url(#paint42_linear_70_113170)"
          />
          <path
            d="M339.187 74.673C339.187 75.1109 339.517 75.6584 339.846 75.8774C340.286 76.0964 340.506 75.8774 340.506 75.4394C340.506 75.0015 340.176 74.454 339.846 74.235C339.517 74.016 339.187 74.235 339.187 74.673Z"
            fill="#212D60"
          />
          <path
            d="M353.585 96.7902C353.585 97.2282 353.915 97.7757 354.245 97.9946C354.684 98.2136 354.904 97.9946 354.904 97.5567C354.904 97.1187 354.574 96.5712 354.245 96.3523C353.915 96.1333 353.585 96.3523 353.585 96.7902Z"
            fill="url(#paint43_linear_70_113170)"
          />
          <path
            d="M351.057 95.3669C351.057 95.8049 351.387 96.3523 351.717 96.5713C352.156 96.7903 352.376 96.5713 352.376 96.1333C352.376 95.6954 352.046 95.1479 351.717 94.9289C351.387 94.7099 351.057 94.9289 351.057 95.3669Z"
            fill="#212D60"
          />
          <path
            d="M348.639 93.9435C348.639 94.3815 348.969 94.9289 349.299 95.1479C349.738 95.3669 349.958 95.1479 349.958 94.7099C349.958 94.272 349.628 93.7245 349.299 93.5055C348.859 93.2865 348.639 93.5055 348.639 93.9435Z"
            fill="#212D60"
          />
          <path
            d="M346.111 92.5201C346.111 92.9581 346.441 93.5055 346.771 93.7245C347.21 93.9435 347.43 93.7245 347.43 93.2865C347.43 92.8486 347.1 92.3011 346.771 92.0821C346.441 91.8631 346.111 92.0821 346.111 92.5201Z"
            fill="url(#paint44_linear_70_113170)"
          />
          <path
            d="M343.583 91.0967C343.583 91.5347 343.913 92.0822 344.243 92.3011C344.682 92.5201 344.902 92.3011 344.902 91.8632C344.902 91.4252 344.572 90.8778 344.243 90.6588C343.913 90.4398 343.583 90.5493 343.583 91.0967Z"
            fill="url(#paint45_linear_70_113170)"
          />
          <path
            d="M341.165 89.5638C341.165 90.0018 341.495 90.5492 341.825 90.7682C342.264 90.9872 342.484 90.7682 342.484 90.3303C342.484 89.8923 342.154 89.3448 341.825 89.1259C341.495 89.0164 341.165 89.1259 341.165 89.5638Z"
            fill="url(#paint46_linear_70_113170)"
          />
          <path
            d="M338.637 88.1405C338.637 88.5784 338.967 89.1259 339.297 89.3449C339.736 89.5638 339.956 89.3449 339.956 88.9069C339.956 88.4689 339.626 87.9215 339.297 87.7025C338.967 87.4835 338.637 87.7025 338.637 88.1405Z"
            fill="url(#paint47_linear_70_113170)"
          />
          <path
            d="M353.365 109.382C353.365 109.82 353.695 110.367 354.025 110.586C354.464 110.805 354.684 110.586 354.684 110.148C354.684 109.71 354.355 109.163 354.025 108.944C353.695 108.725 353.365 108.944 353.365 109.382Z"
            fill="#212D60"
          />
          <path
            d="M350.837 107.958C350.837 108.396 351.167 108.944 351.497 109.163C351.936 109.382 352.156 109.163 352.156 108.725C352.156 108.287 351.827 107.739 351.497 107.52C351.167 107.301 350.837 107.52 350.837 107.958Z"
            fill="#212D60"
          />
          <path
            d="M348.419 106.535C348.419 106.973 348.749 107.52 349.079 107.739C349.518 107.958 349.738 107.739 349.738 107.301C349.738 106.863 349.409 106.316 349.079 106.097C348.749 105.878 348.419 106.097 348.419 106.535Z"
            fill="url(#paint48_linear_70_113170)"
          />
          <path
            d="M345.891 105.112C345.891 105.549 346.221 106.097 346.551 106.316C346.99 106.535 347.21 106.316 347.21 105.878C347.21 105.44 346.881 104.893 346.551 104.674C346.221 104.455 345.891 104.674 345.891 105.112Z"
            fill="url(#paint49_linear_70_113170)"
          />
          <path
            d="M343.473 103.579C343.473 104.017 343.803 104.564 344.133 104.783C344.462 105.002 344.792 104.783 344.792 104.345C344.792 103.907 344.462 103.36 344.133 103.141C343.803 103.031 343.473 103.141 343.473 103.579Z"
            fill="url(#paint50_linear_70_113170)"
          />
          <path
            d="M340.945 102.155C340.945 102.593 341.275 103.141 341.605 103.36C342.044 103.579 342.264 103.36 342.264 102.922C342.264 102.484 341.934 101.936 341.605 101.717C341.275 101.608 340.945 101.717 340.945 102.155Z"
            fill="#212D60"
          />
          <path
            d="M338.417 100.732C338.417 101.17 338.747 101.717 339.077 101.936C339.516 102.155 339.736 101.936 339.736 101.498C339.736 101.06 339.407 100.513 339.077 100.294C338.747 100.075 338.417 100.294 338.417 100.732Z"
            fill="#212D60"
          />
          <path
            opacity="0.43"
            d="M352.816 119.674L336.439 110.148C334.79 109.163 332.812 109.163 331.163 110.148L310.17 122.302L328.635 133.251H336.549L352.816 123.178C354.245 122.411 354.245 120.44 352.816 119.674Z"
            fill="#0C25BA"
          />
          <path
            d="M348.309 111.024V121.316C348.309 121.864 348.09 122.302 347.54 122.63L330.064 132.703C328.415 133.579 326.547 133.579 324.898 132.703L307.532 122.63C306.983 122.302 306.763 121.864 306.873 121.316H306.763V111.024H348.309Z"
            fill="url(#paint51_linear_70_113170)"
          />
          <path
            d="M330.064 122.302L347.54 112.229C348.529 111.681 348.529 110.258 347.54 109.601L329.954 99.4181C328.525 98.5421 326.767 98.5421 325.228 99.4181L307.422 109.71C306.433 110.258 306.433 111.681 307.422 112.229L324.788 122.302C326.547 123.287 328.415 123.287 330.064 122.302Z"
            fill="#F0F0FC"
          />
          <path
            d="M332.262 125.258C331.932 125.477 331.603 126.024 331.603 126.462C331.603 126.9 331.932 127.119 332.262 126.9L343.473 120.331V118.688L332.262 125.258Z"
            fill="#212D60"
          />
          <path
            d="M345.012 117.813L343.473 118.688V120.331L345.122 119.455C345.452 119.236 345.781 118.688 345.781 118.25C345.672 117.813 345.452 117.594 345.012 117.813Z"
            fill="#212D60"
          />
          <path
            d="M348.309 97.6661V107.958C348.309 108.506 348.09 108.944 347.54 109.272L330.064 119.345C328.415 120.221 326.547 120.221 324.898 119.345L307.532 109.272C306.983 108.944 306.763 108.506 306.873 107.958H306.763V97.6661H348.309Z"
            fill="url(#paint52_linear_70_113170)"
          />
          <path
            d="M330.064 108.944L347.54 98.8706C348.529 98.3231 348.529 96.8997 347.54 96.2428L329.954 86.0601C328.525 85.1842 326.767 85.1842 325.228 86.0601L307.532 96.3523C306.543 96.8997 306.543 98.3231 307.532 98.8706L324.898 108.944C326.547 109.82 328.415 109.82 330.064 108.944Z"
            fill="#F0F0FC"
          />
          <path
            d="M332.262 111.791C331.932 112.01 331.603 112.557 331.603 112.995C331.603 113.433 331.932 113.652 332.262 113.433L343.473 106.863V105.221L332.262 111.791Z"
            fill="#212D60"
          />
          <path
            d="M345.012 104.345L343.473 105.221V106.863L345.122 105.987C345.452 105.768 345.781 105.221 345.781 104.783C345.781 104.345 345.452 104.236 345.012 104.345Z"
            fill="#212D60"
          />
          <path
            d="M348.309 84.1987V94.4909C348.309 95.0384 348.09 95.4763 347.54 95.8048L330.064 105.878C328.415 106.754 326.547 106.754 324.898 105.878L307.532 95.8048C306.983 95.4763 306.763 95.0384 306.873 94.4909H306.763V84.1987H348.309Z"
            fill="url(#paint53_linear_70_113170)"
          />
          <path
            d="M330.064 95.5858L347.54 85.5126C348.529 84.9652 348.529 83.5418 347.54 82.8849L329.954 72.7022C328.525 71.8262 326.767 71.8262 325.228 72.7022L307.422 82.9944C306.433 83.5418 306.433 84.9652 307.422 85.5126L324.788 95.5858C326.547 96.4618 328.415 96.4618 330.064 95.5858Z"
            fill="#F0F0FC"
          />
          <path
            d="M332.262 98.4326C331.932 98.6516 331.603 99.1991 331.603 99.637C331.603 100.075 331.932 100.294 332.262 100.075L343.473 93.5055V91.8632L332.262 98.4326Z"
            fill="#212D60"
          />
          <path
            d="M345.012 90.9872L343.473 91.8631V93.5055L345.122 92.6296C345.452 92.4106 345.781 91.8631 345.781 91.4252C345.672 90.9872 345.452 90.7682 345.012 90.9872Z"
            fill="#212D60"
          />
          <path
            d="M348.309 70.7313V81.0235C348.309 81.571 348.09 82.0089 347.54 82.3374L330.064 92.4106C328.415 93.2865 326.547 93.2865 324.898 92.4106L307.532 82.3374C306.983 82.0089 306.763 81.571 306.873 81.0235H306.763V70.7313H348.309Z"
            fill="url(#paint54_linear_70_113170)"
          />
          <path
            d="M330.064 82.0089L347.54 71.9357C348.529 71.3883 348.529 69.9649 347.54 69.308L329.954 59.1253C328.525 58.2493 326.767 58.2493 325.228 59.1253L307.422 69.4174C306.433 69.9649 306.433 71.3883 307.422 71.9357L324.788 82.0089C326.547 82.9944 328.415 82.9944 330.064 82.0089Z"
            fill="#F0F0FC"
          />
          <path
            d="M332.262 84.9652C331.932 85.1841 331.603 85.7316 331.603 86.1696C331.603 86.6075 331.932 86.8265 332.262 86.6075L343.473 80.0381V78.3957L332.262 84.9652Z"
            fill="#212D60"
          />
          <path
            d="M345.012 77.5198L343.473 78.3957V80.0381L345.122 79.1622C345.452 78.9432 345.781 78.3957 345.781 77.9578C345.781 77.5198 345.452 77.3008 345.012 77.5198Z"
            fill="#212D60"
          />
          <path
            d="M324.019 86.3886C324.019 86.8265 324.349 87.374 324.678 87.593C325.118 87.8119 325.338 87.593 325.338 87.155C325.338 86.717 325.008 86.1696 324.678 85.9506C324.349 85.7316 324.019 85.9506 324.019 86.3886Z"
            fill="#212D60"
          />
          <path
            d="M321.601 84.9652C321.601 85.4032 321.931 85.9506 322.26 86.1696C322.7 86.3886 322.92 86.1696 322.92 85.7317C322.92 85.2937 322.59 84.7462 322.26 84.5273C321.931 84.3083 321.601 84.5273 321.601 84.9652Z"
            fill="#212D60"
          />
          <path
            d="M319.073 83.5418C319.073 83.9798 319.403 84.5272 319.732 84.7462C320.172 84.9652 320.392 84.7462 320.392 84.3083C320.392 83.8703 320.062 83.3228 319.732 83.1039C319.403 82.8849 319.073 83.1039 319.073 83.5418Z"
            fill="#212D60"
          />
          <path
            d="M316.655 82.1184C316.655 82.5564 316.985 83.1038 317.314 83.3228C317.754 83.5418 317.974 83.3228 317.974 82.8848C317.974 82.4469 317.644 81.8994 317.314 81.6804C316.985 81.4615 316.655 81.571 316.655 82.1184Z"
            fill="#212D60"
          />
          <path
            d="M314.127 80.5856C314.127 81.0235 314.457 81.571 314.786 81.79C315.226 82.0089 315.446 81.79 315.446 81.352C315.446 80.914 315.116 80.3666 314.786 80.1476C314.457 80.0381 314.127 80.1476 314.127 80.5856Z"
            fill="url(#paint55_linear_70_113170)"
          />
          <path
            d="M311.599 79.1622C311.599 79.6001 311.929 80.1476 312.258 80.3666C312.698 80.5856 312.918 80.3666 312.918 79.9286C312.918 79.4907 312.588 78.9432 312.258 78.7242C311.929 78.5052 311.599 78.7242 311.599 79.1622Z"
            fill="url(#paint56_linear_70_113170)"
          />
          <path
            d="M309.181 77.7388C309.181 78.1767 309.511 78.7242 309.84 78.9432C310.28 79.1622 310.5 78.9432 310.5 78.5052C310.5 78.0672 310.17 77.5198 309.84 77.3008C309.511 77.0818 309.181 77.3008 309.181 77.7388Z"
            fill="url(#paint57_linear_70_113170)"
          />
          <path
            d="M324.459 100.403C324.459 100.841 324.788 101.389 325.118 101.608C325.558 101.827 325.778 101.608 325.778 101.17C325.778 100.732 325.448 100.184 325.118 99.9655C324.788 99.7465 324.459 99.9655 324.459 100.403Z"
            fill="#212D60"
          />
          <path
            d="M321.931 98.9801C321.931 99.4181 322.26 99.9655 322.59 100.185C323.03 100.403 323.249 100.185 323.249 99.7465C323.249 99.3086 322.92 98.7611 322.59 98.5421C322.26 98.3232 321.931 98.4327 321.931 98.9801Z"
            fill="url(#paint58_linear_70_113170)"
          />
          <path
            d="M319.513 97.4472C319.513 97.8852 319.842 98.4327 320.172 98.6516C320.612 98.8706 320.831 98.6516 320.831 98.2137C320.831 97.7757 320.502 97.2283 320.172 97.0093C319.732 96.8998 319.513 97.0093 319.513 97.4472Z"
            fill="url(#paint59_linear_70_113170)"
          />
          <path
            d="M316.985 96.0238C316.985 96.4618 317.314 97.0092 317.644 97.2282C318.084 97.4472 318.304 97.2282 318.304 96.7902C318.304 96.3523 317.974 95.8048 317.644 95.5858C317.314 95.3669 316.985 95.5858 316.985 96.0238Z"
            fill="url(#paint60_linear_70_113170)"
          />
          <path
            d="M314.457 94.6005C314.457 95.0384 314.786 95.5859 315.116 95.8049C315.556 96.0239 315.776 95.8049 315.776 95.3669C315.776 94.9289 315.446 94.3815 315.116 94.1625C314.786 93.9435 314.457 94.1625 314.457 94.6005Z"
            fill="url(#paint61_linear_70_113170)"
          />
          <path
            d="M312.039 93.1771C312.039 93.615 312.368 94.1625 312.698 94.3815C313.138 94.6005 313.358 94.3815 313.358 93.9435C313.358 93.5056 313.028 92.9581 312.698 92.7391C312.368 92.5201 312.039 92.7391 312.039 93.1771Z"
            fill="url(#paint62_linear_70_113170)"
          />
          <path
            d="M309.511 91.7537C309.511 92.1916 309.84 92.7391 310.17 92.9581C310.61 93.1771 310.83 92.9581 310.83 92.5201C310.83 92.0821 310.5 91.5347 310.17 91.3157C309.84 91.0967 309.511 91.3157 309.511 91.7537Z"
            fill="#212D60"
          />
          <path
            d="M323.909 113.871C323.909 114.309 324.239 114.856 324.568 115.075C325.008 115.294 325.228 115.075 325.228 114.637C325.228 114.199 324.898 113.652 324.568 113.433C324.239 113.214 323.909 113.433 323.909 113.871Z"
            fill="url(#paint63_linear_70_113170)"
          />
          <path
            d="M321.381 112.448C321.381 112.885 321.711 113.433 322.04 113.652C322.48 113.871 322.7 113.652 322.7 113.214C322.7 112.776 322.37 112.229 322.04 112.01C321.711 111.791 321.381 112.01 321.381 112.448Z"
            fill="#212D60"
          />
          <path
            d="M318.963 110.915C318.963 111.353 319.293 111.9 319.622 112.119C320.062 112.338 320.282 112.119 320.282 111.681C320.282 111.243 319.952 110.696 319.622 110.477C319.183 110.367 318.963 110.477 318.963 110.915Z"
            fill="#212D60"
          />
          <path
            d="M316.435 109.491C316.435 109.929 316.765 110.477 317.095 110.696C317.534 110.915 317.754 110.696 317.754 110.258C317.754 109.82 317.424 109.272 317.095 109.053C316.765 108.944 316.435 109.053 316.435 109.491Z"
            fill="url(#paint64_linear_70_113170)"
          />
          <path
            d="M313.907 108.068C313.907 108.506 314.237 109.053 314.567 109.272C315.006 109.491 315.226 109.272 315.226 108.834C315.226 108.396 314.896 107.849 314.567 107.63C314.237 107.411 313.907 107.63 313.907 108.068Z"
            fill="url(#paint65_linear_70_113170)"
          />
          <path
            d="M311.489 106.644C311.489 107.082 311.819 107.63 312.148 107.849C312.588 108.068 312.808 107.849 312.808 107.411C312.808 106.973 312.478 106.425 312.148 106.207C311.819 105.988 311.489 106.207 311.489 106.644Z"
            fill="url(#paint66_linear_70_113170)"
          />
          <path
            d="M308.961 105.221C308.961 105.659 309.291 106.206 309.621 106.425C310.06 106.644 310.28 106.425 310.28 105.988C310.28 105.55 309.95 105.002 309.621 104.783C309.291 104.564 308.961 104.783 308.961 105.221Z"
            fill="url(#paint67_linear_70_113170)"
          />
          <path
            d="M323.689 126.462C323.689 126.9 324.019 127.448 324.349 127.667C324.788 127.886 325.008 127.667 325.008 127.229C325.008 126.791 324.678 126.243 324.349 126.024C324.019 125.805 323.689 126.024 323.689 126.462Z"
            fill="#212D60"
          />
          <path
            d="M321.271 125.039C321.271 125.477 321.601 126.024 321.931 126.243C322.37 126.462 322.59 126.243 322.59 125.805C322.59 125.367 322.26 124.82 321.931 124.601C321.491 124.382 321.271 124.492 321.271 125.039Z"
            fill="#212D60"
          />
          <path
            d="M318.743 123.506C318.743 123.944 319.073 124.492 319.403 124.711C319.842 124.93 320.062 124.711 320.062 124.273C320.062 123.835 319.732 123.287 319.403 123.068C319.073 122.959 318.743 123.068 318.743 123.506Z"
            fill="url(#paint68_linear_70_113170)"
          />
          <path
            d="M316.215 122.083C316.215 122.521 316.545 123.068 316.875 123.287C317.314 123.506 317.534 123.287 317.534 122.849C317.534 122.411 317.205 121.864 316.875 121.645C316.545 121.426 316.215 121.645 316.215 122.083Z"
            fill="url(#paint69_linear_70_113170)"
          />
          <path
            d="M313.797 120.659C313.797 121.097 314.127 121.645 314.457 121.864C314.896 122.083 315.116 121.864 315.116 121.426C315.116 120.988 314.786 120.44 314.457 120.221C314.127 120.002 313.797 120.221 313.797 120.659Z"
            fill="url(#paint70_linear_70_113170)"
          />
          <path
            d="M311.269 119.236C311.269 119.674 311.599 120.221 311.929 120.44C312.368 120.659 312.588 120.44 312.588 120.002C312.588 119.564 312.258 119.017 311.929 118.798C311.599 118.579 311.269 118.798 311.269 119.236Z"
            fill="#212D60"
          />
          <path
            d="M308.851 117.813C308.851 118.251 309.181 118.798 309.511 119.017C309.95 119.236 310.17 119.017 310.17 118.579C310.17 118.141 309.84 117.594 309.511 117.375C309.071 117.156 308.851 117.375 308.851 117.813Z"
            fill="#212D60"
          />
          <path
            d="M368.203 109.491L369.302 108.834C369.522 108.725 369.632 108.396 369.632 108.177C369.632 107.958 369.522 107.849 369.302 107.958L368.203 108.615C367.984 108.725 367.874 109.053 367.874 109.272C367.874 109.491 368.093 109.601 368.203 109.491Z"
            fill="url(#paint71_linear_70_113170)"
          />
          <path
            d="M339.077 144.09C338.967 144.09 338.857 144.09 338.857 143.981C338.747 143.871 338.857 143.762 338.967 143.652L383.811 117.484L368.863 108.944C368.753 108.834 368.753 108.725 368.753 108.615C368.863 108.506 368.973 108.506 369.083 108.506L384.36 117.265C384.47 117.265 384.47 117.375 384.47 117.484C384.47 117.594 384.47 117.703 384.36 117.703L339.187 144.09H339.077Z"
            fill="url(#paint72_linear_70_113170)"
          />
          <path
            opacity="0.43"
            d="M314.017 61.0961L297.64 51.5704C295.992 50.5849 294.013 50.5849 292.365 51.5704L271.481 63.7239L289.946 74.673H297.86L314.127 64.5998C315.446 63.8334 315.446 61.8625 314.017 61.0961Z"
            fill="#0C25BA"
          />
          <path
            d="M309.511 52.4463V62.7385C309.511 63.2859 309.291 63.7239 308.741 64.0524L291.265 74.1255C289.617 75.0015 287.748 75.0015 286.099 74.1255L268.733 64.0524C268.184 63.7239 267.964 63.2859 268.074 62.7385H267.964V52.4463H309.511Z"
            fill="url(#paint73_linear_70_113170)"
          />
          <path
            d="M291.265 63.7239L308.741 53.6507C309.731 53.1033 309.731 51.6799 308.741 51.0229L291.155 40.8402C289.727 39.9643 287.968 39.9643 286.429 40.8402L268.624 51.1324C267.634 51.6799 267.634 53.1033 268.624 53.6507L285.99 63.7239C287.748 64.7093 289.727 64.7093 291.265 63.7239Z"
            fill="#F0F0FC"
          />
          <path
            d="M293.464 66.6801C293.134 66.8991 292.804 67.4466 292.804 67.8845C292.804 68.3225 293.134 68.5415 293.464 68.3225L304.675 61.753V60.1107L293.464 66.6801Z"
            fill="#212D60"
          />
          <path
            d="M306.323 59.2347L304.784 60.1106V61.753L306.433 60.8771C306.763 60.6581 307.093 60.1106 307.093 59.6727C307.093 59.2347 306.653 59.0157 306.323 59.2347Z"
            fill="#212D60"
          />
          <path
            d="M309.511 39.0884V49.3806C309.511 49.928 309.291 50.366 308.741 50.6944L291.265 60.7676C289.617 61.6436 287.748 61.6436 286.099 60.7676L268.733 50.6944C268.184 50.366 267.964 49.928 268.074 49.3806H267.964V39.0884H309.511Z"
            fill="url(#paint74_linear_70_113170)"
          />
          <path
            d="M291.265 50.366L308.741 40.2928C309.731 39.7453 309.731 38.3219 308.741 37.665L291.155 27.4823C289.727 26.6064 287.968 26.6064 286.429 27.4823L268.624 37.7745C267.634 38.3219 267.634 39.7453 268.624 40.2928L285.99 50.366C287.748 51.3514 289.727 51.3514 291.265 50.366Z"
            fill="#F0F0FC"
          />
          <path
            d="M293.464 53.3222C293.134 53.5412 292.804 54.0887 292.804 54.5266C292.804 54.9646 293.134 55.1836 293.464 54.9646L304.675 48.3951V46.7527L293.464 53.3222Z"
            fill="#212D60"
          />
          <path
            d="M306.323 45.8768L304.784 46.7528V48.3951L306.433 47.5192C306.763 47.3002 307.093 46.7528 307.093 46.3148C306.873 45.8768 306.653 45.6578 306.323 45.8768Z"
            fill="#212D60"
          />
          <path
            d="M309.511 25.7304V36.0226C309.511 36.5701 309.291 37.008 308.741 37.3365L291.265 47.3002C289.617 48.1761 287.748 48.1761 286.099 47.3002L268.733 37.227C268.184 36.8985 267.964 36.4606 268.074 35.9131H267.964V25.6209H309.511V25.7304Z"
            fill="url(#paint75_linear_70_113170)"
          />
          <path
            d="M291.265 37.008L308.741 26.9348C309.731 26.3874 309.731 24.964 308.741 24.307L291.155 14.1244C289.727 13.2484 287.968 13.2484 286.429 14.1244L268.624 24.4165C267.634 24.964 267.634 26.3874 268.624 26.9348L285.99 37.008C287.748 37.8839 289.727 37.8839 291.265 37.008Z"
            fill="#F0F0FC"
          />
          <path
            d="M293.464 39.8548C293.134 40.0738 292.804 40.6212 292.804 41.0592C292.804 41.4972 293.134 41.7161 293.464 41.4972L304.675 34.9277V33.2853L293.464 39.8548Z"
            fill="#212D60"
          />
          <path
            d="M306.323 32.4094L304.784 33.2853V34.9277L306.433 34.0518C306.763 33.8328 307.093 33.2853 307.093 32.8474C306.873 32.4094 306.653 32.1904 306.323 32.4094Z"
            fill="#212D60"
          />
          <path
            d="M309.511 12.1535V22.4457C309.511 22.9932 309.291 23.4311 308.741 23.7596L291.265 33.8328C289.617 34.7087 287.748 34.7087 286.099 33.8328L268.733 23.7596C268.184 23.4311 267.964 22.9932 268.074 22.4457H267.964V12.1535H309.511Z"
            fill="url(#paint76_linear_70_113170)"
          />
          <path
            d="M291.265 23.4311L308.741 13.3579C309.731 12.8105 309.731 11.3871 308.741 10.7301L291.156 0.54745C289.727 -0.328479 287.968 -0.328479 286.429 0.54745L268.734 10.9491C267.744 11.4966 267.744 12.92 268.734 13.4674L286.1 23.5406C287.748 24.4165 289.727 24.4165 291.265 23.4311Z"
            fill="#F0F0FC"
          />
          <path
            d="M293.464 26.3874C293.134 26.6064 292.804 27.1538 292.804 27.5918C292.804 28.0297 293.134 28.2487 293.464 28.0297L304.675 21.4603V19.8179L293.464 26.3874Z"
            fill="#212D60"
          />
          <path
            d="M306.323 18.942L304.784 19.8179V21.4603L306.433 20.5844C306.763 20.3654 307.093 19.8179 307.093 19.38C306.873 18.942 306.653 18.723 306.323 18.942Z"
            fill="#212D60"
          />
          <path
            d="M285.33 27.8108C285.33 28.2487 285.66 28.7962 285.99 29.0152C286.429 29.2342 286.649 29.0152 286.649 28.5772C286.649 28.1392 286.319 27.5918 285.99 27.3728C285.55 27.1538 285.33 27.3728 285.33 27.8108Z"
            fill="#212D60"
          />
          <path
            d="M282.802 26.3874C282.802 26.8253 283.132 27.3728 283.462 27.5918C283.901 27.8108 284.121 27.5918 284.121 27.1538C284.121 26.7158 283.791 26.1684 283.462 25.9494C283.132 25.7304 282.802 25.9494 282.802 26.3874Z"
            fill="#212D60"
          />
          <path
            d="M280.274 24.964C280.274 25.402 280.604 25.9494 280.934 26.1684C281.373 26.3874 281.593 26.1684 281.593 25.7304C281.593 25.2925 281.264 24.745 280.934 24.526C280.604 24.3071 280.274 24.526 280.274 24.964Z"
            fill="#212D60"
          />
          <path
            d="M277.856 23.5406C277.856 23.9786 278.186 24.526 278.516 24.745C278.955 24.964 279.175 24.745 279.175 24.307C279.175 23.8691 278.845 23.3216 278.516 23.1026C278.186 22.8836 277.856 23.1026 277.856 23.5406Z"
            fill="#212D60"
          />
          <path
            d="M275.328 22.1172C275.328 22.5552 275.658 23.1026 275.988 23.3216C276.427 23.5406 276.647 23.3216 276.647 22.8837C276.647 22.4457 276.317 21.8982 275.988 21.6793C275.658 21.4603 275.328 21.5698 275.328 22.1172Z"
            fill="url(#paint77_linear_70_113170)"
          />
          <path
            d="M272.91 20.5843C272.91 21.0223 273.24 21.5697 273.57 21.7887C274.009 22.0077 274.229 21.7887 274.229 21.3508C274.229 20.9128 273.899 20.3653 273.57 20.1464C273.13 20.0369 272.91 20.1464 272.91 20.5843Z"
            fill="url(#paint78_linear_70_113170)"
          />
          <path
            d="M270.382 19.161C270.382 19.5989 270.712 20.1464 271.042 20.3654C271.481 20.5843 271.701 20.3654 271.701 19.9274C271.701 19.4894 271.371 18.942 271.042 18.723C270.712 18.504 270.382 18.723 270.382 19.161Z"
            fill="url(#paint79_linear_70_113170)"
          />
          <path
            d="M285.66 41.8257C285.66 42.2636 285.99 42.8111 286.319 43.0301C286.759 43.249 286.979 43.0301 286.979 42.5921C286.979 42.1541 286.649 41.6067 286.319 41.3877C285.99 41.1687 285.66 41.3877 285.66 41.8257Z"
            fill="#212D60"
          />
          <path
            d="M283.132 40.4023C283.132 40.8402 283.462 41.3877 283.791 41.6067C284.231 41.8256 284.451 41.6067 284.451 41.1687C284.451 40.7307 284.121 40.1833 283.791 39.9643C283.462 39.7453 283.132 39.9643 283.132 40.4023Z"
            fill="url(#paint80_linear_70_113170)"
          />
          <path
            d="M280.714 38.9789C280.714 39.4168 281.044 39.9643 281.373 40.1833C281.813 40.4023 282.033 40.1833 282.033 39.7453C282.033 39.3074 281.703 38.7599 281.373 38.5409C281.044 38.3219 280.714 38.4314 280.714 38.9789Z"
            fill="url(#paint81_linear_70_113170)"
          />
          <path
            d="M278.186 37.446C278.186 37.884 278.516 38.4314 278.845 38.6504C279.285 38.8694 279.505 38.6504 279.505 38.2124C279.505 37.7745 279.175 37.227 278.845 37.008C278.516 36.8985 278.186 37.008 278.186 37.446Z"
            fill="url(#paint82_linear_70_113170)"
          />
          <path
            d="M275.658 36.0226C275.658 36.4606 275.988 37.008 276.317 37.227C276.757 37.446 276.977 37.227 276.977 36.7891C276.977 36.3511 276.647 35.8036 276.317 35.5847C275.988 35.3657 275.658 35.5847 275.658 36.0226Z"
            fill="url(#paint83_linear_70_113170)"
          />
          <path
            d="M273.24 34.5993C273.24 35.0372 273.57 35.5847 273.899 35.8037C274.339 36.0226 274.559 35.8037 274.559 35.3657C274.559 34.9277 274.229 34.3803 273.899 34.1613C273.57 33.9423 273.24 34.1613 273.24 34.5993Z"
            fill="url(#paint84_linear_70_113170)"
          />
          <path
            d="M270.712 33.1759C270.712 33.6138 271.042 34.1613 271.371 34.3803C271.811 34.5992 272.031 34.3803 272.031 33.9423C272.031 33.5043 271.701 32.9569 271.371 32.7379C271.042 32.5189 270.712 32.7379 270.712 33.1759Z"
            fill="#212D60"
          />
          <path
            d="M285.11 55.293C285.11 55.731 285.44 56.2785 285.77 56.4974C286.209 56.7164 286.429 56.4974 286.429 56.0595C286.429 55.6215 286.1 55.0741 285.77 54.8551C285.44 54.6361 285.11 54.8551 285.11 55.293Z"
            fill="url(#paint85_linear_70_113170)"
          />
          <path
            d="M282.582 53.8697C282.582 54.3077 282.912 54.8551 283.242 55.0741C283.682 55.2931 283.901 55.0741 283.901 54.6361C283.901 54.1982 283.572 53.6507 283.242 53.4317C282.912 53.2128 282.582 53.4317 282.582 53.8697Z"
            fill="#212D60"
          />
          <path
            d="M280.164 52.4463C280.164 52.8843 280.494 53.4317 280.824 53.6507C281.263 53.8697 281.483 53.6507 281.483 53.2127C281.483 52.7748 281.154 52.2273 280.824 52.0083C280.494 51.7893 280.164 52.0083 280.164 52.4463Z"
            fill="#212D60"
          />
          <path
            d="M277.636 50.9134C277.636 51.3514 277.966 51.8989 278.296 52.1178C278.735 52.3368 278.955 52.1178 278.955 51.6799C278.955 51.2419 278.626 50.6944 278.296 50.4755C277.966 50.366 277.636 50.4755 277.636 50.9134Z"
            fill="url(#paint86_linear_70_113170)"
          />
          <path
            d="M275.108 49.49C275.108 49.928 275.438 50.4755 275.768 50.6944C276.208 50.9134 276.427 50.6944 276.427 50.2565C276.427 49.8185 276.098 49.271 275.768 49.0521C275.438 48.9426 275.108 49.0521 275.108 49.49Z"
            fill="url(#paint87_linear_70_113170)"
          />
          <path
            d="M272.69 48.0667C272.69 48.5046 273.02 49.0521 273.35 49.2711C273.789 49.49 274.009 49.2711 274.009 48.8331C274.009 48.3951 273.68 47.8477 273.35 47.6287C273.02 47.4097 272.69 47.6287 272.69 48.0667Z"
            fill="url(#paint88_linear_70_113170)"
          />
          <path
            d="M270.162 46.6433C270.162 47.0812 270.492 47.6287 270.822 47.8477C271.261 48.0666 271.481 47.8477 271.481 47.4097C271.481 46.9717 271.152 46.4243 270.822 46.2053C270.492 45.9863 270.162 46.2053 270.162 46.6433Z"
            fill="url(#paint89_linear_70_113170)"
          />
          <path
            d="M284.891 67.8845C284.891 68.3225 285.22 68.87 285.55 69.0889C285.99 69.3079 286.209 69.0889 286.209 68.651C286.209 68.213 285.88 67.6655 285.55 67.4466C285.22 67.2276 284.891 67.4466 284.891 67.8845Z"
            fill="#212D60"
          />
          <path
            d="M282.473 66.4612C282.473 66.8992 282.802 67.4466 283.132 67.6656C283.572 67.8846 283.791 67.6656 283.791 67.2276C283.791 66.7897 283.462 66.2422 283.132 66.0232C282.802 65.8042 282.473 66.0232 282.473 66.4612Z"
            fill="#212D60"
          />
          <path
            d="M279.945 65.0378C279.945 65.4758 280.274 66.0232 280.604 66.2422C281.044 66.4612 281.264 66.2422 281.264 65.8042C281.264 65.3663 280.934 64.8188 280.604 64.5998C280.274 64.3808 279.945 64.4903 279.945 65.0378Z"
            fill="url(#paint90_linear_70_113170)"
          />
          <path
            d="M277.417 63.5049C277.417 63.9429 277.746 64.4903 278.076 64.7093C278.516 64.9283 278.735 64.7093 278.735 64.2714C278.735 63.8334 278.406 63.2859 278.076 63.067C277.746 62.9575 277.417 63.067 277.417 63.5049Z"
            fill="url(#paint91_linear_70_113170)"
          />
          <path
            d="M274.999 62.0816C274.999 62.5195 275.328 63.067 275.658 63.286C276.098 63.5049 276.317 63.286 276.317 62.848C276.317 62.41 275.988 61.8626 275.658 61.6436C275.328 61.4246 274.999 61.6436 274.999 62.0816Z"
            fill="url(#paint92_linear_70_113170)"
          />
          <path
            d="M272.471 60.6582C272.471 61.0961 272.8 61.6436 273.13 61.8626C273.57 62.0815 273.79 61.8626 273.79 61.4246C273.79 60.9866 273.46 60.4392 273.13 60.2202C272.8 60.0012 272.471 60.2202 272.471 60.6582Z"
            fill="#212D60"
          />
          <path
            d="M270.052 59.2347C270.052 59.6727 270.382 60.2202 270.712 60.4392C271.152 60.6581 271.371 60.4392 271.371 60.0012C271.371 59.5632 271.042 59.0158 270.712 58.7968C270.382 58.5778 270.052 58.7968 270.052 59.2347Z"
            fill="#212D60"
          />
          <path
            opacity="0.43"
            d="M284.451 78.1767L268.074 68.651C266.425 67.6656 264.447 67.6656 262.798 68.651L241.805 80.8045L260.27 91.7536H268.184L284.451 81.6804C285.77 80.914 285.77 78.9432 284.451 78.1767Z"
            fill="#0C25BA"
          />
          <path
            d="M279.834 69.5269V79.8191C279.834 80.3665 279.615 80.8045 279.065 81.133L261.589 91.2062C259.941 92.0821 258.072 92.0821 256.423 91.2062L239.057 81.133C238.508 80.8045 238.288 80.3665 238.398 79.8191H238.288V69.5269H279.834Z"
            fill="url(#paint93_linear_70_113170)"
          />
          <path
            d="M261.589 80.8045L279.065 70.7313C280.054 70.1839 280.054 68.7605 279.065 68.1035L261.479 57.9209C260.05 57.0449 258.292 57.0449 256.753 57.9209L238.947 68.213C237.958 68.7605 237.958 70.1839 238.947 70.7313L256.313 80.8045C258.072 81.6805 260.05 81.6805 261.589 80.8045Z"
            fill="#F0F0FC"
          />
          <path
            d="M263.788 83.6513C263.458 83.8702 263.128 84.4177 263.128 84.8557C263.128 85.2936 263.458 85.5126 263.788 85.2936L274.998 78.7242V77.0818L263.788 83.6513Z"
            fill="#212D60"
          />
          <path
            d="M276.647 76.2059L275.108 77.0818V78.7242L276.757 77.8482C277.087 77.6293 277.417 77.0818 277.417 76.6438C277.197 76.2059 276.977 76.0964 276.647 76.2059Z"
            fill="#212D60"
          />
          <path
            d="M279.834 56.169V66.4612C279.834 67.0086 279.615 67.4466 279.065 67.7751L261.589 77.8483C259.941 78.7242 258.072 78.7242 256.423 77.8483L239.057 67.7751C238.508 67.4466 238.288 67.0086 238.398 66.4612H238.288V56.169H279.834Z"
            fill="url(#paint94_linear_70_113170)"
          />
          <path
            d="M261.589 67.4466L279.065 57.3734C280.054 56.8259 280.054 55.4026 279.065 54.7456L261.479 44.5629C260.05 43.687 258.292 43.687 256.753 44.5629L238.947 54.8551C237.958 55.4026 237.958 56.8259 238.947 57.3734L256.313 67.4466C258.072 68.3225 260.05 68.3225 261.589 67.4466Z"
            fill="#F0F0FC"
          />
          <path
            d="M263.788 70.2933C263.458 70.5123 263.128 71.0598 263.128 71.4978C263.128 71.9357 263.458 72.1547 263.788 71.9357L274.998 65.3662V63.7239L263.788 70.2933Z"
            fill="#212D60"
          />
          <path
            d="M276.647 62.8479L275.108 63.7239V65.3662L276.757 64.4903C277.087 64.2713 277.417 63.7239 277.417 63.2859C277.197 62.8479 276.977 62.6289 276.647 62.8479Z"
            fill="#212D60"
          />
          <path
            d="M279.834 42.7016V52.9937C279.834 53.5412 279.615 53.9792 279.065 54.3076L261.589 64.3808C259.941 65.2568 258.072 65.2568 256.423 64.3808L239.057 54.3076C238.508 53.9792 238.288 53.5412 238.398 52.9937H238.288V42.7016H279.834Z"
            fill="url(#paint95_linear_70_113170)"
          />
          <path
            d="M261.589 53.9792L279.065 43.906C280.054 43.3585 280.054 41.9351 279.065 41.2782L261.479 31.0955C260.05 30.2196 258.292 30.2196 256.753 31.0955L238.947 41.3877C237.958 41.9351 237.958 43.3585 238.947 43.906L256.313 53.9792C258.072 54.9646 260.05 54.9646 261.589 53.9792Z"
            fill="#F0F0FC"
          />
          <path
            d="M263.788 56.9354C263.458 57.1544 263.128 57.7019 263.128 58.1398C263.128 58.5778 263.458 58.7968 263.788 58.5778L274.998 52.0083V50.366L263.788 56.9354Z"
            fill="#212D60"
          />
          <path
            d="M276.647 49.49L275.108 50.366V52.0083L276.757 51.1324C277.087 50.9134 277.417 50.3659 277.417 49.928C277.197 49.49 276.977 49.271 276.647 49.49Z"
            fill="#212D60"
          />
          <path
            d="M279.835 29.2342V39.5263C279.835 40.0738 279.615 40.5118 279.065 40.8402L261.589 50.9134C259.941 51.7894 258.072 51.7894 256.423 50.9134L239.057 40.8402C238.508 40.5118 238.288 40.0738 238.398 39.5263H238.288V29.2342H279.835Z"
            fill="url(#paint96_linear_70_113170)"
          />
          <path
            d="M261.589 40.5117L279.065 30.4385C280.054 29.8911 280.054 28.4677 279.065 27.8108L261.479 17.6281C260.05 16.7521 258.292 16.7521 256.753 17.6281L238.947 27.9203C237.958 28.4677 237.958 29.8911 238.947 30.4385L256.313 40.5117C258.072 41.3877 260.05 41.3877 261.589 40.5117Z"
            fill="#F0F0FC"
          />
          <path
            d="M263.788 43.3585C263.458 43.5775 263.128 44.1249 263.128 44.5629C263.128 45.0009 263.458 45.2199 263.788 45.0009L274.998 38.4314V36.789L263.788 43.3585Z"
            fill="#212D60"
          />
          <path
            d="M276.647 35.9131L275.108 36.7891V38.4314L276.757 37.5555C277.087 37.3365 277.417 36.7891 277.417 36.3511C277.197 36.0226 276.977 35.8036 276.647 35.9131Z"
            fill="#212D60"
          />
          <path
            d="M255.654 44.8914C255.654 45.3294 255.984 45.8768 256.314 46.0958C256.753 46.3148 256.973 46.0958 256.973 45.6578C256.973 45.2199 256.643 44.6724 256.314 44.4534C255.984 44.2344 255.654 44.4534 255.654 44.8914Z"
            fill="#212D60"
          />
          <path
            d="M253.126 43.468C253.126 43.906 253.456 44.4534 253.786 44.6724C254.225 44.8914 254.445 44.6724 254.445 44.2344C254.445 43.7965 254.115 43.249 253.786 43.03C253.456 42.811 253.126 42.9205 253.126 43.468Z"
            fill="#212D60"
          />
          <path
            d="M250.598 41.9351C250.598 42.3731 250.928 42.9205 251.258 43.1395C251.697 43.3585 251.917 43.1395 251.917 42.7016C251.917 42.2636 251.587 41.7161 251.258 41.4972C250.928 41.3877 250.598 41.4972 250.598 41.9351Z"
            fill="#212D60"
          />
          <path
            d="M248.18 40.5117C248.18 40.9497 248.51 41.4971 248.84 41.7161C249.279 41.9351 249.499 41.7161 249.499 41.2782C249.499 40.8402 249.169 40.2927 248.84 40.0738C248.51 39.9643 248.18 40.0738 248.18 40.5117Z"
            fill="#212D60"
          />
          <path
            d="M245.652 39.0884C245.652 39.5263 245.982 40.0738 246.312 40.2928C246.751 40.5117 246.971 40.2928 246.971 39.8548C246.971 39.4168 246.641 38.8694 246.312 38.6504C245.982 38.4314 245.652 38.6504 245.652 39.0884Z"
            fill="url(#paint97_linear_70_113170)"
          />
          <path
            d="M243.234 37.665C243.234 38.103 243.564 38.6504 243.893 38.8694C244.333 39.0884 244.553 38.8694 244.553 38.4314C244.553 37.9935 244.223 37.446 243.893 37.227C243.564 37.008 243.234 37.227 243.234 37.665Z"
            fill="url(#paint98_linear_70_113170)"
          />
          <path
            d="M240.706 36.2416C240.706 36.6795 241.036 37.227 241.366 37.446C241.805 37.665 242.025 37.446 242.025 37.008C242.025 36.5701 241.695 36.0226 241.366 35.8036C241.036 35.5846 240.706 35.8036 240.706 36.2416Z"
            fill="url(#paint99_linear_70_113170)"
          />
          <path
            d="M255.984 58.7968C255.984 59.2348 256.314 59.7822 256.643 60.0012C257.083 60.2202 257.303 60.0012 257.303 59.5632C257.303 59.1253 256.973 58.5778 256.643 58.3588C256.314 58.2493 255.984 58.3588 255.984 58.7968Z"
            fill="#212D60"
          />
          <path
            d="M253.456 57.3734C253.456 57.8114 253.786 58.3588 254.115 58.5778C254.555 58.7968 254.775 58.5778 254.775 58.1398C254.775 57.7019 254.445 57.1544 254.115 56.9354C253.786 56.8259 253.456 56.9354 253.456 57.3734Z"
            fill="url(#paint100_linear_70_113170)"
          />
          <path
            d="M251.038 55.95C251.038 56.388 251.367 56.9354 251.697 57.1544C252.137 57.3734 252.357 57.1544 252.357 56.7165C252.357 56.2785 252.027 55.731 251.697 55.5121C251.367 55.2931 251.038 55.5121 251.038 55.95Z"
            fill="url(#paint101_linear_70_113170)"
          />
          <path
            d="M248.51 54.5266C248.51 54.9646 248.839 55.512 249.169 55.731C249.609 55.95 249.829 55.731 249.829 55.2931C249.829 54.8551 249.499 54.3076 249.169 54.0887C248.839 53.8697 248.51 54.0887 248.51 54.5266Z"
            fill="url(#paint102_linear_70_113170)"
          />
          <path
            d="M246.092 53.1032C246.092 53.5412 246.421 54.0886 246.751 54.3076C247.191 54.5266 247.411 54.3076 247.411 53.8697C247.411 53.4317 247.081 52.8842 246.751 52.6653C246.312 52.4463 246.092 52.6653 246.092 53.1032Z"
            fill="url(#paint103_linear_70_113170)"
          />
          <path
            d="M243.564 51.6799C243.564 52.1178 243.893 52.6653 244.223 52.8843C244.663 53.1033 244.883 52.8843 244.883 52.4463C244.883 52.0084 244.553 51.4609 244.223 51.2419C243.893 51.0229 243.564 51.2419 243.564 51.6799Z"
            fill="url(#paint104_linear_70_113170)"
          />
          <path
            d="M241.036 50.2565C241.036 50.6944 241.365 51.2419 241.695 51.4609C242.135 51.6799 242.355 51.4609 242.355 51.0229C242.355 50.5849 242.025 50.0375 241.695 49.8185C241.365 49.5995 241.036 49.709 241.036 50.2565Z"
            fill="#212D60"
          />
          <path
            d="M255.434 72.3737C255.434 72.8116 255.764 73.3591 256.094 73.5781C256.533 73.797 256.753 73.5781 256.753 73.1401C256.753 72.7021 256.423 72.1547 256.094 71.9357C255.764 71.7167 255.434 71.8262 255.434 72.3737Z"
            fill="url(#paint105_linear_70_113170)"
          />
          <path
            d="M252.906 70.8408C252.906 71.2788 253.236 71.8262 253.566 72.0452C254.005 72.2642 254.225 72.0452 254.225 71.6072C254.225 71.1693 253.895 70.6218 253.566 70.4028C253.236 70.2933 252.906 70.4028 252.906 70.8408Z"
            fill="#212D60"
          />
          <path
            d="M250.488 69.4174C250.488 69.8554 250.818 70.4028 251.148 70.6218C251.587 70.8408 251.807 70.6218 251.807 70.1839C251.807 69.7459 251.477 69.1984 251.148 68.9795C250.818 68.7605 250.488 68.9795 250.488 69.4174Z"
            fill="#212D60"
          />
          <path
            d="M247.96 67.994C247.96 68.432 248.29 68.9794 248.62 69.1984C249.059 69.4174 249.279 69.1984 249.279 68.7605C249.279 68.3225 248.949 67.775 248.62 67.5561C248.29 67.3371 247.96 67.5561 247.96 67.994Z"
            fill="url(#paint106_linear_70_113170)"
          />
          <path
            d="M245.542 66.5706C245.542 67.0086 245.872 67.556 246.202 67.775C246.641 67.994 246.861 67.775 246.861 67.3371C246.861 66.8991 246.531 66.3516 246.202 66.1327C245.762 65.9137 245.542 66.1327 245.542 66.5706Z"
            fill="url(#paint107_linear_70_113170)"
          />
          <path
            d="M243.014 65.1473C243.014 65.5853 243.344 66.1327 243.674 66.3517C244.113 66.5707 244.333 66.3517 244.333 65.9137C244.333 65.4758 244.003 64.9283 243.674 64.7093C243.344 64.4903 243.014 64.7093 243.014 65.1473Z"
            fill="url(#paint108_linear_70_113170)"
          />
          <path
            d="M240.486 63.7239C240.486 64.1618 240.816 64.7093 241.146 64.9283C241.585 65.1473 241.805 64.9283 241.805 64.4903C241.805 64.0524 241.475 63.5049 241.146 63.2859C240.816 63.0669 240.486 63.1764 240.486 63.7239Z"
            fill="url(#paint109_linear_70_113170)"
          />
          <path
            d="M255.214 84.8557C255.214 85.2937 255.544 85.8411 255.874 86.0601C256.313 86.2791 256.533 86.0601 256.533 85.6221C256.533 85.1842 256.204 84.6367 255.874 84.4177C255.544 84.3082 255.214 84.4177 255.214 84.8557Z"
            fill="#212D60"
          />
          <path
            d="M252.796 83.4323C252.796 83.8702 253.126 84.4177 253.456 84.6367C253.896 84.8557 254.115 84.6367 254.115 84.1987C254.115 83.7608 253.786 83.2133 253.456 82.9943C253.126 82.8848 252.796 82.9943 252.796 83.4323Z"
            fill="#212D60"
          />
          <path
            d="M250.268 82.0089C250.268 82.4469 250.598 82.9943 250.928 83.2133C251.368 83.4323 251.587 83.2133 251.587 82.7754C251.587 82.3374 251.258 81.7899 250.928 81.571C250.598 81.352 250.268 81.571 250.268 82.0089Z"
            fill="url(#paint110_linear_70_113170)"
          />
          <path
            d="M247.85 80.5855C247.85 81.0235 248.18 81.5709 248.51 81.7899C248.949 82.0089 249.169 81.7899 249.169 81.352C249.169 80.914 248.84 80.3665 248.51 80.1476C248.07 79.9286 247.85 80.1476 247.85 80.5855Z"
            fill="url(#paint111_linear_70_113170)"
          />
          <path
            d="M245.322 79.1621C245.322 79.6001 245.652 80.1475 245.982 80.3665C246.422 80.5855 246.641 80.3665 246.641 79.9286C246.641 79.4906 246.312 78.9431 245.982 78.7242C245.652 78.5052 245.322 78.7242 245.322 79.1621Z"
            fill="url(#paint112_linear_70_113170)"
          />
          <path
            d="M242.794 77.7388C242.794 78.1767 243.124 78.7242 243.454 78.9432C243.894 79.1622 244.113 78.9432 244.113 78.5052C244.113 78.0672 243.784 77.5198 243.454 77.3008C243.124 77.0818 242.794 77.3008 242.794 77.7388Z"
            fill="#212D60"
          />
          <path
            d="M240.376 76.3154C240.376 76.7533 240.706 77.3008 241.036 77.5198C241.475 77.7388 241.695 77.5198 241.695 77.0818C241.695 76.6438 241.366 76.0964 241.036 75.8774C240.706 75.6584 240.376 75.7679 240.376 76.3154Z"
            fill="#212D60"
          />
          <path
            opacity="0.43"
            d="M253.786 95.6953L237.409 86.0601C235.76 85.0747 233.782 85.0747 232.133 86.0601L211.14 98.2136L229.605 109.163H237.519L253.786 99.0896C255.215 98.4326 255.215 96.4618 253.786 95.6953Z"
            fill="#0C25BA"
          />
          <path
            d="M249.279 87.0455V97.3377C249.279 97.8852 249.059 98.3231 248.51 98.6516L231.034 108.725C229.385 109.601 227.517 109.601 225.868 108.725L208.502 98.5421C207.952 98.2136 207.733 97.7757 207.843 97.2282H207.733V86.936H249.279V87.0455Z"
            fill="url(#paint113_linear_70_113170)"
          />
          <path
            d="M231.034 98.3231L248.51 88.25C249.499 87.7025 249.499 86.2791 248.51 85.6222L230.924 75.4395C229.495 74.5635 227.737 74.5635 226.198 75.4395L208.392 85.7317C207.403 86.2791 207.403 87.7025 208.392 88.25L225.758 98.3231C227.517 99.1991 229.385 99.1991 231.034 98.3231Z"
            fill="#F0F0FC"
          />
          <path
            d="M233.232 101.17C232.902 101.389 232.573 101.936 232.573 102.374C232.573 102.812 232.902 103.031 233.232 102.812L244.443 96.2428V94.6004L233.232 101.17Z"
            fill="#212D60"
          />
          <path
            d="M246.092 93.7245L244.553 94.6005V96.2428L246.202 95.3669C246.531 95.1479 246.861 94.6004 246.861 94.1625C246.641 93.7245 246.421 93.5055 246.092 93.7245Z"
            fill="#212D60"
          />
          <path
            d="M249.279 73.5781V83.8703C249.279 84.4178 249.059 84.8557 248.51 85.1842L231.034 95.2574C229.385 96.1333 227.517 96.1333 225.868 95.2574L208.502 85.1842C207.952 84.8557 207.733 84.4178 207.843 83.8703H207.733V73.5781H249.279Z"
            fill="url(#paint114_linear_70_113170)"
          />
          <path
            d="M231.034 84.8557L248.51 74.7826C249.499 74.2351 249.499 72.8117 248.51 72.1548L230.924 61.9721C229.495 61.0961 227.737 61.0961 226.198 61.9721L208.392 72.2642C207.403 72.8117 207.403 74.2351 208.392 74.7826L225.758 84.8557C227.517 85.8412 229.385 85.8412 231.034 84.8557Z"
            fill="#F0F0FC"
          />
          <path
            d="M233.232 87.812C232.902 88.0309 232.573 88.5784 232.573 89.0164C232.573 89.4543 232.902 89.6733 233.232 89.4543L244.443 82.8849V81.2425L233.232 87.812Z"
            fill="#212D60"
          />
          <path
            d="M246.092 80.3666L244.553 81.2425V82.8849L246.202 82.009C246.531 81.79 246.861 81.2425 246.861 80.8046C246.861 80.3666 246.421 80.1476 246.092 80.3666Z"
            fill="#212D60"
          />
          <path
            d="M249.279 60.2202V70.5123C249.279 71.0598 249.059 71.4977 248.51 71.8262L231.034 81.8994C229.385 82.7753 227.517 82.7753 225.868 81.8994L208.502 71.8262C207.952 71.4977 207.733 71.0598 207.843 70.5123H207.733V60.2202H249.279Z"
            fill="url(#paint115_linear_70_113170)"
          />
          <path
            d="M231.034 71.4978L248.51 61.4246C249.499 60.8771 249.499 59.4537 248.51 58.7968L230.924 48.6141C229.495 47.7382 227.737 47.7382 226.198 48.6141L208.392 58.9063C207.403 59.4537 207.403 60.8771 208.392 61.4246L225.758 71.4978C227.517 72.3737 229.385 72.3737 231.034 71.4978Z"
            fill="#F0F0FC"
          />
          <path
            d="M233.232 74.3446C232.902 74.5635 232.573 75.111 232.573 75.549C232.573 75.9869 232.902 76.2059 233.232 75.9869L244.443 69.4175V67.7751L233.232 74.3446Z"
            fill="#212D60"
          />
          <path
            d="M246.092 66.8991L244.553 67.7751V69.4174L246.202 68.5415C246.531 68.3225 246.861 67.7751 246.861 67.3371C246.641 67.0086 246.421 66.7897 246.092 66.8991Z"
            fill="#212D60"
          />
          <path
            d="M249.279 46.7528V56.9355C249.279 57.4829 249.059 57.9209 248.51 58.2494L231.034 68.3225C229.385 69.1985 227.517 69.1985 225.868 68.3225L208.502 58.2494C207.952 57.9209 207.733 57.4829 207.843 56.9355H207.733V46.6433H249.279V46.7528Z"
            fill="url(#paint116_linear_70_113170)"
          />
          <path
            d="M231.034 58.0304L248.51 47.9572C249.499 47.4097 249.499 45.9863 248.51 45.3294L230.924 35.1467C229.495 34.2708 227.737 34.2708 226.198 35.1467L208.392 45.4389C207.403 45.9863 207.403 47.4097 208.392 47.9572L225.758 58.0304C227.517 58.9063 229.385 58.9063 231.034 58.0304Z"
            fill="#F0F0FC"
          />
          <path
            d="M233.232 60.8772C232.902 61.0961 232.573 61.6436 232.573 62.0816C232.573 62.5195 232.902 62.7385 233.232 62.5195L244.443 55.95V54.3077L233.232 60.8772Z"
            fill="#212D60"
          />
          <path
            d="M246.092 53.4317L244.553 54.3077V55.95L246.202 55.0741C246.531 54.8551 246.861 54.3077 246.861 53.8697C246.641 53.4317 246.421 53.2128 246.092 53.4317Z"
            fill="#212D60"
          />
          <path
            d="M225.099 62.3005C225.099 62.7385 225.428 63.2859 225.758 63.5049C226.198 63.7239 226.418 63.5049 226.418 63.067C226.418 62.629 226.088 62.0815 225.758 61.8626C225.319 61.7531 225.099 61.8626 225.099 62.3005Z"
            fill="#212D60"
          />
          <path
            d="M222.571 60.8771C222.571 61.3151 222.901 61.8625 223.23 62.0815C223.67 62.3005 223.89 62.0815 223.89 61.6436C223.89 61.2056 223.56 60.6581 223.23 60.4391C222.901 60.3297 222.571 60.4391 222.571 60.8771Z"
            fill="#212D60"
          />
          <path
            d="M220.043 59.4537C220.043 59.8917 220.373 60.4392 220.702 60.6581C221.142 60.8771 221.362 60.6581 221.362 60.2202C221.362 59.7822 221.032 59.2348 220.702 59.0158C220.373 58.7968 220.043 59.0158 220.043 59.4537Z"
            fill="#212D60"
          />
          <path
            d="M217.625 58.0303C217.625 58.4683 217.954 59.0158 218.284 59.2347C218.724 59.4537 218.944 59.2347 218.944 58.7968C218.944 58.3588 218.614 57.8114 218.284 57.5924C217.954 57.3734 217.625 57.5924 217.625 58.0303Z"
            fill="#212D60"
          />
          <path
            d="M215.097 56.6069C215.097 57.0449 215.427 57.5924 215.756 57.8113C216.196 58.0303 216.416 57.8113 216.416 57.3734C216.416 56.9354 216.086 56.388 215.756 56.169C215.427 55.95 215.097 56.169 215.097 56.6069Z"
            fill="url(#paint117_linear_70_113170)"
          />
          <path
            d="M212.679 55.1836C212.679 55.6216 213.008 56.169 213.338 56.388C213.778 56.607 213.998 56.388 213.998 55.95C213.998 55.5121 213.668 54.9646 213.338 54.7456C212.899 54.5267 212.679 54.7456 212.679 55.1836Z"
            fill="url(#paint118_linear_70_113170)"
          />
          <path
            d="M210.151 53.7602C210.151 54.1982 210.48 54.7456 210.81 54.9646C211.25 55.1836 211.47 54.9646 211.47 54.5266C211.47 54.0887 211.14 53.5412 210.81 53.3222C210.48 53.1033 210.151 53.2127 210.151 53.7602Z"
            fill="url(#paint119_linear_70_113170)"
          />
          <path
            d="M225.428 76.3154C225.428 76.7533 225.758 77.3008 226.088 77.5198C226.528 77.7388 226.747 77.5198 226.747 77.0818C226.747 76.6438 226.418 76.0964 226.088 75.8774C225.758 75.6584 225.428 75.8774 225.428 76.3154Z"
            fill="#212D60"
          />
          <path
            d="M222.901 74.892C222.901 75.3299 223.23 75.8774 223.56 76.0964C224 76.3154 224.219 76.0964 224.219 75.6584C224.219 75.2204 223.89 74.673 223.56 74.454C223.23 74.235 222.901 74.454 222.901 74.892Z"
            fill="url(#paint120_linear_70_113170)"
          />
          <path
            d="M220.482 73.4686C220.482 73.9065 220.812 74.454 221.142 74.673C221.582 74.892 221.801 74.673 221.801 74.235C221.801 73.7971 221.472 73.2496 221.142 73.0306C220.812 72.8116 220.482 73.0306 220.482 73.4686Z"
            fill="url(#paint121_linear_70_113170)"
          />
          <path
            d="M217.954 72.0452C217.954 72.4832 218.284 73.0307 218.614 73.2496C219.054 73.4686 219.273 73.2496 219.273 72.8117C219.273 72.3737 218.944 71.8263 218.614 71.6073C218.284 71.3883 217.954 71.6073 217.954 72.0452Z"
            fill="url(#paint122_linear_70_113170)"
          />
          <path
            d="M215.427 70.6218C215.427 71.0598 215.756 71.6073 216.086 71.8262C216.526 72.0452 216.745 71.8262 216.745 71.3883C216.745 70.9503 216.416 70.4028 216.086 70.1839C215.756 69.9649 215.427 70.0744 215.427 70.6218Z"
            fill="url(#paint123_linear_70_113170)"
          />
          <path
            d="M213.008 69.089C213.008 69.5269 213.338 70.0744 213.668 70.2934C214.108 70.5123 214.327 70.2934 214.327 69.8554C214.327 69.4174 213.998 68.87 213.668 68.651C213.338 68.5415 213.008 68.651 213.008 69.089Z"
            fill="url(#paint124_linear_70_113170)"
          />
          <path
            d="M210.48 67.6656C210.48 68.1035 210.81 68.651 211.14 68.87C211.58 69.089 211.799 68.87 211.799 68.432C211.799 67.994 211.47 67.4466 211.14 67.2276C210.81 67.1181 210.48 67.2276 210.48 67.6656Z"
            fill="#212D60"
          />
          <path
            d="M224.879 89.7828C224.879 90.2208 225.209 90.7683 225.538 90.9872C225.978 91.2062 226.198 90.9872 226.198 90.5493C226.198 90.1113 225.868 89.5639 225.538 89.3449C225.209 89.1259 224.879 89.3449 224.879 89.7828Z"
            fill="url(#paint125_linear_70_113170)"
          />
          <path
            d="M222.351 88.3594C222.351 88.7974 222.681 89.3449 223.01 89.5638C223.45 89.7828 223.67 89.5638 223.67 89.1259C223.67 88.6879 223.34 88.1405 223.01 87.9215C222.681 87.7025 222.351 87.9215 222.351 88.3594Z"
            fill="#212D60"
          />
          <path
            d="M219.933 86.936C219.933 87.374 220.263 87.9215 220.592 88.1404C221.032 88.3594 221.252 88.1404 221.252 87.7025C221.252 87.2645 220.922 86.7171 220.592 86.4981C220.263 86.2791 219.933 86.4981 219.933 86.936Z"
            fill="#212D60"
          />
          <path
            d="M217.405 85.5127C217.405 85.9507 217.735 86.4981 218.064 86.7171C218.504 86.9361 218.724 86.7171 218.724 86.2791C218.724 85.8412 218.394 85.2937 218.064 85.0747C217.735 84.8558 217.405 85.0747 217.405 85.5127Z"
            fill="url(#paint126_linear_70_113170)"
          />
          <path
            d="M214.877 84.0893C214.877 84.5273 215.207 85.0747 215.536 85.2937C215.976 85.5127 216.196 85.2937 216.196 84.8557C216.196 84.4178 215.866 83.8703 215.536 83.6513C215.207 83.4324 214.877 83.6513 214.877 84.0893Z"
            fill="url(#paint127_linear_70_113170)"
          />
          <path
            d="M212.459 82.5564C212.459 82.9944 212.789 83.5419 213.118 83.7608C213.558 83.9798 213.778 83.7608 213.778 83.3229C213.778 82.8849 213.448 82.3375 213.118 82.1185C212.789 82.009 212.459 82.1185 212.459 82.5564Z"
            fill="url(#paint128_linear_70_113170)"
          />
          <path
            d="M209.931 81.133C209.931 81.571 210.261 82.1185 210.59 82.3374C211.03 82.5564 211.25 82.3374 211.25 81.8995C211.25 81.4615 210.92 80.9141 210.59 80.6951C210.261 80.5856 209.931 80.6951 209.931 81.133Z"
            fill="url(#paint129_linear_70_113170)"
          />
          <path
            d="M224.659 102.374C224.659 102.812 224.989 103.36 225.319 103.579C225.758 103.798 225.978 103.579 225.978 103.141C225.978 102.703 225.648 102.155 225.319 101.936C224.989 101.717 224.659 101.936 224.659 102.374Z"
            fill="#212D60"
          />
          <path
            d="M222.241 100.951C222.241 101.389 222.571 101.936 222.901 102.155C223.34 102.374 223.56 102.155 223.56 101.717C223.56 101.279 223.23 100.732 222.901 100.513C222.571 100.294 222.241 100.513 222.241 100.951Z"
            fill="#212D60"
          />
          <path
            d="M219.713 99.5275C219.713 99.9655 220.043 100.513 220.373 100.732C220.812 100.951 221.032 100.732 221.032 100.294C221.032 99.856 220.702 99.3086 220.373 99.0896C220.043 98.8706 219.713 99.0896 219.713 99.5275Z"
            fill="url(#paint130_linear_70_113170)"
          />
          <path
            d="M217.185 98.1042C217.185 98.5422 217.515 99.0896 217.845 99.3086C218.284 99.5276 218.504 99.3086 218.504 98.8706C218.504 98.4327 218.174 97.8852 217.845 97.6662C217.515 97.4472 217.185 97.6662 217.185 98.1042Z"
            fill="url(#paint131_linear_70_113170)"
          />
          <path
            d="M214.767 96.6808C214.767 97.1188 215.097 97.6662 215.427 97.8852C215.866 98.1042 216.086 97.8852 216.086 97.4472C216.086 97.0093 215.756 96.4618 215.427 96.2428C215.097 96.0238 214.767 96.1333 214.767 96.6808Z"
            fill="url(#paint132_linear_70_113170)"
          />
          <path
            d="M212.239 95.1479C212.239 95.5859 212.569 96.1333 212.899 96.3523C213.338 96.5713 213.558 96.3523 213.558 95.9144C213.558 95.4764 213.228 94.9289 212.899 94.71C212.569 94.6005 212.239 94.71 212.239 95.1479Z"
            fill="#212D60"
          />
          <path
            d="M209.821 93.7245C209.821 94.1625 210.151 94.7099 210.481 94.9289C210.92 95.1479 211.14 94.9289 211.14 94.491C211.14 94.053 210.81 93.5055 210.481 93.2866C210.041 93.1771 209.821 93.2866 209.821 93.7245Z"
            fill="#212D60"
          />
          <path
            d="M287.528 127.995H287.418L241.036 101.717C240.926 101.608 240.926 101.498 240.926 101.389C241.036 101.279 241.146 101.279 241.256 101.279L287.638 127.557C287.748 127.667 287.748 127.776 287.748 127.886C287.638 127.995 287.638 127.995 287.528 127.995Z"
            fill="url(#paint133_linear_70_113170)"
          />
          <path
            opacity="0.43"
            d="M166.516 93.6149L153.107 85.8411C151.788 85.0746 150.139 85.0746 148.82 85.8411L135.96 97.1187L146.732 104.893H153.217L166.516 96.6807C167.615 95.8048 167.615 94.2719 166.516 93.6149Z"
            fill="#0C25BA"
          />
          <path
            d="M160.251 58.4683H132.114V96.6807C132.114 97.0092 132.333 97.3376 132.663 97.5566L144.424 104.455C145.523 105.112 146.842 105.112 147.941 104.455L159.701 97.6661C160.031 97.4471 160.251 97.1187 160.141 96.7902H160.251V58.4683Z"
            fill="url(#paint134_linear_70_113170)"
          />
          <path
            d="M144.424 66.2422L132.663 59.3442C132.004 58.9062 132.004 58.0303 132.663 57.5924L144.534 50.6944C145.523 50.1469 146.732 50.1469 147.721 50.6944L159.702 57.7018C160.361 58.0303 160.361 59.0157 159.702 59.3442L147.941 66.1327C146.842 66.8991 145.523 66.8991 144.424 66.2422Z"
            fill="#F0F0FC"
          />
          <path
            d="M142.995 94.3815L135.85 90.2208C135.301 89.8923 134.971 89.3449 134.971 88.6879V64.8188H134.861C134.422 64.5999 133.872 64.9283 133.872 65.3663V89.5639C133.872 90.2208 134.202 90.7683 134.751 91.0967L141.896 95.2574C142.555 95.5859 143.324 95.2574 143.434 94.6005C143.324 94.491 143.105 94.491 142.995 94.3815Z"
            fill="#36399F"
          />
          <path
            d="M143.434 70.9503C143.434 70.1839 142.995 69.4174 142.335 68.9794L134.861 64.7093V88.6879C134.861 89.3448 135.191 89.8923 135.741 90.2207L142.885 94.3814C142.995 94.4909 143.214 94.4909 143.324 94.4909C143.324 94.3814 143.324 94.2719 143.324 94.1624V70.9503H143.434Z"
            fill="#212D60"
          />
          <path
            d="M137.719 75.3299C137.719 74.5635 138.269 74.235 138.928 74.5635V72.4831C137.169 71.4977 135.741 72.2642 135.741 74.3445L137.719 75.3299Z"
            fill="white"
          />
          <path
            d="M137.829 75.33L135.96 74.235C135.96 75.2205 136.29 76.4249 136.84 77.4103L138.269 76.5344C137.939 76.2059 137.829 75.7679 137.829 75.33Z"
            fill="#474AB6"
          />
          <path
            d="M142.225 77.9577C142.225 75.9869 140.797 73.4686 139.038 72.4832V74.5635C139.697 75.0015 140.357 75.9869 140.357 76.7533C140.357 77.5198 139.807 77.8482 139.148 77.5198C138.818 77.3008 138.488 76.9723 138.269 76.6438L136.84 77.5198C137.389 78.5052 138.159 79.3811 139.038 79.9286C140.797 80.8045 142.225 79.9286 142.225 77.9577Z"
            fill="url(#paint135_linear_70_113170)"
          />
          <path
            d="M137.829 83.9797C137.829 83.2133 138.378 82.8848 139.038 83.2133V81.1329C137.279 80.1475 135.85 80.914 135.85 82.9943L137.829 83.9797C137.829 84.0892 137.829 84.0892 137.829 83.9797Z"
            fill="white"
          />
          <path
            d="M137.829 84.0892L135.96 82.9943C135.96 83.9797 136.29 85.1841 136.84 86.1695L138.268 85.2936C138.049 84.9651 137.829 84.5271 137.829 84.0892Z"
            fill="#474AB6"
          />
          <path
            d="M142.335 86.717C142.335 84.7462 140.906 82.2279 139.148 81.2424V83.3228C139.807 83.7607 140.467 84.7462 140.467 85.5126C140.467 86.279 139.917 86.6075 139.258 86.279C138.928 86.0601 138.598 85.7316 138.378 85.4031L136.95 86.279C137.499 87.2645 138.269 88.1404 139.148 88.6878C140.906 89.5638 142.335 88.6878 142.335 86.717Z"
            fill="url(#paint136_linear_70_113170)"
          />
          <path
            d="M146.072 74.892C146.072 75.111 145.852 75.2204 145.633 75.111C145.413 75.0015 145.193 74.673 145.193 74.454C145.193 74.235 145.413 74.1255 145.633 74.235C145.852 74.3445 146.072 74.673 146.072 74.892Z"
            fill="#FFD33F"
          />
          <path
            d="M146.072 77.4103C146.072 77.6293 145.852 77.7388 145.633 77.6293C145.413 77.5198 145.193 77.1913 145.193 76.9723C145.193 76.7533 145.413 76.6439 145.633 76.7533C145.852 76.7533 146.072 77.0818 146.072 77.4103Z"
            fill="#FD256F"
          />
          <path
            d="M146.072 79.8191C146.072 80.0381 145.852 80.1476 145.633 80.0381C145.413 79.9286 145.193 79.6001 145.193 79.3811C145.193 79.1622 145.413 79.0527 145.633 79.1622C145.852 79.2716 146.072 79.4906 146.072 79.8191Z"
            fill="#232F6D"
          />
          <path
            d="M146.072 82.2279C146.072 82.4469 145.852 82.5564 145.633 82.4469C145.413 82.3374 145.193 82.0089 145.193 81.7899C145.193 81.571 145.413 81.4615 145.633 81.571C145.852 81.6805 146.072 82.0089 146.072 82.2279Z"
            fill="#474AB6"
          />
          <path
            d="M146.072 84.6367C146.072 84.8557 145.852 84.9651 145.633 84.8557C145.413 84.7462 145.193 84.4177 145.193 84.1987C145.193 83.9797 145.413 83.8702 145.633 83.9797C145.852 84.0892 146.072 84.4177 146.072 84.6367Z"
            fill="#474AB6"
          />
          <path
            d="M153.656 99.637L154.755 98.98C154.975 98.8706 155.085 98.5421 155.085 98.3231C155.085 98.1041 154.975 97.9946 154.755 98.1041L153.656 98.7611C153.436 98.8705 153.327 99.199 153.327 99.418C153.327 99.637 153.546 99.7465 153.656 99.637Z"
            fill="url(#paint137_linear_70_113170)"
          />
          <path
            d="M199.16 125.039H199.05L154.206 99.1991C154.096 99.0896 154.096 98.9801 154.096 98.8706C154.206 98.7611 154.316 98.7611 154.426 98.7611L199.269 124.601C199.379 124.711 199.379 124.82 199.379 124.93C199.379 125.039 199.269 125.039 199.16 125.039Z"
            fill="url(#paint138_linear_70_113170)"
          />
          <path
            opacity="0.43"
            d="M40.887 272.852L26.049 264.202C24.6201 263.326 22.7516 263.326 21.3228 264.202L2.30811 275.151L19.0146 285.115H26.1589L40.887 276.027C42.0961 275.37 42.0961 273.619 40.887 272.852Z"
            fill="#0C25BA"
          />
          <path
            d="M37.4798 265.078V274.385C37.4798 274.823 37.26 275.261 36.8203 275.589L21.103 284.677C19.6742 285.553 17.9156 285.553 16.4867 284.677L0.76938 275.589C0.329734 275.37 0.109911 274.823 0.109911 274.385H0V265.078H37.4798Z"
            fill="url(#paint139_linear_70_113170)"
          />
          <path
            d="M21.103 275.261L36.8203 266.173C37.6996 265.626 37.6996 264.312 36.8203 263.874L20.993 254.677C19.6741 253.91 18.0254 253.91 16.8164 254.677L0.769332 263.983C-0.10996 264.531 -0.10996 265.735 0.769332 266.173L16.4867 275.261C17.8056 276.137 19.6741 276.137 21.103 275.261Z"
            fill="#F0F0FC"
          />
          <path
            d="M40.7772 253.144C40.2276 253.144 39.788 253.034 39.4583 252.815L22.422 242.961C22.0923 242.742 21.8724 242.414 21.8724 242.085C21.8724 241.647 22.2022 241.319 22.6418 240.99L28.6869 237.486C29.5662 236.939 30.9951 236.939 31.7645 237.377L48.8007 247.231C49.1305 247.45 49.3503 247.779 49.3503 248.107C49.3503 248.545 49.0206 248.874 48.5809 249.202L42.5358 252.706C41.9862 252.925 41.4367 253.144 40.7772 253.144ZM39.5682 252.487C40.2277 252.925 41.5466 252.815 42.316 252.377L48.3611 248.874C48.8007 248.655 49.0206 248.326 49.0206 248.107C49.0206 247.888 48.9107 247.669 48.5809 247.56L31.5446 237.705C30.8852 237.267 29.5662 237.377 28.7969 237.815L22.7517 241.319C22.3121 241.538 22.0923 241.866 22.0923 242.085C22.0923 242.304 22.2022 242.523 22.5319 242.633L39.5682 252.487Z"
            fill="url(#paint140_linear_70_113170)"
          />
          <path
            d="M33.9627 244.384C34.2924 244.056 34.842 244.056 35.2816 244.384C35.6114 244.713 35.6114 245.26 35.2816 245.698L34.2924 246.684C33.9627 247.012 33.4131 247.012 32.9735 246.684C32.6438 246.355 32.6438 245.808 32.9735 245.37L33.9627 244.384Z"
            fill="#FF969B"
          />
          <path
            d="M29.6761 243.289C30.0058 242.961 30.5554 242.961 30.995 243.289C31.3248 243.618 31.3248 244.165 30.995 244.603L30.0058 245.589C29.6761 245.917 29.1265 245.917 28.6869 245.589C28.3571 245.26 28.3571 244.713 28.6869 244.275L29.6761 243.289Z"
            fill="#FF969B"
          />
          <path
            d="M29.5662 245.917C28.467 246.574 27.258 247.231 26.1589 247.779C25.1697 248.326 23.9607 249.311 22.7517 249.311C21.3228 249.202 20.6633 247.998 20.0039 246.903C19.2345 245.808 18.4651 244.713 17.6957 243.618C17.0363 242.633 18.575 241.757 19.3444 242.633C19.894 243.399 20.5534 244.275 21.103 245.041C21.6525 245.698 22.312 247.45 23.4111 247.231C24.4003 247.012 25.4994 246.027 26.3787 245.589C27.1481 245.151 28.0274 244.713 28.7968 244.165C29.6761 243.727 30.6653 245.37 29.5662 245.917Z"
            fill="url(#paint141_linear_70_113170)"
          />
          <path
            d="M24.6202 253.91C21.9823 255.114 19.3445 256.866 16.8165 258.29C15.1678 259.275 16.5967 261.793 18.3553 260.918C20.6634 259.604 22.8616 258.399 25.1698 257.085C25.2797 256.976 25.4995 256.866 25.7193 256.757C25.7193 256.647 25.8292 256.538 26.159 256.538C26.0491 256.538 25.8292 256.647 25.7193 256.757C25.7193 256.866 25.8292 257.085 25.8292 257.414C25.8292 257.523 25.8292 257.523 25.8292 257.633L29.3464 259.713C28.9068 255.771 27.6977 252.487 24.6202 253.91Z"
            fill="url(#paint142_linear_70_113170)"
          />
          <path
            d="M25.6094 256.757C25.8292 256.647 25.9391 256.538 26.049 256.538C25.8292 256.538 25.7193 256.647 25.6094 256.757Z"
            fill="#2A4280"
          />
          <path
            d="M21.2129 242.961C21.5426 242.742 21.5426 242.414 21.103 242.195L20.5534 241.866C20.1138 241.647 19.5642 241.647 19.1246 241.757C18.7948 241.976 18.7948 242.304 19.2345 242.523L19.784 242.852C20.2237 243.18 20.8831 243.18 21.2129 242.961Z"
            fill="#FFAB83"
          />
          <path
            d="M27.6977 229.932L60.7811 249.092C61.7703 249.64 62.9793 248.983 62.9793 247.779V228.837C62.9793 227.851 62.4297 226.975 61.5505 226.428L27.5878 206.719C27.2581 206.61 26.9283 206.719 26.9283 207.048V228.508C26.9283 229.165 27.2581 229.713 27.6977 229.932Z"
            fill="#212D60"
          />
          <path
            d="M32.0941 214.931L28.2472 212.741V213.179L32.0941 215.369V214.931Z"
            fill="#FF7364"
          />
          <path
            d="M32.0941 216.464L28.2472 214.165V214.603L32.0941 216.793V216.464Z"
            fill="#71D4FF"
          />
          <path
            d="M28.2472 210.333V210.771L29.5661 211.537V211.099L28.2472 210.333Z"
            fill="#FF7364"
          />
          <path
            d="M28.2472 211.099V211.537L29.5661 212.303V211.975L28.2472 211.099Z"
            fill="#FF7364"
          />
          <path
            d="M28.2472 216.683V217.121L29.3463 217.778V217.34L28.2472 216.683Z"
            fill="#FF7364"
          />
          <path
            d="M28.2472 217.559V217.888L29.3463 218.545V218.216L28.2472 217.559Z"
            fill="#FF7364"
          />
          <path
            d="M28.2472 219.858V220.296L30.7752 221.829V221.391L28.2472 219.858Z"
            fill="#FF7364"
          />
          <path
            d="M28.2472 221.501V221.939L30.7752 223.362V223.034L28.2472 221.501Z"
            fill="#FF7364"
          />
          <path
            d="M28.2472 222.377V222.705L29.2364 223.253V222.924L28.2472 222.377Z"
            fill="#71D4FF"
          />
          <path
            d="M28.2472 224.676V225.114L29.2364 225.661V225.223L28.2472 224.676Z"
            fill="#FF7364"
          />
          <path
            d="M28.2472 226.318V226.647L29.2364 227.304V226.866L28.2472 226.318Z"
            fill="#71D4FF"
          />
          <path
            d="M29.786 258.728C29.8959 258.728 30.0058 258.618 30.1157 258.618C29.8959 258.728 29.786 258.728 29.786 258.728Z"
            fill="#2A4280"
          />
          <path
            d="M20.4435 260.808C22.2021 259.713 24.0706 258.618 25.8292 257.523C26.7085 256.976 27.6977 256.428 28.577 255.99C31.5446 254.567 32.6437 257.742 33.3031 259.932C33.523 260.699 33.7428 261.355 33.8527 262.122L30.2256 260.042C30.1157 259.823 30.0058 259.494 30.0058 259.275C29.8959 258.947 29.786 258.837 29.786 258.728C29.8959 258.728 30.0058 258.618 30.1157 258.618C29.8959 258.618 29.786 258.618 29.786 258.728C29.5662 258.837 29.2364 259.056 29.0166 259.275C29.0166 259.275 28.9067 259.275 28.9067 259.385C26.5985 260.699 24.5102 262.012 22.2021 263.436C20.5534 264.312 18.7948 261.793 20.4435 260.808Z"
            fill="url(#paint143_linear_70_113170)"
          />
          <path
            d="M16.2669 247.341C16.3768 245.041 17.2561 240.99 19.2345 241.538C21.103 242.085 22.5318 242.414 23.3012 243.837C23.4111 243.837 23.4111 243.946 23.521 243.946C24.0706 244.713 26.4886 248.874 27.4778 248.545C28.467 248.326 28.3571 248.107 32.8635 245.479C33.8527 244.932 34.8419 246.465 33.8527 247.122C28.467 250.187 28.1373 250.625 27.0382 250.516C25.8291 250.516 25.3895 249.859 23.7408 247.45C23.6309 249.202 23.521 252.487 23.6309 259.823C23.6309 260.37 23.8507 262.341 23.521 262.888C23.1913 263.436 22.9715 263.874 22.312 263.874C21.6525 263.874 21.103 263.655 20.5534 263.326C16.047 260.918 16.047 260.37 15.9371 257.633C15.7173 254.239 16.047 250.735 16.2669 247.341Z"
            fill="url(#paint144_linear_70_113170)"
          />
          <path
            d="M22.4219 237.924C22.3119 238.034 22.202 238.034 21.9822 238.143V238.581C21.9822 238.691 21.8723 238.8 21.7624 238.8H21.6525V238.691C21.6525 238.362 21.4327 238.143 21.2128 238.143C20.993 238.143 20.7732 238.362 20.7732 238.691C20.7732 238.91 20.7732 239.019 20.8831 239.129C20.7732 239.348 20.6633 239.567 20.4435 239.895C20.2236 240.224 19.784 240.443 19.3443 240.333C19.2344 240.333 19.2344 240.224 19.1245 240.224C19.1245 240.99 19.1245 241.647 19.1245 242.414C19.1245 242.961 20.7732 242.961 20.7732 242.414C20.7732 241.866 20.7732 241.209 20.7732 240.662C21.9822 240.552 22.8615 239.786 22.8615 238.8V237.377C22.9714 237.486 22.7516 237.815 22.4219 237.924Z"
            fill="#FF969B"
          />
          <path
            d="M19.6741 240.114C20.0038 240.333 20.5534 240.005 20.7732 239.676C20.8831 239.457 20.993 239.238 21.2129 238.91C21.1029 238.8 21.1029 238.691 21.1029 238.472C21.1029 238.143 21.3228 237.924 21.5426 237.924C21.7624 237.924 21.9822 238.143 21.9822 238.472V238.581H22.0922C22.2021 238.581 22.312 238.472 22.312 238.362V237.924C22.4219 237.815 22.5318 237.815 22.7516 237.705C22.9714 237.596 23.3012 237.267 23.521 236.939C23.6309 236.83 23.6309 236.72 23.6309 236.611C23.8507 236.282 23.9606 236.063 23.9606 235.735C23.9606 235.625 23.9606 235.516 23.8507 235.516C23.7408 235.297 23.4111 235.297 23.1913 235.297C23.0814 235.297 22.9714 235.297 22.8615 235.297C22.6417 235.297 22.5318 235.406 22.312 235.516C21.2129 235.297 19.4543 235.406 19.0146 236.063C18.1353 237.267 18.9047 239.238 19.6741 239.786C19.4543 240.114 19.5642 240.114 19.6741 240.114Z"
            fill="#212D60"
          />
          <path
            d="M71.1127 272.086C71.4424 271.867 71.4424 271.538 71.0027 271.319L70.4532 270.991C70.0135 270.772 69.464 270.772 69.0243 270.881C68.6946 271.1 68.6946 271.429 69.1343 271.648L69.6838 271.976C70.1235 272.305 70.673 272.305 71.1127 272.086Z"
            fill="#FFAB83"
          />
          <path
            opacity="0.43"
            d="M148.051 334.934L133.103 326.284C131.674 325.408 129.805 325.408 128.377 326.284L109.252 337.342L126.068 347.306H133.323L148.161 338.109C149.26 337.342 149.26 335.591 148.051 334.934Z"
            fill="#0C25BA"
          />
          <path
            d="M143.874 327.05V336.357C143.874 336.795 143.654 337.233 143.215 337.561L127.387 346.759C125.959 347.635 124.2 347.635 122.661 346.759L106.834 337.561C106.394 337.342 106.174 336.795 106.174 336.357H106.065V327.05H143.874Z"
            fill="url(#paint145_linear_70_113170)"
          />
          <path
            d="M127.277 337.342L143.105 328.145C143.984 327.598 143.984 326.284 143.105 325.846L127.168 316.649C125.849 315.882 124.2 315.882 122.881 316.649L106.724 326.065C105.845 326.612 105.845 327.817 106.724 328.364L122.551 337.561C123.98 338.109 125.849 338.109 127.277 337.342Z"
            fill="#F0F0FC"
          />
          <path
            d="M147.171 314.897C146.622 314.897 146.182 314.787 145.852 314.568L128.706 304.605C128.377 304.386 128.157 304.057 128.157 303.729C128.157 303.291 128.486 302.962 128.926 302.634L135.081 299.13C135.96 298.583 137.389 298.583 138.159 299.021L155.305 308.984C155.635 309.203 155.854 309.532 155.854 309.86C155.854 310.298 155.525 310.627 155.085 310.955L148.93 314.459C148.38 314.787 147.721 314.897 147.171 314.897ZM145.962 314.349C146.622 314.787 147.941 314.678 148.71 314.24L154.865 310.736C155.305 310.517 155.525 310.189 155.525 309.86C155.525 309.641 155.415 309.422 155.085 309.203L137.939 299.239C137.279 298.802 135.96 298.911 135.191 299.349L129.036 302.853C128.596 303.072 128.377 303.4 128.377 303.729C128.377 303.948 128.486 304.167 128.816 304.386L145.962 314.349Z"
            fill="url(#paint146_linear_70_113170)"
          />
          <path
            d="M124.859 319.605C126.948 318.838 129.036 318.072 131.124 317.196C133.213 316.43 136.51 315.225 137.06 318.619C137.279 319.714 137.169 321.028 136.95 322.233L133.762 320.371C133.762 319.933 133.762 319.605 133.762 319.605C133.762 319.605 131.674 320.371 130.905 320.7C129.146 321.357 127.387 322.014 125.629 322.671C123.76 323.328 122.991 320.262 124.859 319.605Z"
            fill="url(#paint147_linear_70_113170)"
          />
          <path
            d="M129.805 322.342C131.894 321.576 133.982 320.809 136.07 320.043C136.84 319.714 137.609 319.386 138.488 319.276C140.137 319.167 141.676 320.262 142.006 321.904C142.116 322.89 142.116 323.875 141.896 324.97L138.818 323.109V322.999C138.708 322.452 138.928 322.561 138.488 322.561C137.939 322.452 136.4 323.328 135.851 323.547C134.202 324.203 132.443 324.86 130.795 325.408C128.706 326.174 127.827 323.109 129.805 322.342Z"
            fill="url(#paint148_linear_70_113170)"
          />
          <path
            d="M128.816 305.481C129.256 305.152 129.256 304.714 128.706 304.386L127.717 303.838C127.168 303.51 126.288 303.51 125.849 303.729C125.409 304.057 125.409 304.495 125.959 304.824L126.948 305.371C127.497 305.7 128.377 305.809 128.816 305.481Z"
            fill="#FF7A93"
          />
          <path
            d="M139.807 308.327C138.928 308.765 138.159 309.313 137.279 309.751C136.29 310.298 135.081 311.393 133.982 311.612C133.323 311.831 132.663 311.284 132.114 310.627L130.905 311.503C131.564 312.597 132.223 313.692 133.762 313.473C134.971 313.364 136.18 312.269 137.169 311.722C138.379 311.065 139.588 310.408 140.687 309.751C141.566 309.203 140.687 307.889 139.807 308.327Z"
            fill="#FF969B"
          />
          <path
            d="M123.321 315.992C123.76 312.707 123.98 309.86 124.749 306.685C125.739 302.743 127.387 303.181 128.596 304.057C130.355 305.262 131.124 306.247 131.124 309.422C131.344 309.86 131.674 310.408 132.004 310.736L131.014 311.393C131.014 311.612 131.014 311.722 131.014 311.941C130.685 316.539 130.245 316.101 131.234 322.78C131.344 323.547 131.454 324.532 131.124 325.189C130.465 326.284 129.256 326.284 128.157 325.955C123.54 324.751 122.661 321.576 123.321 315.992Z"
            fill="url(#paint149_linear_70_113170)"
          />
          <path
            d="M126.948 301.101C126.948 302.086 126.948 303.072 127.058 304.057C127.058 304.714 128.706 304.714 128.706 304.057C128.706 303.072 128.706 302.086 128.596 301.101C128.596 300.444 126.948 300.444 126.948 301.101Z"
            fill="#FF969B"
          />
          <path
            d="M127.607 301.867C127.497 301.867 127.278 301.758 127.168 301.648C126.398 300.772 126.508 299.021 126.948 297.816C127.278 297.05 127.607 296.612 128.816 296.612C129.146 296.612 130.025 296.502 130.355 296.94C130.465 296.94 130.575 297.05 130.575 297.05V299.568C130.575 300.772 129.586 301.867 128.267 301.867H127.607Z"
            fill="#FF969B"
          />
          <path
            d="M130.025 298.802C129.586 299.13 129.146 299.349 128.816 299.787C128.596 300.115 128.487 300.663 128.267 300.991C128.047 301.429 127.607 301.648 127.168 301.429C126.398 300.991 125.409 298.254 126.288 296.612C126.948 295.407 129.806 295.626 130.465 296.283C131.234 297.159 130.575 298.364 130.025 298.802Z"
            fill="#FF7A93"
          />
          <path
            d="M124.859 298.473C124.64 299.349 124.969 300.444 124.42 301.21C123.87 302.086 121.672 302.634 120.463 304.167C118.375 306.794 119.913 310.955 123.76 311.612C127.497 312.269 129.476 308.765 127.497 305.59C126.508 304.057 128.047 304.167 128.596 303.291C128.816 302.853 129.146 302.086 128.926 301.648C128.706 301.21 128.706 301.21 128.596 300.663C127.937 298.583 130.465 299.458 130.905 297.707C131.564 294.75 125.519 294.641 124.859 298.473Z"
            fill="url(#paint150_linear_70_113170)"
          />
          <path
            d="M139.368 309.422C139.148 308.984 139.258 308.437 139.697 308.218L140.687 307.67C141.126 307.451 141.676 307.561 141.896 307.999C142.116 308.437 142.006 308.984 141.566 309.203L140.577 309.751C140.247 310.079 139.697 309.86 139.368 309.422Z"
            fill="#FF969B"
          />
          <path
            opacity="0.43"
            d="M67.9252 182.522L54.4061 174.638C53.0872 173.872 51.4385 173.872 50.1196 174.638L37.15 186.026L48.0313 193.799H54.6259L68.1451 185.478C69.0244 184.821 69.0243 183.179 67.9252 182.522Z"
            fill="#0C25BA"
          />
          <path
            d="M61.5504 147.047H33.1932V185.588C33.1932 185.916 33.4131 186.245 33.7428 186.464L45.6132 193.361C46.7123 194.018 48.0313 194.018 49.1304 193.361L61.0008 186.464C61.3306 186.245 61.5504 185.916 61.4405 185.588H61.5504V147.047Z"
            fill="url(#paint151_linear_70_113170)"
          />
          <path
            d="M45.6135 154.93L33.743 148.032C33.0835 147.594 33.0835 146.609 33.743 146.28L45.7234 139.382C46.7126 138.835 47.9216 138.835 48.9108 139.382L61.0011 146.39C61.6605 146.718 61.6605 147.704 61.0011 148.142L49.1306 155.04C48.0315 155.478 46.7126 155.478 45.6135 154.93Z"
            fill="#F0F0FC"
          />
          <path
            d="M46.2728 152.85L37.6997 147.813C37.2601 147.485 37.2601 146.828 37.6997 146.499L46.3827 141.463C47.0422 141.025 48.0314 141.025 48.6909 141.463L57.4838 146.499C57.9234 146.718 57.9234 147.485 57.4838 147.704L48.9107 152.631C48.0314 153.288 47.1521 153.288 46.2728 152.85Z"
            fill="#212D60"
          />
          <path
            d="M37.6995 147.266L46.3826 142.229C47.1519 141.791 48.0312 141.791 48.6907 142.229L57.4836 147.266C57.5935 147.375 57.7034 147.375 57.7034 147.485C57.9233 147.156 57.8133 146.718 57.4836 146.499L48.6907 141.463C47.9213 141.025 47.042 141.025 46.3826 141.463L37.6995 146.499C37.3698 146.718 37.2599 147.156 37.4797 147.485C37.4797 147.375 37.5896 147.266 37.6995 147.266Z"
            fill="url(#paint152_linear_70_113170)"
          />
          <path
            d="M53.8566 157.339C53.8566 156.791 54.1863 156.134 54.6259 155.915C55.0656 155.696 55.3953 155.915 55.3953 156.463V170.697L53.8566 171.573V157.339Z"
            fill="#212D60"
          />
          <path
            d="M55.2855 170.697V183.288C55.2855 183.836 54.9557 184.493 54.5161 184.712C54.0764 184.931 53.7467 184.712 53.7467 184.164V171.573L55.2855 170.697Z"
            fill="url(#paint153_linear_70_113170)"
          />
          <path
            d="M50.6691 145.404C49.2403 144.638 47.3718 144.419 45.7231 144.857V145.842C46.9321 145.295 48.5808 145.404 49.6799 145.952C50.889 146.609 50.889 147.704 49.6799 148.361C48.4709 149.018 46.6024 149.018 45.3934 148.361L44.4042 148.908C46.1628 149.893 49.0205 149.893 50.779 148.908C52.4277 148.032 52.4277 146.39 50.6691 145.404Z"
            fill="#FFD33F"
          />
          <path
            opacity="0.43"
            d="M253.896 355.737L240.267 347.854C238.948 347.087 237.299 347.087 235.87 347.854L222.901 359.241L233.782 367.015H240.376L253.896 358.693C255.105 358.036 254.995 356.394 253.896 355.737Z"
            fill="#0C25BA"
          />
          <path
            d="M247.521 320.262H219.054V359.022C219.054 359.35 219.273 359.679 219.603 359.898L231.583 366.796C232.683 367.453 234.002 367.453 235.101 366.796L247.081 359.898C247.411 359.679 247.631 359.35 247.521 359.022H247.631V320.262H247.521Z"
            fill="url(#paint154_linear_70_113170)"
          />
          <path
            d="M231.584 328.145L219.603 321.247C218.944 320.809 218.944 319.824 219.603 319.495L231.584 312.488C232.573 311.941 233.782 311.941 234.771 312.488L246.971 319.495C247.631 319.824 247.631 320.809 246.971 321.247L234.991 328.145C234.002 328.693 232.683 328.693 231.584 328.145Z"
            fill="#F0F0FC"
          />
          <path
            d="M232.133 325.517L223.56 320.481C223.12 320.152 223.12 319.495 223.56 319.167L232.243 314.13C233.012 313.692 233.892 313.692 234.551 314.13L243.344 319.167C243.784 319.386 243.784 320.152 243.344 320.371L234.771 325.408C233.892 325.955 232.902 325.955 232.133 325.517Z"
            fill="#212D60"
          />
          <path
            d="M223.45 319.824L232.133 314.787C232.902 314.349 233.782 314.349 234.441 314.787L243.234 319.824C243.344 319.933 243.454 319.933 243.454 320.043C243.674 319.714 243.564 319.276 243.234 319.057L234.441 314.021C233.672 313.583 232.792 313.583 232.133 314.021L223.45 319.057C223.12 319.276 223.01 319.714 223.23 320.043C223.23 320.043 223.34 319.933 223.45 319.824Z"
            fill="url(#paint155_linear_70_113170)"
          />
          <path
            d="M230.155 357.489L223.01 353.328C222.461 353 222.131 352.452 222.131 351.795V328.036H222.021C221.582 327.817 221.032 328.145 221.032 328.583V352.562C221.032 353.219 221.362 353.766 221.911 354.095L229.056 358.255C229.715 358.584 230.484 358.255 230.594 357.598C230.484 357.598 230.374 357.598 230.155 357.489Z"
            fill="#36399F"
          />
          <path
            d="M230.704 334.277C230.704 333.51 230.265 332.744 229.605 332.415L222.241 328.145V351.905C222.241 352.562 222.571 353.109 223.12 353.438L230.265 357.598C230.374 357.708 230.594 357.708 230.704 357.708C230.704 357.598 230.704 357.489 230.704 357.379V334.277Z"
            fill="#212D60"
          />
          <path
            d="M224.109 351.248C223.89 351.138 223.67 350.7 223.67 350.372V331.43C223.67 331.101 223.89 330.882 224.109 331.101C224.329 331.211 224.549 331.649 224.549 331.977V350.919C224.659 351.248 224.439 351.357 224.109 351.248Z"
            fill="url(#paint156_linear_70_113170)"
          />
          <path
            d="M226.088 352.343C225.868 352.233 225.648 351.795 225.648 351.467V338.985C225.648 338.656 225.868 338.547 226.088 338.656C226.308 338.766 226.528 339.204 226.528 339.532V352.014C226.528 352.343 226.308 352.452 226.088 352.343Z"
            fill="white"
          />
          <path
            d="M227.956 334.824C227.737 334.715 227.517 334.824 227.517 335.153V340.189V347.525V352.562C227.517 352.89 227.737 353.328 227.956 353.438C228.176 353.547 228.396 353.438 228.396 353.109V348.073V340.737V335.7C228.396 335.372 228.286 334.934 227.956 334.824Z"
            fill="url(#paint157_linear_70_113170)"
          />
          <path
            d="M43.5249 232.888C45.943 234.311 47.9214 233.216 47.9214 230.37C47.9214 227.523 45.943 224.129 43.5249 222.705V220.296C47.152 222.377 50.1196 227.523 50.1196 231.683C50.1196 235.844 47.152 237.486 43.5249 235.406V232.888Z"
            fill="url(#paint158_linear_70_113170)"
          />
          <path
            opacity="0.38"
            d="M43.6348 220.296V222.705C41.2168 221.391 39.2383 222.486 39.2383 225.223L37.15 224.019C37.15 219.858 40.0077 218.216 43.6348 220.296Z"
            fill="#9898ED"
          />
          <path
            d="M224.879 361.54L223.78 360.883C223.56 360.774 223.45 360.445 223.45 360.226C223.45 360.007 223.56 359.898 223.78 360.007L224.879 360.664C225.099 360.774 225.209 361.102 225.209 361.321C225.209 361.54 225.099 361.65 224.879 361.54Z"
            fill="url(#paint159_linear_70_113170)"
          />
          <path
            d="M114.418 340.737L113.319 340.08C113.099 339.97 112.989 339.642 112.989 339.423C112.989 339.204 113.099 339.094 113.319 339.204L114.418 339.861C114.638 339.97 114.748 340.299 114.748 340.518C114.748 340.737 114.638 340.846 114.418 340.737Z"
            fill="url(#paint160_linear_70_113170)"
          />
          <path
            d="M39.8978 188.763L38.7987 188.106C38.5789 187.996 38.469 187.668 38.469 187.449C38.469 187.23 38.5789 187.12 38.7987 187.23L39.8978 187.887C40.1177 187.996 40.2276 188.325 40.2276 188.544C40.2276 188.763 40.0078 188.872 39.8978 188.763Z"
            fill="url(#paint161_linear_70_113170)"
          />
          <path
            d="M240.486 102.265L241.585 101.608C241.805 101.498 241.915 101.17 241.915 100.951C241.915 100.732 241.805 100.622 241.585 100.732L240.486 101.389C240.266 101.498 240.156 101.827 240.156 102.046C240.156 102.265 240.376 102.374 240.486 102.265Z"
            fill="url(#paint162_linear_70_113170)"
          />
          <path
            d="M60.5612 212.084L60.4513 211.975L29.5662 194.128L39.1285 187.887L39.4582 188.215L30.5554 194.128L60.5612 211.537L129.586 170.697L129.805 171.135L60.5612 212.084Z"
            fill="url(#paint163_linear_70_113170)"
          />
          <path
            d="M430.523 245.589C429.314 256.538 421.401 267.378 407.002 275.699C375.238 294.093 323.579 293.984 291.595 275.48C288.957 273.947 283.791 270.991 283.791 270.991L114.418 172.777H114.528C103.976 165.989 98.4807 157.448 98.0411 148.799V206.281C97.4915 215.698 102.877 225.223 114.528 232.778H114.418L283.791 330.992C283.791 330.992 288.957 333.948 291.595 335.481C323.579 353.985 375.238 354.095 407.002 335.7C424.258 325.736 432.062 312.378 430.523 299.239V245.589Z"
            fill="url(#paint164_linear_70_113170)"
          />
          <path
            d="M114.528 172.777L283.791 270.991C283.791 270.991 288.957 273.947 291.595 275.48C323.579 293.984 375.238 294.093 407.002 275.699C421.401 267.378 429.314 256.538 430.523 245.589C432.062 232.45 424.039 218.873 406.672 208.8C400.737 205.406 394.252 202.559 387.218 200.478C398.209 179.018 389.087 154.821 359.74 137.849C321.491 115.732 262.139 113.433 219.823 130.951C216.855 127.557 212.899 124.382 207.953 121.535C182.673 106.863 141.786 106.754 116.616 121.316C104.636 128.214 98.3708 137.302 97.9312 146.39C97.9312 147.156 97.9312 147.923 97.9312 148.689C98.4807 157.448 103.976 165.879 114.528 172.777Z"
            fill="#F5F5FF"
          />
          <path
            d="M156.074 172.339L170.143 164.127H170.033L190.587 152.193C199.929 146.828 211.36 146.828 220.592 152.193L356.553 231.026C360.4 233.216 362.488 236.939 363.038 240.771C366.005 234.859 364.357 227.085 357.982 223.362L222.021 144.528C212.679 139.163 201.248 139.163 192.015 144.528L171.462 156.463H171.572L157.503 164.675C153.107 167.193 151.238 172.12 152.008 176.5C152.887 174.857 154.206 173.434 156.074 172.339Z"
            fill="url(#paint165_linear_70_113170)"
          />
          <path
            d="M157.393 184.383L291.156 261.903C303.356 269.02 318.414 269.02 330.614 261.903L357.872 246.136C360.18 244.822 361.939 242.961 362.928 240.771C362.488 236.939 360.29 233.216 356.443 231.026L220.592 152.193C211.25 146.828 199.819 146.828 190.587 152.193L170.033 164.127H170.143L156.074 172.339C154.206 173.434 152.887 174.857 151.898 176.5C152.447 179.675 154.316 182.631 157.393 184.383Z"
            fill="url(#paint166_linear_70_113170)"
          />
          <path
            d="M157.393 184.383L291.156 261.903C303.356 269.02 318.414 269.02 330.614 261.903L357.872 246.136C360.18 244.822 361.939 242.961 362.928 240.771C362.488 236.939 360.29 233.216 356.443 231.026L220.592 152.193C211.25 146.828 199.819 146.828 190.587 152.193L170.033 164.127H170.143L156.074 172.339C154.206 173.434 152.887 174.857 151.898 176.5C152.447 179.675 154.316 182.631 157.393 184.383Z"
            fill="#212D60"
          />
          <path
            d="M285.33 247.341C274.449 240.99 274.339 230.807 285.22 224.457C296.102 218.216 313.797 218.216 324.679 224.566C335.56 230.917 335.67 241.1 324.789 247.45C313.907 253.691 296.212 253.582 285.33 247.341ZM323.36 225.223C313.138 219.311 296.651 219.311 286.539 225.114C276.428 231.026 276.428 240.552 286.649 246.465C296.871 252.377 313.358 252.377 323.47 246.574C333.581 240.771 333.472 231.136 323.36 225.223Z"
            fill="#474AB6"
          />
          <path
            d="M292.914 242.851C286.21 239.019 286.21 232.669 292.804 228.837C299.509 225.004 310.28 225.004 316.985 228.837C323.689 232.669 323.689 239.019 317.095 242.851C310.5 246.793 299.619 246.793 292.914 242.851ZM316.435 229.275C310.06 225.552 299.839 225.552 293.464 229.275C287.199 232.888 287.199 238.91 293.574 242.523C299.949 246.246 310.17 246.246 316.545 242.523C322.81 238.91 322.7 232.997 316.435 229.275Z"
            fill="#474AB6"
          />
          <path
            d="M326.327 245.698C325.008 244.932 323.03 244.932 321.711 245.698L321.601 245.808C312.369 251.063 297.421 250.954 288.188 245.589C278.846 240.224 278.846 231.464 288.078 226.099C297.201 220.844 312.149 220.734 321.381 225.99C321.381 225.99 321.381 225.99 321.491 225.99C321.491 225.99 321.601 225.99 321.601 226.099C321.601 226.099 321.601 226.099 321.711 226.099C323.03 226.756 324.898 226.756 326.107 225.99C327.316 225.333 327.316 224.238 326.217 223.472C326.107 223.362 326.107 223.362 325.998 223.362C325.888 223.362 325.778 223.253 325.778 223.253C313.907 216.683 295.112 216.683 283.462 223.362C271.701 230.151 271.811 241.319 283.572 248.107C295.222 254.896 314.127 255.005 325.888 248.436C325.998 248.436 326.107 248.326 326.217 248.326C327.536 247.669 327.536 246.465 326.327 245.698Z"
            fill="url(#paint167_linear_70_113170)"
          />
          <path
            d="M318.084 228.289C310.83 224.128 299.069 224.019 291.815 228.18C291.046 228.618 291.046 229.384 291.815 229.822C292.584 230.26 293.903 230.26 294.673 229.822C294.673 229.822 294.673 229.822 294.783 229.713C300.498 226.537 309.731 226.537 315.336 229.822C321.052 233.107 321.161 238.581 315.446 241.866C309.731 245.151 300.388 245.151 294.673 241.866C293.903 241.428 292.584 241.428 291.815 241.866C291.046 242.304 291.046 243.07 291.815 243.508C299.179 247.779 310.94 247.779 318.194 243.618C325.448 239.348 325.448 232.559 318.084 228.289Z"
            fill="white"
          />
          <path
            d="M166.626 176.938L205.425 199.493L205.754 199.274L166.956 176.828L166.626 176.938Z"
            fill="#474AB6"
          />
          <path
            d="M174.539 172.449L213.338 194.894L213.668 194.675L174.869 172.23L174.539 172.449Z"
            fill="#474AB6"
          />
          <path
            d="M182.783 167.631L221.582 190.077L221.911 189.967L183.113 167.412L182.783 167.631Z"
            fill="#474AB6"
          />
          <path
            d="M190.696 163.032L229.605 185.478L229.935 185.259L191.026 162.813L190.696 163.032Z"
            fill="#474AB6"
          />
          <path
            d="M197.511 159.091L236.31 181.536L236.639 181.427L197.841 158.872L197.511 159.091Z"
            fill="#474AB6"
          />
          <path
            d="M204.325 155.149L243.124 177.595L243.454 177.376L204.655 154.93L204.325 155.149Z"
            fill="#474AB6"
          />
          <path
            d="M211.03 193.252C210.371 193.69 209.381 193.69 208.722 193.361L184.651 179.456C184.102 179.128 184.102 178.58 184.761 178.142C185.421 177.704 186.41 177.704 187.069 178.033L211.14 191.938C211.799 192.267 211.69 192.814 211.03 193.252Z"
            fill="url(#paint168_linear_70_113170)"
          />
          <path
            d="M240.926 176.062C240.267 176.5 239.277 176.5 238.618 176.171L218.834 164.675C218.284 164.346 218.284 163.799 218.944 163.361C219.603 162.923 220.592 162.923 221.252 163.251L241.036 174.748C241.695 175.076 241.585 175.733 240.926 176.062Z"
            fill="url(#paint169_linear_70_113170)"
          />
          <path
            d="M227.077 183.945C226.418 184.383 225.428 184.383 224.769 184.055L210.041 175.514C209.491 175.186 209.491 174.638 210.151 174.201C210.81 173.763 211.799 173.763 212.459 174.091L227.187 182.631C227.846 182.96 227.737 183.507 227.077 183.945Z"
            fill="url(#paint170_linear_70_113170)"
          />
          <path
            d="M201.138 196.646C200.479 197.084 199.489 197.084 198.83 196.756L197.401 195.88C196.852 195.551 196.852 195.004 197.511 194.566C198.17 194.128 199.16 194.128 199.819 194.456L201.248 195.332C201.907 195.661 201.798 196.318 201.138 196.646Z"
            fill="#FFD33F"
          />
          <path
            d="M215.976 186.573C215.317 187.011 214.327 187.011 213.668 186.682L205.425 181.865C204.875 181.536 204.875 180.989 205.535 180.551C206.194 180.113 207.183 180.113 207.843 180.441L216.086 185.259C216.746 185.588 216.636 186.245 215.976 186.573Z"
            fill="#FFD33F"
          />
          <path
            d="M256.753 212.632C256.533 212.741 256.204 212.741 256.094 212.632C255.874 212.522 255.874 212.303 256.094 212.194C256.314 212.084 256.643 212.084 256.753 212.194C256.973 212.303 256.973 212.522 256.753 212.632Z"
            fill="#474AB6"
          />
          <path
            d="M258.622 213.617C258.402 213.727 258.072 213.727 257.962 213.617C257.743 213.508 257.743 213.289 257.962 213.179C258.182 213.07 258.512 213.07 258.622 213.179C258.732 213.398 258.732 213.508 258.622 213.617Z"
            fill="#474AB6"
          />
          <path
            d="M260.38 214.712C260.161 214.822 259.831 214.822 259.721 214.712C259.501 214.603 259.501 214.384 259.721 214.274C259.941 214.165 260.27 214.165 260.38 214.274C260.6 214.493 260.6 214.603 260.38 214.712Z"
            fill="#474AB6"
          />
          <path
            d="M262.249 215.807C262.029 215.917 261.699 215.917 261.589 215.807C261.37 215.698 261.37 215.479 261.589 215.369C261.809 215.26 262.139 215.26 262.249 215.369C262.469 215.479 262.469 215.698 262.249 215.807Z"
            fill="#474AB6"
          />
          <path
            d="M264.007 216.902C263.788 217.012 263.458 217.012 263.348 216.902C263.128 216.793 263.128 216.574 263.348 216.464C263.568 216.355 263.898 216.355 264.007 216.464C264.227 216.574 264.227 216.793 264.007 216.902Z"
            fill="#474AB6"
          />
          <path
            d="M265.876 217.887C265.656 217.997 265.326 217.997 265.217 217.887C264.997 217.778 264.997 217.559 265.217 217.449C265.436 217.34 265.766 217.34 265.876 217.449C266.096 217.668 266.096 217.778 265.876 217.887Z"
            fill="#474AB6"
          />
          <path
            d="M267.744 218.982C267.525 219.092 267.195 219.092 267.085 218.982C266.865 218.873 266.865 218.654 267.085 218.544C267.305 218.435 267.634 218.435 267.744 218.544C267.964 218.654 267.964 218.873 267.744 218.982Z"
            fill="#474AB6"
          />
          <path
            d="M269.503 220.077C269.283 220.187 268.953 220.187 268.844 220.077C268.624 219.968 268.624 219.749 268.844 219.639C269.063 219.53 269.393 219.53 269.503 219.639C269.723 219.749 269.723 219.968 269.503 220.077Z"
            fill="#474AB6"
          />
          <path
            d="M271.372 221.063C271.152 221.172 270.822 221.172 270.712 221.063C270.492 220.953 270.492 220.734 270.712 220.625C270.932 220.515 271.262 220.515 271.372 220.625C271.591 220.844 271.591 220.953 271.372 221.063Z"
            fill="#474AB6"
          />
          <path
            d="M273.24 222.158C273.02 222.267 272.69 222.267 272.581 222.158C272.361 222.048 272.361 221.829 272.581 221.72C272.8 221.61 273.13 221.61 273.24 221.72C273.35 221.829 273.35 222.048 273.24 222.158Z"
            fill="#474AB6"
          />
          <path
            d="M267.195 206.938L260.38 210.88L259.501 210.442L266.316 206.5L267.195 206.938Z"
            fill="#474AB6"
          />
          <path
            d="M268.953 207.924L262.029 211.975L261.15 211.428L268.074 207.486L268.953 207.924Z"
            fill="#474AB6"
          />
          <path
            d="M270.602 209.019L263.788 212.96L262.908 212.413L269.723 208.471L270.602 209.019Z"
            fill="#474AB6"
          />
          <path
            d="M277.966 206.719L265.546 213.946L264.667 213.398L277.087 206.281L277.966 206.719Z"
            fill="#474AB6"
          />
          <path
            d="M278.626 210.004L271.811 213.946L270.932 213.508L277.746 209.566L278.626 210.004Z"
            fill="#474AB6"
          />
          <path
            d="M280.384 210.99L273.46 214.931L272.581 214.493L279.505 210.552L280.384 210.99Z"
            fill="#474AB6"
          />
          <path
            d="M282.033 212.084L275.218 216.026L274.339 215.479L281.154 211.537L282.033 212.084Z"
            fill="#474AB6"
          />
          <path
            d="M281.154 197.851L267.195 205.953L268.074 206.5L282.033 198.398L281.154 197.851Z"
            fill="#474AB6"
          />
          <path
            d="M282.802 199.931L269.833 207.486L268.953 206.938L281.923 199.493L282.802 199.931Z"
            fill="#474AB6"
          />
          <path
            d="M284.671 199.821L270.602 207.924L271.481 208.471L285.55 200.369L284.671 199.821Z"
            fill="#474AB6"
          />
          <path
            d="M286.319 200.916L279.395 204.967L280.274 205.405L287.199 201.354L286.319 200.916Z"
            fill="#474AB6"
          />
          <path
            d="M289.507 202.668L278.626 209.019L279.505 209.566L290.386 203.216L289.507 202.668Z"
            fill="#474AB6"
          />
          <path
            d="M291.266 203.654L280.384 210.004L281.264 210.552L292.145 204.201L291.266 203.654Z"
            fill="#474AB6"
          />
          <path
            d="M293.024 204.749L282.033 210.99L282.912 211.537L293.903 205.186L293.024 204.749Z"
            fill="#474AB6"
          />
          <path
            d="M287.968 211.099L281.044 215.041L280.164 214.493L287.089 210.552L287.968 211.099Z"
            fill="#474AB6"
          />
          <path
            d="M295.112 205.953L287.968 210.004L288.847 210.552L295.992 206.391L295.112 205.953Z"
            fill="#474AB6"
          />
          <path
            d="M296.871 206.938L281.923 215.479L282.802 216.026L297.75 207.376L296.871 206.938Z"
            fill="#474AB6"
          />
          <path
            d="M298.52 207.924L283.682 216.464L284.561 217.012L299.399 208.362L298.52 207.924Z"
            fill="#474AB6"
          />
          <path
            d="M253.236 214.055L247.741 217.231L248.62 217.778L254.115 214.493L253.236 214.055Z"
            fill="#474AB6"
          />
          <path
            d="M254.995 215.041L249.389 218.216L250.269 218.763L255.874 215.479L254.995 215.041Z"
            fill="#474AB6"
          />
          <path
            d="M258.402 217.012L249.939 221.939L250.818 222.377L259.281 217.559L258.402 217.012Z"
            fill="#474AB6"
          />
          <path
            d="M253.126 223.691L254.006 224.238L260.71 220.406L259.721 219.968L253.126 223.691Z"
            fill="#474AB6"
          />
          <path
            d="M244.773 218.873L245.652 219.42L247.741 218.216L246.861 217.778L244.773 218.873Z"
            fill="#474AB6"
          />
          <path
            d="M246.531 219.858L247.411 220.406L249.389 219.311L248.51 218.763L246.531 219.858Z"
            fill="#474AB6"
          />
          <path
            d="M258.732 226.975L259.611 227.523L262.469 225.88L261.589 225.333L258.732 226.975Z"
            fill="#474AB6"
          />
          <path
            d="M260.38 227.961L261.26 228.508L264.117 226.866L263.238 226.318L260.38 227.961Z"
            fill="#474AB6"
          />
          <path
            d="M241.805 199.931C241.695 199.821 241.695 199.821 241.695 199.821C235.76 196.537 226.418 196.537 220.592 199.931L224.11 201.902C228.066 199.602 234.441 199.712 238.398 201.902C242.355 204.201 242.355 207.924 238.398 210.223C234.441 212.522 227.956 212.522 223.89 210.223C223.89 210.223 223.89 210.223 223.78 210.223L220.373 212.194C220.373 212.194 220.373 212.194 220.482 212.194C226.418 215.588 235.98 215.588 241.915 212.194C247.741 208.909 247.741 203.325 241.805 199.931Z"
            fill="white"
          />
          <path
            d="M224.11 201.902C224 201.902 224 201.902 224.11 201.902C220.043 204.201 220.043 207.924 224 210.333L220.592 212.303C214.767 208.909 214.767 203.325 220.592 199.931L224.11 201.902Z"
            fill="#474AB6"
          />
          <path
            d="M247.85 196.975C251.807 199.274 258.402 199.274 262.359 196.975C266.316 194.675 266.316 190.953 262.359 188.544H262.249L265.656 186.573H265.766C271.701 189.967 271.701 195.551 265.876 198.946C259.941 202.34 250.378 202.34 244.443 198.946C244.443 198.946 244.443 198.946 244.333 198.946L247.85 196.975Z"
            fill="#474AB6"
          />
          <path
            d="M265.876 186.683L262.469 188.653C258.512 186.354 252.137 186.354 248.18 188.653L244.663 186.683C250.378 183.288 259.941 183.288 265.876 186.683Z"
            fill="#FFD33F"
          />
          <path
            d="M248.07 188.653C244.004 190.953 244.004 194.675 247.96 197.084L244.553 199.055C238.728 195.661 238.728 190.077 244.553 186.683C244.553 186.573 248.07 188.653 248.07 188.653Z"
            fill="#474AB6"
          />
          <path
            d="M22.4219 292.451L3.18738 281.94L8.90277 278.327L9.1226 278.765L4.17658 281.83L22.312 291.904L125.629 231.902L125.849 232.231L22.4219 292.451Z"
            fill="url(#paint171_linear_70_113170)"
          />
          <path
            opacity="0.43"
            d="M91.4464 302.086L76.6083 293.436C75.1795 292.561 73.311 292.561 71.8821 293.436L52.8674 304.386L69.574 314.349H76.7182L91.4464 305.262C92.6554 304.605 92.6554 302.853 91.4464 302.086Z"
            fill="#0C25BA"
          />
          <path
            d="M87.2697 294.312V303.619C87.2697 304.057 87.0499 304.495 86.6103 304.824L70.8929 313.911C69.4641 314.787 67.7055 314.787 66.2766 313.911L50.5593 304.824C50.1197 304.605 49.8998 304.057 49.8998 303.619H49.7899V294.312H87.2697Z"
            fill="url(#paint172_linear_70_113170)"
          />
          <path
            d="M70.8929 304.495L86.6102 295.407C87.4895 294.86 87.4895 293.546 86.6102 293.108L70.783 283.911C69.464 283.144 67.8153 283.144 66.6063 283.911L50.5592 293.108C49.68 293.656 49.68 294.86 50.5592 295.298L66.2766 304.386C67.7054 305.371 69.464 305.371 70.8929 304.495Z"
            fill="#F0F0FC"
          />
          <path
            d="M90.677 282.268C90.1274 282.268 89.6878 282.159 89.3581 281.94L72.3218 272.086C71.992 271.867 71.7722 271.538 71.7722 271.21C71.7722 270.772 72.1019 270.443 72.5416 270.115L78.5867 266.611C79.466 266.064 80.8949 266.064 81.6642 266.502L98.7005 276.356C99.0303 276.575 99.2501 276.903 99.2501 277.232C99.2501 277.67 98.9203 277.998 98.4807 278.327L92.4356 281.83C91.886 282.049 91.2265 282.268 90.677 282.268ZM89.468 281.721C90.1274 282.159 91.4464 282.049 92.2157 281.611L98.2609 278.108C98.7005 277.889 98.9203 277.56 98.9203 277.232C98.9203 277.013 98.8104 276.794 98.4807 276.575L81.4444 266.721C80.785 266.283 79.466 266.392 78.6966 266.83L72.6515 270.334C72.2119 270.553 71.992 270.881 71.992 271.21C71.992 271.429 72.1019 271.648 72.4317 271.867L89.468 281.721Z"
            fill="url(#paint173_linear_70_113170)"
          />
          <path
            d="M83.8625 273.619C84.1922 273.29 84.7418 273.29 85.1814 273.619C85.5112 273.947 85.5112 274.494 85.1814 274.932L84.1922 275.918C83.8625 276.246 83.3129 276.246 82.8733 275.918C82.5435 275.589 82.5435 275.042 82.8733 274.604L83.8625 273.619Z"
            fill="#FF969B"
          />
          <path
            d="M79.466 272.414C79.7957 272.086 80.3453 272.086 80.7849 272.414C81.1147 272.743 81.1147 273.29 80.7849 273.728L79.7957 274.713C79.466 275.042 78.9164 275.042 78.4768 274.713C78.1471 274.385 78.1471 273.838 78.4768 273.4L79.466 272.414Z"
            fill="#FF969B"
          />
          <path
            d="M79.3561 275.042C78.257 275.699 77.0479 276.356 75.9488 276.903C74.9596 277.451 73.7506 278.436 72.5416 278.436C71.1127 278.327 70.4532 277.122 69.7938 276.027C69.0244 274.932 68.255 273.838 67.4856 272.743C66.8262 271.757 68.3649 270.881 69.1343 271.757C69.6839 272.524 70.3433 273.4 70.8929 274.166C71.4425 274.823 72.1019 276.575 73.201 276.356C74.1902 276.137 75.2894 275.151 76.1686 274.713C76.938 274.276 77.8173 273.838 78.5867 273.29C79.466 272.852 80.4552 274.494 79.3561 275.042Z"
            fill="url(#paint174_linear_70_113170)"
          />
          <path
            d="M74.52 283.144C71.8821 284.349 69.2442 286.101 66.7163 287.524C65.0676 288.509 66.4965 291.028 68.255 290.152C70.5632 288.838 72.7614 287.633 75.0696 286.32C75.1795 286.21 75.3993 286.101 75.6191 285.991C75.6191 285.882 75.729 285.772 76.0588 285.772C75.9488 285.772 75.729 285.882 75.6191 285.991C75.6191 286.101 75.729 286.32 75.729 286.648C75.729 286.757 75.729 286.758 75.729 286.867L79.2462 288.947C78.8065 284.896 77.4876 281.721 74.52 283.144Z"
            fill="url(#paint175_linear_70_113170)"
          />
          <path
            d="M75.5092 285.882C75.729 285.772 75.8389 285.663 75.9488 285.663C75.6191 285.772 75.5092 285.772 75.5092 285.882Z"
            fill="#2A4280"
          />
          <path
            d="M67.9253 263.874C68.1451 263.764 68.3649 263.764 68.5847 263.545C68.6946 263.436 68.6946 263.107 68.9145 262.998C69.2442 262.669 69.9037 262.998 70.3433 262.779C70.4532 262.669 70.5631 262.56 70.673 262.56C71.1127 262.341 71.4424 263.107 71.8821 262.998C71.992 262.998 72.1019 262.888 72.2118 262.888C72.3217 262.888 72.4316 262.998 72.4316 262.998C72.5415 263.107 72.7614 263.436 72.8713 263.655C72.9812 263.655 73.0911 263.764 73.0911 263.874C73.0911 263.983 73.201 264.093 73.201 264.202C73.201 264.531 73.0911 264.859 72.9812 265.188C72.9812 265.297 72.8713 265.516 72.8713 265.626C72.7614 265.954 72.4316 266.283 72.2118 266.502C72.1019 266.611 71.992 266.721 71.8821 266.721V267.268C71.8821 267.378 71.7722 267.487 71.6622 267.487H71.5523V267.378C71.5523 267.049 71.4424 266.721 71.2226 266.721C71.0028 266.721 70.8929 267.049 70.8929 267.378C70.8929 267.597 70.8929 267.706 71.0028 267.925C70.8929 268.144 70.783 268.472 70.673 268.801C70.4532 269.129 70.0136 269.458 69.6838 269.239C69.5739 269.239 69.5739 269.129 69.464 269.129C69.3541 269.02 69.3541 268.91 69.2442 268.801C69.1343 268.801 69.1343 268.691 69.1343 268.691C69.0244 268.582 69.0244 268.472 69.0244 268.363C68.9145 268.144 68.4748 268.035 68.4748 267.816C68.4748 267.597 68.4748 267.487 68.4748 267.268C68.3649 267.049 68.0352 266.94 67.9253 266.721C67.8153 266.502 67.9253 266.283 68.0352 265.954C68.1451 265.516 67.9253 265.078 67.7054 264.75C67.4856 264.312 67.7054 263.983 67.9253 263.874Z"
            fill="#212D60"
          />
          <path
            d="M69.5739 269.02C69.9037 269.239 70.3433 268.91 70.5632 268.582C70.6731 268.363 70.783 268.035 70.8929 267.706C70.783 267.597 70.783 267.378 70.783 267.159C70.783 266.83 70.8929 266.502 71.1127 266.502C71.3325 266.502 71.4424 266.83 71.4424 267.159C71.4424 267.268 71.4424 267.268 71.4424 267.268H71.5524C71.6623 267.268 71.7722 267.159 71.7722 267.049V266.502C71.8821 266.392 71.992 266.283 72.1019 266.283C72.3217 266.064 72.6515 265.735 72.7614 265.407V267.049C72.7614 268.144 71.8821 269.02 70.8929 269.129C70.8929 269.786 70.8929 270.553 70.8929 271.21C70.8929 271.867 69.3541 271.867 69.3541 271.21C69.3541 270.334 69.3541 269.567 69.3541 268.691C69.464 268.91 69.5739 269.02 69.5739 269.02Z"
            fill="#FF969B"
          />
          <path
            d="M77.4876 259.056L110.571 278.217C111.56 278.765 112.769 278.108 112.769 276.903V257.961C112.769 256.976 112.22 256.1 111.34 255.553L77.2678 235.844C76.938 235.735 76.6083 235.844 76.6083 236.173V257.633C76.7182 258.29 77.0479 258.837 77.4876 259.056Z"
            fill="#212D60"
          />
          <path
            d="M81.884 244.165L78.0371 241.866V242.304L81.884 244.494V244.165Z"
            fill="#71D4FF"
          />
          <path
            d="M81.884 245.589L78.0371 243.289V243.727L81.884 246.027V245.589Z"
            fill="#71D4FF"
          />
          <path
            d="M78.0371 239.457V239.895L79.356 240.662V240.224L78.0371 239.457Z"
            fill="#71D4FF"
          />
          <path
            d="M78.0371 240.333V240.662L79.356 241.428V241.1L78.0371 240.333Z"
            fill="#71D4FF"
          />
          <path
            d="M78.0371 245.917V246.246L79.1362 246.903V246.574L78.0371 245.917Z"
            fill="#71D4FF"
          />
          <path
            d="M78.0371 246.684V247.122L79.1362 247.779V247.341L78.0371 246.684Z"
            fill="#71D4FF"
          />
          <path
            d="M78.0371 249.093V249.421L80.675 250.954V250.625L78.0371 249.093Z"
            fill="#71D4FF"
          />
          <path
            d="M78.0371 250.625V251.063L80.675 252.596V252.158L78.0371 250.625Z"
            fill="#71D4FF"
          />
          <path
            d="M78.0371 251.501V251.939L79.0263 252.487V252.049L78.0371 251.501Z"
            fill="#71D4FF"
          />
          <path
            d="M78.0371 253.91V254.239L79.0263 254.896V254.458L78.0371 253.91Z"
            fill="#71D4FF"
          />
          <path
            d="M78.0371 255.443V255.881L79.0263 256.428V255.99L78.0371 255.443Z"
            fill="#71D4FF"
          />
          <path
            d="M79.6858 287.962C79.7957 287.962 79.9056 287.852 80.0155 287.852C79.6858 287.852 79.6858 287.852 79.6858 287.962Z"
            fill="#2A4280"
          />
          <path
            d="M70.3433 290.042C72.1019 288.947 73.9704 287.852 75.7289 286.757C76.6082 286.21 77.5974 285.663 78.4767 285.225C81.4443 283.801 82.5435 286.976 83.2029 289.166C83.4227 289.933 83.6426 290.59 83.7525 291.356L80.1254 289.276C80.0155 289.057 79.9056 288.728 79.9056 288.509C79.7957 288.181 79.6858 288.071 79.6858 287.962C79.7957 287.962 79.9056 287.852 80.0155 287.852C79.7957 287.852 79.6858 287.852 79.6858 287.962C79.4659 288.071 79.1362 288.29 78.9164 288.509C78.9164 288.509 78.8065 288.509 78.8065 288.619C76.4983 289.933 74.41 291.247 72.1019 292.67C70.4532 293.546 68.6946 291.028 70.3433 290.042Z"
            fill="url(#paint176_linear_70_113170)"
          />
          <path
            d="M66.1667 276.575C66.2766 274.276 67.1559 270.224 69.1343 270.772C71.0028 271.319 72.4316 271.648 73.201 273.071C73.3109 273.071 73.3109 273.181 73.4208 273.181C73.9704 273.947 76.3885 278.108 77.3777 277.779C78.3669 277.56 78.2569 277.341 82.7633 274.713C83.7525 274.166 84.7417 275.699 83.7525 276.356C78.3668 279.422 78.0371 279.86 76.938 279.75C75.729 279.75 75.2893 279.093 73.6407 276.684C73.5308 278.436 73.4208 281.721 73.5308 289.057C73.5308 289.604 73.7506 291.575 73.4208 292.123C73.0911 292.67 72.8713 293.108 72.2118 293.108C71.5523 293.108 71.0028 292.889 70.4532 292.561C65.9469 290.152 65.9469 289.604 65.837 286.867C65.5072 283.363 65.9469 279.969 66.1667 276.575Z"
            fill="url(#paint177_linear_70_113170)"
          />
          <path
            d="M84.1921 245.589V245.917L85.7309 246.903L85.8408 246.465L84.1921 245.589Z"
            fill="#626FA6"
          />
          <path
            d="M84.1921 246.355V246.793L85.7309 247.669V247.231L84.1921 246.355Z"
            fill="#626FA6"
          />
          <path
            d="M84.1921 247.231V247.56L85.7309 248.545V248.107L84.1921 247.231Z"
            fill="#626FA6"
          />
          <path
            d="M84.1921 247.998V248.436L88.039 250.735V250.297L84.1921 247.998Z"
            fill="#626FA6"
          />
          <path
            d="M84.1921 249.53V249.968L87.0498 251.611V251.173L84.1921 249.53Z"
            fill="#626FA6"
          />
          <path
            d="M84.1921 250.406V250.735L87.0498 252.487V252.049L84.1921 250.406Z"
            fill="#626FA6"
          />
          <path
            d="M94.9635 256.647L92.5454 255.224V255.662L94.9635 257.085V256.647Z"
            fill="#626FA6"
          />
          <path
            d="M84.1921 251.173V251.611L87.0498 253.253V252.815L84.1921 251.173Z"
            fill="#626FA6"
          />
          <path
            d="M93.3148 251.392L86.1705 247.122V246.684L93.3148 250.954V251.392Z"
            fill="#626FA6"
          />
          <path
            d="M91.5562 251.063L86.1705 247.888V247.45L91.5562 250.625V251.063Z"
            fill="#626FA6"
          />
          <path
            d="M94.9635 252.706L91.886 250.954V251.282L94.9635 253.034V252.706Z"
            fill="#626FA6"
          />
          <path
            d="M94.9634 253.472L86.1705 248.326V248.764L94.9634 253.91V253.472Z"
            fill="#626FA6"
          />
          <path
            d="M94.9634 254.348L89.0282 250.844V251.282L94.9634 254.786V254.348Z"
            fill="#626FA6"
          />
          <path
            d="M94.9634 255.881L87.3795 251.392V251.83L94.9634 256.209V255.881Z"
            fill="#626FA6"
          />
          <path
            d="M92.2156 255.443L87.3795 252.596V252.268L92.2156 255.115V255.443Z"
            fill="#626FA6"
          />
          <path
            d="M94.8535 257.852L87.3795 253.472V253.034L94.8535 257.414V257.852Z"
            fill="#626FA6"
          />
          <path
            d="M88.5887 255.224L85.731 253.472V253.034L88.5887 254.786V255.224Z"
            fill="#626FA6"
          />
          <path
            d="M94.9635 258.509L88.9183 255.005V255.443L94.9635 258.947V258.509Z"
            fill="#626FA6"
          />
          <path
            d="M94.9635 259.275L85.731 253.91V254.348L94.9635 259.713V259.275Z"
            fill="#626FA6"
          />
          <path
            d="M92.4356 259.056L85.731 255.115V254.677L92.4356 258.618V259.056Z"
            fill="#626FA6"
          />
          <path
            d="M94.9634 260.151L92.8751 258.947V259.385L94.9634 260.589V260.151Z"
            fill="#626FA6"
          />
          <path
            d="M84.1921 254.677V255.115L87.0498 256.757V256.319L84.1921 254.677Z"
            fill="#626FA6"
          />
          <path
            d="M84.1921 255.443V255.881L91.4463 260.151V259.713L84.1921 255.443Z"
            fill="#626FA6"
          />
          <path
            d="M94.9635 261.793L91.886 259.932V260.37L94.9635 262.231V261.793Z"
            fill="#626FA6"
          />
          <path
            d="M84.1921 256.319V256.757L89.4679 259.823L89.5778 259.385L84.1921 256.319Z"
            fill="#626FA6"
          />
          <path
            d="M84.1921 257.085V257.523L90.567 261.246V260.918L84.1921 257.085Z"
            fill="#626FA6"
          />
          <path
            d="M92.9851 263.545L85.8408 259.385V258.947L92.9851 263.107V263.545Z"
            fill="#626FA6"
          />
          <path
            d="M94.7436 265.516L85.8408 260.151V259.823L94.7436 265.078V265.516Z"
            fill="#626FA6"
          />
          <path
            d="M111.011 342.051L110.901 341.941L77.4875 322.452L156.404 275.918L166.846 281.83L188.718 268.91L189.048 269.348L166.846 282.378L156.404 276.465L78.4768 322.452L111.011 341.503L113.758 339.751L113.978 340.189L111.011 342.051Z"
            fill="url(#paint178_linear_70_113170)"
          />
          <path
            d="M214.217 368L214.108 367.89L166.296 340.189L235.98 299.021L236.2 299.458L167.285 340.189L214.217 367.343L224.219 360.555L224.439 360.993L214.217 368Z"
            fill="url(#paint179_linear_70_113170)"
          />
          <path
            d="M236.529 299.896L235.43 299.239C235.211 299.13 235.101 298.802 235.101 298.583C235.101 298.364 235.211 298.254 235.43 298.364L236.529 299.021C236.749 299.13 236.859 299.458 236.859 299.677C236.859 299.896 236.749 300.006 236.529 299.896Z"
            fill="url(#paint180_linear_70_113170)"
          />
          <path
            d="M189.158 269.786L188.058 269.129C187.839 269.02 187.729 268.691 187.729 268.473C187.729 268.254 187.839 268.144 188.058 268.254L189.158 268.91C189.377 269.02 189.487 269.348 189.487 269.567C189.487 269.786 189.377 269.896 189.158 269.786Z"
            fill="url(#paint181_linear_70_113170)"
          />
          <path
            d="M157.063 250.187L155.964 249.531C155.745 249.421 155.635 249.093 155.635 248.874C155.635 248.655 155.745 248.545 155.964 248.655L157.063 249.312C157.283 249.421 157.393 249.749 157.393 249.968C157.393 250.187 157.173 250.297 157.063 250.187Z"
            fill="url(#paint182_linear_70_113170)"
          />
          <path
            d="M126.398 232.778L125.299 232.121C125.079 232.012 124.969 231.683 124.969 231.464C124.969 231.245 125.079 231.136 125.299 231.245L126.398 231.902C126.618 232.012 126.728 232.34 126.728 232.559C126.728 232.778 126.508 232.888 126.398 232.778Z"
            fill="url(#paint183_linear_70_113170)"
          />
          <path
            d="M133.982 291.575L167.285 310.846C168.274 311.393 169.483 310.736 169.483 309.532V290.48C169.483 289.495 168.934 288.619 168.055 288.071L133.872 268.253C133.542 268.035 133.213 268.253 133.213 268.582V290.261C133.213 290.809 133.432 291.247 133.982 291.575Z"
            fill="#212D60"
          />
          <path
            d="M138.379 276.465L134.422 274.166V274.604L138.379 276.903V276.465Z"
            fill="#71D4FF"
          />
          <path
            d="M138.379 277.998L134.422 275.699V276.137L138.379 278.327V277.998Z"
            fill="#71D4FF"
          />
          <path
            d="M134.422 271.867V272.195L135.851 273.071V272.633L134.422 271.867Z"
            fill="#71D4FF"
          />
          <path
            d="M134.422 272.633V273.071L135.851 273.838V273.4L134.422 272.633Z"
            fill="#71D4FF"
          />
          <path
            d="M134.422 278.217V278.655L135.631 279.312V278.874L134.422 278.217Z"
            fill="#71D4FF"
          />
          <path
            d="M134.422 279.093V279.422L135.631 280.079V279.75L134.422 279.093Z"
            fill="#71D4FF"
          />
          <path
            d="M134.422 281.392V281.83L137.06 283.363V283.035L134.422 281.392Z"
            fill="#71D4FF"
          />
          <path
            d="M134.422 283.035V283.473L137.06 285.006V284.568L134.422 283.035Z"
            fill="#71D4FF"
          />
          <path
            d="M134.422 283.911V284.349L135.411 284.896V284.458L134.422 283.911Z"
            fill="#71D4FF"
          />
          <path
            d="M134.422 286.32V286.757L135.411 287.305V286.867L134.422 286.32Z"
            fill="#71D4FF"
          />
          <path
            d="M134.422 287.852V288.29L135.411 288.838V288.509L134.422 287.852Z"
            fill="#71D4FF"
          />
          <g opacity="0.36">
            <path
              d="M144.094 286.539C144.094 282.925 146.622 281.392 149.809 283.254C152.997 285.115 155.525 289.495 155.525 293.217C155.525 296.831 152.997 298.364 149.809 296.502C146.622 294.641 144.094 290.261 144.094 286.539ZM144.424 286.757C144.424 290.152 146.842 294.312 149.809 296.064C152.777 297.816 155.195 296.393 155.195 292.999C155.195 289.604 152.777 285.444 149.809 283.692C146.842 282.049 144.424 283.363 144.424 286.757Z"
              fill="#9FAFFC"
            />
          </g>
          <path
            d="M143.544 286.21C143.544 285.772 143.874 285.663 144.204 285.882C144.534 286.101 144.863 286.648 144.863 287.086C144.863 290.261 147.062 293.984 149.699 295.517C152.447 297.05 154.536 295.845 154.536 292.67C154.536 289.604 152.337 285.882 149.699 284.239C149.26 283.911 149.04 283.473 149.04 283.035C149.04 282.597 149.26 282.487 149.699 282.597C149.809 282.597 149.809 282.706 149.809 282.706C153.217 284.787 155.855 289.495 155.855 293.436C155.855 297.378 153.107 299.021 149.59 296.94C146.402 294.969 143.654 290.261 143.544 286.21Z"
            fill="url(#paint184_linear_70_113170)"
          />
          <path
            d="M70.0137 320.809L51.1089 309.86L57.0441 306.356L57.3738 306.794L52.0981 309.86L70.0137 320.262L106.944 298.692L88.9184 288.947L156.404 249.312L156.624 249.749L89.9076 288.838L107.933 298.692L70.0137 320.809Z"
            fill="url(#paint185_linear_70_113170)"
          />
          <path
            d="M57.5936 307.451L56.4945 306.794C56.2747 306.685 56.1648 306.356 56.1648 306.137C56.1648 305.918 56.2747 305.809 56.4945 305.918L57.5936 306.575C57.8135 306.685 57.9234 307.013 57.9234 307.232C57.9234 307.342 57.8135 307.451 57.5936 307.451Z"
            fill="url(#paint186_linear_70_113170)"
          />
          <path
            d="M9.45234 278.984L8.24331 278.217C8.02349 278.108 7.91357 277.779 7.91357 277.451C7.91357 277.122 8.1334 277.013 8.24331 277.122L9.45234 277.889C9.67216 277.998 9.78207 278.327 9.78207 278.655C9.89198 278.984 9.67216 279.093 9.45234 278.984Z"
            fill="url(#paint187_linear_70_113170)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_70_113170"
            x1="435.405"
            y1="48.5242"
            x2="463.958"
            y2="48.5242"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_70_113170"
            x1="466.242"
            y1="34.3834"
            x2="438.146"
            y2="34.3834"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.3398" stopColor="#E3E5F8" />
            <stop offset="0.4085" stopColor="#D0D5F4" />
            <stop offset="0.4896" stopColor="#B1BDEE" />
            <stop offset="0.5796" stopColor="#879AE5" />
            <stop offset="0.627" stopColor="#6D86E0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_70_113170"
            x1="447.648"
            y1="38.1337"
            x2="457.768"
            y2="38.1337"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_70_113170"
            x1="440.509"
            y1="50.6117"
            x2="441.844"
            y2="50.1473"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_70_113170"
            x1="372.075"
            y1="70.5559"
            x2="441.712"
            y2="70.5559"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_70_113170"
            x1="419.338"
            y1="195.009"
            x2="487.644"
            y2="195.009"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_70_113170"
            x1="449.759"
            y1="195.218"
            x2="417.3"
            y2="195.218"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.4649" stopColor="white" />
            <stop offset="0.4901" stopColor="#F8F8FD" />
            <stop offset="0.5263" stopColor="#E5E5F8" />
            <stop offset="0.5688" stopColor="#C7C7EF" />
            <stop offset="0.579" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_70_113170"
            x1="480.706"
            y1="218.04"
            x2="479.37"
            y2="217.576"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <linearGradient
            id="paint8_linear_70_113170"
            x1="403.273"
            y1="253.737"
            x2="501.123"
            y2="253.737"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <linearGradient
            id="paint9_linear_70_113170"
            x1="398.41"
            y1="139.369"
            x2="429.477"
            y2="139.369"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint10_linear_70_113170"
            x1="398.41"
            y1="125.994"
            x2="429.477"
            y2="125.994"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint11_linear_70_113170"
            x1="398.41"
            y1="112.588"
            x2="429.477"
            y2="112.588"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint12_linear_70_113170"
            x1="398.41"
            y1="99.0915"
            x2="429.477"
            y2="99.0915"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint13_linear_70_113170"
            x1="403.328"
            y1="98.1963"
            x2="404.74"
            y2="98.1963"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint14_linear_70_113170"
            x1="400.843"
            y1="96.7562"
            x2="402.255"
            y2="96.7562"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint15_linear_70_113170"
            x1="398.359"
            y1="95.3161"
            x2="399.77"
            y2="95.3161"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint16_linear_70_113170"
            x1="411.145"
            y1="116.5"
            x2="412.557"
            y2="116.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint17_linear_70_113170"
            x1="408.661"
            y1="115.06"
            x2="410.072"
            y2="115.06"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint18_linear_70_113170"
            x1="406.176"
            y1="113.619"
            x2="407.588"
            y2="113.619"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint19_linear_70_113170"
            x1="403.691"
            y1="112.179"
            x2="405.103"
            y2="112.179"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint20_linear_70_113170"
            x1="401.207"
            y1="110.739"
            x2="402.618"
            y2="110.739"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint21_linear_70_113170"
            x1="413.085"
            y1="131.414"
            x2="414.497"
            y2="131.414"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint22_linear_70_113170"
            x1="405.631"
            y1="127.093"
            x2="407.043"
            y2="127.093"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint23_linear_70_113170"
            x1="403.146"
            y1="125.653"
            x2="404.558"
            y2="125.653"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint24_linear_70_113170"
            x1="400.662"
            y1="124.213"
            x2="402.073"
            y2="124.213"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint25_linear_70_113170"
            x1="398.177"
            y1="122.773"
            x2="399.589"
            y2="122.773"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint26_linear_70_113170"
            x1="407.934"
            y1="141.118"
            x2="409.346"
            y2="141.118"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint27_linear_70_113170"
            x1="405.449"
            y1="139.678"
            x2="406.861"
            y2="139.678"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint28_linear_70_113170"
            x1="402.965"
            y1="138.238"
            x2="404.376"
            y2="138.238"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint29_linear_70_113170"
            x1="429.732"
            y1="142.108"
            x2="428.396"
            y2="141.643"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <linearGradient
            id="paint30_linear_70_113170"
            x1="354.776"
            y1="147.173"
            x2="436.529"
            y2="147.173"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <linearGradient
            id="paint31_linear_70_113170"
            x1="338.879"
            y1="105.172"
            x2="369.946"
            y2="105.172"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint32_linear_70_113170"
            x1="338.879"
            y1="91.7966"
            x2="369.946"
            y2="91.7966"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint33_linear_70_113170"
            x1="338.879"
            y1="78.3913"
            x2="369.946"
            y2="78.3913"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint34_linear_70_113170"
            x1="338.879"
            y1="64.8948"
            x2="369.946"
            y2="64.8948"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint35_linear_70_113170"
            x1="343.797"
            y1="63.9995"
            x2="345.209"
            y2="63.9995"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint36_linear_70_113170"
            x1="341.313"
            y1="62.5593"
            x2="342.724"
            y2="62.5593"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint37_linear_70_113170"
            x1="338.828"
            y1="61.1192"
            x2="340.24"
            y2="61.1192"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint38_linear_70_113170"
            x1="351.614"
            y1="82.3028"
            x2="353.026"
            y2="82.3028"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint39_linear_70_113170"
            x1="349.13"
            y1="80.8626"
            x2="350.542"
            y2="80.8626"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint40_linear_70_113170"
            x1="346.645"
            y1="79.4226"
            x2="348.057"
            y2="79.4226"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint41_linear_70_113170"
            x1="344.161"
            y1="77.9824"
            x2="345.572"
            y2="77.9824"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint42_linear_70_113170"
            x1="341.676"
            y1="76.5423"
            x2="343.088"
            y2="76.5423"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint43_linear_70_113170"
            x1="353.554"
            y1="97.2168"
            x2="354.966"
            y2="97.2168"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint44_linear_70_113170"
            x1="346.1"
            y1="92.8964"
            x2="347.512"
            y2="92.8964"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint45_linear_70_113170"
            x1="343.615"
            y1="91.4563"
            x2="345.027"
            y2="91.4563"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint46_linear_70_113170"
            x1="341.131"
            y1="90.0161"
            x2="342.543"
            y2="90.0161"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint47_linear_70_113170"
            x1="338.646"
            y1="88.576"
            x2="340.058"
            y2="88.576"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint48_linear_70_113170"
            x1="348.403"
            y1="106.921"
            x2="349.815"
            y2="106.921"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint49_linear_70_113170"
            x1="345.919"
            y1="105.481"
            x2="347.33"
            y2="105.481"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint50_linear_70_113170"
            x1="343.434"
            y1="104.041"
            x2="344.846"
            y2="104.041"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint51_linear_70_113170"
            x1="309.215"
            y1="122.209"
            x2="340.282"
            y2="122.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint52_linear_70_113170"
            x1="309.215"
            y1="108.834"
            x2="340.282"
            y2="108.834"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint53_linear_70_113170"
            x1="309.215"
            y1="95.4287"
            x2="340.282"
            y2="95.4287"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint54_linear_70_113170"
            x1="309.215"
            y1="81.9322"
            x2="340.282"
            y2="81.9322"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint55_linear_70_113170"
            x1="314.133"
            y1="81.0369"
            x2="315.545"
            y2="81.0369"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint56_linear_70_113170"
            x1="311.648"
            y1="79.5967"
            x2="313.06"
            y2="79.5967"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint57_linear_70_113170"
            x1="309.164"
            y1="78.1566"
            x2="310.575"
            y2="78.1566"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint58_linear_70_113170"
            x1="321.951"
            y1="99.3402"
            x2="323.362"
            y2="99.3402"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint59_linear_70_113170"
            x1="319.466"
            y1="97.9001"
            x2="320.878"
            y2="97.9001"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint60_linear_70_113170"
            x1="316.981"
            y1="96.4599"
            x2="318.393"
            y2="96.4599"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint61_linear_70_113170"
            x1="314.496"
            y1="95.0198"
            x2="315.908"
            y2="95.0198"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint62_linear_70_113170"
            x1="312.012"
            y1="93.5797"
            x2="313.424"
            y2="93.5797"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint63_linear_70_113170"
            x1="323.89"
            y1="114.254"
            x2="325.302"
            y2="114.254"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint64_linear_70_113170"
            x1="316.436"
            y1="109.934"
            x2="317.848"
            y2="109.934"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint65_linear_70_113170"
            x1="313.952"
            y1="108.494"
            x2="315.363"
            y2="108.494"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint66_linear_70_113170"
            x1="311.467"
            y1="107.053"
            x2="312.878"
            y2="107.053"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint67_linear_70_113170"
            x1="308.982"
            y1="105.613"
            x2="310.394"
            y2="105.613"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint68_linear_70_113170"
            x1="318.739"
            y1="123.958"
            x2="320.151"
            y2="123.958"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint69_linear_70_113170"
            x1="316.255"
            y1="122.518"
            x2="317.666"
            y2="122.518"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint70_linear_70_113170"
            x1="313.77"
            y1="121.078"
            x2="315.182"
            y2="121.078"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint71_linear_70_113170"
            x1="369.722"
            y1="108.225"
            x2="368.386"
            y2="107.76"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <linearGradient
            id="paint72_linear_70_113170"
            x1="338.838"
            y1="126.304"
            x2="384.5"
            y2="126.304"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <linearGradient
            id="paint73_linear_70_113170"
            x1="270.427"
            y1="63.647"
            x2="301.493"
            y2="63.647"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint74_linear_70_113170"
            x1="270.427"
            y1="50.272"
            x2="301.493"
            y2="50.272"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint75_linear_70_113170"
            x1="270.427"
            y1="36.8667"
            x2="301.493"
            y2="36.8667"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint76_linear_70_113170"
            x1="270.427"
            y1="23.37"
            x2="301.493"
            y2="23.37"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint77_linear_70_113170"
            x1="275.345"
            y1="22.4748"
            x2="276.757"
            y2="22.4748"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint78_linear_70_113170"
            x1="272.86"
            y1="21.0347"
            x2="274.272"
            y2="21.0347"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint79_linear_70_113170"
            x1="270.375"
            y1="19.5945"
            x2="271.787"
            y2="19.5945"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint80_linear_70_113170"
            x1="283.162"
            y1="40.7781"
            x2="284.574"
            y2="40.7781"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint81_linear_70_113170"
            x1="280.678"
            y1="39.338"
            x2="282.089"
            y2="39.338"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint82_linear_70_113170"
            x1="278.193"
            y1="37.8979"
            x2="279.605"
            y2="37.8979"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint83_linear_70_113170"
            x1="275.708"
            y1="36.4577"
            x2="277.12"
            y2="36.4577"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint84_linear_70_113170"
            x1="273.223"
            y1="35.0176"
            x2="274.635"
            y2="35.0176"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint85_linear_70_113170"
            x1="285.102"
            y1="55.692"
            x2="286.514"
            y2="55.692"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint86_linear_70_113170"
            x1="277.648"
            y1="51.3717"
            x2="279.06"
            y2="51.3717"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint87_linear_70_113170"
            x1="275.163"
            y1="49.9315"
            x2="276.575"
            y2="49.9315"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint88_linear_70_113170"
            x1="272.678"
            y1="48.4914"
            x2="274.09"
            y2="48.4914"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint89_linear_70_113170"
            x1="270.194"
            y1="47.0512"
            x2="271.605"
            y2="47.0512"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint90_linear_70_113170"
            x1="279.951"
            y1="65.3965"
            x2="281.363"
            y2="65.3965"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint91_linear_70_113170"
            x1="277.466"
            y1="63.9564"
            x2="278.878"
            y2="63.9564"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint92_linear_70_113170"
            x1="274.981"
            y1="62.5162"
            x2="276.393"
            y2="62.5162"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint93_linear_70_113170"
            x1="240.763"
            y1="80.6844"
            x2="271.829"
            y2="80.6844"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint94_linear_70_113170"
            x1="240.763"
            y1="67.3094"
            x2="271.829"
            y2="67.3094"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint95_linear_70_113170"
            x1="240.763"
            y1="53.9039"
            x2="271.829"
            y2="53.9039"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint96_linear_70_113170"
            x1="240.763"
            y1="40.4074"
            x2="271.83"
            y2="40.4074"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint97_linear_70_113170"
            x1="245.681"
            y1="39.5122"
            x2="247.093"
            y2="39.5122"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint98_linear_70_113170"
            x1="243.196"
            y1="38.0721"
            x2="244.608"
            y2="38.0721"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint99_linear_70_113170"
            x1="240.711"
            y1="36.6318"
            x2="242.123"
            y2="36.6318"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint100_linear_70_113170"
            x1="253.498"
            y1="57.8155"
            x2="254.91"
            y2="57.8155"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint101_linear_70_113170"
            x1="251.014"
            y1="56.3754"
            x2="252.425"
            y2="56.3754"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint102_linear_70_113170"
            x1="248.529"
            y1="54.9352"
            x2="249.941"
            y2="54.9352"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint103_linear_70_113170"
            x1="246.044"
            y1="53.4951"
            x2="247.456"
            y2="53.4951"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint104_linear_70_113170"
            x1="243.559"
            y1="52.055"
            x2="244.971"
            y2="52.055"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint105_linear_70_113170"
            x1="255.438"
            y1="72.7294"
            x2="256.85"
            y2="72.7294"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint106_linear_70_113170"
            x1="247.984"
            y1="68.409"
            x2="249.396"
            y2="68.409"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint107_linear_70_113170"
            x1="245.499"
            y1="66.9688"
            x2="246.911"
            y2="66.9688"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint108_linear_70_113170"
            x1="243.014"
            y1="65.5288"
            x2="244.426"
            y2="65.5288"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint109_linear_70_113170"
            x1="240.53"
            y1="64.0886"
            x2="241.941"
            y2="64.0886"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint110_linear_70_113170"
            x1="250.287"
            y1="82.4337"
            x2="251.699"
            y2="82.4337"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint111_linear_70_113170"
            x1="247.802"
            y1="80.9936"
            x2="249.214"
            y2="80.9936"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint112_linear_70_113170"
            x1="245.318"
            y1="79.5535"
            x2="246.729"
            y2="79.5535"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint113_linear_70_113170"
            x1="210.193"
            y1="98.1742"
            x2="241.26"
            y2="98.1742"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint114_linear_70_113170"
            x1="210.193"
            y1="84.7992"
            x2="241.26"
            y2="84.7992"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint115_linear_70_113170"
            x1="210.193"
            y1="71.3938"
            x2="241.26"
            y2="71.3938"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint116_linear_70_113170"
            x1="210.193"
            y1="57.8973"
            x2="241.26"
            y2="57.8973"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint117_linear_70_113170"
            x1="215.111"
            y1="57.002"
            x2="216.523"
            y2="57.002"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint118_linear_70_113170"
            x1="212.626"
            y1="55.5619"
            x2="214.038"
            y2="55.5619"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint119_linear_70_113170"
            x1="210.142"
            y1="54.1217"
            x2="211.554"
            y2="54.1217"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint120_linear_70_113170"
            x1="222.929"
            y1="75.3053"
            x2="224.34"
            y2="75.3053"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint121_linear_70_113170"
            x1="220.444"
            y1="73.8652"
            x2="221.856"
            y2="73.8652"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint122_linear_70_113170"
            x1="217.959"
            y1="72.4251"
            x2="219.371"
            y2="72.4251"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint123_linear_70_113170"
            x1="215.475"
            y1="70.9849"
            x2="216.886"
            y2="70.9849"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint124_linear_70_113170"
            x1="212.99"
            y1="69.5448"
            x2="214.402"
            y2="69.5448"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint125_linear_70_113170"
            x1="224.868"
            y1="90.2193"
            x2="226.28"
            y2="90.2193"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint126_linear_70_113170"
            x1="217.414"
            y1="85.8989"
            x2="218.826"
            y2="85.8989"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint127_linear_70_113170"
            x1="214.929"
            y1="84.4587"
            x2="216.341"
            y2="84.4587"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint128_linear_70_113170"
            x1="212.445"
            y1="83.0186"
            x2="213.856"
            y2="83.0186"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint129_linear_70_113170"
            x1="209.96"
            y1="81.5784"
            x2="211.372"
            y2="81.5784"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint130_linear_70_113170"
            x1="219.717"
            y1="99.9236"
            x2="221.129"
            y2="99.9236"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint131_linear_70_113170"
            x1="217.233"
            y1="98.4836"
            x2="218.644"
            y2="98.4836"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint132_linear_70_113170"
            x1="214.748"
            y1="97.0433"
            x2="216.16"
            y2="97.0433"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint133_linear_70_113170"
            x1="240.821"
            y1="114.671"
            x2="287.743"
            y2="114.671"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <linearGradient
            id="paint134_linear_70_113170"
            x1="132.076"
            y1="81.7209"
            x2="160.2"
            y2="81.7209"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint135_linear_70_113170"
            x1="136.788"
            y1="76.3321"
            x2="142.203"
            y2="76.3321"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint136_linear_70_113170"
            x1="136.878"
            y1="85.0649"
            x2="142.293"
            y2="85.0649"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint137_linear_70_113170"
            x1="155.188"
            y1="98.3124"
            x2="153.852"
            y2="97.8479"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <linearGradient
            id="paint138_linear_70_113170"
            x1="154.039"
            y1="111.903"
            x2="199.42"
            y2="111.903"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <linearGradient
            id="paint139_linear_70_113170"
            x1="2.71393"
            y1="275.205"
            x2="30.4887"
            y2="275.205"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint140_linear_70_113170"
            x1="21.8497"
            y1="245.034"
            x2="49.4072"
            y2="245.034"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF6F1B" />
            <stop offset="1" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint141_linear_70_113170"
            x1="30.0425"
            y1="245.81"
            x2="17.4727"
            y2="245.81"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.0108108" stopColor="#9FAFFC" />
            <stop offset="1" stopColor="#E6F6FF" />
          </linearGradient>
          <linearGradient
            id="paint142_linear_70_113170"
            x1="29.4205"
            y1="257.349"
            x2="16.116"
            y2="257.349"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.0108108" stopColor="#2234A4" />
            <stop offset="0.1346" stopColor="#263299" />
            <stop offset="0.4553" stopColor="#2F2D81" />
            <stop offset="0.7521" stopColor="#342A72" />
            <stop offset="1" stopColor="#36296D" />
          </linearGradient>
          <linearGradient
            id="paint143_linear_70_113170"
            x1="33.9476"
            y1="259.616"
            x2="19.8185"
            y2="259.616"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.0108108" stopColor="#2234A4" />
            <stop offset="0.1346" stopColor="#263299" />
            <stop offset="0.4553" stopColor="#2F2D81" />
            <stop offset="0.7521" stopColor="#342A72" />
            <stop offset="1" stopColor="#36296D" />
          </linearGradient>
          <linearGradient
            id="paint144_linear_70_113170"
            x1="24.6695"
            y1="261.163"
            x2="25.2091"
            y2="246.434"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.0108108" stopColor="#9FAFFC" />
            <stop offset="1" stopColor="#E6F6FF" />
          </linearGradient>
          <linearGradient
            id="paint145_linear_70_113170"
            x1="108.3"
            y1="337.209"
            x2="136.57"
            y2="337.209"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint146_linear_70_113170"
            x1="155.82"
            y1="306.791"
            x2="128.086"
            y2="306.791"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF6F1B" />
            <stop offset="1" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint147_linear_70_113170"
            x1="137.19"
            y1="319.544"
            x2="123.773"
            y2="319.544"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6732EF" />
            <stop offset="0.2757" stopColor="#450D95" />
          </linearGradient>
          <linearGradient
            id="paint148_linear_70_113170"
            x1="141.982"
            y1="322.39"
            x2="128.698"
            y2="322.39"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.1297" stopColor="#6732EF" />
            <stop offset="0.7778" stopColor="#450D95" />
          </linearGradient>
          <linearGradient
            id="paint149_linear_70_113170"
            x1="127.57"
            y1="325.928"
            x2="127.57"
            y2="309.271"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.0108108" stopColor="#9FAFFC" />
            <stop offset="1" stopColor="#E6F6FF" />
          </linearGradient>
          <linearGradient
            id="paint150_linear_70_113170"
            x1="119.51"
            y1="303.606"
            x2="130.919"
            y2="303.606"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.18" stopColor="#FF6F1B" />
            <stop offset="0.3263" stopColor="#FF5527" />
            <stop offset="0.4612" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint151_linear_70_113170"
            x1="33.1677"
            y1="170.474"
            x2="61.5642"
            y2="170.474"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint152_linear_70_113170"
            x1="58.345"
            y1="144.384"
            x2="37.2037"
            y2="144.384"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.3398" stopColor="#E3E5F8" />
            <stop offset="0.4085" stopColor="#D0D5F4" />
            <stop offset="0.4896" stopColor="#B1BDEE" />
            <stop offset="0.5796" stopColor="#879AE5" />
            <stop offset="0.627" stopColor="#6D86E0" />
          </linearGradient>
          <linearGradient
            id="paint153_linear_70_113170"
            x1="54.5616"
            y1="185.725"
            x2="54.5616"
            y2="173.446"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint154_linear_70_113170"
            x1="219.054"
            y1="343.729"
            x2="247.552"
            y2="343.729"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint155_linear_70_113170"
            x1="244.202"
            y1="317"
            x2="222.986"
            y2="317"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.4222" stopColor="#EAEAF9" />
            <stop offset="0.4493" stopColor="#E3E5F8" />
            <stop offset="0.4881" stopColor="#D0D5F4" />
            <stop offset="0.534" stopColor="#B1BDEE" />
            <stop offset="0.5848" stopColor="#879AE5" />
            <stop offset="0.6116" stopColor="#6D86E0" />
          </linearGradient>
          <linearGradient
            id="paint156_linear_70_113170"
            x1="224.146"
            y1="338.267"
            x2="224.146"
            y2="355.757"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint157_linear_70_113170"
            x1="227.992"
            y1="338.267"
            x2="227.992"
            y2="355.757"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <linearGradient
            id="paint158_linear_70_113170"
            x1="50.1142"
            y1="228.23"
            x2="43.5112"
            y2="228.23"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint159_linear_70_113170"
            x1="223.395"
            y1="360.269"
            x2="224.731"
            y2="359.804"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <linearGradient
            id="paint160_linear_70_113170"
            x1="112.951"
            y1="339.468"
            x2="114.287"
            y2="339.004"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <linearGradient
            id="paint161_linear_70_113170"
            x1="38.3709"
            y1="187.509"
            x2="39.7065"
            y2="187.045"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <linearGradient
            id="paint162_linear_70_113170"
            x1="241.996"
            y1="100.943"
            x2="240.66"
            y2="100.478"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <linearGradient
            id="paint163_linear_70_113170"
            x1="29.6094"
            y1="191.38"
            x2="129.834"
            y2="191.38"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <linearGradient
            id="paint164_linear_70_113170"
            x1="98.0141"
            y1="249.181"
            x2="430.749"
            y2="249.181"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.5648" stopColor="#EAEAF9" />
            <stop offset="0.808" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint165_linear_70_113170"
            x1="324.633"
            y1="190.633"
            x2="116.027"
            y2="190.633"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.4222" stopColor="#EAEAF9" />
            <stop offset="0.4493" stopColor="#E3E5F8" />
            <stop offset="0.4881" stopColor="#D0D5F4" />
            <stop offset="0.534" stopColor="#B1BDEE" />
            <stop offset="0.5848" stopColor="#879AE5" />
            <stop offset="0.6116" stopColor="#6D86E0" />
          </linearGradient>
          <linearGradient
            id="paint166_linear_70_113170"
            x1="151.909"
            y1="207.71"
            x2="391.712"
            y2="207.71"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.0108108" stopColor="#120A9D" />
            <stop offset="0.0508211" stopColor="#160A97" />
            <stop offset="0.3169" stopColor="#2A0776" />
            <stop offset="0.5707" stopColor="#39055E" />
            <stop offset="0.8046" stopColor="#42044F" />
            <stop offset="1" stopColor="#45044A" />
          </linearGradient>
          <linearGradient
            id="paint167_linear_70_113170"
            x1="274.795"
            y1="235.908"
            x2="327.266"
            y2="235.908"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <linearGradient
            id="paint168_linear_70_113170"
            x1="211.246"
            y1="193.331"
            x2="207.47"
            y2="173.642"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint169_linear_70_113170"
            x1="241.184"
            y1="176.171"
            x2="238.012"
            y2="159.631"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint170_linear_70_113170"
            x1="227.294"
            y1="184.019"
            x2="224.847"
            y2="171.259"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD33F" />
            <stop offset="1" stopColor="#FF7364" />
          </linearGradient>
          <linearGradient
            id="paint171_linear_70_113170"
            x1="3.23541"
            y1="262.143"
            x2="125.898"
            y2="262.143"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <linearGradient
            id="paint172_linear_70_113170"
            x1="52.8069"
            y1="304.404"
            x2="80.6544"
            y2="304.404"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.2919" stopColor="#EAEAF9" />
            <stop offset="0.388" stopColor="#E3E3F7" />
            <stop offset="0.5253" stopColor="#D0D0F2" />
            <stop offset="0.627" stopColor="#BEBEED" />
          </linearGradient>
          <linearGradient
            id="paint173_linear_70_113170"
            x1="99.2214"
            y1="274.21"
            x2="71.6899"
            y2="274.21"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF6F1B" />
            <stop offset="1" stopColor="#FF3933" />
          </linearGradient>
          <linearGradient
            id="paint174_linear_70_113170"
            x1="79.8656"
            y1="274.986"
            x2="67.2865"
            y2="274.986"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.5459" stopColor="#FF7364" />
            <stop offset="0.7145" stopColor="#FF9457" />
            <stop offset="1" stopColor="#FFD33F" />
          </linearGradient>
          <linearGradient
            id="paint175_linear_70_113170"
            x1="79.2431"
            y1="286.534"
            x2="65.9281"
            y2="286.534"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.0108108" stopColor="#2234A4" />
            <stop offset="0.1346" stopColor="#263299" />
            <stop offset="0.4553" stopColor="#2F2D81" />
            <stop offset="0.7521" stopColor="#342A72" />
            <stop offset="1" stopColor="#36296D" />
          </linearGradient>
          <linearGradient
            id="paint176_linear_70_113170"
            x1="83.7736"
            y1="288.803"
            x2="69.6338"
            y2="288.803"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.0108108" stopColor="#2234A4" />
            <stop offset="0.1346" stopColor="#263299" />
            <stop offset="0.4553" stopColor="#2F2D81" />
            <stop offset="0.7521" stopColor="#342A72" />
            <stop offset="1" stopColor="#36296D" />
          </linearGradient>
          <linearGradient
            id="paint177_linear_70_113170"
            x1="74.4886"
            y1="290.351"
            x2="75.0281"
            y2="275.61"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.5459" stopColor="#FF7364" />
            <stop offset="0.7145" stopColor="#FF9457" />
            <stop offset="1" stopColor="#FFD33F" />
          </linearGradient>
          <linearGradient
            id="paint178_linear_70_113170"
            x1="77.5246"
            y1="305.483"
            x2="188.998"
            y2="305.483"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <linearGradient
            id="paint179_linear_70_113170"
            x1="166.332"
            y1="333.513"
            x2="236.223"
            y2="333.513"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <linearGradient
            id="paint180_linear_70_113170"
            x1="235.075"
            y1="298.592"
            x2="236.41"
            y2="298.128"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <linearGradient
            id="paint181_linear_70_113170"
            x1="187.691"
            y1="268.531"
            x2="189.027"
            y2="268.067"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <linearGradient
            id="paint182_linear_70_113170"
            x1="155.535"
            y1="248.846"
            x2="156.87"
            y2="248.381"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <linearGradient
            id="paint183_linear_70_113170"
            x1="124.887"
            y1="231.431"
            x2="126.222"
            y2="230.967"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <linearGradient
            id="paint184_linear_70_113170"
            x1="155.971"
            y1="293.363"
            x2="143.539"
            y2="293.363"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <linearGradient
            id="paint185_linear_70_113170"
            x1="51.1408"
            y1="285.065"
            x2="156.638"
            y2="285.065"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <linearGradient
            id="paint186_linear_70_113170"
            x1="56.1067"
            y1="306.096"
            x2="57.4423"
            y2="305.632"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <linearGradient
            id="paint187_linear_70_113170"
            x1="7.80881"
            y1="277.517"
            x2="9.31426"
            y2="276.994"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FDA758" />
            <stop offset="1" stopColor="#FD256F" />
          </linearGradient>
          <clipPath id="clip0_70_113170">
            <rect width="509" height="368" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}

export { HomeLogo };
