import { useContext, useEffect, useState } from 'react';
import i18nIsoCountries from 'i18n-iso-countries';

import { CrossChain } from '@/components/CrossChain/CrossChain';
import { DomainList } from '@/components/DomainList/DomainList';
import { GlobalStateContext } from '@/utils/context/GlobalStateContext';
import { IOrder } from '@/api/omni/Order/order.interface';
import { Icon } from '@iconify/react';
import { Link } from 'react-router-dom';
import {
  MyDomainContext,
  MyDomainProvider,
} from '@/utils/context/MyDomainContext';
import { Toaster } from 'react-hot-toast';
import { TransferDomain } from '@/components/DomainList/TransferDomain';
import { WalletList } from '@/components/DomainList/WalletList';
import Nav from '@/components/Nav/Nav';
import OrderList from '@/components/order/OrderList';
import OrderModal from '@/components/order/OrderModal';
import OrderPage from '@/components/order/OrderLayout';

i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const AccountPage = () => {
  const {
    isDuplicatedBindingEthereumAddress,
    setIsDuplicatedBindingEthereumAddress,
    customAddWalletErrorMessage,
    setCustomAddWalletErrorMessage,
  } = useContext(MyDomainContext);
  const { orders, isLoadingOrders } = useContext(GlobalStateContext);

  const [showNav, setShownav] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState<any[]>();
  const [option, setOption] = useState<boolean>(false);

  const [orderInModal, setOrderInModal] = useState<IOrder | undefined>(
    undefined,
  );
  const [isOrderDetailModalOpen, setIsOrderDetailModalOpen] =
    useState<boolean>(false);

  useEffect(() => {
    const order: any = [];
    if (orders.length > 15) {
      for (let i = 0; i <= orders.length / 15; i += 1) {
        order.push(<OrderPage page={page} pages={i} setPage={setPage} />);
      }
    }
    setPages(order);
  }, [orders.length, page]);

  useEffect(() => {
    if (orderInModal) {
      setIsOrderDetailModalOpen(true);
    }
  }, [orderInModal]);

  // useEffect(() => {
  //   console.log(showNav);
  // }, []);

  useEffect(() => {
    if (!isOrderDetailModalOpen) {
      setOrderInModal(undefined);
    }
  }, [isOrderDetailModalOpen]);

  useEffect(() => {
    window.onclick = function (event) {
      if (!event.target.matches('.btn-order-option') && option) {
        document.querySelectorAll<HTMLDivElement>('.e').forEach((e) => {
          setOption(false);
        });
      }
    };
  });

  // useEffect(() => {
  //   async function exec() {
  //     if (cryptoWallets) {
  //       const wallet = _.orderBy(cryptoWallets, 'updatedAt', 'desc');
  //       // setEthereumAddresses(wallet);
  //
  //
  //     }
  //   }
  //   exec();
  // }, [user, cryptoWallets]);

  return (
    <MyDomainProvider>
      <Toaster />

      <Nav title="Dashboard" />

      <OrderModal
        order={orderInModal}
        isOpen={isOrderDetailModalOpen}
        closeModal={() => {
          setIsOrderDetailModalOpen(false);
        }}
      />

      <TransferDomain />
      <CrossChain />

      <div className="flex flex-1 flex-col overflow-y-auto">
        <div className="account-r-con flex w-full flex-1 flex-col gap-8">
          {customAddWalletErrorMessage && (
            <div
              className="relative rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
              role="alert"
            >
              <span className="block sm:inline">
                {customAddWalletErrorMessage}
              </span>
              <span
                className="absolute bottom-0 right-0 top-0 px-4 py-3"
                onClick={() => setCustomAddWalletErrorMessage(undefined)}
              >
                <svg
                  className="h-6 w-6 fill-current text-red-500"
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
            </div>
          )}
          {isDuplicatedBindingEthereumAddress && (
            <div
              className="relative rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
              role="alert"
            >
              <strong className="font-bold">Duplicated Address!</strong>
              <span className="block sm:inline">
                You have already bind this address into your account.
              </span>
              <span
                className="absolute bottom-0 right-0 top-0 px-4 py-3"
                onClick={() => setIsDuplicatedBindingEthereumAddress(false)}
              >
                <svg
                  className="h-6 w-6 fill-current text-red-500"
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
            </div>
          )}
          <div className="px-6">
            <WalletList />
          </div>

          <div className="px-6">
            <DomainList title="My Domains" hiddenSearch count={3} />

            <Link
              to="/mydomain"
              className="gradient-box gradient-shadow w-fit rounded-full  px-8 py-3 text-center font-medium duration-150"
            >
              Show more
            </Link>
          </div>

          <div className="mb-24 flex-1 px-6">
            <div className="mb-10 flex h-fit flex-1 flex-col justify-between gap-5 rounded-2xl bg-white p-6 ">
              <OrderList
                orders={orders}
                isLoading={isLoadingOrders}
                end={orders.length}
                openModal={setOrderInModal}
              />
              {orders !== undefined && page > 0 && (
                <ul className="order-wrapper flex w-full justify-center gap-2">
                  <li
                    className="theme bg cursor-pointer rounded-md p-2 px-3 text-white md:hidden"
                    onClick={() => page > 0 && setPage(page - 1)}
                  >
                    <Icon icon="akar-icons:chevron-left" />
                  </li>
                  {pages}
                  <li
                    className="theme bg cursor-pointer rounded-md p-2 px-3 text-white md:hidden"
                    onClick={() => setPage(page + 1)}
                  >
                    <Icon icon="akar-icons:chevron-right" />
                  </li>
                </ul>
              )}
            </div>

            <Link
              to="/order"
              className="gradient-box gradient-shadow w-fit rounded-full px-8 py-3 text-center font-medium"
            >
              Show more
            </Link>
          </div>
        </div>
      </div>
    </MyDomainProvider>
  );
};

export default AccountPage;
