import '@/App.scss';
import { CartContext } from '@/utils/context/CartContext';
import { GlobalStateContext } from '@/utils/context/GlobalStateContext';
import { motion } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';

import CartItems from '@/components/cart/CartItems';
import CartSummary from '@/components/cart/CartSummary';
import Nav from '@/components/Nav/Nav';

const CartPage = () => {
  const {
    user,
    createNewOrder,
    isEthereumAddressesFinishSetup,
    isManagedWallet,
    setIsShowCart,
    cryptoWallets,
  } = useContext(GlobalStateContext);

  const { shoppingCart } = useContext(CartContext);

  const [orderDelete, setOrderDelete] = useState<boolean>(false);

  const getCheckoutBtnDisabled = () => {
    if (!shoppingCart) {
      return false;
    }
    return (
      (!isManagedWallet
        ? !isEthereumAddressesFinishSetup || shoppingCart.products.length === 0
        : shoppingCart.products.length === 0) ||
      !isEthereumAddressesFinishSetup ||
      shoppingCart.products.length === 0
    );
  };

  useEffect(() => {
    setIsShowCart(false);
  }, [setIsShowCart]);
  return (
    <div className="flex h-screen flex-col">
      <Nav
        cartCount={orderDelete}
        setCartCount={setOrderDelete}
        title="Shopping Cart"
      />
      {/*{ (!isEthereumAddressesFinishSetup && cryptoWallets)(*/}
      {/*  <Alert message="Please bind at least one wallet address before adding domain into shopping cart" />*/}
      {/*)}*/}
      <div className="flex h-full flex-1">
        <div className="flex h-full w-full flex-[7] flex-col overflow-scroll">
          <section className="flex h-full w-full flex-col px-6 pb-16 pt-0 lg:pb-20">
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              className="flex flex-col gap-8 pb-20 md:flex-row"
            >
              <div className="relative flex h-fit w-full flex-col rounded-2xl bg-white px-8 ">
                <CartItems user={user} />
              </div>
              <CartSummary
                cart={shoppingCart}
                onCheckout={createNewOrder}
                disabled={getCheckoutBtnDisabled()}
                // isLoading={isLoadingShoppingCart}
              />
            </motion.div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default CartPage;
