import { AuthClientTokens } from '@react-keycloak/core';
import { Loading } from './Loading';
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import authClient from '@/utils/auth/keycloak';

interface IAuthContext {
  token?: string;
  isAuthenticated: boolean;
  isProviderInitialized: boolean;
  login: () => Promise<void>;
  logout: () => Promise<void>;
}
const AuthContext = createContext<IAuthContext>({
  token: undefined,
  isAuthenticated: false,
  isProviderInitialized: false,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

const MainAuthProvider = ({ children }) => {
  const { keycloak, initialized } = useKeycloak();

  // public variable
  const [token, setToken] = useState<string>();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isProviderInitialized, setIsProviderInitialized] =
    useState<boolean>(initialized);

  // private variable

  // public function
  const login = useCallback(async () => {
    try {
      await keycloak.login();
    } catch (error) {
      console.log(error);
    }
  }, [keycloak]);

  const logout = useCallback(async () => {
    try {
      await keycloak.logout();
      setToken(undefined);
      setIsAuthenticated(false);
    } catch (error) {
      console.log(error);
    }
  }, [keycloak]);

  // private function

  // react hook
  useEffect(() => {
    setIsProviderInitialized(initialized);
  }, [initialized]);

  // update authenticated state
  useEffect(() => {
    setIsAuthenticated(keycloak.authenticated!);
  }, [keycloak.authenticated]);

  // update token for login or not
  useEffect(() => {
    if (isAuthenticated) {
      setToken(keycloak.token);
    } else {
      setToken(undefined);
    }
  }, [isAuthenticated, keycloak.token]);

  const value = useMemo(
    () => ({ token, isAuthenticated, isProviderInitialized, login, logout }),
    [login, logout, token, isAuthenticated, isProviderInitialized],
  );

  // return <Loading />;
  if (!isProviderInitialized) {
    return <Loading />;
  } else {
    return (
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
  }
};

const AuthProvider = ({ children }) => {
  const kcTokens = 'token';

  const handleOnTokens = ({ token }: Pick<AuthClientTokens, 'token'>) => {
    localStorage.setItem(kcTokens, token || '');
  };
  return (
    <ReactKeycloakProvider
      authClient={authClient}
      initOptions={{
        checkLoginIframe: false,
      }}
      onTokens={handleOnTokens}
    >
      <MainAuthProvider>{children}</MainAuthProvider>
    </ReactKeycloakProvider>
  );
};

export { AuthContext, AuthProvider };
