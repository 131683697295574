import { GlobalStateContext } from '@/utils/context';
import { Icon } from '@iconify/react';
import { MessageError } from '@/components/common/Message';
import { MyCheckbox } from '@/components/common/Form';
import {
  MyDomainContext,
  SelectDomainState,
} from '@/utils/context/MyDomainContext';
import { Web3Functions } from '@/components/web3/Web3';
import { ethers } from 'ethers';
import React, { useContext, useEffect, useState } from 'react';
import toast from 'react-hot-toast';

function TransferDomain() {
  const {
    selectedDomain: domain,
    selectDomainState,
    setSelectedDomain,
    isOpenTransfer,
    setIsOpenTransfer,
    setSelectDomainState,
  } = useContext(MyDomainContext);
  const { updateDomains } = useContext(GlobalStateContext);

  const [isValidRecipientAddress, setIsValidRecipientAddress] =
    useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);
  // const [isUsingCorrectNetwork, setIsUsingCorrectNetwork] =
  //   useState<boolean>(false);
  const [recipientAddress, setRecipientAddress] = useState<string>('');
  const [transfering, setTransfering] = useState(false);
  const handleRecipientAddressChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRecipientAddress(e.target.value);
  };
  const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    // TODO: Alex should be send request to API for transferring domain
    e.preventDefault();
    // setIsConnectingToWeb3Wallet(true);
  };
  const handleTransfer = async () => {
    setTransfering(true);
    setError(undefined);
    try {
      const web3 = new Web3Functions(
        +domain!.blockchains[0].chainId,
        undefined,
        '@',
        domain?.name,
        domain?.tld.name,
      );
      const { suc, error } = await web3.transferDomain({
        tokenId: domain!.tokenId,
        newOwner: recipientAddress,
        ownerAddress: domain!.owner.address,
      });
      if (!suc) {
        throw new Error(error!);
      }
      await updateDomains();
      toast.success(`${domain!.fqdn} transferred to ${recipientAddress}`);
      setIsOpenTransfer(false);
    } catch (e) {
      setError((e as Error).message);
    } finally {
      setTransfering(false);
    }
  };

  const handleClose = () => {
    setSelectedDomain(undefined);
    setSelectDomainState(SelectDomainState.None);
    setIsOpenTransfer(false);
  };

  useEffect(() => {
    if (recipientAddress !== '') {
      setIsValidRecipientAddress(ethers.utils.isAddress(recipientAddress));
    } else {
      setIsValidRecipientAddress(true);
    }
  }, [recipientAddress]);

  // useEffect(() => {
  //   if (domain && wallet) {
  //     setIsUsingOwnerAddress(domain.owner.address === wallet);
  //   } else {
  //     setIsUsingOwnerAddress(false);
  //   }
  // }, [domain, wallet]);

  useEffect(() => {
    if (domain && selectDomainState === SelectDomainState.Transfer) {
      setIsOpenTransfer(true);
    }
  }, [domain, setIsOpenTransfer, selectDomainState]);

  if (!isOpenTransfer) return <> </>;
  return (
    <div
      className="absolute left-0 top-0 z-[410] flex h-full w-full items-center justify-center bg-gray-500/20"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          handleClose();
        }
      }}
    >
      <div className="transfer-bg wallet-bar max-h-full w-fit overflow-y-scroll rounded-xl border bg-white">
        <div className="box-shadow flex max-w-2xl flex-col gap-4 p-10">
          {error && (
            <MessageError title="Incorrect network">
              <p>{error}</p>
            </MessageError>
          )}
          {/* {!isUsingOwnerAddress && domain && ( */}
          {/*  <MessageError title={'Incorrect owner address'}> */}
          {/*    <p> */}
          {/*      Please switch your wallet address to the domain owner address */}
          {/*    </p> */}
          {/*    <p> */}
          {/*      <i> */}
          {/*        The current domain owner address: {domain.owner.address}{' '} */}
          {/*      </i> */}
          {/*    </p> */}
          {/*    <p> */}
          {/*      <i>Your current using wallet address: {wallet}</i> */}
          {/*    </p> */}
          {/*  </MessageError> */}
          {/* )} */}
          <p className="text-2xl font-medium">
            Transfer Domain To Other Address
          </p>

          <span>
            You must have the private key in order to manage your domain. if you
            transfer the domain to an exchange or any other account where you do
            not control the private key, you will not be able to access your
            domain. Not your keys, not your domain.
          </span>
          <form onSubmit={(e) => handleFormSubmit(e)}>
            <div className="flex w-full flex-col gap-4">
              <span className="font-bold">Recipient address</span>
              <input
                onChange={(e) => handleRecipientAddressChange(e)}
                className="w-full rounded-md border bg-white px-5 py-3 outline-none duration-200 focus:outline-[#93278f]"
                placeholder="Enter recipient address"
                name="recipientAddress"
                required
              ></input>

              {!isValidRecipientAddress && (
                <MessageError>
                  <span className="font-medium">
                    Please enter a valid Ethereum address.
                  </span>
                </MessageError>
              )}
            </div>
            <div className="mt-5 w-full border"></div>

            <div className="flex w-full flex-col gap-5 py-5">
              <MyCheckbox name="isUnderstand">
                I understand that I am transferring ownership of this domain and
                this action is irreversible
              </MyCheckbox>

              <MyCheckbox name="isNotToExchangeAddress">
                I'm not transferring to an exchange address
              </MyCheckbox>
            </div>
            <div className="w-full border"></div>
            <div className="mt-5 text-right">
              <button
                className="mt-2 w-full rounded-xl border-2 border-[#93278f] px-8 py-4 font-medium text-[#93278f] duration-200 hover:bg-[#93278f] hover:text-white"
                onClick={async (_) => {
                  if (!transfering) await handleTransfer();
                }}
                // type="submit"
                disabled={transfering}
                // onClick={(x) => setTransferDomain(false)}
              >
                {!transfering ? (
                  <> Transfer Domain</>
                ) : (
                  <div className="grid h-full w-full place-items-center">
                    <Icon
                      icon="eos-icons:loading"
                      className="h-auto w-auto self-center "
                    />
                  </div>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export { TransferDomain };
