import { AxiosInstance } from 'axios';
import { errorHandler } from '../../errorHandler';
import config from '@/config';

interface IDownloadInvoiceOuput {
  url: string;
}

export const downloadInvoice =
  (instance: AxiosInstance) => async (orderId: string) => {
    try {
      const invoice = await instance.get<IDownloadInvoiceOuput>(
        `${config.api.router.invoice.get}${orderId}`,
      );
      window.open(invoice.data.url);
      // return invoice.data;
    } catch (e) {
      throw errorHandler(e);
    }
  };
