import * as LayerZeroScan from '@layerzerolabs/scan-client';
import { Attribute } from '@/components/CrossChain/Attribute';
import {
  CrossChainContext,
  SnackState,
  SwappingState,
} from '@/utils/context/CrossChainContext';
import { Icon } from '@iconify/react';
import { MessageStatus } from '@layerzerolabs/scan-client';
import { MyDomainContext } from '@/utils/context/MyDomainContext';
import { StepTitle } from './StepTitle';
import { useContext, useState } from 'react';
import config, { DeploymentEnvironment } from '@/config';
import delay from 'delay';

function Step3() {
  const { selectedDomain: domain } = useContext(MyDomainContext);
  const {
    selectBlockChain,
    swappingState,
    step2Payload,
    setSwappingState,
    setSnackState,
  } = useContext(CrossChainContext);
  const [bridgeState, setBridgeState] = useState<string>(
    'Sending Request to cross chain provider.',
  );

  const mapping: { [key: string]: string } = {
    [MessageStatus.INFLIGHT]: `INFLIGHT to ${selectBlockChain?.name}.`,
    [MessageStatus.DELIVERED]: `DELIVERED to ${selectBlockChain?.name}.`,
  };

  const layerzeroListener = async () => {
    if (step2Payload) {
      const layerzeroEnv =
        config.env === DeploymentEnvironment.PRODUCTION ? 'mainnet' : 'testnet';
      const layerzeroScanClient = LayerZeroScan.createClient(layerzeroEnv);

      let status: LayerZeroScan.MessageStatus | undefined;

      do {
        const b_tx = await layerzeroScanClient.getMessagesBySrcTxHash(
          step2Payload!.hash,
        );
        console.log(`Checking tx status from LayerZero...`);
        b_tx.messages.forEach((m) => {
          status = m.status;
        });
        if (status) {
          setBridgeState(mapping[status]);
        }
        if (status !== LayerZeroScan.MessageStatus.DELIVERED)
          await delay(10000);
      } while (status !== LayerZeroScan.MessageStatus.DELIVERED);

      await delay(5000);
    }
  };
  const textColor = 'text-[#93278f]';

  if (
    !domain ||
    !selectBlockChain ||
    swappingState !== SwappingState.Listener ||
    !step2Payload
  ) {
    return <></>;
  }
  layerzeroListener().then((r) => {
    setSwappingState(SwappingState.Receive);
    setSnackState(SnackState.Success);
  });
  return (
    <>
      <StepTitle
        step={3}
        title="Chain Crossing."
        description={
          'Refrain from closing the tab to avoid interrupting the process. Please be patient and wait for the task to complete'
        }
      />
      <div className="w-full border"></div>
      <div className="flex flex-col space-y-4">
        <Attribute title="Nonce" color={textColor}>
          {step2Payload?.nonce}
        </Attribute>
        <Attribute title="Txn" color={textColor}>
          {step2Payload?.hash}
        </Attribute>
        <Attribute title="Status" color={textColor}>
          {bridgeState}
        </Attribute>
      </div>

      <div>
        <div className="flex flex-col ">
          <div className="grid h-full w-full place-items-center">
            <Icon
              icon="eos-icons:loading"
              className="h-24 w-auto self-center text-gray-300"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export { Step3 };
