import { AxiosInstance } from 'axios';
import { ICart } from './cart.interface';
import { errorHandler } from '../../errorHandler';
import config from '@/config';

export const getCart =
  (instance: AxiosInstance) => async (): Promise<ICart> => {
    try {
      const response = await instance.get<ICart>(
        config.api.router.cart.getCart,
      );
      // console.log('cart',response.data)
      return response.data;
    } catch (e) {
      throw errorHandler(e);
    }
  };
