import { AxiosInstance } from 'axios';
import { errorHandler } from '../../errorHandler';
import config from '@/config';

export const cancelOrder =
  (instance: AxiosInstance) => async (orderId: string) => {
    try {
      await instance
        .delete(`${config.api.router.order.cancelOrder}${orderId}`)
        .catch(errorHandler);
    } catch (e) {
      throw errorHandler(e);
    }
  };
