import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './hook';

const IsNeedAuth = () => {
  const { isAuthenticated } = useAuth();

  const isAutherized = (roles = true) => {
    // if (keycloak && roles) {
    //   return roles.some((r) => {
    //     const realm = keycloak.hasRealmRole(r);
    //     const resource = keycloak.hasResourceRole(r);
    //     return realm || resource;
    //   });
    // }
    // return false;
    return isAuthenticated;
  };

  return isAutherized() ? <Outlet /> : <Navigate to="/" />;
};

export { IsNeedAuth };
