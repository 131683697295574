import { AttributeType, ProfileAttribute } from './ProfileAttribute';
export interface IRecord {
  name: string;
  value: string;
}
interface IProfileRecordTable {
  title: string;
  records: IRecord[];
}
const ProfileRecordTable = (props: IProfileRecordTable) => {
  const { title, records } = props;
  return (
    <div className="space-y-2">
      <div className="flex space-x-4 text-gray-400">
        <div className="text-lg font-bold">{title}</div>
        <div className="text-lg font-semibold">{records.length} Records</div>
      </div>
      {records.map(({ name, value }) => (
        <ProfileAttribute
          key={name}
          title={name}
          type={AttributeType.Copy}
          isFull
        >
          {value}
        </ProfileAttribute>
      ))}
    </div>
  );
};

export { ProfileRecordTable };
