export enum CryptoWalletType {
  IMPORTED = 'imported',
  MANAGED = 'managed',
  ASSOCIATED = 'associated',
  SYSTEM = 'system',
}
export enum BlockchainEco {
  ETHEREUM = 'ethereum',
  SOLANA = 'solana',
  NERA = 'near',
  COSMOS = 'cosmos',
  APTOS = 'aptos',
  TRON = 'tron',
  POLKADOT = 'polkadot',
  CARDANO = 'cardano',
}
interface ImportedWallet {
  id: string;
  eco: BlockchainEco;
  address: string;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  version: number;
}

interface ManagedWallet {
  id: string;
  eco: BlockchainEco;
  publicKey: string;
  encryptedPrivateKey: string;
  encryptedAuthKey: string | null;
  accountId: number;
  indexId: number;
  path: string;
  address: string;
  version: number;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
}

export interface ICryptoWallet {
  id: string;
  name: string;
  address: string;
  eco: BlockchainEco;
  type: CryptoWalletType;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
  version: number;
  managed?: ManagedWallet;
  imported?: ImportedWallet;
}
