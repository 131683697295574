import { Button as MUIButton } from '@mui/material';
import { ReactNode } from 'react';

interface IButtonProps {
  variant?: 'contained' | 'outlined' | 'text';
  color?: 'primary' | 'secondary';
  size?: 'small' | 'medium' | 'large';
  children?: ReactNode;
  onClick?: () => void;
}
const Button = (props: IButtonProps) => {
  const {
    variant = 'contained',
    color = 'primary',
    size = 'medium',
    children,
    onClick,
  } = props;
  return (
    <MUIButton variant={variant} color={color} size={size} onClick={onClick}>
      {children}
    </MUIButton>
  );
};

export { Button };
