import * as luxon from 'luxon';
import { IPaymentOrder } from '@/api/omni/Order/order.interface';
import { Link } from 'react-router-dom';
import { ReactNode, useState } from 'react';
import { Skeleton, Tooltip } from '@mui/material';
import { copyToClipboard } from '@/utils/copyToClipboard';
import Logo from '../common/Logo';

interface IProps {
  children?: ReactNode;
  order: IPaymentOrder;
}
interface IInfoRowProps {
  title?: string;
  value?: string;
  copyable?: boolean;
}
interface IButtonProps {
  url: string;
  text: string;
}

function InfoRow(props: IInfoRowProps) {
  const { title, value, copyable = false } = props;
  const [copyText, setCopyText] = useState('Copy to Clipboard');
  return (
    <div className="my-2 flex w-full flex-col justify-between px-4 text-gray-500 sm:flex-row">
      <span className="w-1/2">
        {title || <Skeleton animation="wave" height={20} width="80%" />}
      </span>
      {copyable && value ? (
        <Tooltip title={copyText} arrow>
          <span
            className="w-1/2 cursor-pointer truncate text-blue-500 hover:underline"
            onClick={(e) =>
              copyToClipboard(e.currentTarget.textContent || '', setCopyText)
            }
          >
            {value}
          </span>
        </Tooltip>
      ) : (
        <span className="w-1/2 truncate text-gray-500">
          {value?.toUpperCase() || (
            <Skeleton animation="wave" height={20} width="100%" />
          )}
        </span>
      )}
    </div>
  );
}

function Button(props: IButtonProps) {
  const { url, text } = props;
  return (
    <Link
      to={url}
      className="w-36 rounded-full bg-[#93278f] py-2 text-center text-lg text-white outline-none duration-200 hover:bg-slate-300 hover:text-black"
    >
      <span>{text}</span>
    </Link>
  );
}

function PaymentResult(props: IProps) {
  const { children, order } = props;
  const { orderId, amount, status, method, details, createdAt } = order;
  const paymentId = details?.id || undefined;

  return (
    <div className="w-full overflow-y-auto scroll-smooth">
      <div className="mx-auto mt-4 w-auto p-6 lg:w-[35rem]">
        <div className="relative flex flex-col items-center space-y-4 rounded-xl bg-white py-8 shadow-md">
          <Logo />
          {children}
          <div className="flex flex-col items-center py-4">
            {status === 'canceled' ? (
              <></>
            ) : (
              <>
                {method ? (
                  <InfoRow title="Payment Method" value={method} />
                ) : (
                  <InfoRow title="Payment Method" value={'FREE'} />
                )}
              </>
            )}

            {status && <InfoRow title="Status" value={status} />}
            {orderId && <InfoRow title="Order ID" value={orderId} copyable />}
            {paymentId && (
              <InfoRow title="Payment ID" value={paymentId} copyable />
            )}
            {amount && <InfoRow title="Amount" value={`USD $${amount}`} />}
            {createdAt && (
              <InfoRow
                title="Transaction Date"
                value={luxon.DateTime.fromISO(createdAt.toString()).toFormat(
                  'MMMM dd, yyyy',
                )}
              />
            )}
          </div>
          <div className="flex items-center space-x-8 py-2">
            <Button url="/" text="Back" />
            <Button url="/account" text="Continue" />
          </div>
        </div>
      </div>
    </div>
  );
}

function PaymentResultSkeleton() {
  return (
    <div className="w-full overflow-y-auto scroll-smooth">
      <div className="mx-auto mt-4 w-auto p-6 lg:w-[35rem]">
        <div className="relative flex flex-col items-center space-y-4 rounded-xl bg-white py-8 shadow-md">
          <Skeleton
            height={68}
            width={224}
            animation="wave"
            variant="rectangular"
            style={{ borderRadius: 8 }}
            className="mb-2"
          />
          <Skeleton
            height={70}
            width={70}
            animation="wave"
            variant="circular"
            className="mx-auto w-auto"
          />
          <Skeleton className="mt-8" animation="wave" height={40} width={150} />

          <div className="flex w-9/12 flex-col items-center py-4">
            <InfoRow />
            <InfoRow />
            <InfoRow />
            <InfoRow />
            <InfoRow />
            <InfoRow />
          </div>
          <div className="flex items-center space-x-8 py-2">
            <Button url="/" text="Back" />
            <Button url="/account" text="Continue" />
          </div>
        </div>
      </div>
    </div>
  );
}

export { PaymentResult, PaymentResultSkeleton, Button };
