import { DomainSearchType } from '@/api/omni/Domain/search';
import { Icon } from '@iconify/react';

enum IconSize {
  XS = 'xs',
  MD = 'md',
  BASE = 'base',
  LG = 'lg',
  XL = 'xl',
}
function Availability({
  isAvailable = DomainSearchType.UNAVAILABLE,
  size = IconSize.XS,
}: {
  isAvailable?: DomainSearchType;
  size?: IconSize;
}) {
  const sizeClass = {
    xs: 'h-5 w-5',
    md: 'h-10 w-10',
    base: 'h-12 w-12',
    lg: 'h-16 w-16',
    xl: 'h-20 w-20',
  };
  if (isAvailable === DomainSearchType.AVAILABLE) {
    return (
      <Icon
        className={`${sizeClass[size]} shrink-0 text-green-600`}
        icon="teenyicons:tick-circle-outline"
      />
    );
  }
  return (
    <Icon
      className={`${sizeClass[size]} shrink-0 text-red-600`}
      icon="teenyicons:x-circle-outline"
    />
  );
}

export { Availability, IconSize };
