import { IPremiumDomain } from '@/api/omni/PremiumDomain/interface';
import DropdownController from '@/Pages/premiumDomain/List/component/dropdownController';
import React, { FC, useState } from 'react';
import TldAvailableBox from '@/Pages/premiumDomain/List/component/tldAvailableBox';

export const Premium_domain_row: FC<IPremiumDomain> = ({
  name,
  status,
  price,
  bonusToken,
  depositToken,
  tld,
  available,
}) => {
  const [dropdown, setDropdown] = useState(false);
  return (
    <div className="border-b border-r border-[#f2f2f2]">
      <div className="flex w-full font-semibold">
        <div
          className={`flex w-[30%] min-w-[140px] items-center gap-4 p-5 py-2 ${
            available > 0 ? `text-[#6ab1b4]` : `text-[#ff4000]`
          } `}
        >
          <span>{name}</span>
          <div
            className="cursor-pointer"
            onClick={(x) => {
              setDropdown(!dropdown);
            }}
          >
            <DropdownController isDropdown={dropdown} />
          </div>
        </div>
        <div className="flex w-[25%] items-center p-5 py-2 text-[#818181]">
          {price}
        </div>
        <div className="flex w-[25%] items-center p-5 py-2 text-[#818181]">
          {bonusToken}
        </div>
        <div className="flex w-[calc(30%-35px)] items-center justify-between p-5 py-2 max-[1220px]:w-[30%]">
          <p className="text-[#818181]">{depositToken}</p>{' '}
          <button
            className="h-full rounded-[0.3rem] bg-gradient-to-tr from-[#cf14ca] to-[#8d0fd7] px-6 py-2 font-semibold text-white"
            onClick={(event) => {
              window.open(
                `${
                  window.location.href.split('/')[0]
                }/premium/search/${name}.meta`,
                '_blank',
                'noopener,noreferrer',
              );
            }}
          >
            Search
          </button>
        </div>
      </div>

      <div
        data-dropdown={dropdown}
        className={`transition-delay flex max-h-0 gap-3 overflow-hidden px-5 pt-0 transition-all data-[dropdown=true]:max-h-[200px] data-[dropdown=true]:p-5 data-[dropdown=true]:pt-2`}
      >
        {/*  TLD*/}
        {tld.map((x) => {
          return (
            <TldAvailableBox
              dropdown={dropdown}
              name={x.name}
              available={x.available}
            ></TldAvailableBox>
          );
        })}
      </div>
    </div>
  );
};
