import {
  CrossChainContext,
  SwappingState,
} from '@/utils/context/CrossChainContext';
import { Icon } from '@iconify/react';
import { SectionTitle } from '../common/SectionTitle';
import { useContext } from 'react';

interface IStepTitleProps {
  step: number;
  title: string;
  description?: string;
}
function StepTitle(props: IStepTitleProps) {
  const { swappingState, setSwappingState } = useContext(CrossChainContext);
  const { step, title, description } = props;
  const goBack = () => {
    if (swappingState) {
      const prevState: SwappingState = (+swappingState - 1) as SwappingState;
      setSwappingState(prevState);
    }
  };
  return (
    <div className="mb-4 space-y-4">
      <div className="flex items-center justify-between">
        <div className="flex space-x-4">
          <div className="flex h-8 w-8 items-center justify-center rounded-full bg-[#93278f] p-1 text-xl font-extrabold text-white">
            {step}
          </div>
          <SectionTitle title={title} />
        </div>
        {+swappingState > 0 && (
          <div
            className=" h-8 w-8 items-center rounded-full bg-[#93278f] p-1.5"
            onClick={() => goBack()}
          >
            <Icon
              className="h-full w-full"
              icon="mingcute:back-fill"
              color="white"
            />
          </div>
        )}
      </div>
      {description && <div>{description}</div>}
    </div>
  );
}

export { StepTitle };
