import { ReactNode, useEffect, useState } from 'react';

interface IModalLayoutProps {
  children: ReactNode;
  isOpen: boolean;
  onClose: (e) => void;
}
function ModalLayout({ children, isOpen, onClose }: IModalLayoutProps) {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(isOpen);

  const handleClose = () => {
    setIsOpenModal(false);
  };

  useEffect(() => {
    onClose(isOpenModal);
  }, [isOpenModal, onClose]);

  useEffect(() => {
    setIsOpenModal(isOpen);
  }, [isOpen]);

  if (!isOpenModal) {
    return <></>;
  }

  return (
    <div
      className="absolute left-0 top-0 z-[410] flex h-full w-full items-center justify-center overflow-y-scroll bg-gray-500/20 py-16"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          handleClose();
        }
      }}
    >
      {children}
    </div>
  );
}

export { ModalLayout };
