import { ICryptoWallet } from '@/api/omni/CryptoWallet/cryptoWallet.interface';
import { Icon } from '@iconify/react';
import { MyDomainContext } from '@/utils/context/MyDomainContext';
import { WalletEthereumAddressView } from './WalletEthereumAddressView';
import { motion } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';

function AddressViewList({ wallets: _wallets }: { wallets: ICryptoWallet[] }) {
  const {
    isRevealOptions,
    setIsRevealOptions,
    privateKey: key,
  } = useContext(MyDomainContext);
  // const { user } = useContext(GlobalStateContext);
  const [privateKey, setPrivateKey] = useState<string>('');
  //   const [reveal, setReveal] = useState<boolean>(false);
  const ani = {
    hide: {
      opacity: 0,
      zIndex: '-1',
      transition: { zIndex: { delay: 0.35 } },
      duration: 0.2,
      y: 25,
    },
    show: { opacity: 1, zIndex: '410', y: 0, transition: { duration: 0.2 } },
  };

  useEffect(() => {
    setPrivateKey(key.replaceAll(/./g, '*'));
  }, [key]);

  return (
    <>
      <motion.div
        variants={ani}
        animate={isRevealOptions ? 'show' : 'hide'}
        // onClick={() => setReveal(false)}
        className="absolute z-[-1] grid h-full w-full place-items-center overflow-hidden opacity-0 backdrop-blur-sm"
      >
        <div className="box-shadow flex flex-col overflow-hidden rounded-none border lg:h-auto lg:w-[44rem] lg:rounded-xl">
          <div className="flex items-center justify-between bg-white px-8 text-xl font-medium lg:rounded-t-lg">
            <span>Private Key</span>
            <Icon
              icon="ep:close-bold"
              className="h-6 w-auto cursor-pointer"
              onClick={() => setIsRevealOptions(false)}
            />
          </div>
          <div className="max-h-auto relative z-50 flex h-full w-full flex-col gap-5 bg-white px-8 pb-8 lg:max-h-[500px] lg:rounded-b-lg">
            <div className="relative">
              <input
                // value="ads"
                value={privateKey}
                disabled
                className="w-full overflow-x-hidden rounded-md border px-6 py-5 pr-16"
              />
              <button
                className="btn-copy rounded-full p-3 hover:bg-gray-200 "
                onClick={() => {
                  navigator.clipboard.writeText(key);
                  setIsRevealOptions(false);
                }}
              >
                <Icon
                  icon="ant-design:copy-filled"
                  className="mx-auto h-5 w-auto text-gray-500"
                />
              </button>
            </div>
          </div>
        </div>
      </motion.div>
      {_wallets?.map((address) => (
        <WalletEthereumAddressView address={address} key={address.address} />
      ))}
    </>
  );
}
export { AddressViewList };
