export enum AxiosState {
  IDLE,
  REQUEST,
  RESPONSE,
  END,
}
export interface IAxiosState {
  totalCount: number;
  count: number;
}

export const AXIOS_INIT_STATE = {
  totalCount: 0,
  count: 0,
};
export const AxiosCountReducer = (
  state: IAxiosState = AXIOS_INIT_STATE,
  action: { type: any },
): IAxiosState => {
  switch (action.type) {
    case AxiosState.REQUEST:
      return { ...state, totalCount: state.totalCount + 1 };
    case AxiosState.RESPONSE:
      return { ...state, count: state.count + 1 };
    case AxiosState.END:
      if (state.totalCount === state.count) {
        return { ...state, totalCount: 0, count: 0 };
      }
      return state;
    default:
      return state;
  }
};
