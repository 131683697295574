import { AxiosInstance } from 'axios';
import { ICryptoWallet } from '@/api/omni/CryptoWallet/cryptoWallet.interface';
import { errorHandler } from '@/api/errorHandler';
import config from '@/config';

export const setDefaultWallet =
  (instance: AxiosInstance) => async (address: string) => {
    try {
      await instance.post<ICryptoWallet[]>(
        config.api.router.cryptoWallet.setDefaultWallet,
        {
          address,
        },
      );
    } catch (e) {
      throw errorHandler(e);
    }
  };
