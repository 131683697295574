import { Alert, Snackbar } from '@mui/material';
import {
  CrossChainContext,
  CrossChainContextProvider,
  SnackState,
} from '@/utils/context/CrossChainContext';
import { ModalLayout } from '../common/ModalLayout';
import {
  MyDomainContext,
  SelectDomainState,
} from '@/utils/context/MyDomainContext';
import { Step0 } from '@/components/CrossChain/Step0';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from '@/components/CrossChain/Step3';
import { Step4 } from '@/components/CrossChain/Step4';
import { Step5 } from '@/components/CrossChain/Step5';
import { motion } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';

function CrossChainBody() {
  const {
    selectedDomain: domain,
    selectDomainState,
    setSelectDomainState,
  } = useContext(MyDomainContext);

  const { snackMessage, snackState, isOpenSnackbar, setIsOpenSnackbar } =
    useContext(CrossChainContext);

  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    if (domain && selectDomainState === SelectDomainState.SwapChain) {
      setShowModal(true);
    }
  }, [domain, selectDomainState]);

  useEffect(() => {
    if (showModal === false) {
      setSelectDomainState(SelectDomainState.None);
    }
  }, [setSelectDomainState, showModal]);

  return (
    <>
      <ModalLayout isOpen={showModal} onClose={setShowModal}>
        <motion.div
          viewport={{ once: true }}
          // transition={{ delay: 0.5 }}
          className="mb-10 flex max-w-3xl flex-1 flex-col"
        >
          <div className="space-y-4 rounded-3xl border border-[#e2e9f2] bg-white p-6 ">
            <>
              <Step0 />
              <Step1 />
              <Step2 />
              <Step3 />
              <Step4 />
              <Step5 />
            </>
          </div>
        </motion.div>
      </ModalLayout>
      <Snackbar
        open={isOpenSnackbar}
        autoHideDuration={6000}
        onClose={() => setIsOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setIsOpenSnackbar(false)}
          severity={snackState === SnackState.Success ? 'success' : 'error'}
          sx={{ width: '100%' }}
        >
          {snackMessage}
        </Alert>
      </Snackbar>
    </>
  );
}

function CrossChain() {
  return (
    <CrossChainContextProvider>
      <CrossChainBody />
    </CrossChainContextProvider>
  );
}

export { CrossChain };
