import '@/App.scss';
import '@/index.css';
import * as Sentry from '@sentry/react';
import { AuthProvider } from './utils/auth/AuthProvider';
import { AxiosProvider } from './utils/axios/AxiosProvider';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { BrowserTracing } from '@sentry/tracing';
import { CartProvider } from '@/utils/context/CartContext';
import { IsNeedAuth } from './utils/auth/IsNeedAuth';
import { ThemeProvider } from '@mui/material';
import { ednsTheme } from './components/common/Themes';
import About from '@/Pages/about';
import Account from '@/Pages/account';
import Cancel from '@/Pages/payment/canceled/[id]';
import Cart from '@/Pages/cart';
import Checkout from '@/Pages/checkout';
import Contact from '@/Pages/contact';
import Domain from '@/Pages/domain/[fqdn]';
import Fail from '@/Pages/payment/failed/[id]';
import Friends from '@/Pages/friends';
import GlobalStateProvider from '@/utils/context/GlobalStateContext';
import Home from '@/Pages';
import Lookup from '@/Pages/look-up';
import Missing from '@/Pages/404';
import MyDomain from '@/Pages/mydomain';
import NotFound from '@/Pages/payment/404';
import Order from '@/Pages/order';
import Premium from '@/Pages/premiumDomain/List';
import PremiumSearch from '@/Pages/premiumDomain/Search';
import React from 'react';
import ReactDOM from 'react-dom';
import Reseller from '@/Pages/reseller';
import ScrollToTop from '@/utils/scripts/ScrollToTop';
import Search from '@/Pages/search';
import Setting from '@/Pages/setting';
import Sms from '@/Pages/sms';
import Success from '@/Pages/payment/success/[id]';
import TransferOrSell from '@/Pages/transfersell';
import UIContainer from '@/components/UIContainer';
import config, { DeploymentEnvironment } from './config';
import reportWebVitals from '@/reportWebVitals';

Sentry.init({
  dsn: config.sentry.dsn,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.2,
});

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AxiosProvider>
        <ThemeProvider theme={ednsTheme}>
          <AuthProvider>
            <GlobalStateProvider>
              <CartProvider>
                <ScrollToTop>
                  <Routes>
                    {/* Maintenance Switch */}
                    <Route path="/" element={<UIContainer />}>
                      <Route index element={<Home />}></Route>
                      <Route path="/about" element={<About />}></Route>
                      <Route path="/contact" element={<Contact />}></Route>
                      <Route path="/search/:fqdn" element={<Search />}></Route>
                      <Route path="/domain/:fqdn" element={<Domain />}></Route>
                      {config.env !== DeploymentEnvironment.PRODUCTION && (
                        <Route
                          path="/premium/search/:fqdn"
                          element={<PremiumSearch />}
                        ></Route>
                      )}
                      {config.env !== DeploymentEnvironment.PRODUCTION && (
                        <Route path="/premium" element={<Premium />}></Route>
                      )}

                      <Route element={<IsNeedAuth />}>
                        <Route path="/setting" element={<Setting />}></Route>
                        <Route path="/account" element={<Account />}></Route>
                        <Route path="/mydomain" element={<MyDomain />}></Route>
                        <Route path="/order" element={<Order />}></Route>

                        <Route path="/look-up" element={<Lookup />}></Route>
                        {/* <Route path="/look-up/:fqdn" element={<LookupResult />}></Route> */}
                        <Route path="/cart" element={<Cart />}></Route>
                        <Route path="/checkout" element={<Checkout />}></Route>
                        <Route path="/sms" element={<Sms />}></Route>
                        <Route
                          path="/payment/404"
                          element={<NotFound />}
                        ></Route>
                        <Route
                          path="/success/:id"
                          element={<Success />}
                        ></Route>
                        <Route path="/failed/:id" element={<Fail />}></Route>
                        <Route
                          path="/canceled/:id"
                          element={<Cancel />}
                        ></Route>
                        <Route
                          path="/payment/success/:id"
                          element={<Success />}
                        ></Route>
                        <Route
                          path="/payment/failed/:id"
                          element={<Fail />}
                        ></Route>
                        <Route
                          path="/payment/canceled/:id"
                          element={<Cancel />}
                        ></Route>
                        <Route
                          path="/transfersell"
                          element={<TransferOrSell />}
                        ></Route>
                        <Route path="/reseller" element={<Reseller />}></Route>
                        <Route
                          path="/reseller/bind"
                          element={<Reseller />}
                        ></Route>
                        <Route path="/friends" element={<Friends />}></Route>
                      </Route>
                    </Route>

                    <Route path="*" element={<Missing />}></Route>
                  </Routes>
                </ScrollToTop>
              </CartProvider>
            </GlobalStateProvider>
            {/* </Auth0Provider> */}
          </AuthProvider>
        </ThemeProvider>
      </AxiosProvider>
    </BrowserRouter>
  </React.StrictMode>,
  // rootElemnet
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
