import { AttributeType, ProfileAttribute } from './ProfileAttribute';
import { CrossChain } from '../CrossChain/CrossChain';
import { DateTime } from 'luxon';
import { DomainMenu } from '../domain/DomainMenu';
import { GlobalStateContext, useMyDomain } from '@/utils/context';
import { ProfileSubSection } from './ProfileSubSection';
import { SectionLayout } from '../common/SectionLayout';
import { TransferDomain } from '../DomainList/TransferDomain';
import { Web3Functions } from '../web3/Web3';
import { getChainIcon } from '../web3/getChainIcon';
import { useContext, useEffect, useState } from 'react';
import DomainStatusBar, {
  DomainStatus,
} from '@/components/domain/DomainStatusBar';
import MortgageBox from '@/components/Mortgage';
import md5 from 'crypto-js/md5';

interface IProfileTabMainProps {
  fqdn?: string;
  tld?: string;

  useWeb3?: Web3Functions;
}
const ProfileTabMain = (props: IProfileTabMainProps) => {
  const { fqdn, tld, useWeb3 } = props;

  const { domain } = useMyDomain();
  if (domain === undefined) throw new Error('Domain Not Found');
  const { user } = useContext(GlobalStateContext);

  const [gradientColor, setGradientColor] = useState<string[]>(['', '']);
  const [contractAddress, setContractAddress] = useState<string>('');
  const [isOwner, setIsOwner] = useState<boolean>(false);

  // Prasing address for displaying
  const handlePraseDate = (date: string): string => {
    // display date in format YYYY-MM-DD
    return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
  };
  // User wallet address whether is owner of domain at init
  useEffect(() => {
    const isOwner = user?.wallets.find(
      (wallet) => wallet?.address === domain.owner.address,
    );
    setIsOwner(!!isOwner);
  }, [user, domain]);
  // Generate domain Color
  useEffect(() => {
    // md5 hash domain
    if (fqdn) {
      const hash = md5(fqdn).toString();

      setGradientColor([
        `linear-gradient(to bottom, #${hash.slice(0, 6)} 0%, #${hash.slice(
          6,
          12,
        )} 100%)`,
        `linear-gradient(to right, #${hash.slice(12, 18)} 0%, #${hash.slice(
          18,
          24,
        )} 100%)`,
      ]);
    }
  }, [fqdn]);

  useEffect(() => {
    const exec = async () => {
      if (!useWeb3) return;

      setContractAddress(await useWeb3.getContractAddress('DefaultWrapper'));
    };
    exec();
  }, [useWeb3]);

  return (
    <>
      <SectionLayout
        className="z-20 mt-4 space-y-8 bg-white bg-no-repeat"
        style={{
          backgroundImage: gradientColor[1],
          backgroundSize: '100% 7rem',
        }}
      >
        <div className="mt-8 space-y-4">
          <div
            style={{ background: gradientColor[0] }}
            className={`z-20 h-28 w-28 rounded-full border border-white`}
          ></div>
          <div className="flex justify-between">
            <div className="text-3xl font-bold">{fqdn}</div>
            <DomainStatusBar status={DomainStatus.Active}></DomainStatusBar>
          </div>

          {isOwner && (
            <DomainMenu reserveDomain={true} domain={domain} listView />
          )}
        </div>
      </SectionLayout>
      <SectionLayout className="bg-white">
        <div className="space-y-2">
          <ProfileSubSection title="Addresses">
            <ProfileAttribute
              title={
                <div className="h-4 w-4">
                  {getChainIcon(+domain.blockchains[0]?.chainId)}
                </div>
              }
              type={AttributeType.Copy}
              isShort
            >
              {domain.owner?.address}
            </ProfileAttribute>
          </ProfileSubSection>
          <ProfileSubSection title="Ownership">
            <ProfileAttribute title={'User'} type={AttributeType.Link} isShort>
              {domain.owner?.address}
            </ProfileAttribute>
            <ProfileAttribute title={'Owner'} type={AttributeType.Link} isShort>
              {domain.owner.address}
            </ProfileAttribute>
            <ProfileAttribute title={'Expiry'}>
              {handlePraseDate(domain.expiry?.toString() || '1971-01-01')}
            </ProfileAttribute>
            <ProfileAttribute title={'TLD'} type={AttributeType.Link}>
              {tld}
            </ProfileAttribute>
            <ProfileAttribute
              title={'Contract'}
              type={AttributeType.Copy}
              isShort
            >
              {contractAddress}
            </ProfileAttribute>
          </ProfileSubSection>
        </div>
      </SectionLayout>

      <TransferDomain />
      <CrossChain />
      <MortgageBox useWeb3={useWeb3} />
    </>
  );
};

export { ProfileTabMain };
