/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type { IBridge, IBridgeInterface } from "../IBridge";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "nonce",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "ref",
        type: "bytes32",
      },
    ],
    name: "Accepted",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "uint256",
        name: "nonce",
        type: "uint256",
      },
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address",
      },
      {
        indexed: true,
        internalType: "bytes32",
        name: "ref",
        type: "bytes32",
      },
    ],
    name: "Bridged",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "ref",
        type: "bytes32",
      },
    ],
    name: "Received",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "nonce",
        type: "uint256",
      },
      {
        internalType: "bytes32",
        name: "ref",
        type: "bytes32",
      },
      {
        internalType: "enum Chain",
        name: "srcChain",
        type: "uint8",
      },
      {
        internalType: "enum CrossChainProvider",
        name: "provider",
        type: "uint8",
      },
      {
        internalType: "bytes",
        name: "name",
        type: "bytes",
      },
      {
        internalType: "bytes",
        name: "tld",
        type: "bytes",
      },
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "uint64",
        name: "expiry",
        type: "uint64",
      },
    ],
    name: "accept",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "nonce",
        type: "uint256",
      },
      {
        internalType: "bytes32",
        name: "ref",
        type: "bytes32",
      },
      {
        internalType: "enum Chain",
        name: "dstChain",
        type: "uint8",
      },
      {
        internalType: "enum CrossChainProvider",
        name: "provider",
        type: "uint8",
      },
      {
        internalType: "bytes32",
        name: "name",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "tld",
        type: "bytes32",
      },
    ],
    name: "bridge",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "enum Chain",
        name: "dstChain",
        type: "uint8",
      },
      {
        internalType: "enum CrossChainProvider",
        name: "provider",
        type: "uint8",
      },
      {
        internalType: "bytes32",
        name: "name",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "tld",
        type: "bytes32",
      },
    ],
    name: "estimateFee",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "ref",
        type: "bytes32",
      },
    ],
    name: "getAcceptedRequest",
    outputs: [
      {
        components: [
          {
            internalType: "enum Chain",
            name: "srcChain",
            type: "uint8",
          },
          {
            internalType: "enum CrossChainProvider",
            name: "provider",
            type: "uint8",
          },
          {
            internalType: "bytes32",
            name: "tld",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "name",
            type: "bytes32",
          },
          {
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            internalType: "uint64",
            name: "expiry",
            type: "uint64",
          },
        ],
        internalType: "struct IBridge.AcceptedRequest",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "ref",
        type: "bytes32",
      },
    ],
    name: "getBridgedRequest",
    outputs: [
      {
        components: [
          {
            internalType: "enum Chain",
            name: "dstChain",
            type: "uint8",
          },
          {
            internalType: "enum CrossChainProvider",
            name: "provider",
            type: "uint8",
          },
          {
            internalType: "bytes32",
            name: "tld",
            type: "bytes32",
          },
          {
            internalType: "bytes32",
            name: "name",
            type: "bytes32",
          },
          {
            internalType: "address",
            name: "owner",
            type: "address",
          },
          {
            internalType: "uint64",
            name: "expiry",
            type: "uint64",
          },
        ],
        internalType: "struct IBridge.BridgedRequest",
        name: "",
        type: "tuple",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [],
    name: "getNonce",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "uint256",
        name: "nonce",
        type: "uint256",
      },
      {
        internalType: "enum Chain",
        name: "dstChain",
        type: "uint8",
      },
      {
        internalType: "enum CrossChainProvider",
        name: "provider",
        type: "uint8",
      },
      {
        internalType: "bytes32",
        name: "name",
        type: "bytes32",
      },
      {
        internalType: "bytes32",
        name: "tld",
        type: "bytes32",
      },
      {
        internalType: "address",
        name: "owner",
        type: "address",
      },
      {
        internalType: "uint64",
        name: "expiry",
        type: "uint64",
      },
    ],
    name: "getRef",
    outputs: [
      {
        internalType: "bytes32",
        name: "",
        type: "bytes32",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "enum Chain",
        name: "chain",
        type: "uint8",
      },
    ],
    name: "getRemoteBridge",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes32",
        name: "ref",
        type: "bytes32",
      },
    ],
    name: "isReceived",
    outputs: [
      {
        internalType: "bool",
        name: "",
        type: "bool",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "bytes",
        name: "payload",
        type: "bytes",
      },
    ],
    name: "receive_",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "enum Chain",
        name: "chain",
        type: "uint8",
      },
      {
        internalType: "address",
        name: "target",
        type: "address",
      },
    ],
    name: "setRemoteBridge",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class IBridge__factory {
  static readonly abi = _abi;
  static createInterface(): IBridgeInterface {
    return new utils.Interface(_abi) as IBridgeInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IBridge {
    return new Contract(address, _abi, signerOrProvider) as IBridge;
  }
}
