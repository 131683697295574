interface ISectionTitleProps {
  title: string;
  size?: 'sm' | 'md' | 'xl' | '2xl' | '3xl' | '4xl';
}

function SectionTitle(props: ISectionTitleProps) {
  const { title, size = '2xl' } = props;
  const sizeMap = {
    'sm': 'text-sm',
    'md': 'text-md',
    'xl': 'text-xl',
    '2xl': 'text-2xl',
    '3xl': 'text-3xl',
    '4xl': 'text-4xl',
  };

  return (
    <div className={`${sizeMap[size]} font-medium text-[#2c2b4e]`}>{title}</div>
  );
}

export { SectionTitle };
