import { IOrder } from '@/api/omni/Order/order.interface';
import { OrderDetails } from './OrderDetails';
import { Skeleton } from '@mui/material';
import { useState } from 'react';

interface OrderListProps {
  orders: IOrder[];
  start?: number;
  end?: number;
  isLoading?: boolean;
  openModal: (e) => void;
}
function OrderList(props: OrderListProps) {
  const { orders, start = 0, end = 7, isLoading = false, openModal } = props;
  const [dropdown, setDropdown] = useState<string>('');

  const dropdownHandler = (orderId: string) => {
    setDropdown(dropdown === orderId ? '' : orderId);
  };
  return (
    <div className="flex flex-1 flex-col gap-5 rounded-xl lg:gap-0">
      <div className="hidden w-full gap-5 rounded-t-lg px-8 py-5 text-[#809FB8] md:flex">
        <span className="flex-[3]">ID</span>
        <span className="flex-[1]">Amount</span>
        <span className="flex-[2]">Status</span>
        <span className="flex-[1]">Created on</span>
        <span className=""></span>
      </div>
      {!isLoading && orders ? (
        orders
          .slice(start, end)
          .map((order) => (
            <OrderDetails
              order={order}
              key={order.id}
              openModal={openModal}
              option={order.id === dropdown}
              setOptionCallback={dropdownHandler}
            />
          ))
      ) : (
        <div className="w-full rounded-md bg-white odd:bg-[#FAF3FF]">
          <div className="flex h-full w-full flex-col items-center gap-5 px-6 py-10 lg:flex-row lg:py-5">
            <div className="h-2.5 w-full flex-[3] rounded-full">
              <Skeleton />
            </div>
            <div className="h-2.5 w-full flex-[1] rounded-full">
              <Skeleton />
            </div>
            <div className="h-2.5 w-full flex-[2] rounded-full">
              <Skeleton />
            </div>
            <div className="h-2.5 w-full flex-[1] rounded-full">
              <Skeleton />
            </div>
            <div className="flex h-2.5 w-auto rounded-full">
              <Skeleton />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default OrderList;
