import { Icon } from '@iconify/react';
import { MyDomainContext } from '@/utils/context/MyDomainContext';
import { Tooltip } from '@mui/material';
import { motion } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';

function SearchFilter() {
  const { setSearchDomain } = useContext(MyDomainContext);
  const [isOpenFilter, setIsOpenFilter] = useState<boolean>(false);

  useEffect(() => {
    if (!isOpenFilter) {
      setSearchDomain('');
    }
  }, [isOpenFilter, setSearchDomain]);
  return (
    <>
      {isOpenFilter && (
        <motion.input
          initial={{ width: 0 }}
          whileInView={{ width: 200 }}
          className="md:text-md relative w-full rounded-md border px-12 pl-4 text-sm outline-blue-400"
          placeholder="Search domain name"
          onBlur={() => setIsOpenFilter(false)}
          onChange={(e) => {
            setSearchDomain(e.target.value);
          }}
        />
      )}
      <Tooltip
        title={isOpenFilter ? 'Close' : 'Filter Domain'}
        placement={'top'}
        arrow
      >
        <Icon
          icon={isOpenFilter ? 'ri:filter-line' : 'ic:sharp-search'}
          className="h-8 w-auto cursor-pointer content-center rounded-full border bg-edns p-1 font-bold  text-white hover:bg-purple-400"
          onClick={() => setIsOpenFilter(!isOpenFilter)}
        />
      </Tooltip>
    </>
  );
}
export { SearchFilter };
