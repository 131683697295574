import * as luxon from 'luxon';
import { BaseProvider } from '@ethersproject/providers/src.ts/base-provider';
import { BigNumber, ethers } from 'ethers';
import {
  Bridge__factory,
  DomainRecordFacet__factory,
  IRegistry__factory,
  MigrationManager__factory,
  Mortgage__factory,
  PublicResolver,
  TokenMock__factory,
  Wrapper__factory,
} from '@/utils/contracts//typechain';
import { PublicResolver__factory as PublicResolverFactory } from '@/utils/contracts//typechain/factories/PublicResolver__factory';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import WalletConnectProvider from '@walletconnect/web3-provider';
import Web3Modal, { IProviderOptions } from 'web3modal';
import axios from 'axios';
import config from '@/config';
// import provider from '@/utils/contracts//provider';
import {
  CrossChainProvider,
  GasStationResponse,
  IGetNFT,
  IInputAccecptCrossChainRequest,
  IInputDepositPremiumDomain,
  IInputWithdrawPremiumDomain,
  IMigration,
  IOutRequestCrossChain,
  IReClaim,
  ISetNFT,
  ISetRecordProps,
  ISetReverse,
  ISignedMessage,
  ITransferDomainProps,
  IcheckReclaim,
  IgetReverse,
  NETWORK_PARAMS,
  SetRecordType,
  mappingInContractChain,
  migrationConfig,
} from '@/components/web3/Web3.interface';
import { EDNSRegistrarController__factory } from '@/utils/contracts//v1/EDNSRegistrarController__factory';
import { IGetContractsData, getContractsData } from '@/utils/getAllContract';

export class Web3Functions {
  CHAIN_ID: number;

  GAS_LIMIT: number | undefined;

  private contracts: IGetContractsData;

  private publicResolve: PublicResolver | undefined;

  private host_bytes: Uint8Array | undefined;

  private name_bytes: Uint8Array | undefined;

  private tld_bytes: Uint8Array | undefined;

  providerOptions: IProviderOptions;

  constructor(
    chainID: number,
    provider?: BaseProvider,
    host?: string,
    name?: string,
    tld?: string,
  ) {
    this.CHAIN_ID = chainID;
    this.GAS_LIMIT = chainID === 5 ? 400000 : undefined;

    this.providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          infuraId: config.web3.blockchain.infura.id,
          rpc: {
            [chainID]: config.web3.blockchain.node[chainID],
          },
        },
      },
      binancechainwallet: {
        package: true,
      },
      coinbasewallet: {
        package: CoinbaseWalletSDK,
        options: {
          appName: 'EDNS Domains',
          infuraId: config.web3.blockchain.infura.id,
        },
      },
    };
    const _contracts = getContractsData(chainID);
    if (!_contracts) {
      throw new Error(`Contract Not Found ChainId : ${chainID}`);
    }
    this.contracts = _contracts;
    if (provider) {
      if (this.contracts.addresses.PublicResolver !== null)
        this.publicResolve = PublicResolverFactory.connect(
          this.contracts.addresses.PublicResolver,
          provider,
        );
    }

    this.name_bytes = name ? ethers.utils.toUtf8Bytes(name) : undefined;
    this.host_bytes = host
      ? ethers.utils.toUtf8Bytes(host)
      : ethers.utils.toUtf8Bytes('@');
    this.tld_bytes = tld ? ethers.utils.toUtf8Bytes(tld) : undefined;
  }

  namehash = (name: string, tld: string): string => {
    const basenode = ethers.utils.namehash(tld);
    const labelhash = ethers.utils.solidityKeccak256(
      ['string', 'bytes32'],
      [name, basenode],
    );
    const nodehash = ethers.utils.solidityKeccak256(
      ['bytes32', 'bytes32'],
      [basenode, labelhash],
    );
    return nodehash;
  };

  async signMessage(): Promise<ISignedMessage> {
    const web3Modal = new Web3Modal({
      network: 'mainnet',
      providerOptions: this.providerOptions,
      disableInjectedProvider: false,
      cacheProvider: false,
    });
    web3Modal.clearCachedProvider();
    localStorage.removeItem('walletconnect');
    const _provider = await web3Modal.connect();
    const provider = new ethers.providers.Web3Provider(_provider);
    console.debug('Getting signer...');
    const signer = provider.getSigner();
    console.debug('Assembling message...');
    const message = `Welcome to EDNS Domains.\n You are about to link your address ${await signer.getAddress()} to our system.\n${luxon.DateTime.now().toISO()}`;
    console.debug('Signing message...');
    const signature = await signer.signMessage(message);
    console.debug('Getting address..');
    const address = await signer.getAddress();
    localStorage.removeItem('walletconnect');
    console.debug({ message, signature, address });
    return { message, signature, address };
  }

  async init(provider_?: ethers.providers.Web3Provider) {
    console.debug(`init...`);
    let _provider_: ethers.providers.Web3Provider | undefined = provider_;
    let _provider_save;
    if (!_provider_) {
      const web3Modal = new Web3Modal({
        providerOptions: this.providerOptions,
        disableInjectedProvider: false,
        cacheProvider: false,
      });
      console.debug(`Web3Modal...`);
      web3Modal.clearCachedProvider();
      localStorage.removeItem('walletconnect');
      console.debug(`Connecting to Web3 Modal...`);
      const _provider = await web3Modal.connect();

      _provider_save = _provider;
      console.debug(`Retrieving 'ethers' provider...`);
      _provider_ = new ethers.providers.Web3Provider(_provider);
    }
    console.debug(`Checking network...`);

    const network = await _provider_.getNetwork();
    console.log(network);
    let currentChainId = network.chainId;
    console.log(this.CHAIN_ID);
    if (currentChainId !== this.CHAIN_ID) {
      try {
        try {
          console.debug(
            `Incorrect network`,
            `Current ID ${currentChainId}`,
            `This ID ${this.CHAIN_ID}`,
          );
          await _provider_.send('wallet_switchEthereumChain', [
            { chainId: NETWORK_PARAMS[this.CHAIN_ID].chainId },
          ]);
        } catch (error: any) {
          console.log(NETWORK_PARAMS[this.CHAIN_ID]);
          if (error.code === 4902) {
            await _provider_.send('wallet_addEthereumChain', [
              NETWORK_PARAMS[this.CHAIN_ID],
            ]);
          } else {
            await this.init();
          }
        }
        //
        currentChainId = 5;
      } catch (error) {
        console.error(error);
      }
    }
    const provider_afterChangeNetwork = new ethers.providers.Web3Provider(
      _provider_save,
    );

    const _network = await provider_afterChangeNetwork.getNetwork();
    currentChainId = _network.chainId;
    console.debug(`Returning 'ethers' provider...`);
    if (this.CHAIN_ID === 5) {
      provider_afterChangeNetwork.getFeeData = async () => {
        const gasPrice = await _provider_!.getGasPrice();
        return {
          maxFeePerGas: ethers.utils.parseUnits('1.5', 'gwei'),
          maxPriorityFeePerGas: ethers.utils.parseUnits('1.5', 'gwei'),
          gasPrice,
        };
      };
    } else if (this.CHAIN_ID === 137) {
      provider_afterChangeNetwork.getFeeData = async () => {
        const gasPrice = await _provider_!.getGasPrice();
        const response = await axios.get<GasStationResponse>(
          'https://gasstation-mainnet.matic.network/v2',
        );
        return {
          maxFeePerGas: ethers.utils.parseUnits(
            `${Math.ceil(response.data.fast.maxFee)}`,
            'gwei',
          ),
          maxPriorityFeePerGas: ethers.utils.parseUnits(
            `${Math.ceil(response.data.fast.maxPriorityFee)}`,
            'gwei',
          ),
          gasPrice,
        };
      };
    }

    return provider_afterChangeNetwork;
  }

  async transferDomain(
    props: ITransferDomainProps,
  ): Promise<{ suc: boolean; error?: string }> {
    // ): Promise<ethers.providers.Web3Provider | undefined> {
    const provider = props.provider || (await this.init());
    if ((await provider.getNetwork()).chainId === this.CHAIN_ID) {
      console.debug('Getting signer...');
      let signer = provider.getSigner(props.ownerAddress);

      console.debug(`Connecting the the contract...`);
      if (this.contracts.addresses.DefaultWrapper === null) {
        throw new Error('Wrapper Not Found');
      }
      if (this.contracts.addresses['Registry.DomainRecordFacet'] === null) {
        throw new Error('Wrapper Not Found');
      }
      const defaultWrapper = Wrapper__factory.connect(
        this.contracts.addresses.DefaultWrapper,
        signer,
      );
      const domainRecord = DomainRecordFacet__factory.connect(
        this.contracts.addresses['Registry.DomainRecordFacet'],
        signer,
      );
      console.debug(`Getting the 'owner' address of the token...`);
      const owner = await defaultWrapper.callStatic.ownerOf(props.tokenId);
      const signerAddress = await signer.getAddress();
      if (signerAddress !== owner) console.log(signerAddress, owner);
      try {
        signer = provider.getSigner(owner);
        console.log(await signer.getAddress(), owner);
      } catch (e) {
        return {
          suc: false,
          error: `Wrong Signer : Owner address is ${owner}, current signer address is ${signerAddress}`,
        };
      }
      console.debug(`Start transfer...`);
      await defaultWrapper['safeTransferFrom(address,address,uint256)'](
        owner,
        props.newOwner,
        props.tokenId,
      );
      // await new Promise<void>((resolve, reject) => {
      //   try {
      //     domainRecord.on(
      //       domainRecord.filters.SetDomainOwner(
      //         this.name_bytes!,
      //         this.tld_bytes!,
      //         props.newOwner,
      //       ),
      //       () => resolve(),
      //     );
      //   } catch (error) {
      //     reject(error);
      //   }
      // });
      await new Promise((resolve) => setTimeout(resolve, 60000));
      return { suc: true };
    }
    return { suc: false, error: 'Wrong Chain Id' };
  }
  public updatehost(host: string | undefined) {
    this.host_bytes = host
      ? ethers.utils.toUtf8Bytes(host)
      : ethers.utils.toUtf8Bytes('@');
  }

  async setRecord(props: ISetRecordProps): Promise<string | null> {
    const _host = props.host
      ? ethers.utils.toUtf8Bytes(props.host)
      : this.host_bytes;
    try {
      const provider = await this.init();

      const signer = provider.getSigner(props._ownerAddress);
      if (!this.contracts.addresses.PublicResolver) {
        throw new Error('PublicResolver Not Found');
      }
      const signerResolverWithSigner = PublicResolverFactory.connect(
        this.contracts.addresses.PublicResolver,
        signer,
      );

      if (props.type === SetRecordType.TypedAddress && props.address) {
        await signerResolverWithSigner.setMultiCoinAddress(
          _host!,
          this.name_bytes!,
          this.tld_bytes!,
          props.address.coinType,
          props.address.address,
          { gasLimit: this.GAS_LIMIT },
        );
        // await new Promise<void>((resolve, reject) => {
        //   try {
        //     resolver.on(
        //       resolver.filters.setT(
        //         props.nodehash,
        //       ),
        //       () => resolve(),
        //     );
        //   } catch (error) {
        //     reject(error);
        //   }
        // });
      } else if (props.type === SetRecordType.TypedText && props.text) {
        await signerResolverWithSigner.setTypedText(
          _host!,
          this.name_bytes!,
          this.tld_bytes!,
          ethers.utils.toUtf8Bytes(props.text.type),
          props.text.text,
        );
        // await new Promise<void>((resolve, reject) => {
        //   try {
        //     resolver.on(
        //       resolver.filters.(
        //         this.host_bytes,
        //         this.name_bytes,
        //         this.tld_bytes,
        //         ethers.utils.toUtf8Bytes(props.text!.type),
        //         props.text!.text,
        //       ),
        //       () => resolve(),
        //     );
        //   } catch (error) {
        //     reject(error);
        //   }
        // });
      } else if (props.type === SetRecordType.Address && props.address) {
        await signerResolverWithSigner.setAddress(
          _host!,
          this.name_bytes!,
          this.tld_bytes!,
          props.address.address,
        );
      } else if (props.type === SetRecordType.Text && props.text) {
        await signerResolverWithSigner.setText(
          _host!,
          this.name_bytes!,
          this.tld_bytes!,
          props.text!.text,
        );
      }
    } catch (e) {
      const error: any = e;
      console.log(e);
      if (error.code === 4100) {
        return 'Wrong Signer. Please Switch to Correct Signer before set Record.';
      }
      return error.reason;
    }
    return null;
  }

  async checkReclaim(props: IcheckReclaim): Promise<boolean> {
    // const [name, tld] = props.domain.split('.');
    // const regisiryOwner = await EDNSRegistry()[this.CHAIN_ID].callStatic.owner(
    //   this.namehash(name, tld),
    // );
    // const registrarOwner = await BaseRegistrarImplementation()[
    //   this.CHAIN_ID
    // ].ownerOf(props.tokenID);
    // console.log({ regisiryOwner });
    // console.log({ registrarOwner });
    // if (regisiryOwner === registrarOwner) {
    //   return true;
    // }
    // return false;
    return true;
  }

  async reclaim(props: IReClaim) {
    // : Promise<ethers.Transaction> {
    // const provider = await this.init();
    // const signer = provider.getSigner(props._ownerAddress);
    // const [name, tld] = props.domain.split('.');
    // const Registrar = BaseRegistrarImplementation__factory.connect(
    //   config.contractAddress[this.CHAIN_ID].baseRegistrar,
    //   signer,
    // );
    // const RegistrarController = EDNSRegistrarController__factory.connect(
    //   config.contractAddress[this.CHAIN_ID].classicalRegistrarController,
    //   signer,
    // );
    // const tokenId = BigNumber.from(props.tokenID);
    // const basenode = await RegistrarController.tlds(
    //   ethers.utils.toUtf8Bytes(tld),
    // );
    // const transaction = await Registrar.reclaim(
    //   tokenId,
    //   basenode,
    //   props._ownerAddress,
    // );
    // return transaction;
  }

  async setReverse(props: ISetReverse): Promise<ethers.Transaction> {
    const provider = await this.init();
    console.log(props._ownerAddress);
    const signer = await provider.getSigner(props._ownerAddress);
    console.log(props._ownerAddress);
    // const Registrar = ReverseRegistrar__factory.connect(
    //   config.contractAddress[this.CHAIN_ID].publicResolver,
    //   signer,
    // );
    if (!this.contracts.addresses.PublicResolver) {
      throw new Error('PublicResolver Not Found');
    }
    const signerResolverWithSigner = PublicResolverFactory.connect(
      this.contracts.addresses.PublicResolver,
      signer,
    );
    const transaction = await signerResolverWithSigner.setReverseAddress(
      this.host_bytes!,
      this.name_bytes!,
      this.tld_bytes!,
      await signer.getAddress(),
    );
    // await new Promise((resolve) => setTimeout(resolve, 18000));
    return transaction;
  }

  async getReverse(props: IgetReverse): Promise<string> {
    if (!this.publicResolve) {
      throw new Error('Missing Provider during create Web3Function');
    }
    const domain = await this.publicResolve.callStatic.getReverseAddress(
      props.address,
    );
    return domain;
  }

  async setNFTRecordWeb3(props: ISetNFT): Promise<string | null> {
    try {
      const provider = await this.init();
      const signer = provider.getSigner(props._ownerAddress);
      if (!this.contracts.addresses.PublicResolver) {
        throw new Error('PublicResolver Not Found');
      }
      const signerResolverWithSigner = PublicResolverFactory.connect(
        this.contracts.addresses.PublicResolver,
        signer,
      );
      await signerResolverWithSigner.setNFT(
        this.host_bytes!,
        this.name_bytes!,
        this.tld_bytes!,
        props.chainID,
        props.contractAddress,
        props.tokenId,
      );
    } catch (e) {
      const error: any = e;
      console.log({ error });
      if (error.code === 4100) {
        return 'Wrong Signer. Please Switch to Correct Signer before set Record.';
      }
    }
    return null;
  }

  async getNFT(
    props: IGetNFT,
  ): Promise<[string, BigNumber] & { contract_: string; tokenId: BigNumber }> {
    if (!this.publicResolve) {
      throw new Error('Missing Provider during create Web3Function');
    }
    const _host = props.host
      ? ethers.utils.toUtf8Bytes(props.host)
      : this.host_bytes;
    const result = await this.publicResolve.callStatic.getNFT(
      _host!,
      this.name_bytes!,
      this.tld_bytes!,
      props.chainID,
    );
    return result;
  }

  async getBaseAddressRecord(host?: string): Promise<string> {
    if (!this.publicResolve) {
      throw new Error('Missing Provider during create Web3Function');
    }
    const _host = host ? ethers.utils.toUtf8Bytes(host) : this.host_bytes;
    try {
      const result = await this.publicResolve.callStatic.getAddress(
        _host!,
        this.name_bytes!,
        this.tld_bytes!,
      );
      return result;
    } catch (e) {
      if (
        (e as Error).message !==
        "Cannot read properties of undefined (reading 'toHexString')"
      ) {
        console.log(e);
      }
      return '0x';
    }
  }

  async getBaseTextRecord(host?: string): Promise<string> {
    if (!this.publicResolve) {
      throw new Error('Missing Provider during create Web3Function');
    }
    const _host = host ? ethers.utils.toUtf8Bytes(host) : this.host_bytes;
    try {
      const result = await this.publicResolve.callStatic.getText(
        _host!,
        this.name_bytes!,
        this.tld_bytes!,
      );
      return result;
    } catch (e) {
      return '';
    }
  }

  async getTypedAddressRecord(
    coinType: number,
    host?: string,
  ): Promise<string> {
    if (!this.publicResolve) {
      throw new Error('Missing Provider during create Web3Function');
    }
    const _host = host ? ethers.utils.toUtf8Bytes(host) : this.host_bytes;
    try {
      const result = await this.publicResolve.callStatic.getMultiCoinAddress(
        _host!,
        this.name_bytes!,
        this.tld_bytes!,
        coinType,
      );
      return result;
    } catch (e) {
      if (
        (e as Error).message !==
        "Cannot read properties of undefined (reading 'toHexString')"
      ) {
        console.log(e);
      }
      return '0x';
    }
  }

  async getTypedTextRecord(type: string, host?: string): Promise<string> {
    if (!this.publicResolve) {
      throw new Error('Missing Provider during create Web3Function');
    }
    const _host = host ? ethers.utils.toUtf8Bytes(host) : this.host_bytes;
    try {
      const result = await this.publicResolve.callStatic.getTypedText(
        _host!,
        this.name_bytes!,
        this.tld_bytes!,
        ethers.utils.toUtf8Bytes(type),
      );
      return result;
    } catch (e) {
      if (
        (e as Error).message !==
        "Cannot read properties of undefined (reading 'toHexString')"
      ) {
        console.log(e);
      }
      return '';
    }
  }

  async migration(input: IMigration) {
    try {
      const provider = await this.init();
      const signer = provider.getSigner(input.domain.owner.address);
      const config = migrationConfig[this.CHAIN_ID];
      const contracts = await getContractsData(this.CHAIN_ID);

      if (contracts?.addresses.MigrationManager) {
        const migrationManager = MigrationManager__factory.connect(
          contracts?.addresses.MigrationManager,
          signer,
        );

        const ednsRegistrarController =
          EDNSRegistrarController__factory.connect(
            config.EDNSController,
            signer,
          );
        const tokenId = await ednsRegistrarController.getTokenId(
          input.domain.name,
          input.domain.tld.name,
        );

        const action = await migrationManager.migrate(
          config.baseRegister,
          tokenId,
          input.domain.name,
          input.domain.tld.name,
        );
        // await new Promise<void>((resolve, reject) => {
        //   try {
        //     const filter = migrationManager.filters['Migrated']();
        //     migrationManager.on(filter, (owwner:string,name:string,tld:string,tokenid:BigNumber) => {
        //       if()
        //       console.log('Done!');
        //       resolve();
        //     });
        //   } catch (error) {
        //     reject(error);
        //   }
        // });
        //
        const tx = await action.wait();
        await new Promise((r) => setTimeout(r, 2000));
        // console.log(tx.transactionHash);
      } else {
        throw new Error(
          `MigrationManager Contract Not found , Chain Id ${this.CHAIN_ID}`,
        );
      }
    } catch (e) {
      const error: any = e;
      console.log({ error });

      if (error.code === 4100) {
        throw new Error(
          'Wrong Signer. Please Switch to Correct Signer before set Record.',
        );
      }

      throw new Error(error.message);
    }
    // return null;
  }

  async crossChainRequest(
    chainId: string,
    ownerAddress: string,
    crossProvider: CrossChainProvider,
  ): Promise<IOutRequestCrossChain> {
    // if (this.name_bytes && this.tld_bytes && this.contracts.addresses.Bridge) {
    if (!this.name_bytes || !this.tld_bytes) {
      throw new Error('Missing Name and Tld');
    }
    if (!this.contracts.addresses.Bridge) {
      throw new Error('Missing Bridge Contract');
    }
    if (!this.contracts.addresses['Registry.Diamond']) {
      throw new Error('Missing Bridge Contract');
    }
    const provider = await this.init();
    const signer = provider.getSigner(ownerAddress);
    const bridgeContract = Bridge__factory.connect(
      this.contracts.addresses.Bridge,
      signer,
    );
    const _registry = IRegistry__factory.connect(
      this.contracts.addresses['Registry.Diamond'],
      signer,
    );

    const dstChain = mappingInContractChain[+chainId];
    const srcChain = mappingInContractChain[this.CHAIN_ID];
    const _name = ethers.utils.keccak256(this.name_bytes);
    const _tld = ethers.utils.keccak256(this.tld_bytes);
    console.log({
      chain: dstChain,
      provider: CrossChainProvider.LAYERZERO,
      name: _name,
      tld: _tld,
    });
    const fee = await bridgeContract.estimateFee(
      dstChain,
      CrossChainProvider.LAYERZERO,
      _name,
      _tld,
    );
    console.log({ fee: ethers.utils.formatEther(fee) });
    if ((await signer.getBalance()).lt(fee))
      throw new Error('Signer Balance Not Enough.');

    const nonce = await bridgeContract.getNonce();
    const expiry = await _registry['getExpiry(bytes32,bytes32)'](_name, _tld);
    console.log(expiry);

    const ref = await bridgeContract.getRef(
      nonce,
      dstChain,
      crossProvider,
      _name,
      _tld,
      await _registry['getOwner(bytes32,bytes32)'](_name, _tld),
      expiry,
    );
    const tx2 = await bridgeContract.bridge(
      nonce,
      ref,
      dstChain,
      crossProvider,
      _name,
      _tld,
      {
        value: fee,
      },
    );

    await tx2.wait();
    console.log('Res from Bridge Request.', {
      nonce: nonce.toNumber(),
      ref,
      expiry,
      hash: tx2.hash,
      srcChain: dstChain,
      crossProvider,
      ownerAddress,
    });

    return {
      nonce: nonce.toNumber(),
      ref,
      expiry,
      hash: tx2.hash,
      srcChain: dstChain,
      crossProvider,
      ownerAddress,
    };
    // }
  }

  async crossChainAcceptingRequest(
    input: IInputAccecptCrossChainRequest,
  ): Promise<string> {
    if (!this.name_bytes || !this.tld_bytes) {
      throw new Error('Missing Name and Tld');
    }
    if (!this.contracts.addresses.Bridge) {
      throw new Error('Missing Bridge Contract');
    }

    const provider = await this.init();
    const signer = provider.getSigner(input.owner);
    const bridgeContract = Bridge__factory.connect(
      this.contracts.addresses.Bridge,
      signer,
    );
    console.log('Payload to', {
      nonce: input.nonce,
      ref: input.ref,
      srcChain: input.srcChain,
      provider: input.provider,
      name: input.name,
      tld: input.tld,
      owner: input.owner,
      expiry: input.expiry,
    });
    const tx3 = await bridgeContract.accept(
      input.nonce,
      input.ref,
      input.srcChain,
      input.provider,
      input.name,
      input.tld,
      input.owner,
      input.expiry,
    );

    await new Promise<void>((resolve, reject) => {
      try {
        const filter = bridgeContract.filters['Accepted'](
          null,
          null,
          input.ref,
        );
        bridgeContract.on(filter, () => {
          console.log('Done!');
          resolve();
        });
      } catch (error) {
        reject(error);
      }
    });
    return tx3.hash;
  }
  async depositPremiumDomain(input: IInputDepositPremiumDomain) {
    const [name, tld] = input.fqdn.split('.');
    const _name_ = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(name));
    const _tld_ = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(tld));
    if (!this.contracts.addresses.Mortgage) {
      throw new Error('Missing Bridge Contract');
    }
    if (!this.contracts.addresses.Token) {
      throw new Error('Missing Token Contract');
    }
    const provider = await this.init();
    const signer = provider.getSigner(input.owner);
    const tokenContract = TokenMock__factory.connect(
      this.contracts.addresses.Token,
      signer,
    );

    const mortgageContract = Mortgage__factory.connect(
      this.contracts.addresses.Mortgage,
      signer,
    );
    const depositAmount = ethers.utils.parseUnits(input.amount, 18);
    const allow = await tokenContract.allowance(
      input.owner,
      this.contracts.addresses.Mortgage,
    );
    console.log({
      allow: ethers.utils.formatEther(allow),
      deposit: ethers.utils.formatEther(depositAmount),
    });
    if (allow.lt(depositAmount)) {
      const tx = await tokenContract.approve(
        this.contracts.addresses.Mortgage,
        ethers.constants.MaxUint256,
      );
      await tx.wait();
    }
    const tx = await mortgageContract.deposit(
      _name_,
      _tld_,
      input.owner,
      input.owner,
      depositAmount,
    );
    await tx.wait();
  }

  getTokenContractAddress() {
    if (!this.contracts.addresses.Token) {
      throw new Error('Missing Bridge Contract');
    }
    return this.contracts.addresses.Token;
  }

  async addTokenToWallet() {
    try {
      const provider = await this.init();
      const tokenSymbol = 'EDNS';
      const tokenDecimals = 18;
      const address = this.getTokenContractAddress();

      // await provider.send('wallet_watchAsset', [
      //   {
      //     method: 'wallet_watchAsset',
      //     params: {
      //       type: 'ERC20',
      //       options: {
      //         address: address,
      //         symbol: tokenSymbol,
      //         decimals: tokenDecimals,
      //         image:
      //           'https://assets.coingecko.com/coins/images/30880/large/ednslogo.jpg?1688459892',
      //       },
      //     },
      //   },
      // ]);
      if (window.ethereum) {
        await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: address,
              symbol: tokenSymbol,
              decimals: tokenDecimals,
              image:
                'https://assets.coingecko.com/coins/images/30880/large/ednslogo.jpg?1688459892',
            },
          },
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  async withDrawPremiumDomain(input: IInputWithdrawPremiumDomain) {
    const [name, tld] = input.fqdn.split('.');
    const _name_ = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(name));
    const _tld_ = ethers.utils.keccak256(ethers.utils.toUtf8Bytes(tld));
    console.log({
      name,
      tld,
    });
    if (!this.contracts.addresses.Mortgage) {
      throw new Error('Missing Bridge Contract');
    }

    const provider = await this.init();
    const signer = provider.getSigner(input.owner);
    const mortgageContract = Mortgage__factory.connect(
      this.contracts.addresses.Mortgage,
      signer,
    );
    console.log(this.contracts.addresses.Mortgage);
    const gasPrice = await signer.getFeeData();

    console.log({
      amount_hex: input.amount,
      amount: ethers.utils.parseUnits(input.amount),
    });
    const gas = await mortgageContract.estimateGas.withdraw(
      _name_,
      _tld_,
      input.owner,
      ethers.utils.parseUnits(input.amount),
    );

    await mortgageContract.withdraw(
      _name_,
      _tld_,
      input.owner,
      ethers.utils.parseUnits(input.amount),
      {
        gasPrice: gasPrice.gasPrice!,
        gasLimit: gas,
      },
    );
  }
  getContractAddress(contractName: string): string {
    return this.contracts.addresses[contractName] || '';
  }
}
