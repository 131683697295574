import { AxiosInstance } from 'axios';
import { IOrder } from './order.interface';
import { errorHandler } from '../../errorHandler';
import config from '@/config';

export const createOrder =
  (instance: AxiosInstance) => async (): Promise<IOrder> => {
    try {
      const order = await instance.post<IOrder>(
        config.api.router.order.createOrder,
        {},
      );
      return order.data;
    } catch (e) {
      throw errorHandler(e);
    }
  };
