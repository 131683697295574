import { AxiosInstance } from 'axios';
import { IUser } from './user.interface';
import { errorHandler } from '../../errorHandler';
import config from '@/config';

export const getUser =
  (instance: AxiosInstance) => async (): Promise<IUser> => {
    try {
      const user = await instance.get<IUser>(config.api.router.user.get);
      return user.data;
    } catch (e) {
      throw errorHandler(e);
    }
  };
