// import { BlockchainEco, CryptoWalletType } from './cryptoWallet.interface';
// import config from '@/../config';
// import axios from 'axios';
// import { errorHandler } from '../errorHandler';

// export enum AssociatedCryptoWalletType {
//   FROM_USER = 'from_user',
//   FROM_EVENT = 'from_event',
//   REMOVED = 'removed',
// }

// interface ICreateAssociatedWallet {
//   eco: BlockchainEco;
//   wallet: {
//     associated: {
//       address: string;
//       type: AssociatedCryptoWalletType;
//       signature: string;
//       message: string;
//     };
//   };
//   accessToken: string;
// }

// interface ICreateImportedWallet {
//   eco: BlockchainEco;
//   wallet: {
//     imported: {
//       name: string;
//       address: string;
//       publicKey: string;
//       privateKey: string;
//     };
//   };
//   accessToken: string;
// }
// interface ICreateManagedWallet {
//   eco: BlockchainEco;
//   accessToken: string;
// }
// export async function createAssociatedWallet(input: ICreateAssociatedWallet) {
//   try {
//     const url = new URL('/omni/wallet', config.api.endpoint);
//     await axios.post(url.href, { type: CryptoWalletType.ASSOCIATED, ...input }, { headers: { Authorization: `Bearer ${input.accessToken}` } });
//     await new Promise((r) => setTimeout(r, 2000));
//   } catch (e) {
//     throw errorHandler(e);
//   }
// }
// export async function createImportedWallet(input: ICreateImportedWallet) {
//   try {
//     const url = new URL('/omni/wallet', config.api.endpoint);
//     await axios.post(url.href, { type: CryptoWalletType.IMPORTED, ...input }, { headers: { Authorization: `Bearer ${input.accessToken}` } });
//     await new Promise((r) => setTimeout(r, 2000));
//   } catch (e) {
//     throw errorHandler(e);
//   }
// }
// export async function createManagedWallet(input: ICreateManagedWallet) {
//   try {
//     const url = new URL('/omni/wallet', config.api.endpoint);
//     await axios.post(url.href, { type: CryptoWalletType.MANAGED, ...input }, { headers: { Authorization: `Bearer ${input.accessToken}` } });
//     await new Promise((r) => setTimeout(r, 2000));
//   } catch (e) {
//     throw errorHandler(e);
//   }
// }

import { AxiosInstance } from 'axios';
import { BlockchainEco, CryptoWalletType } from './cryptoWallet.interface';
import { errorHandler } from '../../errorHandler';
import config from '@/config';

export enum AssociatedCryptoWalletType {
  FROM_USER = 'from_user',
  FROM_EVENT = 'from_event',
  REMOVED = 'removed',
}

interface ICreateAssociatedWallet {
  eco: BlockchainEco;
  wallet: {
    associated: {
      address: string;
      type: AssociatedCryptoWalletType;
      signature: string;
      message: string;
    };
  };
  accessToken?: string;
}

interface ICreateImportedWallet {
  eco: BlockchainEco;
  wallet: {
    imported: {
      name: string;
      address: string;
      publicKey: string;
      privateKey: string;
    };
  };
  accessToken?: string;
}
interface ICreateManagedWallet {
  eco: BlockchainEco;
}
export const createCryptoWallet =
  (instance: AxiosInstance) =>
  (type: CryptoWalletType) =>
  async (
    input:
      | ICreateAssociatedWallet
      | ICreateManagedWallet
      | ICreateImportedWallet,
  ) => {
    try {
      await instance.post(config.api.router.cryptoWallet.create, {
        type,
        ...input,
      });
      await new Promise((r) => setTimeout(r, 2000));
    } catch (e) {
      throw errorHandler(e);
    }
  };

export const createAssociatedWallet =
  (instance: AxiosInstance) => async (input: ICreateAssociatedWallet) => {
    try {
      await instance.post(config.api.router.cryptoWallet.create, {
        type: CryptoWalletType.ASSOCIATED,
        ...input,
      });
      await new Promise((r) => setTimeout(r, 2000));
    } catch (e) {
      throw errorHandler(e);
    }
  };

export const createImportedWallet =
  (instance: AxiosInstance) => async (input: ICreateImportedWallet) => {
    try {
      await instance.post(config.api.router.cryptoWallet.create, {
        type: CryptoWalletType.IMPORTED,
        ...input,
      });
      await new Promise((r) => setTimeout(r, 2000));
    } catch (e) {
      throw errorHandler(e);
    }
  };
export const createManagedWallet =
  (instance: AxiosInstance) => async (input: ICreateManagedWallet) => {
    try {
      await instance.post(config.api.router.cryptoWallet.create, {
        type: CryptoWalletType.MANAGED,
        ...input,
      });
      await new Promise((r) => setTimeout(r, 2000));
    } catch (e) {
      throw errorHandler(e);
    }
  };
