import {
  CrossChainContext,
  MyDomainContext,
  SwappingState,
} from '@/utils/context';
import { Icon } from '@iconify/react';
import { StepTitle } from '@/components/CrossChain/StepTitle';
import { useContext, useEffect } from 'react';

function Step0() {
  const { selectedDomain: domain } = useContext(MyDomainContext);
  const { swappingState } = useContext(CrossChainContext);

  useEffect(() => {
    console.log('step 0 ', swappingState);
  }, [swappingState]);

  if (!domain || swappingState !== SwappingState.CHECKING) {
    return <></>;
  }

  return (
    <>
      <StepTitle step={0} title="Checking Cross Chain History" description="" />
      <div className="w-full border"></div>
      <div>
        <div className="flex flex-col ">
          <div className="grid h-full w-full place-items-center">
            <Icon
              icon="eos-icons:loading"
              className="h-24 w-auto self-center text-gray-300"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export { Step0 };
