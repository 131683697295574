import { CartContext } from '@/utils/context/CartContext';
import { GlobalStateContext } from '@/utils/context';
import { Skeleton } from '@mui/material';
import { useContext, useEffect } from 'react';
import CacheCartProduct from '@/components/cart/cacheCart/CacheCartProduct';

// interface CartItemsProps {
//   cacheDomain: ICacheDomain[];
// }

function CacheCartItems() {
  const { isLoadingCart: isLoading } = useContext(CartContext);
  const { cacheDomain } = useContext(GlobalStateContext);

  useEffect(() => {
    console.log(cacheDomain);
  }, [cacheDomain]);
  return (
    <div className="relative flex flex-col">
      {!isLoading ? (
        <>
          {cacheDomain.map((_cacheDomain, i) => (
            <CacheCartProduct
              key={`${_cacheDomain.domain.domain}.${_cacheDomain.domain.tld.name}`}
              domain={_cacheDomain}
              index={i}
              length={cacheDomain.length - 1}
            />
          ))}
        </>
      ) : (
        <div className="flex flex-col bg-white before:gap-5">
          <div className="relative flex justify-between gap-4 py-2">
            <div className="flex-[4]">
              <Skeleton height={20} />
            </div>
            <div className="flex-[8]"></div>
            <div className="flex-[1]">
              <Skeleton height={20} />
            </div>
          </div>
          <div className="relative flex justify-between gap-4 py-2">
            <div className="flex-[4]">
              <Skeleton height={30} />
            </div>
            <div className="flex-[8]"></div>
            <div className="flex-[1]">
              <Skeleton height={30} />
            </div>
          </div>
          <div className="relative flex justify-between gap-4 py-2">
            <div className="flex-[2]">
              <Skeleton height={45} />
            </div>
            <div className="flex-[9]">
              <Skeleton height={45} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default CacheCartItems;
