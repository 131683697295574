import { GlobalStateContext } from '@/utils/context/GlobalStateContext';
import { MyDomainContext } from '@/utils/context/MyDomainContext';
import { removeCryptoWallet } from '@/api/omni/CryptoWallet/removeCryptoWallet';
import { revealManagedWalletPrivateKey } from '@/api/omni/CryptoWallet/revealManagedWalletPrivateKey';
import { setDefaultWallet } from '@/api/omni/CryptoWallet/setDefaultWallet';
import { useAxios } from '@/utils/axios/AxiosProvider';
import { useContext } from 'react';
import EthereumAddressView from '@/components/Account/EthereumAddressView';

function WalletEthereumAddressView({ address }: { address: any }) {
  const {
    setSelectedWallet,
    setIsRevealOptions,
    setPrivateKey,
    selectedWallet,
  } = useContext(MyDomainContext);
  const { instance } = useAxios();
  const { updateCryptoWallet, updateUser, user } =
    useContext(GlobalStateContext);
  const handleSelectWallet = async (e) => {
    setSelectedWallet(e);
  };

  const handleReveal = async (walletId: string) => {
    const response = await revealManagedWalletPrivateKey(instance)({
      walletId,
    });
    setPrivateKey(response);
    setIsRevealOptions((prev) => !prev);
  };

  const handleDeleteEthereumAddressClick = async (walletId: string) => {
    await removeCryptoWallet(instance)(walletId);
    await updateCryptoWallet();
  };

  const handleSetDefault = async (address: string) => {
    await setDefaultWallet(instance)(address);
    await updateUser();
  };
  const isDefaultWallet = (address: string): boolean => {
    if (user) {
      if (user.default_address !== null && user.default_address) {
        return user.default_address === address;
      }
    }
    return false;
  };
  return (
    <EthereumAddressView
      key={address.address}
      index={1}
      ethereumAddress={address}
      isDefaultEthereumAddress={isDefaultWallet(address.address)}
      handleClick={() => {
        handleSelectWallet(address);
      }}
      selected={selectedWallet?.address}
      type={address.type}
      handleDeleteEthereumAddressClick={handleDeleteEthereumAddressClick}
      handleSetDefaultWallet={handleSetDefault}
      setReveal={false}
      handleReveal={handleReveal}
      hoverChangeBg
    />
  );
}

export { WalletEthereumAddressView };
