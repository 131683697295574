import { DateTime } from 'luxon';
import { DomainMenu } from './DomainMenu';
import { IBlockchain } from '@/api/omni/Blockchain/blockchain.interface';
import { IDomain } from '@/api/omni/Domain/domain.interface';
import { Link } from 'react-router-dom';
import { MyDomainContext } from '@/utils/context';
import { getChainIcon } from '../web3/getChainIcon';
import { motion } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import config from '@/config';

interface IManageProps {
  domain: IDomain;
  default?: boolean;
  reserveDomain?: boolean;
  callback?;
  renewal?: (domain: IDomain) => Promise<void>;
}

interface IDomainInfoProps {
  name: string;
  tld: string;
  chainId: number;
}
function DomainInfo({ chainId, name, tld }: IDomainInfoProps) {
  return (
    <div className="flex flex-1 flex-col items-center justify-between gap-2">
      <div className="grid h-12 place-items-center rounded-full bg-white">
        {getChainIcon(chainId)}
      </div>
      <div className="flex items-center text-lg">
        <span className="font-normal">{name}</span>
        <span className="font-semibold">.{tld}</span>
      </div>
    </div>
  );
}
interface IDomainLabelProps {
  className?: string;
  text: string;
  isPremium?: boolean;
}
function DomainLabel({
  className = '',
  text,
  isPremium = false,
}: IDomainLabelProps) {
  if (isPremium) {
    return (
      <label
        className={`rounded-xl bg-gradient-to-r from-[#01455e] to-[#000435] px-4 py-1 text-white`}
      >
        {/*<svg*/}
        {/*  xmlns="http://www.w3.org/2000/svg"*/}
        {/*  width="24"*/}
        {/*  height="24"*/}
        {/*  viewBox="0 0 24 24"*/}
        {/*>*/}
        {/*  <path*/}
        {/*    fill="gold"*/}
        {/*    d="M2.005 19h20v2h-20zm0-14l5 3l5-6l5 6l5-3v12h-20z"*/}
        {/*  />*/}
        {/*</svg>*/}
        {text}
      </label>
    );
  }

  return (
    <label className={`rounded-xl px-4 py-1 text-white ${className}`}>
      {' '}
      {text}
    </label>
  );
}

function Manage(props: IManageProps) {
  const { default: defaultStyle, domain, reserveDomain } = props;
  const { name, tld: tldObj, owner } = domain;
  const { displayView } = useContext(MyDomainContext);
  const tld = tldObj.name;

  const [blockChain, setBlockChain] = useState<IBlockchain>();

  const isExpired =
    DateTime.now().toMillis() >
    DateTime.fromISO(domain.expiry?.toString() || '1970-01-01').toMillis();

  const getChainId = (): number => {
    if (blockChain === undefined) {
      if (config.env === 'development') {
        return 5;
      }
      return 137;
    }
    return +blockChain.chainId;
  };

  useEffect(() => {
    const blockchain = domain.blockchains[0];
    setBlockChain(blockchain);
  }, []);

  if (displayView === 'grid') {
    return (
      <motion.div
        initial={{ y: 20, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        viewport={{ once: true }}
        // style={domain.status === 'migration' ? { filter: 'grayscale(1)' } : {}}
        className={`${
          reserveDomain && 'bg-card'
        } box-shadow gradient-box-1  gradient-box-rounded-2xl relative z-0 flex flex-col justify-between space-y-2 p-4 
        ${defaultStyle ? 'bg-blue-700 text-white' : 'bg-white'}`}
      >
        <div className="flex justify-between">
          <div className="relative space-x-2">
            {isExpired && (
              <DomainLabel className=" bg-red-600" text="Expired" />
            )}
            <DomainLabel
              className={
                domain.status === 'migration' ? 'bg-[#93278f]' : 'bg-card'
              }
              text={domain.status === 'migration' ? 'V1' : 'V2'}
            />
            <DomainLabel
              className={
                domain.tld.type.toLowerCase() === 'universal'
                  ? 'bg-gradient-to-r from-[#29eecb] to-[#167c4c]'
                  : 'bg-gradient-to-r from-[#d2a56d] to-[#83502e]'
              }
              text={
                domain.type === 'premium'
                  ? 'Premium'.toUpperCase()
                  : domain.tld.type.toUpperCase()
              }
              isPremium={domain.type === 'premium'}
            />
          </div>

          {owner && !defaultStyle && (
            <DomainMenu reserveDomain={reserveDomain} domain={domain} />
          )}
        </div>
        {domain.status === 'migration' ? (
          <div style={{ filter: 'grayscale(1)' }}>
            <DomainInfo chainId={getChainId()} name={name} tld={tld} />
          </div>
        ) : (
          <Link to={`/domain/${name}.${tld}`} className="z-20">
            <div>
              <DomainInfo chainId={getChainId()} name={name} tld={tld} />
            </div>
          </Link>
        )}
      </motion.div>
    );
  } else {
    return (
      <>
        <div className="grid h-10 w-full grid-cols-12 items-center justify-between py-2">
          <div className=" col-span-4 text-lg">
            <Link to={`/domain/${name}.${tld}`} className="z-20">
              <span className="font-normal">{name}</span>
              <span className="font-semibold">.{tld}</span>
            </Link>
          </div>
          <div className="col-span-4 space-x-2">
            {isExpired && (
              <DomainLabel className=" bg-red-600" text="Expired" />
            )}
            <DomainLabel
              className={
                domain.status === 'migration' ? 'bg-[#93278f]' : 'bg-card'
              }
              text={domain.status === 'migration' ? 'V1' : 'V2'}
            />
            <DomainLabel
              className={'bg-[#93278f]'}
              text={domain.tld.type.substring(0, 3)}
            />
          </div>
          <div className="col-span-3">
            <div className="">{getChainIcon(getChainId(), 'name')}</div>
          </div>
          <div className="col-span-1">
            <DomainMenu reserveDomain={reserveDomain} domain={domain} />
          </div>
        </div>
      </>
    );
  }
}

export default Manage;
