import { IGetOrders } from '@/utils/interface';
import axios from 'axios';
import config from '@/config';

export async function getOrders(): Promise<IGetOrders> {
  const url = new URL(`/orders`, `${config.api.endpoint}`);
  const response = await axios.get<IGetOrders>(url.href, {
    // headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response.data;
}
