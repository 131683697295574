import { CartContext } from '@/utils/context/CartContext';
import { Icon } from '@iconify/react';
import { LinearProgress } from '@mui/material';
import { useContext, useState } from 'react';

function PromoCodeBox() {
  const {
    refreshCart,
    promoCodes,
    handleApplyPromoCode,
    handleDeletePromoCode,
  } = useContext(CartContext);

  const [promoCode, setPromoCode] = useState<string>('');
  const [isInvalidPromotionalCode, setIsInvalidPromotionalCode] =
    useState<boolean>(false);
  const [haveReseller, setHaveReseller] = useState(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>('');

  const handlePromoCodeApply = async () => {
    try {
      setErrMsg('');
      setIsLoading(true);
      setHaveReseller(true);
      await handleApplyPromoCode(promoCode);

      setPromoCode('');
      setIsLoading(false);
    } catch (err: any) {
      setIsInvalidPromotionalCode(true);
      setHaveReseller(false);
      console.log(err);
      setErrMsg(err.message);
    } finally {
      await refreshCart();
      setIsLoading(false);
    }
  };

  const handlePromoCodeDelete = async (code: string) => {
    try {
      setIsLoading(true);
      await handleDeletePromoCode(code);
    } catch (err) {
      console.log(err);
    } finally {
      await refreshCart();
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="relative mt-2">
        <div className="absolute left-3 top-4">
          <i className="fa fa-search z-20 text-gray-400 hover:text-gray-500"></i>
        </div>
        <input
          type="text"
          className="z-0 h-14 w-full appearance-none rounded-xl border pl-4 pr-24 shadow focus:shadow focus:outline-none"
          placeholder="Promotional Code"
          // disabled={!!shoppingCart.promotionalCode}
          value={promoCode}
          onChange={(e) => {
            setPromoCode(e.target.value);
            setIsInvalidPromotionalCode(false);
          }}
          disabled={isLoading}
        />
        <div className="absolute right-2 top-2">
          <button
            type="button"
            className={`mb-2 rounded-md bg-gradient-to-br from-purple-600 to-blue-500 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-gradient-to-bl focus:outline-none ${'opacity-100'}`}
            onClick={() => handlePromoCodeApply()}
            disabled={isLoading}
          >
            Apply
          </button>
        </div>
      </div>
      {isLoading && <LinearProgress />}
      {isInvalidPromotionalCode && (
        <div
          className="border-b border-t border-red-500 bg-red-100 px-4 py-3 text-red-700"
          role="alert"
        >
          <p className="font-bold">Invalid Code</p>
          <p className="text-sm">{errMsg}</p>
        </div>
      )}
      <div className="flex flex-wrap gap-2">
        {promoCodes &&
          promoCodes.map((code) => (
            <div className="inline-flex" key={code.code}>
              <button
                className="rounded-l-lg border border-gray-200 bg-white px-4 py-2 text-sm font-bold text-blue-700 hover:bg-gray-100 focus:z-10 focus:text-blue-700 focus:ring-2 focus:ring-blue-700"
                disabled={isLoading}
              >
                {code.code}
              </button>
              <button
                disabled={isLoading}
                className="rounded-r-md border border-l-0 border-gray-200 bg-white px-2 py-1 text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:text-blue-700 focus:ring-2 focus:ring-blue-700"
                onClick={() => {
                  console.log('removeCode');
                  handlePromoCodeDelete(code.code);
                }}
              >
                <Icon icon="emojione:cross-mark" className="h-4 w-auto" />
              </button>
            </div>
          ))}
      </div>
    </>
  );
}

export default PromoCodeBox;
