import { ICart } from '@/api/omni/Cart/cart.interface';
import { IOrder } from '@/api/omni/Order/order.interface';
import { ReactNode } from 'react';
import { Skeleton } from '@mui/material';
import CheckoutButton from '@/components/checkout/CheckoutButton';
import PromoCodeBox from '@/components/promoCode/PromoCodeBox';

interface CartSummaryProps {
  onCheckout: () => Promise<IOrder | undefined>;
  disabled?: boolean;
  isLoading?: boolean;
  cart?: ICart | undefined;
}
interface SummaryProps {
  title: string;
  isLoading?: boolean;
  children?: ReactNode;
  fontSize?: string;
}
function SummaryColumn(props: SummaryProps) {
  const { title, isLoading = false, children, fontSize = '' } = props;
  if (isLoading) {
    return (
      <div
        className={`mt-2 flex justify-between space-x-2 font-semibold ${fontSize}`}
      >
        <div className="h-4 w-full rounded-full">
          <Skeleton height={20} />
        </div>
        <div className="h-4 w-full rounded-full">
          <Skeleton height={20} />
        </div>
      </div>
    );
  }
  return (
    <div
      className={`mt-2 flex justify-between space-x-2 font-semibold ${fontSize}`}
    >
      <span>{title}</span>
      <span>{children}</span>
    </div>
  );
}
function CartSummary(props: CartSummaryProps) {
  const { onCheckout, disabled = false, cart, isLoading = false } = props;
  return (
    <>
      {cart ? (
        <div className="box-shadow sticky mb-20 flex h-auto w-full max-w-[420px] flex-[3] flex-col gap-3 self-start rounded-2xl bg-white px-6 py-8 md:min-w-[300px]">
          <span className="flex w-full border-b border-dashed pb-4 text-xl font-medium">
            Order Summary
          </span>
          <SummaryColumn
            title="Subtotal"
            isLoading={isLoading}
            fontSize="text-base"
          >
            USD {cart.subtotal}
          </SummaryColumn>
          {cart.promoCodes.length > 0 && (
            <div className="text-md flex-[1] flex-col">
              <SummaryColumn title="Discounts" isLoading={isLoading}>
                USD {cart.subtotal - cart.total}
              </SummaryColumn>
            </div>
          )}
          <SummaryColumn title="Total" isLoading={isLoading} fontSize="text-xl">
            <span className="text-[#f05dbe]">USD {cart.total}</span>
          </SummaryColumn>
          {cart.products.length > 0 && <PromoCodeBox />}

          <CheckoutButton onCheckout={onCheckout} disabled={disabled} />
        </div>
      ) : (
        <div className="box-shadow sticky flex h-auto w-full max-w-[420px] flex-[3] flex-col gap-2 self-start  rounded-2xl bg-white px-6 py-8">
          <span className="flex w-full border-b border-dashed pb-2 text-2xl font-medium">
            Order Summary
          </span>
          <SummaryColumn
            title="Subtotal"
            isLoading
            fontSize="text-l"
          ></SummaryColumn>
          <div className="mt-4 w-full border"></div>

          <SummaryColumn
            title="Total"
            isLoading
            fontSize="text-xl"
          ></SummaryColumn>
          <div className="my-2 h-3 w-full rounded-full pb-3">
            <Skeleton height={30} />
          </div>

          <CheckoutButton onCheckout={onCheckout} disabled={disabled} />
        </div>
      )}
    </>
  );
}

export default CartSummary;
