import '@/App.scss';
import { AuthContext } from '@/utils/auth/AuthProvider';
import {
  Badge,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { CartContext, GlobalStateContext } from '@/utils/context';
import { Icon } from '@iconify/react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { domainNameCount } from '@/components/DomainFIlter/domainNameCount';
import { motion } from 'framer-motion';
import { useAuth } from '@/utils/auth/hook';
import { useContext, useEffect, useRef, useState } from 'react';
import CacheCartItems from '@/components/cart/cacheCart/CacheCartItems';
import CartItems from '@/components/cart/CartItems';
import MessageNotification from '@/components/SMS/MessageNotification';
import _ from 'lodash';

interface IProps {
  reload?: boolean;
  setreload?(item: boolean): void;
  setCartCount?(item: boolean): void;
  cartCount?: boolean;
  home?: boolean;
  title?: string;
  setIsAddedDomainToShoppingCart?: any;
}

export type OptionType = {
  value: any;
  label: any;
};

function NavBody(props: IProps) {
  const { title, home } = props;
  const { isAuthenticated } = useAuth();
  const {
    tlds,
    user,
    setIsShowNav,
    isShowCart,
    setIsShowCart,
    setIsShowCartWithoutAuth,
    isShowCartWithoutAuth,
    cacheDomain,
  } = useContext(GlobalStateContext);
  const { shoppingCart, productsCount: shoppingCartDomainsCount } =
    useContext(CartContext);

  const { login } = useContext(AuthContext);

  const [searchDomain, setSearchDomain] = useState<string>();
  const users = useRef<HTMLDivElement>(null);
  const option = useRef<HTMLAnchorElement>(null);
  const [noti, setNoti] = useState<boolean>(false);
  const cart_out = useRef<HTMLDivElement>(null);
  const [error, setError] = useState(false);
  const regex = new RegExp(
    /^[a-z0-9\-_\u00a9\u00ae\u2000-\u3300\ud83c\ud000-\udfff\ud83d\ud000-\udfff\ud83e\ud000-\udfff]{5,128}$/,
  );
  const [selectedTldOption, setSelectedTldOption] = useState<string>('');

  useEffect(() => {
    if (tlds && tlds.length > 0) {
      setSelectedTldOption(tlds[0].name);
    }
  }, [tlds]);

  useEffect(() => {
    console.log(cacheDomain);
  }, [cacheDomain]);
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div className="left-0 top-0 z-[400] w-full items-center justify-center px-6 py-6 pb-6 duration-200 md:px-6">
      <motion.div
        initial={{ visibility: 'hidden' }}
        animate={
          isShowCart
            ? { visibility: 'visible', opacity: 1 }
            : {
                visibility: 'hidden',
                opacity: 0,
                transition: { visibility: { delay: 0.5 } },
              }
        }
        className="fixed left-0 top-0 z-[400] h-full w-full bg-[#231C50]/40"
        onClick={() => {
          setIsShowNav(false);
          setIsShowCart(false);
          setIsShowCartWithoutAuth(false);
        }}
      ></motion.div>

      {/*Cart With Auth*/}
      <motion.div
        initial={{ x: '100%' }}
        animate={isShowCart ? { x: '0%' } : { x: '100%' }}
        transition={{ duration: 0.35, ease: 'easeInOut' }}
        className="shadow-l-2xl fixed right-0 top-0 z-[400] flex h-full w-full flex-col gap-5 overflow-scroll overflow-x-scroll border-l bg-white p-8 text-black md:pr-2 lg:top-0 lg:w-[475px]"
      >
        <div className="flex h-[30px] items-center justify-between pt-16 text-2xl font-medium md:pt-0 lg:pt-0">
          Cart
          <Icon
            className="cursor-pointer"
            onClick={() => {
              setIsShowCart(false);
            }}
            icon="gridicons:cross"
          />
        </div>
        {shoppingCart ? (
          shoppingCart.products.length !== 0 ? (
            <>
              <div
                ref={cart_out}
                className="relative flex flex-1 flex-col overflow-y-scroll"
              >
                <CartItems user={user} />
              </div>

              <div className="flex shrink-0 flex-col gap-2">
                <div className="my-2 flex items-end justify-end gap-6 text-2xl">
                  <span className="text-xl">Subtotal</span>
                  <span className="text-2xl font-semibold text-[#f05dbe]">
                    ${shoppingCart.total}
                  </span>
                </div>
                <Link
                  onClick={() => {
                    setIsShowNav(false);
                  }}
                  to={`/cart${location.search}`}
                  // ref={button}
                  data-on
                  className="gradient-box mt-1 flex w-full items-center justify-center gap-2 rounded-lg py-5 text-xl font-semibold text-white opacity-100"
                >
                  <motion.span animate={{ display: 'block' }}>
                    Checkout
                  </motion.span>
                </Link>
              </div>
            </>
          ) : (
            <div className="flex flex-1 flex-col items-center justify-center">
              <span className="text-xl">Your shopping cart is empty.</span>
            </div>
          )
        ) : (
          <div className="flex flex-1 flex-col items-center justify-center">
            <Icon
              icon="eos-icons:loading"
              className="h-16 w-auto text-gray-300"
            />
          </div>
        )}
      </motion.div>
      {/*Cart With Auth*/}

      {/*Cart Without Auth*/}
      <motion.div
        initial={{ x: '100%' }}
        animate={isShowCartWithoutAuth ? { x: '0%' } : { x: '100%' }}
        transition={{ duration: 0.35, ease: 'easeInOut' }}
        className="shadow-l-2xl fixed right-0 top-10 z-[400] flex h-screen w-full flex-col gap-5 overflow-x-visible border-l bg-white p-8 text-black lg:top-0 lg:w-[475px]"
      >
        <div className="flex h-[30px] items-center justify-between pt-16 text-2xl font-medium md:pt-0 lg:pt-0">
          Cart Without Auth
          <Icon
            className="cursor-pointer"
            onClick={() => {
              setIsShowCartWithoutAuth(false);
            }}
            icon="gridicons:cross"
          />
        </div>

        {cacheDomain ? (
          cacheDomain.length !== 0 ? (
            <>
              <div
                ref={cart_out}
                className="relative flex flex-1 flex-col overflow-y-scroll"
              >
                <CacheCartItems />
                <div className="flex shrink-0 flex-col gap-2">
                  <button
                    onClick={async () => {
                      await login();
                    }}
                    // ref={button}
                    data-on
                    className="gradient-box mt-1 flex w-full items-center justify-center gap-2 rounded-lg py-5 text-xl font-semibold text-white opacity-100"
                  >
                    <motion.span animate={{ display: 'block' }}>
                      Checkout
                    </motion.span>
                  </button>
                </div>
              </div>

              <div className="flex shrink-0 flex-col gap-2">
                <div className="my-2 flex items-end justify-end gap-6 text-2xl">
                  {/*<span className="text-xl">Subtotal</span>*/}
                  {/*<span className="text-2xl font-semibold text-[#f05dbe]">*/}
                  {/*  ${shoppingCart.total}*/}
                  {/*</span>*/}
                </div>
                {/*<Link*/}
                {/*  onClick={() => {*/}
                {/*    setIsShowNav(false);*/}
                {/*  }}*/}
                {/*  to={`/cart${location.search}`}*/}
                {/*  // ref={button}*/}
                {/*  data-on*/}
                {/*  className="gradient-box mt-1 flex w-full items-center justify-center gap-2 rounded-lg py-5 text-xl font-semibold text-white opacity-100"*/}
                {/*>*/}
                {/*  <motion.span animate={{ display: 'block' }}>*/}
                {/*    Checkout*/}
                {/*  </motion.span>*/}
                {/*</Link>*/}
              </div>
            </>
          ) : (
            <div className="flex flex-1 flex-col items-center justify-center">
              <span className="text-xl">Your shopping cart is empty.</span>
            </div>
          )
        ) : (
          <div className="flex flex-1 flex-col items-center justify-center">
            <Icon
              icon="eos-icons:loading"
              className="h-16 w-auto text-gray-300"
            />
          </div>
        )}
      </motion.div>
      {/*Cart Without Auth*/}

      <nav className="relative flex w-full items-center justify-between">
        <div className="flex text-2xl font-medium">{title}</div>
        <div
          ref={users}
          className="opacity-1 z-50 hidden h-full w-full flex-row items-center justify-center gap-5 text-xl md:relative md:flex md:h-auto md:w-auto md:justify-between md:text-sm"
        >
          {home ? null : (
            <>
              <div className="relative h-full w-full bg-white">
                <TextField
                  value={searchDomain}
                  placeholder="Search Your Domain Name"
                  color="primary"
                  InputProps={{
                    style: {
                      height: '40px',
                    },
                    endAdornment: (
                      <InputAdornment
                        position="start"
                        className="cursor-pointer"
                        onClick={() => {
                          if (regex.test(searchDomain || '')) {
                            setError(false);
                            navigate(
                              `/search/${searchDomain}.${selectedTldOption}${location.search}`,
                            );
                          } else {
                            setError(true);
                          }
                        }}
                      >
                        <Icon
                          icon={'material-symbols:search'}
                          className="h-4 w-4"
                        />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    setError(!regex.test(e.currentTarget.value));
                    setError(!(domainNameCount(e.currentTarget.value) >= 5));
                    setSearchDomain(_.toLower(e.currentTarget.value));
                  }}
                  fullWidth
                />
                <div
                  className={`search-warn box-shadow absolute mt-3 w-full rounded-lg border bg-white p-10 py-8 text-xs text-red-500 ${
                    error && 'visible'
                  }`}
                >
                  <Icon
                    className="mx-auto mb-5 h-auto w-12"
                    icon="ep:warning-filled"
                  />
                  <div className="ml-4 list-item">
                    Sorry, only letters (a-z), numbers (0-9), underscore (_),
                    and hyphen (-) are allowed.
                  </div>
                  <div className="mb-2 ml-4 mt-3 list-item">
                    Your domain must be between 5-128 characters long.
                  </div>
                  {/* <li>Domain must be between 5 and 128 characters. </li> */}
                </div>
              </div>
              <div className="flex w-[250px] items-center rounded-lg border bg-white text-base outline-none">
                <Select
                  // options={tldOptions}
                  value={selectedTldOption}
                  // styles={customStyles}
                  sx={{ height: 40 }}
                  onChange={(e) => setSelectedTldOption(e.target.value)}
                  placeholder="Select TLD"
                  className="h-full w-full text-center"
                  MenuProps={{
                    style: {
                      height: '400px',
                      overflowY: 'scroll',
                      boxShadow: 'none',
                    },
                  }}
                >
                  {tlds.map(({ name: tld }) => (
                    <MenuItem key={tld} value={tld}>
                      {tld}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </>
          )}

          <div className="relative z-50 flex flex-col items-center md:block">
            {isAuthenticated && (
              <>
                {/* <img className="mb-10 block w-28 rounded-full md:hidden" src={user?.picture} alt="gam"></img> */}
                <div className="flex items-center">
                  <div className="relative" onClick={() => setNoti(true)}>
                    <div
                      className="msg-box absolute right-[-20px] top-[45px] h-[500px] w-[200px] items-end"
                      data-show={noti}
                    >
                      <div className="noti-box box-shadow border">
                        <div className="noti-title w-full px-6 text-left text-base">
                          Lates Notifications
                        </div>
                        <div className="flex h-full w-full flex-1 flex-col justify-between gap-3 px-4 py-2">
                          <MessageNotification />
                          <MessageNotification />
                          <MessageNotification />
                          <MessageNotification />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Link
                    to="/setting"
                    className="btn-top-option gradient-box gradient-box-hover gradient-box-rounded-full grid aspect-square h-12 place-items-center !rounded-full [&>*]:hover:text-white"
                  >
                    <Icon
                      icon={'ant-design:setting-filled'}
                      className="h-full w-full p-3 text-slate-400 "
                    />
                  </Link>
                </div>
                <div className="user-option absolute mt-[-1px] flex w-full flex-col">
                  <Link
                    to="/mydomain"
                    ref={option}
                    className="option option-in cursor-pointer whitespace-nowrap bg-transparent bg-white px-3 py-3 duration-300 ease-in-out hover:text-sky-600 lg:py-5"
                  >
                    My Domain
                  </Link>
                  <Link
                    to="/account"
                    ref={option}
                    className="option option-in cursor-pointer whitespace-nowrap bg-transparent bg-white px-3 py-3 duration-300 ease-in-out hover:text-sky-600 lg:py-5"
                  >
                    My Account
                  </Link>
                  <Link
                    to=""
                    ref={option}
                    onClick={() => {
                      // logout({ returnTo: window.location.origin });
                      // TODO
                    }}
                    className="option option-in cursor-pointer whitespace-nowrap bg-transparent bg-white px-3 py-3 duration-300 ease-in-out hover:text-sky-600 lg:py-5"
                  >
                    Logout
                  </Link>
                </div>
              </>
            )}
          </div>
          {isAuthenticated && (
            <>
              <div
                onClick={() => setIsShowCart(true)}
                className="btn-shoppingCart relative  cursor-pointer text-2xl duration-200 ease-in-out hover:text-blue-600 "
              >
                <div className="btn-top-option gradient-box gradient-box-hover gradient-box-rounded-full grid aspect-square h-12 place-items-center !rounded-full [&>*>*]:hover:text-white">
                  <Badge
                    badgeContent={shoppingCartDomainsCount}
                    color="primary"
                  >
                    <Icon
                      icon="ion:cart"
                      className="h-6 w-6 text-slate-400 hover:text-white"
                    />
                  </Badge>
                </div>

                <div className="absolute right-[2px] top-[6px] grid aspect-square h-5 place-items-center rounded-full text-xs"></div>
              </div>
              <Link
                to="/account"
                className="btn-user flex flex-row items-center gap-1 font-medium hover:text-sky-600"
              >
                {user?.username}
              </Link>
            </>
          )}
          {!isAuthenticated && (
            <>
              <div
                onClick={() => setIsShowCartWithoutAuth(true)}
                className="btn-shoppingCart relative cursor-pointer text-2xl duration-200 ease-in-out hover:text-blue-600 "
              >
                <div className="btn-top-option gradient-box gradient-box-hover gradient-box-rounded-full grid aspect-square h-12 place-items-center !rounded-full [&>*>*]:hover:text-white">
                  <Badge badgeContent={cacheDomain.length} color="primary">
                    <Icon
                      icon="ion:cart"
                      className="h-6 w-6 text-slate-400 hover:text-white"
                    />
                  </Badge>
                </div>

                <div className="absolute right-[2px] top-[6px] grid aspect-square h-5 place-items-center rounded-full text-xs"></div>
              </div>
            </>
          )}
        </div>
      </nav>
    </div>
  );
}

function Nav(props: IProps) {
  return <NavBody {...props} />;
}

export default Nav;
