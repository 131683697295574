import {
  CrossChainProvider,
  IOutRequestCrossChain,
} from '@/components/web3/Web3.interface';
import {
  Dispatch,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { GlobalStateContext } from '@/utils/context/GlobalStateContext';
import { IBlockchain } from '@/api/omni/Blockchain/blockchain.interface';
import { ITld } from '@/api/omni/Tld/tld.interface';
import { MyDomainContext } from '@/utils/context/MyDomainContext';
import { getTldById } from '@/api/omni/Tld/listTld';
import { useAxios } from '../axios/AxiosProvider';

export enum SwappingState {
  CHECKING,
  Init,
  Blockchain,
  Receive,
  Listener,
  Done,
}
export enum SnackState {
  None,
  Success,
  Error,
}
export interface ICrossChainContext {
  isOpenSnackbar: boolean;
  setIsOpenSnackbar: Dispatch<SetStateAction<boolean>>;
  selectBlockChain: IBlockchain | undefined;
  setSelectBlockChain: Dispatch<SetStateAction<IBlockchain | undefined>>;
  swappingState: SwappingState;
  setSwappingState: Dispatch<SetStateAction<SwappingState>>;
  snackState: SnackState | undefined;
  setSnackState: Dispatch<SetStateAction<SnackState | undefined>>;
  snackMessage: string;
  setSnackMessage: Dispatch<SetStateAction<string>>;
  tld: ITld | undefined;
  setTld: Dispatch<SetStateAction<ITld | undefined>>;
  selectedBridge: CrossChainProvider | undefined;
  setSelectedBridge: Dispatch<SetStateAction<CrossChainProvider | undefined>>;
  step2Payload: IOutRequestCrossChain | undefined;
  setStep2Payload: Dispatch<SetStateAction<IOutRequestCrossChain | undefined>>;
  step4tx: string | undefined;
  setStep4tex: Dispatch<SetStateAction<string | undefined>>;
}
const CrossChainContext = createContext<ICrossChainContext>({
  step2Payload: undefined,
  setStep2Payload: () => {},
  isOpenSnackbar: false,
  setIsOpenSnackbar: () => {},
  selectBlockChain: undefined,
  setSelectBlockChain: () => {},
  swappingState: SwappingState.Init,
  setSwappingState: () => {},
  snackState: SnackState.None,
  setSnackState: () => {},
  snackMessage: '',
  setSnackMessage: () => {},
  tld: undefined,
  setTld: () => {},
  selectedBridge: undefined,
  setSelectedBridge: () => {},
  step4tx: undefined,
  setStep4tex: () => {},
});

function CrossChainContextProvider({ children }) {
  const { selectedDomain } = useContext(MyDomainContext);
  const { instance } = useAxios();
  const { blockChains } = useContext(GlobalStateContext);
  const [isOpenSnackbar, setIsOpenSnackbar] = useState<boolean>(false);
  const [selectBlockChain, setSelectBlockChain] = useState<IBlockchain>();
  const [swappingState, setSwappingState] = useState<SwappingState>(
    SwappingState.Init,
  );
  const [step4tx, setStep4tex] = useState<string | undefined>();
  const [step2Payload, setStep2Payload] = useState<
    IOutRequestCrossChain | undefined
  >();

  const [snackState, setSnackState] = useState<SnackState>();
  const [snackMessage, setSnackMessage] = useState<string>('');
  const [tld, setTld] = useState<ITld>();
  const [selectedBridge, setSelectedBridge] = useState<CrossChainProvider>();

  const context = useMemo<ICrossChainContext>(
    () => ({
      step2Payload,
      setStep2Payload,
      isOpenSnackbar,
      setIsOpenSnackbar,
      selectBlockChain,
      setSelectBlockChain,
      swappingState,
      setSwappingState,
      snackState,
      setSnackState,
      step4tx,
      setStep4tex,
      snackMessage,
      setSnackMessage,
      tld,
      setTld,
      selectedBridge,
      setSelectedBridge,
    }),
    [
      step2Payload,
      step4tx,
      isOpenSnackbar,
      selectBlockChain,
      swappingState,
      snackState,
      snackMessage,
      tld,
      selectedBridge,
    ],
  );

  const checkLocalStorage = () => {
    if (selectedDomain) {
      const prevChain = localStorage.getItem(`${selectedDomain.fqdn}:chain`);
      const prevData = localStorage.getItem(`${selectedDomain.fqdn}:payload`);

      console.log(prevChain);
      if (prevChain !== null) {
        const blockchain = blockChains.find((x) => x.chainId === prevChain);
        setSelectBlockChain(blockchain);
        if (blockchain && prevData !== null) {
          const payload: IOutRequestCrossChain = JSON.parse(prevData);
          setStep2Payload(payload);
          setIsOpenSnackbar(true);
          setSwappingState(SwappingState.Listener);
          setSnackState(SnackState.Success);
          setSnackMessage('Bridge selected');
        }
      } else {
        setSwappingState(SwappingState.Init);
        setSelectBlockChain(undefined);
        setSelectedBridge(CrossChainProvider.LAYERZERO);
      }
    }
  };

  useEffect(() => {
    const fetchTld = async () => {
      if (selectedDomain) {
        // console.log('fetchTld');
        setSwappingState(SwappingState.CHECKING);
        const res = await getTldById(instance)(selectedDomain.tld.name);
        setTld(res);
        checkLocalStorage();
      }
    };
    fetchTld();
  }, [selectedDomain]);

  return (
    <CrossChainContext.Provider value={context}>
      {children}
    </CrossChainContext.Provider>
  );
}

export { CrossChainContext, CrossChainContextProvider };
