import { GlobalStateContext } from '@/utils/context/GlobalStateContext';
import { Icon } from '@iconify/react';
import { bindReseller } from '@/api/bind-reseller';
import { getReseller } from '@/api/get-reseller';
import { useContext, useEffect, useRef, useState } from 'react';

interface IBind {
  display: boolean;
  setDisplay: (display: boolean) => void;
  code: string;
}

const BindPage = (props: IBind) => {
  const { display, setDisplay, code } = props;
  const { user, updateUser } = useContext(GlobalStateContext);
  const [isDone, setIsDone] = useState(false);
  const [resellerId, setResellerId] = useState<string | null>(null);
  const [boundStatus, setBoundStatus] = useState(false);
  const [isBinding, setIsBinging] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const requestBind = async () => {
    const exec = async () => {
      if (
        // user?.reseller === null &&
        inputRef.current
      ) {
        try {
          setIsBinging(true);
          console.log(inputRef.current.value);
          const reseller = await getReseller(inputRef.current.value);

          setResellerId(reseller.id);
          await bindReseller(reseller.id);
          setBoundStatus(true);
          setIsDone(true);
          await updateUser();
        } catch (e) {
          setIsDone(true);
          console.error(e);
        }
      }
    };
    exec();
  };

  const reset = () => {
    setIsDone(false);
    setBoundStatus(false);
    setIsBinging(false);
    if (inputRef.current) {
      inputRef.current.value = '';
    }
  };

  useEffect(() => {
    if (!display) {
      reset();
    }
  }, [display]);

  useEffect(() => {
    if (code !== '') {
      if (inputRef.current) {
        inputRef.current.value = code;
        requestBind();
      }
    }
  }, [user]);

  //   useEffect(() => {
  //     const exec = async () => {
  //       if (!accessToken) {
  //         return;
  //       }
  //       if (!params.id) {
  //         return;
  //       }

  //       if (user?.reseller === null) {
  //         try {
  //           const reseller = await getReseller(accessToken, params.id);
  //           setResellerId(reseller.id);
  //           await bindReseller(accessToken, reseller.id);
  //           setBoundStatus(true);
  //           setLoading(false);
  //         } catch (e) {
  //           setLoading(false);
  //         }
  //       }
  //     };
  //     exec();
  //   }, [accessToken]);

  return (
    <>
      <div
        onClick={() => setDisplay(false)}
        data-display={display}
        className="bind-bg fixed left-0 top-0 z-[999] h-full w-full bg-black/40"
      ></div>
      <div
        data-display={display}
        className="bind-main-con fixed bottom-0 left-0 z-[999] h-fit w-full"
      >
        <div className="absolute bottom-0 left-0 w-full rounded-t-2xl bg-white p-10 md:rounded-none">
          <div className="mx-auto w-full max-w-[350px]">
            <div className="mb-4 flex items-end justify-between text-2xl">
              <p>Reseller Binding</p>
              {isDone && (
                <p
                  onClick={reset}
                  className="text-[rgba(147, 39, 143, 1)] cursor-pointer text-base hover:underline"
                >
                  back
                </p>
              )}
            </div>
            <div
              data-binding={isBinding}
              data-done={isDone}
              className="bind-con relative"
            >
              <div data-binding={isBinding} className="binding-con relative">
                <input
                  ref={inputRef}
                  type="text"
                  className="w-full rounded-md border px-3 py-2 text-[18px] outline-none"
                  placeholder="Enter Reseller Code"
                />
                <Icon
                  icon="ant-design:login-outlined"
                  onClick={requestBind}
                  className="icon-align-center absolute right-2 h-[20px] w-auto cursor-pointer text-gray-500"
                />
              </div>
              <div
                data-binding={isBinding}
                data-loading={isDone}
                className="loading absolute left-0 top-0 grid h-full w-full place-items-center"
              >
                <Icon
                  icon="eos-icons:bubble-loading"
                  className="text-[rgba(147, 39, 143, 1)] h-[50px] w-auto"
                />
              </div>
              <div
                data-done={isDone}
                className="binding-msg-con absolute left-0 top-0 flex h-full w-full gap-10"
              >
                {isDone === true && (
                  <>
                    {
                      <div>Reseller Not Implement</div>
                      //   user?.reseller === null && (
                      //   <>
                      //     {resellerId === null ? (
                      //       /*Can't Find Reseller In DB*/
                      //       <div className="text-lg">Invalid Reseller Link</div>
                      //     ) : (
                      //       <>
                      //         {boundStatus ? (
                      //           /*Bound Action Success*/
                      //           <div className="text-lg">Success to Bound Reseller by using Code {params.id}</div>
                      //         ) : (
                      //           /*Bound Action Fail*/
                      //           <div className="text-lg">Bound Fail</div>
                      //         )}
                      //       </>
                      //     )}
                      //   </>
                      // )
                    }
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BindPage;
