import { AxiosInstance } from 'axios';
import { ICart } from './cart.interface';
import { errorHandler } from '../../errorHandler';
import config from '@/config';

interface IUpdateCartAmount {
  id: number;
  type: 'domain' | 'product' | 'premiumdomain';
  value?: number;
  owner?: string;
}
export const updateCartAmount =
  (instance: AxiosInstance) =>
  async (props: IUpdateCartAmount): Promise<ICart> => {
    const { id, type, value, owner } = props;
    try {
      const _type = type === 'premiumdomain' ? 'domain' : type;
      const response = await instance.patch<ICart>(
        `${config.api.router.cart.updateCartAmount}${_type}/${id}`,
        {
          value,
          owner,
        },
      );
      return response.data;
    } catch (e) {
      throw errorHandler(e);
    }
  };
