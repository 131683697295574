import { useEffect, useRef } from 'react';
// import { withAuthenticationRequired } from '@auth0/auth0-react';
import { IOrder } from '@/api/omni/Order/order.interface';
import { getChainIcon } from '../web3/getChainIcon';
import _ from 'lodash';

interface OrderModalProps {
  order: IOrder | undefined;
  isOpen: boolean;
  closeModal: () => void;
}

function OrderModal(props: OrderModalProps) {
  const { order, isOpen, closeModal } = props;
  const conRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (conRef.current && !conRef.current.contains(e.target)) {
        closeModal();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeModal, conRef]);

  return (
    <>
      {isOpen && (
        <div className="fixed left-0 top-0 z-[9999] grid h-full w-full place-items-center overflow-hidden bg-black/10">
          <div
            ref={conRef}
            className="scrollbar-hidden flex h-full w-full flex-col overflow-hidden rounded-none shadow-2xl lg:h-auto lg:w-[28rem] lg:rounded-2xl"
          >
            <div className="theme flex items-center justify-between bg-white px-8 py-7 text-xl font-semibold text-[#2c2b4e] shadow-2xl lg:rounded-t-lg">
              <span>Order information</span>
            </div>
            <div className="max-h-auto z-50 flex h-full w-full flex-col gap-5 overflow-y-scroll bg-white px-8 py-9 pt-0 lg:max-h-[500px] lg:rounded-b-lg">
              {order && (
                <>
                  <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                    <span className="flex-[2] text-lg font-medium">
                      Order ID
                    </span>
                    <span className="lg:text-md text-xs">{order.id}</span>
                  </div>
                  <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                    <span className="flex-[2] font-medium">Status</span>
                    <span>{_.startCase(_.toLower(order.status))}</span>
                  </div>
                  <div className="border-b border-dashed"></div>
                  {order.products.map((product) => {
                    if (
                      product.type === 'domain' ||
                      product.type === 'premiumdomain'
                    ) {
                      return (
                        <div
                          key={product.domain?.fqdn}
                          className="flex flex-col gap-5"
                        >
                          {product.domain && (
                            <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                              <div className="flex-[2] font-medium">Domain</div>
                              <div className="flex">
                                {product.domain.fqdn}
                                <span className="flex h-6 w-6 items-center rounded-full p-1.5">
                                  {getChainIcon(
                                    +product.domain?.blockchain.chainId,
                                  )}
                                </span>
                              </div>
                            </div>
                          )}
                          {product.domain && (
                            <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                              <div className="flex-[2] font-medium">Status</div>
                              <div className="flex">
                                {product.domain.status.toUpperCase()}
                              </div>
                            </div>
                          )}
                          <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                            <span className="flex-[2] font-medium">
                              Owner's Address
                            </span>
                            <span className="lg:text-md text-xs">
                              {product.domain?.owner.address}
                            </span>
                          </div>
                          <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                            <span className="flex-[2] font-medium">Price</span>
                            <span> {product.total} USD</span>
                          </div>
                          <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                            <span className="flex-[2] font-medium">Type</span>
                            <span>
                              {_.startCase(_.toLower(product.domain?.method))}
                            </span>
                          </div>
                          <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                            <span className="flex-[2] font-medium">
                              Duration
                            </span>
                            <span>{product.domain?.duration} year(s)</span>
                          </div>
                          <div className="border-b border-dashed"></div>
                        </div>
                      );
                    }
                    return (
                      <div
                        key={product.domain?.fqdn}
                        className="flex flex-col gap-5"
                      >
                        <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                          <span className="flex-[2] font-medium">Product</span>
                          <span>{product.product.name}</span>
                        </div>
                        <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                          <span className="flex-[2] font-medium">
                            Description
                          </span>
                          <span>{product.product.description}</span>
                        </div>
                        <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5">
                          <span className="flex-[2] font-medium">Amount</span>
                          <span>{product.total}</span>
                        </div>
                        <div className="border-b"></div>
                      </div>
                    );
                  })}
                  <div className="flex items-center gap-5">
                    <span className="flex-[2] text-lg font-semibold">
                      Amount
                    </span>
                    <span className="text-lg font-semibold text-[#F05DBE]">
                      {order.total} USD
                    </span>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default OrderModal;
