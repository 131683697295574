import { Button } from '../common/Button';
import {
  Divider,
  FormControl,
  InputAdornment,
  List,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import {
  FC,
  Fragment,
  JSX,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { GlobalStateContext, useMyDomain } from '@/utils/context';
import { IResolverGdn, ISubDomain } from '@/api/resolver/interface';
import { Icon } from '@iconify/react';
import { ProfileSetRecord } from './ProfileSetRecord';
import { ProfileSubDomainItem } from './ProfileSubDomainItem';
import { SectionLayout } from '../common/SectionLayout';
import { Web3Functions } from '../web3/Web3';

enum SortBy {
  // CreateDate = 'createDate',
  Name = 'name',
}
enum SortOrder {
  Ascending = 'asc',
  Descending = 'desc',
}

interface IOrderingIcon {
  order: SortOrder;
}
const OrderingIcon = (props: IOrderingIcon) => {
  const { order } = props;
  switch (order) {
    case SortOrder.Ascending:
      return <Icon icon="mdi:sort-ascending" className="h-full w-full p-1" />;
    case SortOrder.Descending:
      return <Icon icon="mdi:sort-descending" className="h-full w-full p-1" />;
    default:
      return <></>;
  }
};

export interface ISubDomainProps {
  subdomain: string;
  fqdn: string;
}
interface IProfileTabSubdomainsProps {
  useWeb3: Web3Functions;
  subDomains?: IResolverGdn<ISubDomain>;
  emit?: (component: JSX.Element) => void;
}

const ProfileTabSubdomains: FC<IProfileTabSubdomainsProps> = ({
  subDomains,
  useWeb3,
  emit,
}) => {
  const [isOpenAddRecord, setIsOpenAddRecord] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<SortBy>(SortBy.Name);
  const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.Ascending);

  const { user } = useContext(GlobalStateContext);
  const { domain } = useMyDomain();
  if (domain === undefined) throw new Error('Domain Not Found');

  const subDomainsData: ISubDomainProps[] = useMemo<ISubDomainProps[]>(() => {
    if (!subDomains) {
      return [];
    }
    const result: ISubDomainProps[] = [];

    subDomains.data?.hosts.forEach((host) => {
      result.push({
        subdomain: host,
        fqdn: domain.fqdn,
      });
    });
    return result;
  }, [subDomains, domain]);

  const sortedData = useMemo<ISubDomainProps[]>(() => {
    return subDomainsData.sort((a, b) => {
      switch (sortOrder) {
        case SortOrder.Descending:
          return b.subdomain.localeCompare(a.subdomain);

        default:
          return a.subdomain.localeCompare(b.subdomain);
      }
    });
  }, [sortOrder, subDomainsData]);

  const isOwner: boolean = useMemo<boolean>(() => {
    if (user) {
      const isOwner = user.wallets.find(
        (wallet) => wallet?.address === domain.owner.address,
      );
      // setIsOwner(!!isOwner);
      return !!isOwner;
    }
    return false;
  }, [user, domain]);

  // Open add record modal
  const handleOpenAddRecord = () => {
    setIsOpenAddRecord(true);
  };

  useEffect(() => {
    if (emit && isOwner) {
      emit(
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenAddRecord}
        >
          Add Records
        </Button>,
      );
    }
  }, [isOwner]);

  return (
    <Fragment>
      <SectionLayout className="bg-white">
        <div className="space-y-4">
          <div className="flex gap-x-2">
            <FormControl sx={{ minWidth: 150 }}>
              <Select
                value={sortBy}
                onChange={(e) => setSortBy(e.target.value as SortBy)}
                sx={{ height: '40px' }}
              >
                {Object.keys(SortBy).map((key) => (
                  <MenuItem key={key} value={SortBy[key]}>
                    {key}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <div className="flex gap-x-2">
              {Object.keys(SortOrder).map((key) => (
                <div
                  key={key}
                  aria-label={SortOrder[key]}
                  onClick={() => setSortOrder(SortOrder[key])}
                  className={`h-10 w-10 cursor-pointer rounded-lg border text-gray-400 hover:bg-gray-200 ${
                    sortOrder === SortOrder[key]
                      ? 'bg-edns text-white hover:bg-edns-hover'
                      : ''
                  }`}
                >
                  <OrderingIcon order={SortOrder[key]} />
                </div>
              ))}
            </div>
            <TextField
              variant="outlined"
              placeholder="Search"
              size="small"
              sx={{ height: '40px', width: '100%' }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Icon
                      icon={'material-symbols:search'}
                      className="h-4 w-4"
                    />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <Divider />
        </div>
        <List>
          {sortedData.map((data, i) => (
            <Fragment key={data.subdomain}>
              <ProfileSubDomainItem subDomain={data} />
              {i + 1 !== sortedData.length && (
                <Divider variant="inset" component="li" />
              )}
            </Fragment>
          ))}
        </List>
        {false && (
          <div className="flex justify-center py-16 text-2xl font-semibold">
            No subnames have been added
          </div>
        )}
        <Divider />
      </SectionLayout>
      <ProfileSetRecord
        isOpenAddRecord={isOpenAddRecord}
        setIsOpenAddRecord={setIsOpenAddRecord}
        useWeb3={useWeb3}
      />
    </Fragment>
  );
};

export { ProfileTabSubdomains };
