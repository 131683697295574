import {
  CrossChainContext,
  SnackState,
  SwappingState,
} from '@/utils/context/CrossChainContext';
import { Icon } from '@iconify/react';
import { MyDomainContext } from '@/utils/context/MyDomainContext';
import { SectionTitle } from '../common/SectionTitle';
import { StepTitle } from './StepTitle';
import { Web3Functions } from '@/components/web3/Web3';
import { ethers } from 'ethers';
import { useContext, useState } from 'react';
import delay from 'delay';

function Step4() {
  const { selectedDomain: domain } = useContext(MyDomainContext);
  const [loading, setLoading] = useState(false);
  const {
    selectBlockChain,
    swappingState,
    step2Payload,
    setSwappingState,
    setSnackState,
    setIsOpenSnackbar,
    setSnackMessage,
    setStep4tex,
  } = useContext(CrossChainContext);
  const textColor = 'text-[#93278f]';

  if (!domain || !selectBlockChain || swappingState !== SwappingState.Receive) {
    return <></>;
  }

  async function handleAcceptDomain() {
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      const useWeb3 = new Web3Functions(
        +selectBlockChain!.chainId!,
        undefined,
        '@',
        domain!.name,
        domain!.tld.name,
      );
      if (!step2Payload) {
        throw new Error('step2Payload Not Found.');
      }
      const tx = await useWeb3.crossChainAcceptingRequest({
        nonce: step2Payload.nonce,
        ref: step2Payload.ref,
        srcChain: step2Payload.srcChain,
        provider: step2Payload.crossProvider,
        expiry: step2Payload.expiry,
        owner: step2Payload.ownerAddress,
        name: ethers.utils.hexlify(ethers.utils.toUtf8Bytes(domain!.name)),
        tld: ethers.utils.toUtf8Bytes(domain!.tld.name),
      });

      localStorage.removeItem(`${domain!.fqdn}:chain`);
      localStorage.removeItem(`${domain!.fqdn}:payload`);
      await delay(1000);

      setStep4tex(tx);
      setSwappingState(SwappingState.Done);
      setSnackState(SnackState.Success);
    } catch (e) {
      setSnackState(SnackState.Error);
      setSnackMessage((e as Error).message);
      setIsOpenSnackbar(true);
      setLoading(false);
      return;
    }
  }
  return (
    <>
      <StepTitle
        step={4}
        title="Receive Domain"
        description="To receive the domain, switch to the target chain."
      />
      <div className="w-full border"></div>
      <div className="flex flex-col space-y-4">
        <SectionTitle title="Result" size="xl" />
      </div>
      <div>
        <div className="flex flex-col ">
          Domain sent to the target chain, please sign the action for receive
          the domain.
        </div>
      </div>

      <div className="mt-5 text-right">
        <button
          className="mt-2 w-full rounded-xl border-2 border-[#93278f] px-8 py-4 font-medium text-[#93278f] duration-200 hover:bg-[#93278f] hover:text-white"
          onClick={async (x) => {
            await handleAcceptDomain();
          }}
          disabled={loading ? true : false}
        >
          {loading ? (
            <div>
              <div className="flex flex-col ">
                <div className="grid h-full w-full place-items-center">
                  <Icon
                    icon="eos-icons:loading"
                    className="h-12 w-auto self-center text-gray-300"
                  />
                </div>
              </div>
            </div>
          ) : (
            <>Receive Domain</>
          )}
        </button>
      </div>
    </>
  );
}

export { Step4 };
