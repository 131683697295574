import {
  Collapse,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { FC, Fragment, useEffect, useState } from 'react';
import { ISubDomainProps } from './ProfileTabSubdomains';
import { MdExpandLess, MdExpandMore } from 'react-icons/md';

import {
  IAllRecords,
  IGetResolverInput,
  IResolverGdn,
} from '@/api/resolver/interface';
import { Network } from '@/utils/enums';
import { ProfileTabRecords } from './ProfileTabRecords';
import { getAllRecords } from '@/api/resolver/getAllRecords';
import { useAxios } from '@/utils/axios/AxiosProvider';
import { useMyDomain } from '@/utils/context';
import md5 from 'crypto-js/md5';

interface IProfileSubDomainItemProps {
  subDomain: ISubDomainProps;
}
export const ProfileSubDomainItem: FC<IProfileSubDomainItemProps> = ({
  subDomain,
}) => {
  const fqdn = [
    subDomain.subdomain !== '@' ? subDomain.subdomain : undefined,
    subDomain.fqdn,
  ]
    .filter((e) => e !== undefined)
    .join('.');

  const [gradientColor, setGradientColor] = useState<string[]>(['', '']);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [allRecords, setAllRecords] = useState<IResolverGdn<IAllRecords>>();
  const { domain } = useMyDomain();
  const { resolverGdn } = useAxios();
  if (domain === undefined) throw new Error('Domain not found');

  const handleFetchRecord = async () => {
    const getResolverPayload: IGetResolverInput = {
      fqdn,
      net: Network.MAINNET,
      chain_id: Number(domain.blockchains[0].chainId),
    };
    const records = await getAllRecords(resolverGdn)(getResolverPayload);

    setAllRecords(records);
  };

  const handleClick = async () => {
    setIsOpen(!isOpen);
    if (!allRecords) {
      await handleFetchRecord();
    }
  };

  useEffect(() => {
    // md5 hash domain
    if (fqdn) {
      const hash = md5(fqdn).toString();

      setGradientColor([
        `linear-gradient(to bottom, #${hash.slice(0, 6)} 0%, #${hash.slice(
          6,
          12,
        )} 100%)`,
        `linear-gradient(to right, #${hash.slice(12, 18)} 0%, #${hash.slice(
          18,
          24,
        )} 100%)`,
      ]);
    }
  }, [fqdn]);

  return (
    <Fragment>
      <ListItem disablePadding>
        <ListItemButton onClick={handleClick}>
          <ListItemIcon className="pr-4">
            <div
              style={{ background: gradientColor[0] }}
              className={`z-20 h-10 w-10 rounded-full border border-white`}
            ></div>
          </ListItemIcon>
          <ListItemText
            primary={
              <Fragment>
                <Typography className=" inline-block" component={'span'}>
                  {fqdn.split('.')[0]}
                </Typography>
                <Typography
                  className=" inline-block font-bold text-gray-500"
                  component={'span'}
                >
                  .{fqdn.split('.').slice(1).join('.')}
                </Typography>
              </Fragment>
            }
          />

          {isOpen ? <MdExpandLess /> : <MdExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse
        in={isOpen}
        timeout="auto"
        unmountOnExit
        className=" pb-4 pl-14"
      >
        <ProfileTabRecords
          fqdn={fqdn}
          allRecords={allRecords?.data}
          borderless
        />
      </Collapse>
    </Fragment>
  );
};
