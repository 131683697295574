export const TextRecordTypeOptions = [
  { value: 'email', label: 'Email' },
  { value: 'url', label: 'Url' },
  { value: 'avatar', label: 'Avatar' },
  { value: 'description', label: 'Description' },
  { value: 'notice', label: 'Notice' },
  { value: 'keywords', label: 'Keywords' },
  { value: 'discord', label: 'Discord' },
  { value: 'github', label: 'Github' },
  { value: 'reddit', label: 'Reddit' },
  { value: 'twitter', label: 'Twitter' },
  { value: 'telegram', label: 'Telegram' },
  { value: 'delegate', label: 'Delegate' },
];

export const TextRecordPlaceholder = {
  email: 'example@gmail.com',
  url: 'https://www.example.com',
  avatar:
    'https://gateway.dedrive.io/v1/access/1e9721fbb43347f5a66b699e9fef0fe3',
  description: 'Hello, This is example from edns.',
  notice: 'This is notice.',
  keywords: 'EDNS Web3 Cool',
  discord: 'https://discord.com/invite/XesptqUUmm',
  github: 'https://github.com/ednsdomains/contracts',
  reddit: 'u/edns',
  twitter: 'https://twitter.com/ednsdomains',
  telegram: 'https://t.me/edns_community',
  delegate: '',
};

export const AddressRecordTypeOptions = [
  { value: 'address', label: 'Address' },
];

export const DNSRecordTypeOptions = [
  {
    value: 'A',
    label: 'A – Routes traffic to an IPv4 address and some AWS resources',
  },
  {
    value: 'AAAA',
    label: 'AAAA – Routes traffic to an IPv6 address and some AWS resources',
  },
  {
    value: 'CNAME',
    label:
      'CNAME – Routes traffic to another domain name and to some AWS resources',
  },
  { value: 'MX', label: 'MX – Specifies mail servers' },
  {
    value: 'TXT',
    label:
      'TXT – Used to verify email senders and for application-specific values',
  },
  { value: 'PTR', label: 'PTR – Maps an IP address to a domain name' },
  {
    value: 'SRV',
    label: 'SRV – Application-specific values that identify servers',
  },
  { value: 'SPF', label: 'SPF – Not recommended' },
  { value: 'NAPTR', label: 'NAPTR – Used by DDDS applications' },
  {
    value: 'CAA',
    label:
      'CAA – Restricts CAs that can create SSL/TLS certificates for the domain',
  },
];

export const DNSPlaceholderMapping: { [key: string]: string } = {
  A: '192.0.235',
  AAAA: '2001:0db8::8a2e:0370:bab5',
  CNAME: 'https://www.example.com/',
  MX: '10 mailserver.example.com',
  TXT: 'Sample Text Entries',
  PTR: 'https://www.example.com/',
  SRV: '1 10 5269 xmpp-server.example.com',
  SPF: 'v=spf1 ip4:192.168.0.1/16-all',
  NAPTR: '10 100 "s" "SIP+D2U" "" foo.example.com',
  CAA: '0 issue "caa.example.com"',
};

export const DNSHintsMapping: { [key: string]: string } = {
  A: 'Enter multiple values on separate lines.',
  AAAA: 'Enter multiple values on separate lines.',
  CNAME: 'Enter multiple values on separate lines.',
  MX: 'Enter multiple values on separate lines. Format: [priority] [mail server host name]',
  TXT: 'Enter multiple values on separate lines. You can separate long strings into separate lines by appending a space at the end before you continue the string on the next line.',
  PTR: 'Enter multiple values on separate lines.',
  SRV: 'Enter multiple values on separate lines. Format: [priority] [weight] [port] [server host name]',
  SPF: 'Enter multiple values on separate lines.',
  NAPTR:
    'Enter multiple values on separate lines. Format: [order] [preference] [flags] [services] [regexp] [replacement]',
  CAA: 'Enter multiple values on separate lines. Format: [flag] [tag] [value]',
};
