import { Icon } from '@iconify/react';
import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import Select from 'react-select';
// import { IUser } from '@/utils/interface/user.interface';
import { CartContext } from '@/utils/context/CartContext';
import { Chip } from '@mui/material';
import { GlobalStateContext } from '@/utils/context/GlobalStateContext';
import { ICartProduct } from '@/api/omni/Cart/cart.interface';
import { ICryptoWallet } from '@/api/omni/CryptoWallet/cryptoWallet.interface';
import { ITldPrice } from '@/api/omni/Tld/tld.interface';
import { IUser } from '@/api/omni/User/user.interface';
import { Toaster } from 'react-hot-toast';
import { getChainIcon } from '../web3/getChainIcon';
import { updateCartAmount } from '@/api/omni/Cart/updateCartAmount';
import { useAxios } from '@/utils/axios/AxiosProvider';

interface IProps {
  product: ICartProduct;
  user: IUser;
  index: number;
  popup?: boolean;
  length?: number;
}

interface UpdateCountProps {
  callback?: () => void;
  enable: boolean;
  children?: ReactNode;
}
function UpdateCount(props: UpdateCountProps) {
  const { callback, enable, children } = props;
  return (
    <button
      onClick={callback}
      className={`grid aspect-square h-8 w-8 cursor-pointer place-items-center rounded-full border bg-white ${
        enable ? 'active:bg-gray-200' : 'disabled:opacity-50'
      }`}
      disabled={!enable}
    >
      {children}
    </button>
  );
}

function CartProduct(props: IProps) {
  const { product, index, length } = props;
  const { instance } = useAxios();
  const { setShoppingCart, deleteCartProduct } = useContext(CartContext);
  const item = useRef<HTMLDivElement>(null);

  const { cryptoWallets } = useContext(GlobalStateContext);

  // const [domain, setDomain] = useState<ICartProduct | undefined>(product.domain);
  const [isDomainUpdating, setIsDomainUpdating] = useState<boolean>(false);
  const [isUpdateDecrease, setIsUpdateDecrease] = useState<boolean>(true);
  const [isUpdateIncrease, setIsUpdateIncrease] = useState<boolean>(true);
  const [isDomainDeleting, setIsDomainDeleting] = useState<boolean>(false);
  const [ethereumAddresses, setEthereumAddresses] = useState<
    { value: ICryptoWallet; label: string }[]
  >([]);
  const [option, setOption] = useState(false);
  const [quantity, setQuantity] = useState<number>(
    product.type === 'domain' || product.type === 'premiumdomain'
      ? product.domain!.duration
      : product.quantity!,
  );

  const customStyles = {
    menuList: (base) => ({
      ...base,
      '::-webkit-scrollbar': {
        width: '4px',
      },
    }),
    control: (base) => ({
      ...base,
      width: '100%',
      height: '100%',
      border: '1px #eaeff5 solid',
      outline: 'none',
      background: '#fdfaff',
    }),
  };

  useEffect(() => {
    // update quantity
    if (product.type === 'domain' || product.type === 'premiumdomain') {
      setQuantity(product.domain!.duration);
    } else {
      setQuantity(product.quantity!);
    }
  }, [product]);

  useEffect(() => {
    const _ethereumAddresses = cryptoWallets.map((addr) => ({
      value: addr,
      label: addr.address,
    }));

    setEthereumAddresses(_ethereumAddresses);
  }, [cryptoWallets]);

  useEffect(() => {
    const r = document.querySelector(':root') as HTMLElement;
    if (item.current) {
      r.style.setProperty('--cart-h', `${item.current.clientHeight}px`);
    }
  });

  useEffect(() => {
    window.onclick = (e) => {
      if (!e.target.closest('.domain-select') && option) {
        setOption(false);
      }
    };
  });

  const updateOwnerAddress = async (address: string) => {
    // TODO update Wallet Address
    const type =
      product.type === 'domain' || product.type === 'premiumdomain'
        ? product.type
        : 'product';
    setIsDomainUpdating(true);
    const newCart = await updateCartAmount(instance)({
      id: product.id,
      type,
      owner: address,
    });
    setShoppingCart(newCart);
    setIsDomainUpdating(false);
  };

  const deleteDomain = async () => {
    setIsDomainDeleting(true);
    await deleteCartProduct(`${product.id}`);
    setIsDomainDeleting(false);
  };

  const getDefaultAddressIndex = () => {
    return product.domain.owner.address !== null
      ? ethereumAddresses.findIndex(
          (address) => address.label === product.domain.owner.address,
        )
      : null;
  };

  const getTldPrice = () => {
    const domainLenght = product.domain?.name.length;
    const priceList: ITldPrice =
      product.domain?.tld.price[product.domain?.method];
    switch (domainLenght) {
      case 1: {
        return priceList.oneLetter;
      }
      case 2: {
        return priceList.twoLetter;
      }
      case 3: {
        return priceList.threeLetter;
      }
      case 4: {
        return priceList.fourLetter;
      }
      default: {
        return priceList.fiveLetter;
      }
    }
  };

  const increaseValue = async () => {
    setIsDomainUpdating(true);
    if (quantity >= 5) {
      setIsUpdateIncrease(false);
    } else {
      const _quantity = quantity + 1;
      setQuantity(_quantity);
      const type =
        product.type === 'domain' || product.type === 'premiumdomain'
          ? product.type
          : 'product';
      const newCart = await updateCartAmount(instance)({
        id: product.id,
        type,
        value: _quantity,
      });
      setShoppingCart(newCart);
      setIsUpdateDecrease(true);
    }
    setIsDomainUpdating(false);
  };

  const decreaseValue = async () => {
    setIsDomainUpdating(true);
    if (quantity > 1) {
      const _quantity = quantity - 1;
      setQuantity(_quantity);
      setIsUpdateIncrease(true);

      const type =
        product.type === 'domain' || product.type === 'premiumdomain'
          ? product.type
          : 'product';
      const newCart = await updateCartAmount(instance)({
        id: product.id,
        type,
        value: _quantity,
      });
      setShoppingCart(newCart);
    } else if (quantity > 0) {
      const _quantity = quantity - 1;
      setQuantity(_quantity);
    }
    setIsDomainUpdating(false);
  };

  if (product.type === 'domain' || product.type === 'premiumdomain') {
    return (
      <div
        key={index}
        ref={item}
        className={`cart-con flex flex-col pb-12 ${
          !(index === length) && 'border-b'
        } bg-white before:gap-5`}
      >
        <Toaster />
        {/* {domain.status === 'AVAILABLE' ? null : <div className="mt-5 rounded-md bg-red-500 px-5 py-5 font-medium text-red-200">Domain not available</div>} */}
        <div className="relative flex flex-col justify-between gap-4 py-12 pb-4">
          <div className="flex max-w-full flex-[2] items-center justify-between  text-base lg:text-lg">
            <div className="flex gap-x-2">
              <div className="w-fit">
                <Chip
                  label={product.domain?.method.toLocaleUpperCase()}
                  variant="outlined"
                  size="small"
                  color="primary"
                />
              </div>
              <p className=" text-base font-bold">
                {product.domain?.name}.{product.domain?.tld.name}
              </p>
              <div className="flex h-6 w-6 items-center rounded-full p-1.5">
                {getChainIcon(+product.domain?.blockchain.chainId)}
              </div>
            </div>

            <div
              className={`flex min-w-fit shrink-0 items-center truncate text-xl text-[#393859] ${
                quantity >= 3 && 'line-through'
              }`}
            >
              {isDomainUpdating ? (
                <motion.span
                  className="h-full w-full items-center justify-end lg:justify-start"
                  animate={
                    isDomainUpdating ? { display: 'flex' } : { display: 'none' }
                  }
                >
                  <Icon
                    icon="eos-icons:loading"
                    className="h-6 w-auto text-gray-300"
                  />
                </motion.span>
              ) : (
                `$ ${product.subtotal / quantity}`
              )}
            </div>
          </div>
          <hr className=" border-dashed" />
          <div className="flex justify-between">
            <div className="relative flex w-fit">
              <div className="flex min-w-fit items-center gap-2 rounded-full border bg-[#fdf8ff] p-2 py-1">
                <UpdateCount
                  callback={() => decreaseValue()}
                  enable={isUpdateDecrease && !isDomainUpdating}
                >
                  -
                </UpdateCount>

                <p className="h-full w-fit shrink-0 py-2 text-center">{`${quantity} year(s)`}</p>

                <UpdateCount
                  callback={() => increaseValue()}
                  enable={isUpdateIncrease && !isDomainUpdating}
                >
                  +
                </UpdateCount>
              </div>
            </div>
            <div className="flex items-center justify-end text-left md:justify-start">
              {/*<motion.span*/}
              {/*  className="h-full w-full items-center justify-end lg:justify-start"*/}
              {/*  animate={*/}
              {/*    isDomainUpdating ? { display: 'flex' } : { display: 'none' }*/}
              {/*  }*/}
              {/*>*/}
              {/*  <Icon*/}
              {/*    icon="eos-icons:loading"*/}
              {/*    className="h-6 w-auto text-gray-300"*/}
              {/*  />*/}
              {/*</motion.span>*/}
              <motion.span
                className={`text-right text-xl font-medium md:text-left ${
                  quantity <= 0 && 'line-through'
                }`}
                animate={
                  isDomainUpdating ? { display: 'none' } : { display: 'block' }
                }
              >
                ${product.total}
              </motion.span>
            </div>
          </div>
          <hr className=" border-dashed" />
        </div>
        <div className="flex flex-col items-center gap-2  pr-[1px] lg:flex-row lg:gap-10">
          <span className="self-start lg:self-center">Address:</span>
          {ethereumAddresses.length ? (
            <Select
              options={ethereumAddresses}
              styles={customStyles}
              defaultValue={
                getDefaultAddressIndex() !== null
                  ? ethereumAddresses[getDefaultAddressIndex()!]
                  : ethereumAddresses[0]
              }
              // TODO
              className="w-full"
              onChange={(e) => {
                updateOwnerAddress(`${e?.value.address}`);
                // TODO
              }}
            />
          ) : null}
        </div>
        <button
          onClick={() => {
            deleteDomain();
            // TODO delet Domain
          }}
          data-show={quantity === 0}
          className="btn-delete w-full items-center rounded-md bg-red-500"
          disabled={isDomainDeleting}
        >
          {!isDomainDeleting ? (
            <Icon
              className="mx-auto h-auto w-5 text-white"
              icon="ant-design:delete-outlined"
            />
          ) : (
            <Icon
              icon="eos-icons:loading"
              className="mx-auto h-7 w-auto text-white"
            />
          )}
        </button>
      </div>
    );
  }
  // Product
  return (
    <div
      key={index}
      ref={item}
      className={`cart-con flex flex-col ${
        !(index === length) && 'border-b'
      } bg-white before:gap-5`}
    >
      Product
    </div>
  );
}

export default CartProduct;
