import { IDomain } from '@/utils/interface';
import axios from 'axios';
import config from '@/config';

export async function getDomains(): Promise<IDomain[]> {
  const url = new URL(`/domains`, `${config.api.endpoint}`);
  const response = await axios.get<IDomain[]>(url.href, {
    // headers: { Authorization: `Bearer ${accessToken}` },
  });
  return response.data;
}
