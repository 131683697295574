import { AxiosInstance } from 'axios';
import { errorHandler } from '../../errorHandler';
import config from '@/config';

export const deletePromoCode =
  (instance: AxiosInstance) => async (code: string) => {
    try {
      await instance.delete(`${config.api.router.cart.deletePromoCode}${code}`);
    } catch (e) {
      errorHandler(e);
    }
  };
