import { FC } from 'react';
import { IListTld } from '@/api/omni/PremiumDomain/interface';

const TldAvailableBox: FC<IListTld> = ({ name, available, dropdown }) => {
  if (available) {
    return (
      <div
        data-dropdown={dropdown}
        className="inline-flex h-[50px] flex-col items-center gap-1 rounded-md bg-green-200 px-8 py-2 text-xs font-bold text-green-700 opacity-0 transition-opacity delay-0 duration-200 data-[dropdown=true]:opacity-100 data-[dropdown=true]:delay-75"
      >
        .{name}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="13"
          viewBox="0 0 16 16"
        >
          <path
            fill="currentColor"
            d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Zm3.707-9.293l-4.003 4a1 1 0 0 1-1.415 0l-1.997-2a1 1 0 1 1 1.416-1.414l1.29 1.293l3.295-3.293a1 1 0 0 1 1.414 1.414Z"
          />
        </svg>
      </div>
    );
  } else {
    return (
      <div
        data-dropdown={dropdown}
        className="inline-flex h-[50px] flex-col items-center gap-1 rounded-md bg-red-200 px-8 py-2 text-xs font-bold text-red-700 opacity-0 transition-opacity delay-0 duration-300 data-[dropdown=true]:opacity-100 data-[dropdown=true]:delay-75"
      >
        .{name}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path
            fill="currentColor"
            d="M19.1 4.9C15.2 1 8.8 1 4.9 4.9S1 15.2 4.9 19.1s10.2 3.9 14.1 0s4-10.3.1-14.2m-4.3 11.3L12 13.4l-2.8 2.8l-1.4-1.4l2.8-2.8l-2.8-2.8l1.4-1.4l2.8 2.8l2.8-2.8l1.4 1.4l-2.8 2.8l2.8 2.8z"
          />
        </svg>
      </div>
    );
  }
};
export default TldAvailableBox;
