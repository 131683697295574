interface IProfileTab {
  children: string;
  isSelected?: boolean;
  onClick?: () => void;
}
const ProfileTab = (props: IProfileTab) => {
  const { children, isSelected = false, onClick } = props;
  return (
    <div
      className={`cursor-pointer text-xl ${isSelected && 'text-edns'}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export { ProfileTab };
