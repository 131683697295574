import { IDomain } from '@/api/omni/Domain/domain.interface';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import {
  DomainPurchaseMethod,
  IAddDomainToCartInput,
  addDomainToCart,
} from '@/api/omni/Cart/addDomainToCart';
import { GlobalStateContext } from '@/utils/context/GlobalStateContext';
import {
  MyDomainContext,
  SelectDomainState,
} from '@/utils/context/MyDomainContext';
import { Web3Functions } from '@/components/web3/Web3';
import { useAxios } from '@/utils/axios/AxiosProvider';
import Manage from '@/components/domain/Manage';

interface IDomainItemsProps {
  count: number;
}

function DomainItems(props: IDomainItemsProps) {
  const { count } = props;
  const { instance } = useAxios();
  const { domains, products, blockChains, getProvider, setIsShowNav } =
    useContext(GlobalStateContext);
  const {
    selectedWallet,
    selectedBlockchainId,
    searchDomain,
    setSelectedDomain,
    setSelectDomainState,
  } = useContext(MyDomainContext);
  const [filteredDomains, setFilteredDomains] = useState<IDomain[]>([]);
  const [reserveDomains, setReserveDomains] = useState<{
    [key: string]: string;
  }>({});

  const handleSelectDomain = (domain: IDomain, state: SelectDomainState) => {
    setSelectDomainState(state);
    setSelectedDomain(domain);
  };

  useEffect(() => {
    if (selectedWallet) {
      const _domains = domains.filter(
        (x) =>
          x.owner.address === selectedWallet.address &&
          x.fqdn.includes(searchDomain) &&
          (!selectedBlockchainId ||
            (selectedBlockchainId &&
              x.blockchains.find((b) => +b.chainId === selectedBlockchainId))),
      );
      const __domains = _domains.splice(
        0,
        count === 0 ? _domains.length : count,
      );
      setFilteredDomains(__domains);
    }
  }, [selectedWallet, domains, searchDomain, count, selectedBlockchainId]);

  const queryReserveDomain = useCallback(
    async (chainId: string, address: string): Promise<string> => {
      try {
        const web3 = new Web3Functions(+chainId, await getProvider(+chainId));
        const domain = await web3.getReverse({ address });
        return domain;
      } catch (e) {
        console.log(chainId);
        console.log(e);
        return '';
      }
    },
    [getProvider],
  );

  const queryAllChains = useCallback(async (): Promise<{
    [key: string]: string;
  }> => {
    const promises: Promise<string>[] = blockChains.map((chain) =>
      queryReserveDomain(chain.chainId, selectedWallet!.address),
    );
    return Promise.all(promises).then((results: string[]) => {
      const resultHash: { [key: string]: string } = {};
      blockChains.forEach((id, index) => {
        resultHash[id.chainId] = results[index];
      });
      return resultHash;
    });
  }, [blockChains, queryReserveDomain, selectedWallet]);

  useEffect(() => {
    const exec = async () => {
      if (blockChains.length > 0 && selectedWallet && domains.length > 0) {
        const reserveDomains = await queryAllChains();
        setReserveDomains(reserveDomains);
      }
    };
    exec();
  }, [blockChains, selectedWallet, domains, queryAllChains]);
  return (
    <>
      {filteredDomains.length <= 0 && (
        <div>
          <span className="text-lg">No Domain in this Wallet</span>
        </div>
      )}
      {filteredDomains.map((domain) => {
        const renewCallback = async (domain: IDomain) => {
          const _product = products.find((p) => p.type === 'domain');
          if (!_product) {
            throw new Error('Product Not Found, Type:Domain');
          }
          const input: IAddDomainToCartInput = {
            productId: `${_product!.id}`,
            domain: {
              method: DomainPurchaseMethod.RENEWAL,
              name: domain.name,
              duration: 1,
              tldId: domain.tld.name,
              blockchainId: domain.blockchains.pop()!.id,
              walletId: domain.owner.id,
            },
          };
          await addDomainToCart(instance)(input);

          setIsShowNav(true);
        };

        return (
          <Manage
            key={domain.tokenId}
            domain={domain}
            callback={handleSelectDomain}
            renewal={renewCallback}
            reserveDomain={
              reserveDomains?.[+domain.blockchains[0]?.chainId] === domain.fqdn
            }
          />
        );
      })}
    </>
  );
}
export { DomainItems };
