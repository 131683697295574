import { AxiosInstance } from 'axios';
import { IOrder } from './order.interface';
import { errorHandler } from '../../errorHandler';
import config from '@/config';

export const getOrder =
  (instance: AxiosInstance) =>
  async (orderId: string): Promise<IOrder> => {
    try {
      const response = await instance.get<IOrder>(
        `${config.api.router.order.getOrder}${orderId}`,
      );
      return response.data;
    } catch (e) {
      throw errorHandler(e);
    }
  };
