import { AddWallet } from './AddWallet';
import { AddressViewList } from './AddressViewList';
import { GlobalStateContext } from '@/utils/context/GlobalStateContext';
import { ICryptoWallet } from '@/api/omni/CryptoWallet/cryptoWallet.interface';
import { MyDomainContext } from '@/utils/context';
import { Skeleton } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';

interface Position {
  x: number;
  y: number;
}
function WalletList() {
  const {
    isFetchWallets,
    cryptoWallets: wallets,
    isManagedWallet,
    user,
  } = useContext(GlobalStateContext);

  const { setSelectedWallet } = useContext(MyDomainContext);

  const scrolls = useRef<HTMLDivElement>(null);
  const [hasWallet, setHasWallet] = useState<boolean>(false);

  const position: Position = { x: 0, y: 0 };
  const clientPos: Position = { x: 0, y: 0 };

  const mouseMoveHandler = (e) => {
    const ele = scrolls.current;
    if (ele === null) return;

    // How far the mouse has been moved
    const dx = e.clientX - clientPos.x;
    const dy = e.clientY - clientPos.y;

    // Scroll the element
    ele.scrollTop = position.y - dy;
    ele.scrollLeft = position.x - dx;
  };

  const mouseUpHandler = () => {
    const ele = scrolls.current;
    if (ele === null) return;

    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    ele.style.cursor = 'default';
    ele.style.removeProperty('user-select');
  };
  const mouseDownHandler = (e) => {
    const ele = scrolls.current;
    if (ele === null) return;

    ele.style.cursor = 'grab';
    ele.style.userSelect = 'none';
    position.x = ele.scrollLeft;
    position.y = ele.scrollTop;
    clientPos.x = e.clientX;
    clientPos.y = e.clientY;

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  useEffect(() => {
    if (wallets.length > 0) {
      setHasWallet(true);
      if (user) {
        if (user.default_address !== null && user.default_address) {
          const _defaultAddress = wallets.find(
            (x) => x.address === user.default_address,
          );
          setSelectedWallet(_defaultAddress);
        }
      }
    } else {
      setHasWallet(false);
    }
  }, [wallets, user, setSelectedWallet]);
  const sortedWallet = (): ICryptoWallet[] => {
    if (user?.default_address !== null && user?.default_address) {
      return wallets.sort((a, b) => {
        if (a.address === user!.default_address) return -1;
        if (b.address === user!.default_address) return 1;
        return 0;
      });
    }
    return wallets;
  };
  return (
    <div
      ref={scrolls}
      onMouseDown={mouseDownHandler}
      className="z-[0] flex w-full gap-4 overflow-x-auto rounded-3xl border border-[#E3E9F1] bg-white p-6"
    >
      {!isFetchWallets ? (
        <>
          {!hasWallet && (
            <div className="flex min-h-[220px] w-[442px] shrink-0 items-center justify-center rounded-2xl bg-red-500 px-5 py-5 text-xl font-medium text-white">
              Please add your wallet address
            </div>
          )}
          <AddWallet isManagedWallet={isManagedWallet} />
          <AddressViewList wallets={sortedWallet()} />
        </>
      ) : (
        <>
          <Skeleton
            // style={{ border: '1px solid rgb(229, 231, 235)' }}
            // width={445}
            width={445}
            height={228}
            // borderRadius="1.25rem"
            // baseColor="white"
            // highlightColor="#f1f1f1"
          />

          <Skeleton
            // style={{ border: '1px solid rgb(229, 231, 235)' }}
            width="445px"
            height={228}
            // borderRadius="1.25rem"
            // baseColor="white"
            // highlightColor="#f1f1f1"
          />
        </>
      )}
    </div>
  );
}

export { WalletList };
