import { GlobalStateContext } from '@/utils/context/GlobalStateContext';
import { Icon } from '@iconify/react';
import { Outlet } from 'react-router-dom';
import { useContext, useRef, useState } from 'react';
import LeftNav from './Nav/LeftNav';
import NFooter from './NFooter';

// import { createEthereumAddress as _createEthereumAddress } from '@/api/create-ethereum-address';

import { Alert, LinearProgress, Slide, Snackbar } from '@mui/material';
import { BlindingWallet } from './BlindingWallet';
import { useAuth } from '@/utils/auth/hook';
import { useAxios } from '@/utils/axios/AxiosProvider';
import ResellerBind from '@/Pages/reseller/bind';

function UIContainer() {
  const { isAuthenticated } = useAuth();

  const { setIsShowNav, isShowNav } = useContext(GlobalStateContext);
  const { loadingCount } = useAxios();

  const container = useRef<HTMLDivElement>(null);

  const [display, setDisplay] = useState(false);
  const [resellerCode, setResellerCode] = useState('');

  return (
    <>
      <BlindingWallet />

      <div className="flex h-screen flex-col" data-theme="light">
        <LinearProgress
          variant="determinate"
          value={(loadingCount.count / loadingCount.totalCount) * 100}
          style={{
            position: 'absolute',
            top: 0,
            width: '100%',
            zIndex: 1000,
            display: loadingCount.totalCount > 0 ? 'block' : 'none',
          }}
        />

        <div className="top-nav-con w-full shrink-0 justify-end gap-5 px-10 py-5 text-xs text-gray-500 md:text-sm">
          <Icon
            onClick={() => {
              setIsShowNav(!isShowNav);
              console.log('click');
            }}
            className="btn-option h-5 w-auto cursor-pointer"
            icon="charm:menu-hamburger"
            color="#93278f"
          />
        </div>
        <div className="main-bg flex flex-1 flex-col overflow-hidden md:flex-row">
          <ResellerBind
            display={display}
            setDisplay={setDisplay}
            code={resellerCode}
          />
          <LeftNav
            isAuthenticated={isAuthenticated}
            setResellerCode={setResellerCode}
          />
          <section className="flex flex-1 flex-col overflow-y-hidden pt-[60px] md:pt-0">
            <div className="h-full w-full overflow-y-hidden md:mt-0">
              <div
                ref={container}
                className="relative flex h-full w-full flex-col overflow-hidden md:rounded-2xl"
              >
                <Outlet />
              </div>
            </div>
            <NFooter />
          </section>
        </div>
      </div>
      {false && (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          onClose={() => {}}
          message="Note archived"
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          TransitionComponent={(props) => <Slide {...props} direction="left" />}
        >
          <Alert onClose={() => {}} severity="success" sx={{ width: '100%' }}>
            This is a success message!
          </Alert>
        </Snackbar>
      )}
    </>
  );
}

export default UIContainer;
