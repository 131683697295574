import { CSSProperties, ReactNode } from 'react';

interface ISectionLayoutProps {
  children?: ReactNode | ReactNode[];
  className?: string;
  style?: CSSProperties;
  borderless?: boolean;
}
const SectionLayout = (props: ISectionLayoutProps) => {
  const { children, className, style, borderless } = props;
  return (
    <div
      className={`relative h-auto w-full rounded-2xl  border-slate-300  ${className} ${
        !borderless ? 'border p-6' : ' p-4'
      }`}
      style={style}
    >
      {children}
    </div>
  );
};

export { SectionLayout };
