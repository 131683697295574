import { useEffect, useState } from 'react';
import md5 from 'crypto-js/md5';

interface IHashIcon {
  text: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl';
}
const SIZE_MAP = {
  'xs': 'h-8 w-8',
  'sm': 'h-12 w-12',
  'md': 'h-14 w-14',
  'lg': 'h-16 w-16',
  'xl': 'h-20 w-20',
  '2xl': 'h-24 w-24',
  '3xl': 'h-28 w-28',
  '4xl': 'h-32 w-32',
};
const HashIcon = (props: IHashIcon) => {
  const { text, size = 'md' } = props;
  const [gradientColor, setGradientColor] = useState<string[]>(['', '']);

  useEffect(() => {
    // md5 hash domain
    const hash = md5(text).toString();

    setGradientColor([
      `linear-gradient(to bottom, #${hash.slice(0, 6)} 0%, #${hash.slice(
        6,
        12,
      )} 100%)`,
      `linear-gradient(to right, #${hash.slice(12, 18)} 0%, #${hash.slice(
        18,
        24,
      )} 100%)`,
    ]);
  }, [text]);
  return (
    <>
      <div
        style={{ background: gradientColor[0] }}
        className={`${SIZE_MAP[size]} z-20 rounded-full border border-white`}
      ></div>
    </>
  );
};

export { HashIcon };
