import { AxiosInstance } from 'axios';
import { IDomain } from './domain.interface';
import config from '@/config';

export const getDomainDetails =
  (instance: AxiosInstance) =>
  async (fqdn: string): Promise<IDomain> => {
    const response = await instance.get<IDomain>(
      `${config.api.router.domain.detail}${fqdn}`,
    );
    const data = await response.data;
    return data;
  };
