/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type { IPortal, IPortalInterface } from "../IPortal";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "enum CrossChainProvider",
        name: "provider",
        type: "uint8",
      },
    ],
    name: "PacketReceived",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "address",
        name: "sender",
        type: "address",
      },
      {
        indexed: false,
        internalType: "enum Chain",
        name: "dstChain",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "enum CrossChainProvider",
        name: "provider",
        type: "uint8",
      },
    ],
    name: "PacketSent",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "enum CrossChainProvider",
        name: "provider",
        type: "uint8",
      },
      {
        indexed: false,
        internalType: "string",
        name: "reason",
        type: "string",
      },
    ],
    name: "ProviderError",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: "bytes32",
        name: "id",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "address",
        name: "receiver",
        type: "address",
      },
      {
        indexed: false,
        internalType: "string",
        name: "resaon",
        type: "string",
      },
    ],
    name: "ReceiverError",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "enum Chain",
        name: "dstChain",
        type: "uint8",
      },
      {
        internalType: "enum CrossChainProvider",
        name: "provider",
        type: "uint8",
      },
      {
        internalType: "bytes",
        name: "payload",
        type: "bytes",
      },
    ],
    name: "estimateFee",
    outputs: [
      {
        internalType: "uint256",
        name: "",
        type: "uint256",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "enum CrossChainProvider",
        name: "provider",
        type: "uint8",
      },
    ],
    name: "getProvider",
    outputs: [
      {
        internalType: "address",
        name: "",
        type: "address",
      },
    ],
    stateMutability: "view",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "enum CrossChainProvider",
        name: "provider",
        type: "uint8",
      },
      {
        internalType: "bytes",
        name: "payload",
        type: "bytes",
      },
    ],
    name: "receive_",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address payable",
        name: "sender",
        type: "address",
      },
      {
        internalType: "enum Chain",
        name: "dstChain",
        type: "uint8",
      },
      {
        internalType: "enum CrossChainProvider",
        name: "provider",
        type: "uint8",
      },
      {
        internalType: "bytes",
        name: "payload",
        type: "bytes",
      },
    ],
    name: "send_",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "enum CrossChainProvider",
        name: "",
        type: "uint8",
      },
      {
        internalType: "address",
        name: "address_",
        type: "address",
      },
    ],
    name: "setProvider",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

export class IPortal__factory {
  static readonly abi = _abi;
  static createInterface(): IPortalInterface {
    return new utils.Interface(_abi) as IPortalInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IPortal {
    return new Contract(address, _abi, signerOrProvider) as IPortal;
  }
}
