import { Icon } from '@iconify/react';
import { ReactNode, useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import { copyToClipboard } from '@/utils/copyToClipboard';

export enum AttributeType {
  None,
  Copy,
  Link,
  Tick,
}
interface IProfileAttribute {
  title: ReactNode;
  children?: string;
  type?: AttributeType;
  isShort?: boolean;
  isFull?: boolean;
}
const ProfileAttribute = (props: IProfileAttribute) => {
  const { title, children, isShort, type = AttributeType.None, isFull } = props;
  const [icon, setIcon] = useState<AttributeType>(AttributeType.None);

  const AttributeIcon = () => {
    switch (icon) {
      case AttributeType.Copy:
        return <Icon icon="akar-icons:copy" />;
      case AttributeType.Link:
        return <Icon icon="akar-icons:link" />;
      case AttributeType.Tick:
        return <Icon icon="mdi:tick" />;
      default:
        return <></>;
    }
  };

  const handleCopy = () => {
    copyToClipboard(children || '');
    // change icon from copy to tick icon, after 1 second change back to copy icon
    setIcon(AttributeType.Tick);
    setTimeout(() => {
      setIcon(AttributeType.Copy);
    }, 1000);
  };

  const handleLink = () => {
    // TODO:
  };

  const handleOnClick = () => {
    switch (type) {
      case AttributeType.Copy:
        handleCopy();
        break;
      case AttributeType.Link:
        handleLink();
        break;
      default:
        break;
    }
  };

  // Short text for displaying
  const handleShortText = (value: string = 'xxxxx'): string => {
    if (isShort) {
      return `${value.slice(0, 5)}...${value.slice(-5)}`;
    } else {
      return value;
    }
  };

  useEffect(() => {
    setIcon(type);
  }, [type]);

  return (
    <div
      className={`flex h-10 items-center justify-between rounded-lg border bg-gray-100 px-2 ${
        isFull ? 'w-full' : 'w-fit'
      }`}
      onClick={handleOnClick}
    >
      <div
        className={`w-full grid-cols-4 space-x-2 overflow-hidden px-2 md:grid-cols-5 lg:grid-cols-7 xl:grid-cols-7 2xl:grid-cols-9 ${
          isFull ? 'grid ' : 'flex'
        }`}
      >
        <div className="col-span-1 font-bold text-gray-400">
          {children !== undefined ? title : <Skeleton sx={{ width: '100%' }} />}
        </div>
        <div className="col-span-full col-start-2 self-center truncate font-medium">
          {children !== undefined ? (
            handleShortText(children)
          ) : (
            <Skeleton sx={{ width: '100%' }} />
          )}
        </div>
      </div>
      <AttributeIcon />
    </div>
  );
};

export { ProfileAttribute };
