import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import {useAuth0, withAuthenticationRequired} from '@auth0/auth0-react';
import i18nIsoCountries from 'i18n-iso-countries';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'react-phone-input-2/lib/material.css';
import 'react-phone-number-input/style.css';
import { GlobalStateContext } from '@/utils/context/GlobalStateContext';
import { IBillingAddress, IDomain, IEthereumAddress } from '@/utils/interface';
import { MessageRecord } from '@/components/SMS/sentMessageRecord';
import { Skeleton } from '@mui/material';
import { getDomains } from '@/api/get-domains';
import { isValidPhoneNumber } from 'libphonenumber-js';
import MessageBox from '@/components/SMS/MessageBox';
import Nav from '@/components/Nav/Nav';
import OwlCarousel from 'react-owl-carousel';
import PhoneInput from 'react-phone-input-2';
import Picker from 'emoji-picker-react';
import axios from 'axios';

i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));

interface IlateMsg {
  msg: string;
  date: string;
}
interface Isender {
  sender: string;
  date: string | undefined;
}

const ani = {
  hide: {
    opacity: 0,
    zIndex: '-1',
    transition: { zIndex: { delay: 0.35 } },
    duration: 0.2,
  },
  show: { opacity: 1, zIndex: '100', transition: { duration: 0.2 } },
};
interface SmsQuota {
  total: number;
}

interface User {
  smsQuota?: SmsQuota;
}
const SmsPage = () => {
  const navigate = useNavigate();
  const [phoneNumber, setPhoneNumber] = useState<any>();
  const [isRetreivedAccessToken, setIsRetreivedAccessToken] =
    useState<boolean>(false);
  const [isBillingAddressHasBeenSetup, setIsBillingAddressHasBeenSetup] =
    useState<boolean | undefined>(undefined);
  const { user } = useContext(GlobalStateContext);
  const [selectedSender, setSelectedSender] = useState<string | undefined>();
  const [billingAddress, setBillingAddress] = useState<
    IBillingAddress | undefined
  >(undefined);
  const [ethereumAddresses, setEthereumAddresses] = useState<
    IEthereumAddress[] | undefined
  >(undefined);
  const [defaultEthereumAddress, setDefaultEthereumAddress] = useState<
    IEthereumAddress | undefined
  >(undefined);
  const [receiverType, setReceiverType] = useState('PhoneNumber');
  // const [orderInModal, setOrderInModal] = useState<IOrder | undefined>(undefined);
  const receiverDomain = useRef<HTMLInputElement>(null);
  // const receiverDomainChatBox = useRef<HTMLInputElement>(null);
  // const [isConnectingToWeb3Wallet, setIsConnectingToWeb3Wallet] = useState<boolean>(false);
  // const [isOrderDetailModalOpen, setIsOrderDetailModalOpen] = useState<boolean>(false);
  // const [receiverPhoneNumber, setReceiverPhoneNumber] = useState<any>();
  // const [isCancelingOrder, setIsCancelingOrder] = useState<{ [key: string]: boolean }>({});
  const [updateQuote, setUpdateQuote] = useState(false);
  // const [isEthereumAddressHasBeenSetup, setIsEthereumAddressHasBeenSetup] = useState<boolean | undefined>(undefined);
  const [
    isDuplicatedBindingEthereumAddress,
    setIsDuplicatedBindingEthereumAddress,
  ] = useState<boolean | undefined>(undefined);
  const scrolls = useRef<HTMLDivElement>(null);
  const [customAddWalletErrorMessage, setCustomAddWalletErrorMessage] =
    useState<string | undefined>(undefined);
  const footer = useRef<HTMLDivElement>(null);
  const [countryOptions, setCountryOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [domains, setDomains] = useState<IDomain[]>([]);
  const [message, setMessage] = useState<boolean>(false);
  const [messageList, setMessageList] = useState<MessageRecord>();
  const [currentDomain, setCurrentDomain] = useState('');
  // const [msgType, setMsgType] = useState('SMS');
  // const [userNow, setUserNow] = useState<User>();
  const [_message, set_Message] = useState('');
  const [_messageChatBox, set_MessageChatBox] = useState('');
  const [sendBy, setSendBy] = useState(false);
  // const [width, setWidth] = useState(window.innerWidth);
  const [reload, setReload] = useState(false);
  const [domainComponent, setDomainComponent] = useState<any[]>([]);
  const [isSelect, setIsSelect] = useState<number>(-1);
  const startPosition = useRef(0);
  const [load, setLoad] = useState(false);

  function time_ago(time) {
    switch (typeof time) {
      case 'number':
        break;
      case 'string':
        time = +new Date(time);
        break;
      case 'object':
        if (time.constructor === Date) time = time.getTime();
        break;
      default:
        time = +new Date();
    }
    const time_formats = [
      [60, 'seconds', 1], // 60
      [120, '1 minute ago', '1 minute from now'], // 60*2
      [3600, 'minutes', 60], // 60*60, 60
      [7200, '1 hour ago', '1 hour from now'], // 60*60*2
      [86400, 'hours', 3600], // 60*60*24, 60*60
      [172800, 'Yesterday', 'Tomorrow'], // 60*60*24*2
      [604800, 'days', 86400], // 60*60*24*7, 60*60*24
      [1209600, 'Last week', 'Next week'], // 60*60*24*7*4*2
      [2419200, 'weeks', 604800], // 60*60*24*7*4, 60*60*24*7
      [4838400, 'Last month', 'Next month'], // 60*60*24*7*4*2
      [29030400, 'months', 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
      [58060800, 'Last year', 'Next year'], // 60*60*24*7*4*12*2
      [2903040000, 'years', 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
      [5806080000, 'Last century', 'Next century'], // 60*60*24*7*4*12*100*2
      [58060800000, 'centuries', 2903040000], // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
    ];
    let seconds = (+new Date() - time) / 1000;
    let token = 'ago';
    let list_choice = 1;

    if (seconds === 0) {
      return 'Just now';
    }
    if (seconds < 0) {
      seconds = Math.abs(seconds);
      token = 'from now';
      list_choice = 2;
    }
    let i = 0;
    let format;
    while ((format = time_formats[(i += 1)]))
      if (seconds < format[0]) {
        if (typeof format[2] === 'string') return format[list_choice];
        return `${Math.floor(seconds / format[2])} ${format[1]} ${token}`;
      }
    return time;
  }
  const [create, setCreate] = useState(false);
  const [emojiPicker, setEmojiPicker] = useState(false);
  const [isSMS, setIsSMS] = useState(false);
  const [msgOption, setMsgOption] = useState('sms');
  useEffect(() => {
    const _countryOptions = new Set<{ value: string; label: string }>();
    const names = i18nIsoCountries.getNames('en');
    for (const code in names) {
      _countryOptions.add({ label: names[code], value: code });
    }
    setCountryOptions([..._countryOptions]);
  }, []);

  // Get access token from Auth0
  // useEffect(() => {
  //   async function exec() {
  //     if (isAuthenticated) {
  //       const token = await getAccessTokenSilently();
  //       setAccessToken(token);
  //     }
  //     setIsRetreivedAccessToken(true);
  //   }
  //   exec();
  // }, [isAuthenticated, getAccessTokenSilently]);

  // Get and set user
  useEffect(() => {
    async function exec() {
      if (isRetreivedAccessToken && countryOptions.length > 0) {
        // setUser(_user);
        // setBillingAddress(_user.billingAddress);
        // setEthereumAddresses(_.orderBy(_user.ethereumAddresses, 'updatedAt', 'desc'));
        // setDefaultEthereumAddress(_user.defaultEthereumAddress);
        const _domains = await getDomains();
        const filterDomain = _domains.filter((x) => x.phoneNumber !== null);
        // console.log({filterDomain})
        setDomains(_domains);
        setLoad(true);
        // console.log(_domains[0].fqdn)
        if (filterDomain.length > 0) {
          setCurrentDomain(filterDomain[0].fqdn);
          setIsSelect(_domains.findIndex((value) => value === filterDomain[0]));
        }
        setSelectedSender('');
      }
    }
    exec();
  }, [countryOptions, isRetreivedAccessToken, updateQuote]);

  useEffect(() => {
    if (isRetreivedAccessToken && billingAddress) {
      const _isBillingAddressHasBeenSetup =
        !!billingAddress.addressLine1 &&
        !!billingAddress.city &&
        !!billingAddress.state &&
        !!billingAddress.zip &&
        !!billingAddress.country &&
        !!billingAddress.firstname &&
        !!billingAddress.lastname;
      setIsBillingAddressHasBeenSetup(_isBillingAddressHasBeenSetup);
    }
  }, [billingAddress, isRetreivedAccessToken]);

  function updateMsgBox() {
    // console.log(selectedSender)
    const filterSentMessage = messageList?.sent.filter(
      (x) => x.receiver === selectedSender,
    );
    const filterRecMessage = messageList?.received.filter(
      (x) => x.sender === selectedSender,
    );
    const msgArray = filterSentMessage!.concat(filterRecMessage!);
    msgArray
      .sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt))
      .reverse();
    return msgArray;
  }
  function handleSetSelectedSender(sender: string) {
    const filterSentMessage = messageList?.sent.filter(
      (x) => x.receiver === sender,
    );
    const filterRecMessage = messageList?.received.filter(
      (x) => x.sender === sender,
    );
    const msgArray = filterSentMessage!.concat(filterRecMessage!);
    msgArray
      .sort((a, b) => Date.parse(a.createdAt) - Date.parse(b.createdAt))
      .reverse();
    setSelectedSender(sender);
    // setMsgArray(msgArray)
    setIsSMS(msgArray[0].type !== 'SMS');
  }
  async function reply() {
    const result = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/message/send`,
      {
        sender: currentDomain,
        receiver: selectedSender,
        type: 'DOMAIN',
        message: _messageChatBox,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    set_MessageChatBox('');
    if (reload) {
      setReload(false);
    } else {
      setReload(true);
    }
    // alert(result)
  }

  useEffect(() => {
    const a = document.querySelector(':root') as HTMLElement;
    let ethernetAddressesLength;
    if (ethereumAddresses) {
      ethereumAddresses?.length >= 5
        ? (ethernetAddressesLength = '5')
        : (ethernetAddressesLength = (
            ethereumAddresses?.length + 1
          ).toString());
    }
    if (ethernetAddressesLength) {
      // console.log(ethernetAddressesLength);
      a.style.setProperty('--card-quantity', ethernetAddressesLength);
    }
  }, [ethereumAddresses]);

  useEffect(() => {
    const r = document.querySelector(':root') as HTMLElement;
    const box = document.querySelector('.msg-upper-box') as HTMLElement;
    let height;
    const resize = () => {
      height = `${box.clientHeight - 24}px`;
      r.style.setProperty('--msg-outer-box', height);
    };
    window.addEventListener('resize', () => {
      resize();
    });
    resize();
  }, []);

  // get message by address
  useEffect(() => {
    async function getresult(address) {
      try {
        const result = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/message/${address}`,
          {
            headers: {
              'Content-Type': 'application/json',
              // 'Authorization': `Bearer ${accesstoken}`,
            },
          },
        );
        console.log(result.data);
        setMessageList(result.data);
      } catch (e) {
        console.error(e);
      }
    }
    if (currentDomain !== '') getresult(currentDomain);

    // console.log(messageList);
  }, [defaultEthereumAddress, currentDomain, reload]);

  const handleMessage = async () => {
    try {
      if (msgOption === 'domain') {
        if (receiverDomain.current?.value !== '') {
          await axios.post(
            `${process.env.REACT_APP_API_ENDPOINT}/message/send`,
            {
              sender: currentDomain,
              receiver: receiverDomain.current?.value,
              type: 'DOMAIN',
              message: _message,
            },
            {
              headers: {
                'Content-Type': 'application/json',
                // 'Authorization': `Bearer ${accessToken}`,
              },
            },
          );
          set_Message('');
          if (reload) {
            setReload(false);
          } else {
            setReload(true);
          }
          alert(`Sent to ${receiverDomain.current!.value}`);
        }
      } else if (msgOption === 'sms') {
        if (
          user
          // && user?.smsQuota.total <= 0
        ) {
          alert(`Quote Not enought.`);
          return;
        }
        if (msgOption === 'sms' && sendBy === false) {
          if (!isValidPhoneNumber(`${phoneNumber}`)) {
            alert(`This is wrong receiverPhoneNumber : ${phoneNumber}`);
            return;
          }
        }
        // sendBy ? setReceiverType('Domain') : setReceiverType('PhoneNumber');
        const receiver = sendBy ? receiverDomain.current!.value : phoneNumber;

        await axios.post(
          `${process.env.REACT_APP_API_ENDPOINT}/message/send`,
          {
            sender: currentDomain,
            receiver,
            type: 'SMS',
            message: _message,
            receiverType: sendBy ? 'Domain' : 'PhoneNumber',
          },
          {
            headers: {
              'Content-Type': 'application/json',
              // 'Authorization': `Bearer ${accessToken}`,
            },
          },
        );
        // setUser(undefined);
        setUpdateQuote(!updateQuote);
        set_Message('');
        alert(`Sent to ${receiver}`);
      }
    } catch (e) {
      const error = e as any;
      alert(error.response.data.message);
    }
  };

  const setDomain = useCallback((value) => {
    setCurrentDomain(value.fqdn);
  }, []);

  const renderDomains = () => {
    if (domainComponent.length !== domains.length) {
      // clean domainComponent
      domainComponent.splice(0, domainComponent.length);
      domains.forEach((value, i) => {
        if (value.phoneNumber !== null) {
          domainComponent.push(
            <motion.div
              onClick={() => {
                setDomain(value);
                setSelectedSender('');
                setIsSelect(i);
              }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ delay: i * 0.15, ease: 'easeOut', duration: 0.5 }}
              className={`${
                i === isSelect ? 'bg-purple-700 text-white' : 'text-black'
              } box-shadow relative flex max-w-[325px] cursor-pointer snap-start flex-col items-center justify-center gap-2 rounded-xl border bg-white p-10 py-6 duration-200 hover:bg-purple-600 hover:text-white`}
            >
              <Icon
                icon="cryptocurrency:matic"
                className={`${
                  i === isSelect ? ' text-white' : 'text-purple-500'
                } text-5xl`}
              />
              <text className="mt-2 text-lg">{value.name}</text>
              <div className="rounded-md font-medium">.{value.tld.name}</div>
            </motion.div>,
          );
        }
      });
    } else if (domains.length === 0) {
      load === false
        ? domainComponent.push(
            <Skeleton
              // style={{ border: '1px solid rgb(229, 231, 235)' }}
              width="100%"
              height={171}
              // borderRadius="1rem"
              // baseColor="white"
              // highlightColor="#f1f1f1"
            ></Skeleton>,
          )
        : domainComponent.push(
            <div className="grid h-[171px] w-full place-items-center rounded-lg border bg-red-500 p-6 text-white">
              Domain not found
            </div>,
          );
    }

    return domainComponent;
  };

  const [bind, setBind] = useState<boolean | undefined>(undefined);
  useEffect(() => {
    domains.length > 0 && setBind(false);
    domains.forEach((domain) => {
      domain.phoneNumber !== null && setBind(true);
    });
    console.log(bind);
  }, [domains]);

  // console.log(currentDomain);
  const renderSender = () => {
    const senderHashMap = new Map<string, IlateMsg>();
    messageList?.sent.map((receivedMessage) => {
      const msg = receivedMessage.message;
      const date = receivedMessage.createdAt;
      if (senderHashMap.has(receivedMessage.receiver)) {
        if (
          new Date(date) >
          new Date(senderHashMap.get(receivedMessage.receiver)?.date!)
        ) {
          senderHashMap.set(receivedMessage.sender, { msg, date });
        }
      } else {
        senderHashMap.set(receivedMessage.sender, { msg, date });
      }
      return '';
    });

    messageList?.sent.map((sentMessage) => {
      const msg = sentMessage.message;
      const date = sentMessage.createdAt;
      if (senderHashMap.has(sentMessage.receiver)) {
        if (
          new Date(date) >
          new Date(senderHashMap.get(sentMessage.receiver)?.date!)
        ) {
          senderHashMap.set(sentMessage.receiver, { msg, date });
        }
      } else {
        senderHashMap.set(sentMessage.receiver, { msg, date });
      }
      return '';
    });
    const ary = Array.from(senderHashMap.keys());
    let send: Isender[] = ary.map((x) => {
      return { sender: x, date: senderHashMap.get(x)?.date };
    });
    send = send.sort((a, b) => Date.parse(b.date!) - Date.parse(a.date!));
    const senderList = send
      .filter((x) => x.sender !== currentDomain)
      .map((sender, index) => {
        if (selectedSender === sender.sender) {
          return (
            <MessageBox
              setMessage={setMessage}
              sender={sender.sender}
              setSelectedSender={handleSetSelectedSender}
              last={index === ary.length - 1 && index > 5}
              laterMsg={senderHashMap.get(sender.sender)?.msg}
              latestDate={senderHashMap.get(sender.sender)?.date}
            />
          );
        }
        return (
          <MessageBox
            setMessage={setMessage}
            sender={sender.sender}
            setSelectedSender={handleSetSelectedSender}
            last={index === ary.length - 1 && index > 5}
            laterMsg={senderHashMap.get(sender.sender)?.msg}
            latestDate={senderHashMap.get(sender.sender)?.date}
          />
        );
      });

    return senderList;
  };
  // console.log({ selectedSender, currentDomain });

  const renderMessage = () => {
    if (messageList) {
      const msgArray = updateMsgBox();
      const msgList = msgArray.map((msg) => {
        if (msg.sender === selectedSender) {
          // I am receiver
          return (
            <div className="flex px-5">
              <div className="relative">
                <div className="max-w-[350px] break-words rounded-2xl rounded-bl-md bg-[#D9E1E7] p-6 py-5">
                  <p>{msg.message}</p>
                </div>
                <p className="mt-2 text-xs text-[#809FB8]">
                  {time_ago(new Date(msg.createdAt).getTime())}
                </p>
              </div>
            </div>
          );
        }
        // I am Sender
        return (
          <div className="flex justify-end px-5">
            <div className="relative">
              <div className="max-w-[350px] break-words rounded-2xl rounded-br-md bg-[#1AD598] p-6 py-5 text-white">
                <p>{msg.message}</p>
              </div>
              <p className="mt-2 w-full text-right text-xs text-[#809FB8]">
                {time_ago(new Date(msg.createdAt).getTime())}
              </p>
            </div>
          </div>
        );
      });
      return msgList;
    }
    return <></>;
  };
  const onEmojiClick = (event, emojiObject) => {
    set_MessageChatBox(_message + emojiObject.emoji);
  };

  window.addEventListener(
    'mousedown',
    (event) => {
      if (
        !(event.target as HTMLElement).closest('.btn-message-options') &&
        emojiPicker
      ) {
        setEmojiPicker(false);
      }
    },
    false,
  );

  // useEffect(() => {
  //   window.addEventListener('resize', () => {
  //     setWidth(window.innerWidth);
  //   });
  // });

  // const domainResize = () => {
  //   return width < 768 ? 2 : width < 1950 ? 5 : 7;
  // };

  return (
    <>
      {/* <motion.div */}
      {/*  variants={ani} */}
      {/*  onClick={() => setIsOrderDetailModalOpen(false)} */}
      {/*  className="modal-con fixed z-[-1] grid h-full w-full place-items-center overflow-hidden opacity-0 backdrop-blur-sm" */}
      {/* > */}
      {/*  <div className="flex h-full w-full flex-col overflow-hidden rounded-none shadow-2xl lg:h-auto lg:w-[32rem] lg:rounded-lg"> */}
      {/*    <div className="flex items-center justify-between bg-blue-500 px-8 py-7 text-xl font-semibold text-white shadow-2xl lg:rounded-t-lg"> */}
      {/*      <span>Order information</span> */}
      {/*      <Icon icon="ep:close-bold" className="h-7 w-auto cursor-pointer" onClick={() => setIsOrderDetailModalOpen(false)} /> */}
      {/*    </div> */}
      {/*    <div className="max-h-auto z-50 flex h-full flex-col gap-5 overflow-y-scroll bg-white px-8 py-9 lg:max-h-[500px] lg:rounded-b-lg"> */}
      {/*      {orderInModal && ( */}
      {/*        <> */}
      {/*          <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5"> */}
      {/*            <span className="flex-[2] text-lg font-medium">Order ID</span> */}
      {/*            <span className="lg:text-md text-xs">{orderInModal.id}</span> */}
      {/*          </div> */}
      {/*          <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5"> */}
      {/*            <span className="flex-[2] font-medium">Status</span> */}
      {/*            <span>{_.startCase(_.toLower(orderInModal.status))}</span> */}
      {/*          </div> */}
      {/*          <div className="border-b"></div> */}
      {/*          {orderInModal.domains.map((orderDomain, i) => ( */}
      {/*            <div key={i} className="flex flex-col gap-5"> */}
      {/*              <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5"> */}
      {/*                <span className="flex-[2] font-medium">Domain</span> */}
      {/*                <span>{orderDomain.fqdn}</span> */}
      {/*              </div> */}
      {/*              <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5"> */}
      {/*                <span className="flex-[2] font-medium">Owner Address</span> */}
      {/*                <span className="lg:text-md text-xs">{orderDomain.owner['address']}</span> */}
      {/*              </div> */}
      {/*              <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5"> */}
      {/*                <span className="flex-[2] font-medium">Price</span> */}
      {/*                <span>$ {orderDomain.price} USD</span> */}
      {/*              </div> */}
      {/*              <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5"> */}
      {/*                <span className="flex-[2] font-medium">Type</span> */}
      {/*                <span>{_.startCase(_.toLower(orderDomain.type))}</span> */}
      {/*              </div> */}
      {/*              <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5"> */}
      {/*                <span className="flex-[2] font-medium">Durations</span> */}
      {/*                <span>{orderDomain.durations} years</span> */}
      {/*              </div> */}
      {/*              <div className="border-b"></div> */}
      {/*            </div> */}
      {/*          ))} */}
      {/*          {orderInModal.successNowPayments && ( */}
      {/*            <div className="flex gap-5"> */}
      {/*              <span className="flex-[2] font-medium">Payment Gateway</span> */}
      {/*              <span>Cryptocurrency</span> */}
      {/*            </div> */}
      {/*          )} */}
      {/*          {orderInModal.successCheckout && ( */}
      {/*            <div className="flex gap-5"> */}
      {/*              <span className="flex-[2] font-medium">Payment Gateway</span> */}
      {/*              <span>Credit Card</span> */}
      {/*            </div> */}
      {/*          )} */}
      {/*          <div className="flex gap-5"> */}
      {/*            <span className="flex-[2] font-medium">Amount</span> */}
      {/*            <span>$ {orderInModal.amount} USD</span> */}
      {/*          </div> */}
      {/*        </> */}
      {/*      )} */}
      {/*    </div> */}
      {/*  </div> */}
      {/* </motion.div> */}
      <Nav title="Message" />
      {!bind && bind !== undefined && (
        <div
          data-show={!bind}
          className="missingCon sms box-shadow relative z-[510] mt-3 border px-10 py-5 lg:rounded-md"
          // onClick={() => {
          //   navigate('/setting');
          // }}
        >
          <div className="absolute left-0 top-0 h-full w-2 rounded-l-md bg-red-500"></div>
          <div className="absolute left-0 top-0 h-full w-[10px] rounded-l-md bg-[#ea6667]"></div>
          <div className="flex items-center gap-4">
            <Icon
              className="h-20 w-auto text-[#ea6667] md:h-10"
              icon="radix-icons:cross-circled"
            />
            <div className="flex flex-col justify-between">
              <p className="text-lg">Alert</p>
              <span
                onClick={() => navigate('/mydomain')}
                className={`hover:underline `}
              >
                Please bind at least one phone
              </span>
            </div>
          </div>
        </div>
      )}
      {!bind && bind !== undefined && (
        <div className="absolute left-0 top-0 z-[300] h-full w-full backdrop-blur-sm"></div>
      )}
      <div className="msg-upper-box flex h-full flex-1 flex-col overflow-x-auto p-10 px-0 pb-0 pt-6">
        <div className="msg-outer-box flex w-full flex-1 flex-col gap-8">
          {customAddWalletErrorMessage && (
            <div
              className="relative rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
              role="alert"
            >
              <span className="block sm:inline">
                {customAddWalletErrorMessage}
              </span>
              <span
                className="absolute bottom-0 right-0 top-0 px-4 py-3"
                onClick={() => setCustomAddWalletErrorMessage(undefined)}
              >
                <svg
                  className="h-6 w-6 fill-current text-red-500"
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3. 029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
            </div>
          )}
          {isDuplicatedBindingEthereumAddress && (
            <div
              className="relative rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
              role="alert"
            >
              <strong className="font-bold">Duplicated Address!</strong>
              <span className="block sm:inline">
                {' '}
                You have already bind this address into your account.
              </span>
              <span
                className="absolute bottom-0 right-0 top-0 px-4 py-3"
                onClick={() => setIsDuplicatedBindingEthereumAddress(false)}
              >
                <svg
                  className="h-6 w-6 fill-current text-red-500"
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
            </div>
          )}
          <div className="px-6 text-xl md:px-10">Choose the domains</div>

          <OwlCarousel
            margin={10}
            // loop
            dots={false}
            stagePadding={window.innerWidth < 768 ? 24 : 40}
            startPosition={startPosition.current}
            onDragged={({ item }) => {
              startPosition.current = item.index;
            }}
            responsive={{
              0: {
                items: 2,
              },
              1024: {
                items: 5,
              },
              1950: {
                items: 7,
              },
            }}
            className="owl-theme relative pb-2"
          >
            {renderDomains()}
          </OwlCarousel>
          {/* <button className="mt-5 px-6 font-semibold text-blue-900 md:px-10">SEE ALL Friends</button> */}

          <section className="msg-box-h flex min-h-[500px] w-full flex-col gap-5 overflow-hidden px-6 md:px-10">
            <div className="flex h-full w-full flex-col gap-8 rounded-xl pb-6 md:pb-10">
              <section className="flex flex-1 overflow-hidden rounded-xl border">
                <div
                  className="sms-msg-con msg-left-con flex flex-col border-r bg-white"
                  data-msg={message}
                >
                  <div
                    data-createMsg={create}
                    data-type={msgOption}
                    className="msg-bar flex h-[300px] gap-3 border-b p-5 py-2"
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-6">
                        <div className="text-sm font-medium md:text-lg">
                          All messages
                        </div>
                        <div className="flex gap-3">
                          <button
                            disabled={!bind}
                            onClick={() => setCreate(!create)}
                            className={`btn-message-options grid aspect-square h-12 place-items-center rounded-xl border ${
                              !bind ? 'opacity-50' : 'opacity-100'
                            }`}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="mr-[-2px] mt-[-1px] h-5 w-auto"
                              width="25.999"
                              height="26"
                              viewBox="0 0 25.999 26"
                            >
                              <path
                                id="icon"
                                d="M624.131,230.834l-3.565-1.387a5.706,5.706,0,0,1-1.4-3.564A56.486,56.486,0,0,1,619,220a57.929,57.929,0,0,1,.166-5.881,4.748,4.748,0,0,1,4.949-4.953A57.436,57.436,0,0,1,630,209h.028a1,1,0,1,1-.028,2c-2.495,0-4.352.007-5.66.155a2.854,2.854,0,0,0-3.188,3.19c-.147,1.308-.152,3.163-.152,5.656s.008,4.348.158,5.655a2.864,2.864,0,0,0,3.2,3.191c1.306.148,3.16.154,5.645.154s4.339-.007,5.645-.156a2.869,2.869,0,0,0,3.2-3.2c.149-1.306.158-3.161.158-5.648v-.028a1,1,0,0,1,2,.028,56.524,56.524,0,0,1-.172,5.875,4.764,4.764,0,0,1-4.959,4.955A56.747,56.747,0,0,1,630,231h-.3A53.724,53.724,0,0,1,624.131,230.834ZM629,222a1,1,0,0,1-1-1v-3a1,1,0,0,1,.293-.706l12-12a1,1,0,0,1,1.414,0l3,3a1,1,0,0,1,0,1.415l-12,12A1,1,0,0,1,632,222Zm1-3.587V220h1.587l8-8L638,210.414ZM639.414,209,641,210.586,642.586,209,641,207.414Z"
                                transform="translate(-619 -205)"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                      {/* <div className="flex items-center gap-2">
                        <p className="txt-send-type">
                          <div data-send={sendBy} className="flex gap-3">
                            <p>Phone</p>
                            <p>Domain</p>
                          </div>
                        </p>
                        <div onClick={() => setSendBy(!sendBy)} className="relative flex h-[25px] w-[45px] cursor-pointer items-center rounded-full bg-[#93278f]">
                          <div data-send={sendBy} className="btn-send-type aspect-square w-[21px] rounded-full border bg-white"></div>
                        </div>
                      </div> */}
                    </div>
                    <div
                      data-createMsg={create}
                      className="msg-option-inner flex flex-col gap-3"
                    >
                      <div className="relative flex w-full cursor-pointer rounded-md border font-medium text-white shadow-inner">
                        <div
                          className="msg-option-phone z-[100] flex-1 p-3 text-center duration-200"
                          onClick={() => {
                            setMsgOption('sms');
                            setReceiverType('PhoneNumber');
                          }}
                          data-type={msgOption}
                        >
                          Phone
                        </div>
                        <div
                          className="msg-option-domain z-[100] flex-1 p-3 text-center duration-200"
                          onClick={() => {
                            setMsgOption('domain');
                            setReceiverType('domain');
                          }}
                          data-type={msgOption}
                        >
                          Domain
                        </div>
                        <div
                          className="msg-option z-[99] !bg-edns"
                          data-type={msgOption}
                        ></div>
                      </div>
                    </div>
                    <div
                      data-createMsg={create}
                      className="msg-option-inner flex flex-col gap-3"
                    >
                      {msgOption === 'sms' ? (
                        <>
                          {sendBy === false ? (
                            <PhoneInput
                              country="hk"
                              placeholder="Enter phone number"
                              value={phoneNumber}
                              containerClass="flex-1 z-[999] min-w-0"
                              inputClass="min-w-full white[100]"
                              onChange={(e, _) => {
                                setPhoneNumber(`+${e}`);
                              }}
                            />
                          ) : (
                            <input
                              className="h-[50px] w-full rounded-md border p-3 outline-edns"
                              placeholder="Type your contact's address"
                              ref={receiverDomain}
                            ></input>
                          )}

                          <textarea
                            className="h-[100px] w-full rounded-md border p-3 outline-edns"
                            value={_message}
                            onChange={(e) => {
                              set_Message(e.currentTarget.value);
                            }}
                            placeholder="Message..."
                          ></textarea>
                          <div className="flex items-center gap-2">
                            <p className="txt-send-type">
                              <div data-send={sendBy} className="flex gap-3">
                                <p>Phone</p>
                                <p>Domain</p>
                              </div>
                            </p>
                            <div
                              onClick={() => setSendBy(!sendBy)}
                              className="relative flex h-[25px] w-[45px] cursor-pointer items-center rounded-full bg-edns"
                            >
                              <div
                                data-send={sendBy}
                                className="btn-send-type aspect-square w-[21px] rounded-full border bg-white"
                              ></div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <input
                            className="h-[50px] w-full rounded-md border p-3 outline-edns"
                            placeholder="Type your contact's address"
                            ref={receiverDomain}
                          ></input>
                          <textarea
                            className="h-[100px] w-full rounded-md border p-3 outline-edns"
                            placeholder="Message..."
                            value={_message}
                            onChange={(e) => {
                              set_Message(e.currentTarget.value);
                            }}
                          ></textarea>
                        </>
                      )}
                      <button
                        className="btn-msg-add shrink-0 rounded-md bg-edns py-3 text-white duration-200 hover:bg-edns-hover"
                        onClick={() => {
                          handleMessage();
                        }}
                      >
                        Send
                      </button>
                    </div>
                  </div>
                  <div className="sender-con flex-1 flex-col overflow-y-auto">
                    {renderSender()}
                  </div>
                </div>
                <div
                  className="msg-right-con flex w-full flex-1 flex-col bg-white"
                  data-msg={message}
                >
                  <div className="sticky top-0 flex h-[65px] w-full items-center justify-between border-b bg-white px-5">
                    {bind && (
                      <div className="flex w-full items-center justify-between">
                        <div className="flex items-center">
                          <div className="shrink-0 pr-5">
                            <div className="relative aspect-square h-10 overflow-hidden rounded-full">
                              {selectedSender !== undefined &&
                                selectedSender !== '' && (
                                  <Icon
                                    icon="cryptocurrency:matic"
                                    className="h-auto w-full text-5xl text-edns"
                                  />
                                )}
                            </div>
                          </div>
                          <p>
                            {selectedSender !== undefined &&
                              selectedSender !== '' &&
                              selectedSender}
                          </p>
                        </div>
                        <p>
                          Quota: SMS Not Implement
                          {/* {user?.smsQuota.total} */}
                        </p>
                      </div>
                    )}

                    <div className="mr-[-2px] flex gap-3">
                      {/* <button className="h-12 aspect-square border rounded-xl btn-message-options grid place-items-center">
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-auto" width="19.912" height="19.912" viewBox="0 0 19.912 19.912">
                            <path
                              id="search"
                              d="M1758.184,229.6l-4.511-4.511a8.463,8.463,0,1,1,1.408-1.409l4.511,4.511a1,1,0,0,1-1.409,1.408Zm-16.221-11.158a6.472,6.472,0,0,0,10.981,4.643c.02-.024.041-.047.063-.07s.046-.044.07-.063a6.471,6.471,0,1,0-11.114-4.51Z"
                              transform="translate(-1739.973 -209.979)"
                            />
                          </svg>
                        </button> */}
                      {/* <button className="h-12 aspect-square rounded-xl hidden md:grid place-items-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="28" height="6" viewBox="0 0 28 6">
                          <path
                            id="dotted_icon"
                            data-name="dotted icon"
                            d="M1828,220a3,3,0,1,1,3,3A3,3,0,0,1,1828,220Zm-11,0a3,3,0,1,1,3,3A3,3,0,0,1,1817,220Zm-11,0a3,3,0,1,1,3,3A3,3,0,0,1,1806,220Z"
                            transform="translate(-1806 -217)"
                            fill="#99b2c6"
                          />
                        </svg>
                      </button> */}
                      <button
                        className="grid aspect-square h-7 place-items-center rounded-xl text-gray-400 active:bg-gray-300 md:hidden"
                        onClick={() => setMessage(false)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          aria-hidden="true"
                          width="1em"
                          height="1em"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="none"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="m8 4 8 8-8 8"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="relative flex flex-1 flex-col-reverse gap-5 overflow-y-auto bg-[#F8F9FA] py-5">
                    {/* <div className="w-full text-center text-[#809FB8] md:py-2">15th September | 10:14 AM</div> */}
                    {renderMessage()}
                  </div>
                  {isSMS ? (
                    <div className="flex gap-1 p-5 py-2">
                      <div className="relative flex h-[50px] w-full items-center rounded-full border bg-[#F7F9FA]">
                        <textarea
                          className="relative h-[50px] w-full resize-none overflow-y-auto bg-transparent px-5 py-[15px] text-xs leading-[20px] outline-none md:py-[14px] md:text-sm"
                          placeholder="type message here..."
                          value={_messageChatBox}
                          onChange={(e) => {
                            set_MessageChatBox(e.target.value);
                          }}
                        ></textarea>
                        <button
                          onClick={async () => {
                            await reply();
                          }}
                          className="theme bg mr-[3px] grid aspect-square h-[42px] place-items-center rounded-full"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.913"
                            height="13.832"
                            viewBox="0 0 17.913 13.832"
                          >
                            <path
                              id="icon"
                              d="M1666.205,1197.472a.985.985,0,0,1,0-1.4l4.272-4.241h-13.52a.988.988,0,1,1,0-1.975h13.521l-4.273-4.242a.984.984,0,0,1,0-1.4,1,1,0,0,1,1.408,0l5.971,5.927h0l.017.018,0,0,.014.015,0,.005.012.013.006.007.01.012.007.008.009.011.007.009.007.01.008.01.007.009.007.01.007.01.007.01.007.01.006.01.007.011.005.009.007.012,0,.008.008.015,0,.006.009.017,0,0,.01.02h0a.973.973,0,0,1,.09.345h0c0,.008,0,.015,0,.023v.005c0,.006,0,.012,0,.019s0,.011,0,.017,0,0,0,.007h0c0,.008,0,.017,0,.025s0,0,0,0,0,.014,0,.021,0,.005,0,.008,0,.011,0,.017v.047l0,.015v.009l0,.016v.008l0,.016v.007l0,.017v.007l0,.016,0,.007,0,.016,0,.008,0,.014,0,.01,0,.013,0,.011,0,.011,0,.013,0,.009-.006.015,0,.006-.007.018v0l-.009.02h0a.984.984,0,0,1-.2.292l-5.971,5.927a1,1,0,0,1-1.408,0Z"
                              transform="translate(-1655.962 -1183.93)"
                              fill="#f7f9fa"
                            />
                          </svg>
                        </button>
                      </div>
                      <div className="ml-3 hidden items-center gap-2 sm:flex">
                        <button
                          onClick={() => setEmojiPicker(true)}
                          className="first-line:odd: btn-message-options relative grid aspect-square h-12 place-items-center rounded-full border bg-[#99B2C6] duration-200 hover:bg-[#93278f]"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-auto w-5"
                            width="23"
                            height="23"
                            viewBox="0 0 23 23"
                          >
                            <path
                              id="Form_8"
                              data-name="Form 8"
                              d="M1727,1190.5a11.5,11.5,0,1,1,11.5,11.5A11.513,11.513,0,0,1,1727,1190.5Zm1.958,0a9.542,9.542,0,1,0,9.542-9.543A9.553,9.553,0,0,0,1728.958,1190.5Zm3.344,3.478a.984.984,0,0,1-.059-1.007.95.95,0,0,1,.784-.513,1.09,1.09,0,0,1,.888.407l.017.023a5.416,5.416,0,0,0,9.136,0,.977.977,0,0,1,.63-.4.989.989,0,0,1,.734.139,1,1,0,0,1,.266,1.356,7.38,7.38,0,0,1-12.4,0Zm8.4-6.414a1.957,1.957,0,1,1,1.958,1.958A1.96,1.96,0,0,1,1740.7,1187.563Zm-8.319,0a1.958,1.958,0,1,1,1.958,1.958A1.959,1.959,0,0,1,1732.383,1187.563Z"
                              transform="translate(-1727 -1179)"
                              fill="#f7f9fa"
                            />
                          </svg>
                          <div
                            data-show={emojiPicker}
                            className="emoji-con absolute right-0 top-[-330px]"
                          >
                            <Picker onEmojiClick={onEmojiClick} />
                          </div>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="p-5">SMS Can't Reply</div>
                  )}
                </div>
              </section>
            </div>
          </section>
        </div>

        {/* <Footer /> */}
      </div>
    </>
  );
};

export default SmsPage;
