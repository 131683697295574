import { Icon } from '@iconify/react';
import { LinearProgress, MenuItem } from '@mui/material';
import { ReactNode, useState } from 'react';

interface OptionBtnProps {
  callback?: (e?) => void | Promise<void>;
  icon: string;
  children?: ReactNode;
  isShown?: boolean;
}

function OptionBtn(props: OptionBtnProps) {
  const { callback, icon, children, isShown = true } = props;
  if (!isShown) {
    return null;
  }
  return (
    <MenuItem onClick={callback} className="hover:text-gray font-medium">
      <div className="grid min-w-[30px] place-items-center">
        <Icon
          icon={icon}
          className="h-7 w-auto cursor-pointer text-blue-500 duration-200 hover:scale-110"
        />
      </div>
      <p>{children}</p>
    </MenuItem>
  );
}
function ListBtn(props: OptionBtnProps) {
  const { callback, icon, children, isShown = true } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleCallback = async () => {
    if (callback) {
      setIsLoading(true);
      await callback();
      setIsLoading(false);
    }
  };
  if (!isShown) {
    return null;
  }
  return (
    <div
      onClick={handleCallback}
      className="hover:text-gray relative cursor-pointer font-medium"
    >
      <div className="flex min-w-[30px] items-center gap-x-2">
        <Icon
          icon={icon}
          className="h-7 w-auto cursor-pointer text-blue-500 duration-200 hover:scale-110"
        />
        <p>{children}</p>
      </div>
      {isLoading && (
        <div className=" absolute h-2 w-full">
          <LinearProgress color="primary" />
        </div>
      )}
    </div>
  );
}

export { OptionBtn, ListBtn };
