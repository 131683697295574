import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import Nav from '@/components/Nav/Nav';
import i18nIsoCountries from 'i18n-iso-countries';

i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const ani = {
  hide: {
    opacity: 0,
    zIndex: '-1',
    transition: { zIndex: { delay: 0.35 } },
    duration: 0.2,
  },
  show: { opacity: 1, zIndex: '100', transition: { duration: 0.2 } },
};
const FriendsPage = () => {
  const [isConnectingToWeb3Wallet, setIsConnectingToWeb3Wallet] =
    useState<boolean>(false);
  const [isOrderDetailModalOpen, setIsOrderDetailModalOpen] =
    useState<boolean>(false);
  const [
    isDuplicatedBindingEthereumAddress,
    setIsDuplicatedBindingEthereumAddress,
  ] = useState<boolean | undefined>(undefined);
  const scrolls = useRef<HTMLDivElement>(null);
  const [customAddWalletErrorMessage, setCustomAddWalletErrorMessage] =
    useState<string | undefined>(undefined);
  const footer = useRef<HTMLDivElement>(null);
  const [countryOptions, setCountryOptions] = useState<
    { value: string; label: string }[]
  >([]);
  // const [domains, setDomains] = useState<IDomain[]>([]);

  useEffect(() => {
    const _countryOptions = new Set<{ value: string; label: string }>();
    const names = i18nIsoCountries.getNames('en');
    for (const code in names) {
      _countryOptions.add({ label: names[code], value: code });
    }
    setCountryOptions([..._countryOptions]);
  }, []);

  // Get access token from Auth0
  // useEffect(() => {
  //   async function exec() {
  //     if (isAuthenticated) {
  //       const token = await getAccessTokenSilently();
  //       setAccessToken(token);
  //     }
  //     setIsRetreivedAccessToken(true);
  //   }
  //   exec();
  // }, [isAuthenticated, getAccessTokenSilently]);

  // Get and set user
  // useEffect(() => {
  //   async function exec() {
  //     if (accessToken && isRetreivedAccessToken && isAuthenticated && countryOptions.length > 0) {
  //       const _user = await getUser(accessToken);
  //       // setUser(_user);
  //       // setBillingAddress(_user.billingAddress);
  //       setEthereumAddresses(_.orderBy(_user.ethereumAddresses, 'updatedAt', 'desc'));
  //       // setDefaultEthereumAddress(_user.defaultEthereumAddress);
  //     }
  //   }
  //   exec();
  // }, [accessToken, countryOptions, isAuthenticated, isRetreivedAccessToken]);

  // useEffect(() => {
  //   if (accessToken && isRetreivedAccessToken && isAuthenticated && billingAddress) {
  //     const _isBillingAddressHasBeenSetup =
  //       !!billingAddress.addressLine1 &&
  //       !!billingAddress.city &&
  //       !!billingAddress.state &&
  //       !!billingAddress.zip &&
  //       !!billingAddress.country &&
  //       !!billingAddress.firstname &&
  //       !!billingAddress.lastname;
  //     setIsBillingAddressHasBeenSetup(_isBillingAddressHasBeenSetup);
  //   }
  // }, [accessToken, billingAddress, isAuthenticated, isRetreivedAccessToken]);

  // useEffect(() => {
  //   if (accessToken && isRetreivedAccessToken && isAuthenticated && ethereumAddresses) {
  //     setIsEthereumAddressHasBeenSetup(ethereumAddresses.length > 0);
  //   }
  // }, [accessToken, ethereumAddresses, isAuthenticated, isRetreivedAccessToken]);

  // Get and set orders
  // useEffect(() => {
  //   async function exec() {
  //     if (accessToken && isRetreivedAccessToken && isAuthenticated) {
  //       const _orders = await getOrders(accessToken);
  //       setOrders(_.orderBy(_orders.orders, 'createdAt', 'desc'));
  //     }
  //   }
  //   exec();
  // }, [accessToken, isAuthenticated, isRetreivedAccessToken]);

  // const createEthereumAddress = async (address: string, message: string, signature: string) => {
  //   if (accessToken) {
  //     const isEthereumAddressAlreadyExist = ethereumAddresses?.find((_address) => _address.address === address);
  //     if (!isEthereumAddressAlreadyExist) {
  //       await _createEthereumAddress(accessToken, address, message, signature);
  //       const [_defaultEthereumaddress, _ethereumAddresses] = await Promise.all([getDefaultEthereumAddress(accessToken), getEthereumAddresses(accessToken)]);
  //       setEthereumAddresses(_ethereumAddresses);
  //       setDefaultEthereumAddress(_defaultEthereumaddress);
  //     } else {
  //       setIsDuplicatedBindingEthereumAddress(true);
  //     }
  //   }
  // };

  // const handleAddWalletClick = async () => {
  //   if (accessToken && isRetreivedAccessToken && isAuthenticated) {
  //     try {
  //       const result = await signMessage();
  //       if (result) {
  //         const { address, signature, message } = result;
  //         await createEthereumAddress(address, message, signature);
  //       }
  //     } catch (e) {
  //       console.error({ e });
  //       if (e instanceof Error) {
  //         if (e.message === 'No Binance Chain Wallet found') setCustomAddWalletErrorMessage(e.message);
  //       }
  //     }
  //   }
  // };

  // const handleSetAsDefaultEthereumAddressClick = async (address: string) => {
  //   if (accessToken && isRetreivedAccessToken && isAuthenticated) {
  //     await createDefaultEthereumAddress(accessToken, address);
  //     const _address = await getDefaultEthereumAddress(accessToken);
  //     setDefaultEthereumAddress(_address);
  //   }
  // };

  // const handleDeleteEthereumAddressClick = async (address: string) => {
  //   if (accessToken && isRetreivedAccessToken && isAuthenticated) {
  //     await deleteEthereumAddress(accessToken, address);
  //     const [_defaultEthereumaddress, _ethereumAddresses] = await Promise.all([getDefaultEthereumAddress(accessToken), getEthereumAddresses(accessToken)]);
  //     setEthereumAddresses(_ethereumAddresses);
  //     setDefaultEthereumAddress(_defaultEthereumaddress);
  //   }
  // };

  // const handleCancelOrderClick = async (orderId: string) => {
  //   if (accessToken && isRetreivedAccessToken && isAuthenticated && !isCancelingOrder[orderId]) {
  //     const _isCancelingOrder = _.clone(isCancelingOrder);
  //     _isCancelingOrder[orderId] = true;
  //     setIsCancelingOrder(_isCancelingOrder);
  //
  //     await cancelOrder(accessToken, orderId);
  //     // const _orders = await getOrders(accessToken);
  //     // setOrders(_.orderBy(_orders.orders, 'createdAt', 'desc'));
  //
  //     _isCancelingOrder[orderId] = false;
  //     setIsCancelingOrder(_isCancelingOrder);
  //   }
  // };

  // const EthereumAddressesView = ethereumAddresses?.map((ethereumAddress, i) => {
  //   return (
  //     <EthereumAddressView
  //       key={i}
  //       index={i}
  //       ethereumAddress={ethereumAddress}
  //       isDefaultEthereumAddress={ethereumAddress.address === defaultEthereumAddress?.address}
  //       handleSetAsDefaultEthereumAddressClick={handleSetAsDefaultEthereumAddressClick}
  //       handleDeleteEthereumAddressClick={handleDeleteEthereumAddressClick}
  //     />
  //   );
  // });

  useEffect(() => {
    if (scrolls.current) {
      if (scrolls.current.scrollWidth > scrolls.current.clientWidth) {
        scrolls.current.classList.add('cursor-grab');
      } else {
        scrolls.current.classList.remove('cursor-grab');
      }
    }

    window.addEventListener('resize', () => {
      // check horizontal overflow
      if (scrolls.current) {
        if (scrolls.current.scrollWidth > scrolls.current.clientWidth) {
          scrolls.current.classList.add('cursor-grab');
        } else {
          scrolls.current.classList.remove('cursor-grab');
        }
      }
    });
  }, []);

  if (scrolls.current) {
    if (scrolls.current.scrollWidth > scrolls.current.clientWidth) {
      scrolls.current.classList.add('cursor-grab');
    } else {
      // console.log(scrolls.current.scrollWidth, scrolls.current.clientWidth);
      scrolls.current.classList.remove('cursor-grab');
    }
  }

  let pos = { top: 0, left: 0, x: 0, y: 0 };
  let ele;

  const mouseMoveHandler = function (e) {
    if (scrolls.current) {
      ele = scrolls.current;
    }
    // How far the mouse has been moved
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    // Scroll the element
    ele.scrollTop = pos.top - dy;
    ele.scrollLeft = pos.left - dx;
  };
  const mouseUpHandler = function () {
    if (scrolls.current) {
      ele = scrolls.current;
    }
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    // ele.style.cursor = 'grab';
    ele.style.removeProperty('user-select');
  };
  const mouseDownHandler = function (e) {
    if (scrolls.current) {
      ele = scrolls.current;
    }
    // ele.style.cursor = 'grabbing';
    ele.style.userSelect = 'none';
    pos = {
      // The current scroll
      left: ele.scrollLeft,
      top: ele.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  useEffect(() => {
    const r = document.querySelector(':root') as HTMLElement;
    if (footer.current) {
      r.style.setProperty('--footer-h', `${footer.current.offsetHeight}px`);
    }
  });

  return (
    <>
      <motion.div
        variants={ani}
        animate={isOrderDetailModalOpen ? 'show' : 'hide'}
        onClick={() => setIsOrderDetailModalOpen(false)}
        className="fixed z-[-1] grid h-full w-full place-items-center overflow-hidden opacity-0 backdrop-blur-sm"
      >
        <div className="flex h-full w-full flex-col overflow-hidden rounded-none shadow-2xl lg:h-auto lg:w-[32rem] lg:rounded-lg">
          <div className="flex items-center justify-between bg-blue-500 px-8 py-7 text-xl font-semibold text-white shadow-2xl lg:rounded-t-lg">
            <span>Order information</span>
            <Icon
              icon="ep:close-bold"
              className="h-7 w-auto cursor-pointer"
              onClick={() => setIsOrderDetailModalOpen(false)}
            />
          </div>
          <div className="max-h-auto z-50 flex h-full flex-col gap-5 overflow-y-scroll bg-white px-8 py-9 lg:max-h-[500px] lg:rounded-b-lg">
            {/* {orderInModal && ( */}
            {/*  <> */}
            {/*    <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5"> */}
            {/*      <span className="flex-[2] text-lg font-medium">Order ID</span> */}
            {/*      <span className="lg:text-md text-xs">{orderInModal.id}</span> */}
            {/*    </div> */}
            {/*    <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5"> */}
            {/*      <span className="flex-[2] font-medium">Status</span> */}
            {/*      <span>{_.startCase(_.toLower(orderInModal.status))}</span> */}
            {/*    </div> */}
            {/*    <div className="border-b"></div> */}
            {/*    {orderInModal.domains.map((orderDomain, i) => ( */}
            {/*      <div key={i} className="flex flex-col gap-5"> */}
            {/*        <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5"> */}
            {/*          <span className="flex-[2] font-medium">Domain</span> */}
            {/*          <span>{orderDomain.fqdn}</span> */}
            {/*        </div> */}
            {/*        <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5"> */}
            {/*          <span className="flex-[2] font-medium">Owner Address</span> */}
            {/*          <span className="lg:text-md text-xs">{orderDomain.owner['address']}</span> */}
            {/*        </div> */}
            {/*        <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5"> */}
            {/*          <span className="flex-[2] font-medium">Price</span> */}
            {/*          <span>$ {orderDomain.price} USD</span> */}
            {/*        </div> */}
            {/*        <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5"> */}
            {/*          <span className="flex-[2] font-medium">Type</span> */}
            {/*          <span>{_.startCase(_.toLower(orderDomain.type))}</span> */}
            {/*        </div> */}
            {/*        <div className="flex flex-col items-start gap-0 lg:flex-row lg:items-center lg:gap-5"> */}
            {/*          <span className="flex-[2] font-medium">Durations</span> */}
            {/*          <span>{orderDomain.durations} years</span> */}
            {/*        </div> */}
            {/*        <div className="border-b"></div> */}
            {/*      </div> */}
            {/*    ))} */}
            {/*    {orderInModal.successNowPayments && ( */}
            {/*      <div className="flex gap-5"> */}
            {/*        <span className="flex-[2] font-medium">Payment Gateway</span> */}
            {/*        <span>Cryptocurrency</span> */}
            {/*      </div> */}
            {/*    )} */}
            {/*    {orderInModal.successCheckout && ( */}
            {/*      <div className="flex gap-5"> */}
            {/*        <span className="flex-[2] font-medium">Payment Gateway</span> */}
            {/*        <span>Credit Card</span> */}
            {/*      </div> */}
            {/*    )} */}
            {/*    <div className="flex gap-5"> */}
            {/*      <span className="flex-[2] font-medium">Amount</span> */}
            {/*      <span>$ {orderInModal.amount} USD</span> */}
            {/*    </div> */}
            {/*  </> */}
            {/* )} */}
          </div>
        </div>
      </motion.div>
      {/* <motion.div variants={ani} animate={change ? 'show' : 'hide'} className="fixed grid place-items-center w-full h-full backdrop-blur-sm opacity-0 z-[-1]">
      <div className="w-full lg:w-[24rem] h-full lg:h-[30rem] flex flex-col shadow-2xl">
        <div className="flex-[1] text-xl font-semibold px-8 py-7 lg:rounded-t-lg bg-blue-500 shadow-2xl text-white flex justify-between items-center">
          <span>Update Password</span>
          <Icon icon="ep:close-bold" className="cursor-pointer h-7 w-auto" onClick={() => setChange(!change)} />
        </div>
        <div className="flex flex-col px-8 py-7 gap-5 bg-white lg:rounded-b-lg">
          <div className="flex flex-col gap-1">
            <span>Current Password</span>
            <input className="border p-2 outline-none rounded-md"></input>
          </div>
          <div className="flex flex-col gap-1">
            <span>New Password</span>
            <input className="border p-2 outline-none rounded-md"></input>
          </div>
          <div className="flex flex-col gap-1">
            <span>Confirm New Password</span>
            <input className="border p-2 outline-none rounded-md"></input>
          </div>
          <button className="bg-blue-500 hover:bg-blue-600 duration-200 py-4 text-white rounded-md mt-4">Save</button>
        </div>
      </div>
    </motion.div> */}
      <motion.div
        variants={ani}
        animate={isConnectingToWeb3Wallet ? 'show' : 'hide'}
        className="fixed z-[-1] grid h-full w-full place-items-center opacity-0 backdrop-blur-sm"
      >
        <div className="flex h-full w-full flex-col lg:h-[40rem] lg:w-[27rem] ">
          <div className="flex flex-[1] items-center justify-between bg-blue-500 px-8 py-8 text-xl font-semibold text-white shadow-2xl lg:rounded-t-lg">
            <span>Connect Wallet</span>
            <Icon
              icon="ep:close-bold"
              className="h-7 w-auto cursor-pointer"
              onClick={() => setIsConnectingToWeb3Wallet(false)}
            />
          </div>
          <div className="relative grid flex-[12] grid-cols-1 grid-rows-2 gap-5 bg-white px-12 py-10 shadow-2xl lg:rounded-b-lg">
            <div
              className="flex h-full w-full cursor-pointer flex-col items-center justify-center gap-3 rounded-lg border font-semibold shadow-lg duration-200 hover:scale-110"
              // onClick={() => handleMetaMaskClick()}
            >
              <div className="grid h-12 place-items-center">
                <Icon className="h-12 w-auto" icon="logos:metamask-icon" />
              </div>
              MetaMask
            </div>
            <div
              className="flex w-full cursor-pointer flex-col items-center justify-center gap-3 rounded-lg border font-semibold shadow-lg duration-200 hover:scale-110"
              // onClick={() => handleWalletConnectClick()}
            >
              <div className="grid h-12 place-items-center">
                <svg
                  className="h-8 w-auto"
                  version="1.1"
                  baseProfile="basic"
                  id="Layer_1"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 387.6 237.6"
                >
                  <path
                    id="WalletConnect_00000073703063438220642730000002917717552236472496_"
                    fill="#3B99FC"
                    d="M79.4,46.4
  c63.2-61.9,165.7-61.9,228.9,0l7.6,7.4c3.2,3.1,3.2,8.1,0,11.2l-26,25.5c-1.6,1.5-4.1,1.5-5.7,0l-10.5-10.3
  c-44.1-43.2-115.6-43.2-159.7,0l-11.2,11c-1.6,1.5-4.1,1.5-5.7,0L71,65.8c-3.2-3.1-3.2-8.1,0-11.2L79.4,46.4z M362.1,99.1l23.2,22.7
  c3.2,3.1,3.2,8.1,0,11.2L280.8,235.3c-3.2,3.1-8.3,3.1-11.4,0c0,0,0,0,0,0l-74.1-72.6c-0.8-0.8-2.1-0.8-2.9,0c0,0,0,0,0,0
  l-74.1,72.6c-3.2,3.1-8.3,3.1-11.4,0c0,0,0,0,0,0L2.4,133c-3.2-3.1-3.2-8.1,0-11.2l23.2-22.7c3.2-3.1,8.3-3.1,11.4,0l74.1,72.6
  c0.8,0.8,2.1,0.8,2.9,0c0,0,0,0,0,0l74.1-72.6c3.2-3.1,8.3-3.1,11.4,0c0,0,0,0,0,0l74.1,72.6c0.8,0.8,2.1,0.8,2.9,0l74.1-72.6
  C353.8,96,358.9,96,362.1,99.1z"
                  />
                </svg>
              </div>
              <span>WalletConnect</span>
            </div>
          </div>
          <div className="flex"></div>
        </div>
      </motion.div>
      <Nav title="Friends" />
      <div className="flex h-full flex-1 flex-col overflow-y-auto p-10 pb-0 pt-6">
        <div className="account-r-con flex w-full min-w-[897px] flex-1 flex-col gap-2 lg:gap-8">
          {customAddWalletErrorMessage && (
            <div
              className="relative rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
              role="alert"
            >
              <span className="block sm:inline">
                {customAddWalletErrorMessage}
              </span>
              <span
                className="absolute bottom-0 right-0 top-0 px-4 py-3"
                onClick={() => setCustomAddWalletErrorMessage(undefined)}
              >
                <svg
                  className="h-6 w-6 fill-current text-red-500"
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
            </div>
          )}
          {isDuplicatedBindingEthereumAddress && (
            <div
              className="relative rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
              role="alert"
            >
              <strong className="font-bold">Duplicated Address!</strong>
              <span className="block sm:inline">
                {' '}
                You have already bind this address into your account.
              </span>
              <span
                className="absolute bottom-0 right-0 top-0 px-4 py-3"
                onClick={() => setIsDuplicatedBindingEthereumAddress(false)}
              >
                <svg
                  className="h-6 w-6 fill-current text-red-500"
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
            </div>
          )}
          <div className="text-xl ">Choose the domain</div>
          <div>
            <div className="domains-grid-con sms relative pb-2 pr-10">
              <div className="box-shadow relative flex max-w-[325px] flex-col items-center justify-center gap-2 rounded-xl border bg-white p-10 py-6">
                <Icon
                  icon="mi:options-vertical"
                  className="absolute right-2 top-4  h-10 w-auto"
                />
                <Icon
                  icon="cryptocurrency:matic"
                  className="text-5xl text-purple-500"
                />
                <text className="mt-2 text-lg">asd</text>
                <div className="rounded-md border p-5 py-3 font-medium">
                  .meta
                </div>
              </div>
              <div className="box-shadow relative flex max-w-[325px] flex-col items-center justify-center gap-2 rounded-xl border bg-white p-10 py-6">
                <Icon
                  icon="mi:options-vertical"
                  className="absolute right-2 top-4  h-10 w-auto"
                />
                <Icon
                  icon="cryptocurrency:matic"
                  className="text-5xl text-purple-500"
                />
                <text className="mt-2 text-lg">asd</text>
                <div className="rounded-md border p-5 py-3 font-medium">
                  .meta
                </div>
              </div>
              <div className="box-shadow relative flex max-w-[325px] flex-col items-center justify-center gap-2 rounded-xl border bg-white p-10 py-6">
                <Icon
                  icon="mi:options-vertical"
                  className="absolute right-2 top-4  h-10 w-auto"
                />
                <Icon
                  icon="cryptocurrency:matic"
                  className="text-5xl text-purple-500"
                />
                <text className="mt-2 text-lg">asd</text>
                <div className="rounded-md border p-5 py-3 font-medium">
                  .meta
                </div>
              </div>
              <div className="box-shadow relative flex max-w-[325px] flex-col items-center justify-center gap-2 rounded-xl border bg-white p-10 py-6">
                <Icon
                  icon="mi:options-vertical"
                  className="absolute right-2 top-4  h-10 w-auto"
                />
                <Icon
                  icon="cryptocurrency:matic"
                  className="text-5xl text-purple-500"
                />
                <text className="mt-2 text-lg">asd</text>
                <div className="rounded-md border p-5 py-3 font-medium">
                  .meta
                </div>
              </div>
            </div>
            <button className="mt-5 font-semibold text-blue-900">
              SEE ALL Friends
            </button>
          </div>
          <section className="flex w-full flex-1 flex-col gap-5 overflow-hidden">
            <div>
              <span className="text-lg">heyhye.meta</span>{' '}
              <button className="theme text ml-5 rounded-md border px-8 py-2 font-semibold">
                Add Friends
              </button>
            </div>
            <div className="domains-grid-con w-full pb-10">
              <div className="relative flex max-w-[325px] flex-col items-center justify-center gap-2 rounded-xl border bg-white p-10 py-6">
                <Icon
                  icon="cryptocurrency:matic"
                  className="text-5xl text-purple-500"
                />
                <text className="mt-2 text-lg">asd</text>
                <div className="rounded-md border p-5 py-3 font-medium">
                  .meta
                </div>
              </div>
              <div className="relative flex max-w-[325px] flex-col items-center justify-center gap-2 rounded-xl border bg-white p-10 py-6">
                <Icon
                  icon="cryptocurrency:matic"
                  className="text-5xl text-purple-500"
                />
                <text className="mt-2 text-lg">asd</text>
                <div className="rounded-md border p-5 py-3 font-medium">
                  .meta
                </div>
              </div>
              <div className="relative flex max-w-[325px] flex-col items-center justify-center gap-2 rounded-xl border bg-white p-10 py-6">
                <Icon
                  icon="cryptocurrency:matic"
                  className="text-5xl text-purple-500"
                />
                <text className="mt-2 text-lg">asd</text>
                <div className="rounded-md border p-5 py-3 font-medium">
                  .meta
                </div>
              </div>
              <div className="relative flex max-w-[325px] flex-col items-center justify-center gap-2 rounded-xl border bg-white p-10 py-6">
                <Icon
                  icon="cryptocurrency:matic"
                  className="text-5xl text-purple-500"
                />
                <text className="mt-2 text-lg">asd</text>
                <div className="rounded-md border p-5 py-3 font-medium">
                  .meta
                </div>
              </div>
              <div className="relative flex max-w-[325px] flex-col items-center justify-center gap-2 rounded-xl border bg-white p-10 py-6">
                <Icon
                  icon="cryptocurrency:matic"
                  className="text-5xl text-purple-500"
                />
                <text className="mt-2 text-lg">asd</text>
                <div className="rounded-md border p-5 py-3 font-medium">
                  .meta
                </div>
              </div>
              <div className="relative flex max-w-[325px] flex-col items-center justify-center gap-2 rounded-xl border bg-white p-10 py-6">
                <Icon
                  icon="cryptocurrency:matic"
                  className="text-5xl text-purple-500"
                />
                <text className="mt-2 text-lg">asd</text>
                <div className="rounded-md border p-5 py-3 font-medium">
                  .meta
                </div>
              </div>
              <div className="relative flex max-w-[325px] flex-col items-center justify-center gap-2 rounded-xl border bg-white p-10 py-6">
                <Icon
                  icon="cryptocurrency:matic"
                  className="text-5xl text-purple-500"
                />
                <text className="mt-2 text-lg">asd</text>
                <div className="rounded-md border p-5 py-3 font-medium">
                  .meta
                </div>
              </div>
              <div className="relative flex max-w-[325px] flex-col items-center justify-center gap-2 rounded-xl border bg-white p-10 py-6">
                <Icon
                  icon="cryptocurrency:matic"
                  className="text-5xl text-purple-500"
                />
                <text className="mt-2 text-lg">asd</text>
                <div className="rounded-md border p-5 py-3 font-medium">
                  .meta
                </div>
              </div>
              <div className="relative flex max-w-[325px] flex-col items-center justify-center gap-2 rounded-xl border bg-white p-10 py-6">
                <Icon
                  icon="cryptocurrency:matic"
                  className="text-5xl text-purple-500"
                />
                <text className="mt-2 text-lg">asd</text>
                <div className="rounded-md border p-5 py-3 font-medium">
                  .meta
                </div>
              </div>
              <div className="relative flex max-w-[325px] flex-col items-center justify-center gap-2 rounded-xl border bg-white p-10 py-6">
                <Icon
                  icon="cryptocurrency:matic"
                  className="text-5xl text-purple-500"
                />
                <text className="mt-2 text-lg">asd</text>
                <div className="rounded-md border p-5 py-3 font-medium">
                  .meta
                </div>
              </div>
              <div className="relative flex max-w-[325px] flex-col items-center justify-center gap-2 rounded-xl border bg-white p-10 py-6">
                <Icon
                  icon="cryptocurrency:matic"
                  className="text-5xl text-purple-500"
                />
                <text className="mt-2 text-lg">asd</text>
                <div className="rounded-md border p-5 py-3 font-medium">
                  .meta
                </div>
              </div>
              <div className="relative flex max-w-[325px] flex-col items-center justify-center gap-2 rounded-xl border bg-white p-10 py-6">
                <Icon
                  icon="cryptocurrency:matic"
                  className="text-5xl text-purple-500"
                />
                <text className="mt-2 text-lg">asd</text>
                <div className="rounded-md border p-5 py-3 font-medium">
                  .meta
                </div>
              </div>
              <div className="relative flex max-w-[325px] flex-col items-center justify-center gap-2 rounded-xl border bg-white p-10 py-6">
                <Icon
                  icon="cryptocurrency:matic"
                  className="text-5xl text-purple-500"
                />
                <text className="mt-2 text-lg">asd</text>
                <div className="rounded-md border p-5 py-3 font-medium">
                  .meta
                </div>
              </div>
              <div className="relative flex max-w-[325px] flex-col items-center justify-center gap-2 rounded-xl border bg-white p-10 py-6">
                <Icon
                  icon="cryptocurrency:matic"
                  className="text-5xl text-purple-500"
                />
                <text className="mt-2 text-lg">asd</text>
                <div className="rounded-md border p-5 py-3 font-medium">
                  .meta
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      {/* <Footer /> */}
    </>
  );
};

export default FriendsPage;
