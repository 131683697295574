import { GlobalStateContext } from '@/utils/context/GlobalStateContext';
import { formatsByName } from '@ensdomains/address-encoder';
import { motion } from 'framer-motion';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LookupResult from '@/components/Look-up/LookupResult';
import Nav from '@/components/Nav/Nav';
import Select from 'react-select';
import img from '@/static/lookup.svg';

const recordTypeOptions = [
  { value: 'all', label: 'All' },
  { value: 'address', label: 'Address' },
  // {value:'NFT',label:'NFT'}
  { value: 'textRecord', label: 'Text Record' },
  // { value: "content", label: "Content" },
];
const textRecordTypeOptions = [
  { value: 'email', label: 'Email' },
  { value: 'url', label: 'Url' },
  { value: 'avatar', label: 'Avatar' },
  { value: 'description', label: 'Description' },
  { value: 'notice', label: 'Notice' },
  { value: 'keywords', label: 'Keywords' },
  { value: 'discord', label: 'Discord' },
  { value: 'github', label: 'Github' },
  { value: 'reddit', label: 'Reddit' },
  { value: 'twitter', label: 'Twitter' },
  { value: 'telegram', label: 'Telegram' },
  { value: 'delegate', label: 'Delegate' },
];
interface IRecord {
  type: string;
  coinType: string;
  address: string;
}
interface ITextRecord {
  type: string;
  textType: string;
  address: string;
}
const recordAddressCategoryOptions = Object.keys(formatsByName)
  .filter((c) => !c.match(/_LEGACY/))
  .sort()
  .map((v) => ({ value: v, label: v }));
const LookupPage = () => {
  type OptionType = {
    value: any;
    label: any;
  };
  const { tlds } = useContext(GlobalStateContext);
  const [currentSelectedRecordCoinType, setCurrentSelectedRecordCoinType] =
    useState<string | undefined>('BTC');
  const [currentSelectedRecordTextype, setCurrentSelectedRecordTextype] =
    useState<string | undefined>('email');
  const [currentSelectedRecordType, setCurrentSelectedRecordType] = useState<
    string | undefined
  >('address');
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const input = useRef<HTMLInputElement>(null);
  const [site, setSite] = useState(false);
  const regex = new RegExp(/^[a-zA-Z0-9\-_]{5,128}$/);
  const [tldOptions, setTldOptions] = useState<OptionType[]>([]);
  const [selectedTldOption, setSelectedTldOption] = useState<
    OptionType | undefined
  >(undefined);
  const location = useLocation();
  const [inputDomain, setInputDomain] = useState('');
  const [page, setPage] = useState(false);

  useEffect(() => {
    const _tldOptions = tlds.map((_tld) => ({
      value: _tld.name,
      label: `.${_tld.name}`,
    }));
    setTldOptions(_tldOptions);
    const metaTld = tlds.find((_tld) => _tld.name === 'meta');
    if (metaTld)
      setSelectedTldOption({ value: metaTld.name, label: `.${metaTld.name}` });
  }, [tlds]);

  useEffect(() => {
    if (location.search !== '' && !site) {
      setSite(true);
      setTimeout(() => {
        const url = window.location.href;
        const _url = url.split(`${window.location.origin}`)[1];
        navigate(_url);
      }, 1000);
    }
    // exec();
  }, [input, location, navigate, site]);

  const customStyles = {
    control: (base) => ({
      ...base,
      width: '100%',
      height: '100%',
      border: '2px solid rgb(229, 231, 235)',
      outline: 'none',
      padding: '0.375rem 0',
      borderRadius: '0.5rem',
    }),
  };

  const handleChange = (options) => {
    setSelectedTldOption(options);
  };
  function handleOnclick() {
    setPage(true);
  }
  if (page === false) {
    return (
      <div className="flex flex-1 flex-col overflow-hidden">
        {/* <div className="main bg lookup-bg absolute top-0 left-0 flex h-full w-full flex-col justify-end"></div> */}
        <Nav home title="Look Up" />
        <div className="m-auto grid h-full w-full place-items-center overflow-y-auto">
          <div className="flex w-full flex-1 flex-col items-center justify-center px-12 xl:justify-center xl:px-0">
            <img
              src={img}
              className="mx-auto !w-[calc(18vw+5rem)] min-w-[400px]"
              alt=""
            />
            <div className="flex w-[725px] flex-col place-content-center rounded-3xl border bg-white/80 p-12 py-8 backdrop-blur-sm">
              <div className="relative">
                <h1 className="text-center text-xl font-medium">
                  Enter Domain and Select Record Type below
                </h1>
              </div>
              <div className="relative mt-10 flex w-full flex-col gap-5 rounded-md text-xl md:h-14 md:flex-row md:gap-5">
                <div className="relative h-full w-full flex-col">
                  <input
                    ref={input}
                    onChange={(e) => {
                      setError(!regex.test(e.currentTarget.value));
                      setInputDomain(e.target.value);
                    }}
                    className="main input md:text-md h-full w-full rounded-lg border-2 px-10 py-3.5 text-sm outline-blue-400 md:block 2xl:px-12"
                    type="text"
                    placeholder="Enter Valid Domain"
                  />
                  <svg
                    className="absolute left-3 top-[16px] h-5 w-5 fill-current text-3xl text-gray-300 2xl:left-5 2xl:top-[18px]"
                    x="0px"
                    y="0px"
                    viewBox="0 0 56.966 56.966"
                  >
                    <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                  </svg>
                </div>
                <div className="search-con z-[80] flex h-full items-center rounded-md text-base outline-none md:block">
                  {tldOptions && (
                    <Select
                      options={tldOptions}
                      value={selectedTldOption}
                      styles={customStyles}
                      onChange={handleChange}
                      className="h-full w-full text-center "
                    ></Select>
                  )}
                </div>
              </div>
              <div className="text-md relative mb-3 mt-8 flex  w-full flex-col gap-5  text-center md:flex-row">
                <label className="py-4" htmlFor="">
                  Record Type:
                </label>
                {/* <button
                className={`rounded border-2 px-10 py-2 ${
                  currentSelectedRecordType === recordTypeOptions[0].value ? 'border-[#93278f] bg-[#93278f] text-white' : 'border-[#93278f] text-[#93278f]'
                }`}
                onClick={() => setCurrentSelectedRecordType(recordTypeOptions[0].value)}
              >
                All
              </button> */}
                <button
                  data-on={
                    currentSelectedRecordType === recordTypeOptions[1].value
                  }
                  className={`gradient-box w-36 rounded px-6 py-2 font-medium ${
                    currentSelectedRecordType === recordTypeOptions[1].value
                      ? ' text-white'
                      : 'text-black'
                  }`}
                  onClick={() =>
                    setCurrentSelectedRecordType(recordTypeOptions[1].value)
                  }
                >
                  Address
                </button>{' '}
                <button
                  data-on={
                    currentSelectedRecordType === recordTypeOptions[2].value
                  }
                  className={`gradient-box w-36 px-6 py-2 font-medium ${
                    currentSelectedRecordType === recordTypeOptions[2].value
                      ? ' bg-[#93278f] text-white'
                      : 'text-black'
                  }`}
                  onClick={() =>
                    setCurrentSelectedRecordType(recordTypeOptions[2].value)
                  }
                >
                  Text Record
                </button>
              </div>
              <motion.div
                animate={
                  currentSelectedRecordType !== recordTypeOptions[0].value
                    ? 'hidden'
                    : 'show'
                }
                className={`relative mb-3 mt-3 flex h-full w-full flex-col gap-5 text-center text-sm md:flex-row `}
              >
                {currentSelectedRecordType === recordTypeOptions[1].value && (
                  <div className="flex flex-col items-center md:flex-row ">
                    <label className="py-2 pr-4" htmlFor="">
                      Record Subtype:
                    </label>
                    <div className="relative flex py-2">
                      <Select
                        defaultValue={recordAddressCategoryOptions.find(
                          (v) => v.value === 'BTC',
                        )}
                        options={recordAddressCategoryOptions}
                        onChange={(e) =>
                          setCurrentSelectedRecordCoinType(e?.value)
                        }
                        className={`fixed left-0 top-0 h-full w-full text-center `}
                      />
                    </div>
                  </div>
                )}
                {currentSelectedRecordType === recordTypeOptions[2].value && (
                  <div className="flex items-center">
                    <label className="py-2 pr-4" htmlFor="">
                      Record Subtype:
                    </label>
                    <div className="relative flex py-2">
                      <Select
                        defaultValue={textRecordTypeOptions[0]}
                        options={textRecordTypeOptions}
                        onChange={(e) =>
                          setCurrentSelectedRecordTextype(e?.value)
                        }
                        className="fixed left-0 top-0 z-[80] h-full w-full text-center"
                      />
                    </div>
                  </div>
                )}
              </motion.div>
            </div>
            <motion.div className="relative mx-auto mb-12 mt-5 text-right md:text-center">
              <div
                className="gradient-box gradient-shadow grid cursor-pointer place-items-center text-black"
                onClick={() => {
                  handleOnclick();
                }}
              >
                <div className="rounded-lg px-6 py-4 font-medium text-black">
                  Look up Domain
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    );
  }
  const subType =
    currentSelectedRecordType === 'address'
      ? currentSelectedRecordCoinType
      : currentSelectedRecordTextype;
  if (subType && currentSelectedRecordType && selectedTldOption) {
    return (
      <LookupResult
        subType={subType}
        fqdn={`${inputDomain}.${selectedTldOption.value}`}
        recordCoinType={currentSelectedRecordType}
      ></LookupResult>
    );
  }
  return <div>Not Found</div>;
};
export default LookupPage;
