import { createTheme } from '@mui/material';

const ednsTheme = createTheme({
  palette: {
    primary: {
      main: '#bb29bb',
    },
  },
});

export { ednsTheme };
