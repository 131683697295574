import _ from 'lodash';

interface IStatusProps {
  text: string;
  textColor: string;
  pointColor: string;
}
function StatusComponent(props: IStatusProps) {
  const { text, textColor, pointColor } = props;
  return (
    <div
      className={`flex shrink-0 items-center justify-between gap-2 font-medium ${textColor}`}
    >
      <div className={`aspect-square h-3 rounded-full ${pointColor}`}></div>
      {text}
    </div>
  );
}

function Status({ status }: { status: string }) {
  switch (status) {
    case 'created':
      return (
        <StatusComponent
          text="Created"
          textColor="text-blue-900"
          pointColor="bg-blue-900"
        />
      );
    case 'pending_payment':
      return (
        <StatusComponent
          text="Pending Payment"
          textColor="text-blue-900"
          pointColor="bg-blue-900"
        />
      );
    case 'paid':
      return (
        <StatusComponent
          text="Paid"
          textColor="text-orange-500"
          pointColor="bg-orange-500"
        />
      );
    case 'in_queue':
      return (
        <StatusComponent
          text="In Queue"
          textColor="text-orange-500"
          pointColor="bg-orange-500"
        />
      );
    case 'processing':
      return (
        <StatusComponent
          text="Processing"
          textColor="text-orange-500"
          pointColor="bg-orange-500"
        />
      );
    case 'completed':
      return (
        <StatusComponent
          text="Completed"
          textColor="text-green-600"
          pointColor="bg-green-600"
        />
      );
    case 'expired':
      return (
        <StatusComponent
          text="Expired"
          textColor="text-red-600"
          pointColor="bg-red-600"
        />
      );
    case 'canceled':
      return (
        <StatusComponent
          text="Canceled"
          textColor="text-red-600"
          pointColor="bg-red-600"
        />
      );
    case 'error':
      return (
        <StatusComponent
          text="Error"
          textColor="text-red-600"
          pointColor="bg-red-600"
        />
      );
    default:
      return (
        <StatusComponent
          text={_.startCase(status)}
          textColor="text-red-600"
          pointColor="bg-red-600"
        />
      );
  }
}
export { Status };
