import React from 'react';

interface IPaymentCardProps {
  name: string;
  icon: any;
  point: string[];
  isSelected: boolean;
  children?: React.ReactNode;
  amount: number;
  orderId: string;
  onCreate: (orderId: string) => Promise<void>;
  disable: boolean;
}
const PaymentCard = (props: IPaymentCardProps) => {
  const { onCreate, orderId, disable } = props;
  return (
    <div
      className={`flex  w-full flex-col rounded-lg bg-violet-50 ${
        props.isSelected && 'border '
      } ${disable && 'grayscale'}`}
    >
      {/*Header*/}
      <div className="flex flex-row items-center gap-4 rounded-lg border bg-purple-100 px-8 py-4">
        <div className="payment-icon rounded-full p-2">{props.icon}</div>
        <span className="text-xl">{props.name}</span>
      </div>
      {/*Header*/}
      {/*Body*/}
      <div className="flex h-full flex-col justify-start gap-4 px-8 py-4">
        {/*Price*/}
        <div className="flex flex-row items-center gap-2">
          <span className="text-4xl">${props.amount}</span>
          <div className="flex flex-col ">
            <p className="self-baseline text-sm font-light text-gray-600">
              USD
            </p>
            <p className="text-sm font-light text-gray-600">Total</p>
          </div>
        </div>
        {/*Price*/}
        {/*Point*/}
        <ul className="payment-ul list-inside text-start">
          {props.point.map((p) => (
            <li className="font-medium">{p}</li>
          ))}
        </ul>
        {/*Point*/}
        {/*Crypto Icon*/}
        {props.children}
        {/*Crypto Icon*/}
        {/*Check Out Button*/}

        <div className="flex h-full items-end">
          <button
            className="btn-now mx-auto mt-8 flex w-[100%] w-full justify-center rounded-md py-5 text-xl font-medium text-white opacity-100 outline-none"
            disabled={disable}
            onClick={async () => {
              if (disable) {
                return;
              }
              await onCreate(orderId);
            }}
          >
            Pay Now
          </button>
        </div>
        {/*Check Out Button*/}
      </div>
      {/*Body*/}
    </div>
  );
};

export default PaymentCard;
