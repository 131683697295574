import { DateTime } from 'luxon';
import { Icon } from '@iconify/react';
import { Menu, MenuItem } from '@mui/material';
import { OrderStatus } from '@/utils/interface';
import { cancelOrder } from '@/api/omni/Order/cancelOrder';
import { useNavigate } from 'react-router-dom';
import React, { useContext, useState } from 'react';
import _ from 'lodash';

import { GlobalStateContext } from '@/utils/context/GlobalStateContext';
import { IOrder } from '@/api/omni/Order/order.interface';
import { Status } from '@/components/common/status';
import { downloadInvoice } from '@/api/omni/Invoice/getInvoice';
import { getReceipt } from '@/api/omni/Receipt/getReceipt';
import { useAxios } from '@/utils/axios/AxiosProvider';

interface OrderStatusProps {
  order: IOrder;
  openModal: (e: IOrder | undefined) => void;
  option: boolean;
  setOptionCallback: (orderId: string) => void;
}
interface OrderOptionProps {
  callback?: (e?) => void | Promise<void>;
  icon: string;
  children?: React.ReactNode;
}
interface DetailsColumnProps {
  tag: string;
  size?: number;
  children?: React.ReactNode;
}

function OptionButton(props: OrderOptionProps) {
  const { callback, icon, children } = props;
  return (
    <MenuItem>
      <div
        onClick={callback}
        className="flex cursor-pointer items-center gap-2.5 whitespace-nowrap"
      >
        <div className="grid min-w-[30px] place-items-center">
          <Icon
            icon={icon}
            className="h-7 w-auto cursor-pointer text-blue-500 duration-200 hover:scale-110"
          />
        </div>
        <p>{children}</p>
      </div>
    </MenuItem>
  );
}

function DetailsColumn(props: DetailsColumnProps) {
  const { children, tag, size = 1 } = props;
  return (
    <div
      className={`flex flex-[${size}] w-full max-w-full items-center justify-between space-x-4 overflow-hidden`}
    >
      <span className="block text-lg font-medium md:hidden">{tag}</span>
      <span className="shrink-0 truncate">{children}</span>
    </div>
  );
}

function OrderDetails(props: OrderStatusProps) {
  const { order, openModal, setOptionCallback } = props;
  // const [option, setOption] = useState<boolean>(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isOpenMenu = Boolean(anchorEl);

  const [isCancelingOrder, setIsCancelingOrder] = useState<{
    [key: string]: boolean;
  }>({});
  const navigate = useNavigate();

  const { instance } = useAxios();
  const { updateOrders } = useContext(GlobalStateContext);

  const handleCancelOrderClick = async (orderId: string) => {
    if (!isCancelingOrder[orderId]) {
      const _isCancelingOrder = _.clone(isCancelingOrder);
      _isCancelingOrder[orderId] = true;
      setIsCancelingOrder(_isCancelingOrder);

      await cancelOrder(instance)(orderId);
      await updateOrders();

      _isCancelingOrder[orderId] = false;
      setIsCancelingOrder(_isCancelingOrder);
    }
  };
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    console.log('open menu');
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className="w-full rounded-md bg-white even:bg-[#FAF3FF]">
      <div className="flex h-full w-full flex-col items-center gap-5 px-6 py-6 md:flex-row lg:py-5">
        <DetailsColumn tag="ID" size={3}>
          {order.id}
        </DetailsColumn>
        <DetailsColumn tag="Status" size={1}>
          ${order.total}
        </DetailsColumn>
        <DetailsColumn tag="Status" size={2}>
          <Status status={order.status} />
        </DetailsColumn>
        <DetailsColumn tag="Created on" size={1}>
          {DateTime.fromISO(order.createdAt.toString()).toRelative({
            locale: 'en',
          })}
        </DetailsColumn>
        <div className="relative">
          <Icon
            onClick={(e: any) => {
              setOptionCallback(order.id);
              handleOpenMenu(e);
            }}
            icon="mi:options-horizontal"
            className="btn-order-option-trigger h-6 w-6 cursor-pointer"
          />
          <Menu anchorEl={anchorEl} open={isOpenMenu} onClose={handleMenuClose}>
            <div
            // data-enable={props.option}
            // className="props.items-center btn-order-option absolute right-0 z-50 mt-6 flex flex-[1] flex-col justify-between gap-2 rounded-xl border border-[#f0f2f6] bg-white p-3 lg:mt-0"
            >
              <OptionButton
                icon="fe:search"
                callback={() => {
                  if (openModal) {
                    openModal(order);
                  }
                  handleMenuClose();
                }}
              >
                Order Details
              </OptionButton>

              {order.status === OrderStatus.PENDING_PAYMENT && (
                <OptionButton
                  icon="fluent:payment-16-regular"
                  callback={() => {
                    if (order.status === OrderStatus.PENDING_PAYMENT) {
                      navigate(`/checkout?id=${order.id}`);
                    }
                    handleMenuClose();
                  }}
                >
                  Checkout
                </OptionButton>
              )}
              {order.status === OrderStatus.PENDING_PAYMENT && (
                <OptionButton
                  icon="gridicons:cross"
                  callback={() => {
                    handleCancelOrderClick(order.id);
                    handleMenuClose();
                  }}
                >
                  Cancel Order
                </OptionButton>
              )}

              <OptionButton
                icon="tabler:file-invoice"
                callback={async () => {
                  await downloadInvoice(instance)(order.id);
                  handleMenuClose();
                }}
              >
                Invoice
              </OptionButton>
              {(order.status === OrderStatus.COMPLETED ||
                order.status === OrderStatus.PAID ||
                order.status === OrderStatus.IN_QUEUE ||
                order.status === OrderStatus.PROCESSING) && (
                <OptionButton
                  icon="bi:receipt"
                  callback={async () => {
                    await getReceipt(instance)(order.id);
                    handleMenuClose();
                  }}
                >
                  Get Receipt
                </OptionButton>
              )}
            </div>
          </Menu>
        </div>
      </div>
    </div>
  );
}

export { OrderDetails };
