import { BlockchainButton } from '../Blockchain/BlockchainButton';
import {
  CrossChainContext,
  SnackState,
  SwappingState,
} from '@/utils/context/CrossChainContext';
import { IBlockchain } from '@/api/omni/Blockchain/blockchain.interface';
import { MyDomainContext } from '@/utils/context/MyDomainContext';
import { SectionTitle } from '../common/SectionTitle';
import { StepTitle } from './StepTitle';
import { getChainIcon } from '../web3/getChainIcon';
import { useContext } from 'react';

function Step1() {
  const { selectedDomain: domain } = useContext(MyDomainContext);
  // const [isCheckedLocalStorage, setIsCheckedLocalStorage] = useState(false);
  const {
    selectBlockChain,
    swappingState,
    setSelectBlockChain,
    setSwappingState,
    setIsOpenSnackbar,
    setSnackMessage,
    setSnackState,
    tld,
  } = useContext(CrossChainContext);

  const handleOnClick = () => {
    if (selectBlockChain) {
      setSwappingState(SwappingState.Blockchain);
      setSnackState(SnackState.Success);
      setSnackMessage('Blockchain selected');
    } else {
      setIsOpenSnackbar(true);
      setSnackState(SnackState.Error);
      setSnackMessage('Please select a blockchain');
    }
    setIsOpenSnackbar(true);
  };

  if (!domain || swappingState !== SwappingState.Init) {
    return <></>;
  }

  return (
    <>
      <StepTitle
        step={1}
        title="Select Chain"
        description="Enable the transfer of the universal domain to other blockchain networks"
      />
      <div className="w-full border"></div>
      <div className="flex flex-col space-y-4">
        <SectionTitle title="Network" size="sm" />
        <div className="grid grid-cols-2 items-center sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 2xl:grid-cols-8">
          {tld
            ?.blockchains!.filter(
              (block: IBlockchain) =>
                block.chainId !== domain!.blockchains[0]!.chainId,
            )
            .map((blockchain) => (
              <BlockchainButton
                blockchain={blockchain}
                setSelected={(e) => setSelectBlockChain(e)}
                selected={selectBlockChain!}
                key={blockchain.id}
              />
            ))}
        </div>
        <div className="flex items-center space-x-4">
          <span> Current Domain: {domain?.fqdn} </span>
          {domain && getChainIcon(+domain!.blockchains[0].chainId)}
        </div>
      </div>

      <div className="w-full border"></div>

      <div className="mt-5 text-right">
        <button
          className="mt-2 w-full rounded-xl border-2 border-[#93278f] px-8 py-4 font-medium text-[#93278f] duration-200 hover:bg-[#93278f] hover:text-white"
          onClick={handleOnClick}
          type="submit"
          // onClick={(x) => setTransferDomain(false)}
        >
          Select Bridge
        </button>
      </div>
    </>
  );
  // }
}

export { Step1 };
