import { motion } from 'framer-motion';
import Logo from '@/components/common/Logo';

const Loading = () => {
  return (
    <div className="main-bg flex h-screen w-screen items-center justify-center">
      <motion.div
        animate={{
          scale: [1, 2, 2, 1],
          rotateY: [0, 0, 270, 0],
        }}
        transition={{
          duration: 2,
          ease: 'easeInOut',
          times: [0, 0.2, 0.5, 1],
          repeat: Infinity,
          repeatDelay: 1,
        }}
      >
        <Logo />
      </motion.div>
    </div>
  );
};
export { Loading };
