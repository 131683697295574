import { Bridge } from './Bridge';
import {
  CrossChainContext,
  SnackState,
  SwappingState,
} from '@/utils/context/CrossChainContext';
import { CrossChainProvider } from '@/components/web3/Web3.interface';
import { Icon } from '@iconify/react';
import { MyDomainContext } from '@/utils/context/MyDomainContext';
import { SectionTitle } from '../common/SectionTitle';
import { StepTitle } from './StepTitle';
import { Web3Functions } from '../web3/Web3';
import { useContext, useState } from 'react';

function Step2() {
  const { selectedDomain: domain } = useContext(MyDomainContext);
  const [loading, setLoading] = useState(false);
  const {
    selectBlockChain: toChain,
    setSwappingState,
    swappingState,
    selectedBridge,
    setSelectedBridge,
    setIsOpenSnackbar,
    setSnackState,
    setSnackMessage,
    setStep2Payload,
  } = useContext(CrossChainContext);

  const handleOnClick = async () => {
    if (loading === true) {
      return;
    }
    console.log(selectedBridge);
    if (selectedBridge === undefined) {
      setSnackState(SnackState.Error);
      setSnackMessage('Please select a bridge');
      setIsOpenSnackbar(true);
      return;
    }

    try {
      setLoading(true);
      const useWeb3 = new Web3Functions(
        +domain!.blockchains[0].chainId,
        undefined,
        '@',
        domain!.name,
        domain!.tld.name,
      );

      console.log(+toChain!.chainId!);
      const payload = await useWeb3.crossChainRequest(
        toChain!.chainId!,
        domain!.owner.address,
        selectedBridge,
      );
      if (payload) {
        localStorage.setItem(`${domain!.fqdn}:chain`, toChain!.chainId);
        localStorage.setItem(
          `${domain!.fqdn}:payload`,
          JSON.stringify(payload),
        );
        setStep2Payload(payload);
        setIsOpenSnackbar(true);
        setSwappingState(SwappingState.Listener);
        setSnackState(SnackState.Success);
        setSnackMessage('Signed Request');
      }
    } catch (e) {
      console.log(e);
      setSnackState(SnackState.Error);
      setSnackMessage((e as Error).message);
      setIsOpenSnackbar(true);
      setLoading(false);
    }
  };

  if (!domain || !toChain || swappingState !== SwappingState.Blockchain) {
    return <></>;
  }
  return (
    <>
      <StepTitle
        step={2}
        title="Select Bridge"
        description="Please choose cross-chain intermedio"
      />
      <div className="w-full border"></div>
      <div className="flex flex-col space-y-4">
        <SectionTitle title="Bridge" size="xl" />
      </div>

      <div className="w-full items-center space-y-4">
        <Bridge
          name="LAYERZERO"
          onClick={() => setSelectedBridge(CrossChainProvider.LAYERZERO)}
          selected={selectedBridge === CrossChainProvider.LAYERZERO}
        />
        <Bridge
          name="MULTICHAIN"
          onClick={() => setSelectedBridge(CrossChainProvider.MULTICHAIN)}
          selected={selectedBridge === CrossChainProvider.MULTICHAIN}
          disable
        />
        <Bridge
          name="ROUTER_PROTOCOL"
          onClick={() => setSelectedBridge(CrossChainProvider.ROUTER_PROTOCOL)}
          selected={selectedBridge === CrossChainProvider.ROUTER_PROTOCOL}
          disable
        />
        <Bridge
          name="NOMAD"
          onClick={() => setSelectedBridge(CrossChainProvider.NOMAD)}
          selected={selectedBridge === CrossChainProvider.NOMAD}
          disable
        />
      </div>

      <div className="mt-5 text-right">
        <button
          className="mt-2 w-full rounded-xl border-2 border-[#93278f] px-8 py-4 font-medium text-[#93278f] duration-200 hover:bg-[#93278f] hover:text-white"
          type="submit"
          disabled={loading ? true : false}
          onClick={handleOnClick}
        >
          {loading ? (
            <div>
              <div className="flex flex-col ">
                <div className="grid h-full w-full place-items-center">
                  <Icon
                    icon="eos-icons:loading"
                    className="h-12 w-auto self-center text-gray-300"
                  />
                </div>
              </div>
            </div>
          ) : (
            <>Send Bridge Request</>
          )}
        </button>
      </div>
    </>
  );
}

export { Step2 };
