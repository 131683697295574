import { BigNumber, BigNumberish, BytesLike, ethers } from 'ethers';
import { IDomain } from '@/api/omni/Domain/domain.interface';
import config from '@/config';

export interface ISignedMessage {
  address: string;
  signature: string;
  message: string;
}

export interface SafeLow {
  maxPriorityFee: number;
  maxFee: number;
}

export interface Standard {
  maxPriorityFee: number;
  maxFee: number;
}

export interface Fast {
  maxPriorityFee: number;
  maxFee: number;
}

export interface GasStationResponse {
  safeLow: SafeLow;
  standard: Standard;
  fast: Fast;
  estimatedBaseFee: number;
  blockTime: number;
  blockNumber: number;
}
export interface ITransferDomainProps {
  tokenId: string;
  newOwner: string;
  provider?: ethers.providers.Web3Provider;
  ownerAddress: string;
}

export enum SetRecordType {
  TypedAddress = 'typedAddress',
  Address = 'address',
  TypedText = 'typedtext',
  Text = 'text',
  DNS = 'dns',
}
export interface ISetRecordProps {
  _ownerAddress: string;
  type: SetRecordType;
  address?: {
    coinType: number;
    address: string;
  };
  text?: {
    type: string;
    text: string;
  };
  host?: string;
}
export interface IcheckReclaim {
  domain: string;
  tokenID: string;
}

export interface IReClaim {
  domain: string;
  _ownerAddress: string;
  tokenID: string;
}

export interface ISetReverse {
  // domain: string;
  _ownerAddress: string;
}

export interface IgetReverse {
  address: string;
}

export interface ISetNFT {
  chainID: string;
  _ownerAddress: string;
  tokenId: string;
  contractAddress: string;
  nodehash: string;
  host?: string;
}

export interface IMigration {
  domain: IDomain;
}

export interface IGetNFT {
  chainID: string;
  nodehash: string;
  host?: string;
}

export interface IOutRequestCrossChain {
  nonce: number;
  ref: string;
  expiry: BigNumber;
  srcChain: InContractChain;
  crossProvider: CrossChainProvider;
  hash: string;
  ownerAddress: string;
}

export interface IInputAccecptCrossChainRequest {
  nonce: BigNumberish;
  ref: BytesLike;
  srcChain: BigNumberish;
  provider: BigNumberish;
  owner: string;
  expiry: BigNumberish;
  name: BytesLike;
  tld: BytesLike;
}

export interface IInputWithdrawPremiumDomain {
  owner: string;
  fqdn: string;
  amount: string;
}

export interface IInputDepositPremiumDomain {
  owner: string;
  fqdn: string;
  amount: string;
}

export const mainnetFunction_chainID = config.env === 'development' ? 5 : 137;

export enum InContractChain {
  ETHEREUM,
  BNB,
  POLYGON,
  AVALANCHE,
  FANTOM,
  OPTIMISM,
  ARBITRUM,
  IOTEX,
  OKC,
  GNOSIS,
  CELO,
  HARMONY,
  MOONBEAM,
  MOONRIVER,
  ZKSYNC,
  POLYGON_ZKEVM,
}

export const mappingInContractChain = {
  1: InContractChain.ETHEREUM,
  56: InContractChain.BNB,
  137: InContractChain.POLYGON,
  43114: InContractChain.AVALANCHE,
  250: InContractChain.FANTOM,
  10: InContractChain.OPTIMISM,
  42161: InContractChain.ARBITRUM,
  4689: InContractChain.IOTEX,
  66: InContractChain.OKC,
  100: InContractChain.GNOSIS,
  42220: InContractChain.CELO,
  1666600000: InContractChain.HARMONY,
  1284: InContractChain.MOONBEAM,
  1285: InContractChain.MOONRIVER,
  // 1: InContractChain.ZKSYNC,
  1101: InContractChain.POLYGON_ZKEVM,
  5: InContractChain.ETHEREUM,
  97: InContractChain.BNB,
  43113: InContractChain.AVALANCHE,
  80001: InContractChain.POLYGON,
  421613: InContractChain.ARBITRUM,
  420: InContractChain.OPTIMISM,
  4002: InContractChain.FANTOM,
  4690: InContractChain.IOTEX,
  65: InContractChain.OKC,
  10200: InContractChain.GNOSIS,
  44787: InContractChain.CELO,
  280: InContractChain.ZKSYNC,
  1442: InContractChain.POLYGON_ZKEVM,
  1666700000: InContractChain.HARMONY,
};

export enum CrossChainProvider {
  LAYERZERO,
  MULTICHAIN,
  ROUTER_PROTOCOL,
  NOMAD,
}

export const migrationConfig: {
  [chainId: number]: {
    EDNSController: string;
    baseRegister: string;
  };
} = {
  5: {
    EDNSController: '0xb977101Fba674a61c2a999CA36438FCB28E69e3b',
    baseRegister: '0xafFDDAd389bEe8a2AcBa0367dFAE5609B93c7F9b',
  },
  137: {
    EDNSController: '0x8C856f71d71e8CF4AD9A44cDC426b09e315c6A6a',
    baseRegister: '0x53a0018f919bde9C254bda697966C5f448ffDDcB',
  },
};

interface INETWORK_PARAMS {
  [key: number]: {
    chainId: string; // 5
    rpcUrls: [];
    chainName: string;
    nativeCurrency: {
      name: string;
      symbol: string;
      decimals: number;
    };
  };
}

export const NETWORK_PARAMS: any = {
  1: {
    // ETH
    chainId: '0x1', // 5
    rpcUrls: [config.web3.blockchain.node[1].endpoint],
    chainName: 'ETH',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  5: {
    // GOERLI
    chainId: '0x5', // 5
    rpcUrls: [config.web3.blockchain.node[5].endpoint],
    chainName: 'Goerli',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  56: {
    chainId: '0x38', // 5
    rpcUrls: [config.web3.blockchain.node[56].endpoint],
    chainName: 'BNB',
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
  },
  97: {
    // BNB_CHAIN_TESTNET
    chainId: '0x61', // 97
    rpcUrls: [config.web3.blockchain.node[97].endpoint],
    chainName: 'BNB_CHAIN_TESTNET',
    nativeCurrency: {
      name: 'tBNB',
      symbol: 'tBNB',
      decimals: 18,
    },
  },
  43114: {
    // AVALANCHE
    chainId: '0xa86a', // 97
    rpcUrls: [config.web3.blockchain.node[43114].endpoint],
    chainName: 'AVALANCHE',
    nativeCurrency: {
      name: 'AVAX',
      symbol: 'AVAX',
      decimals: 18,
    },
  },
  43113: {
    // AVALANCHE_FUJI
    chainId: '0xa869', // 97
    rpcUrls: [config.web3.blockchain.node[43113].endpoint],
    chainName: 'AVALANCHE_FUJI',
    nativeCurrency: {
      name: 'AVAX',
      symbol: 'AVAX',
      decimals: 18,
    },
  },
  137: {
    // Polygon
    chainId: '0x89', // 137
    rpcUrls: [config.web3.blockchain.node[137].endpoint],
    chainName: 'Polygon Mainnet',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
    blockExplorerUrls: ['https://polygonscan.com/'],
  },
  80001: {
    // POLYGON_MUMBAI
    chainId: '0x13881', // 97
    rpcUrls: [config.web3.blockchain.node[80001].endpoint],
    chainName: 'POLYGON_MUMBAI',
    nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18,
    },
  },
  42161: {
    //ARBITRUM MAINNET
    chainId: '0xa4b1', // 97
    rpcUrls: [config.web3.blockchain.node[42161].endpoint],
    chainName: 'ARBITRUM',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  421613: {
    // ARBITRUM_GOERLI
    chainId: '0x66eed', // 97
    rpcUrls: [config.web3.blockchain.node[421613].endpoint],
    chainName: 'ARBITRUM_GOERLI',
    nativeCurrency: {
      name: 'AGOR',
      symbol: 'AGOR',
      decimals: 18,
    },
  },
  10: {
    //OPTIMISM
    chainId: '0xa', // 97
    rpcUrls: [config.web3.blockchain.node[10].endpoint],
    chainName: 'OP Mainnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  420: {
    // OPTIMISM_GOERLI
    chainId: '0x1a4', // 97
    rpcUrls: [config.web3.blockchain.node[420].endpoint],
    chainName: 'OPTIMISM_GOERLI',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  250: {
    // FANTOM
    chainId: '0xfa', // 97
    rpcUrls: [config.web3.blockchain.node[250].endpoint],
    chainName: 'FANTOM',
    nativeCurrency: {
      name: 'FTM',
      symbol: 'FTM',
      decimals: 18,
    },
  },
  4002: {
    // FANTOM_TESTNET
    chainId: '0xfa2', // 97
    rpcUrls: [config.web3.blockchain.node[4002].endpoint],
    chainName: 'FANTOM_TESTNET',
    nativeCurrency: {
      name: 'FTM',
      symbol: 'FTM',
      decimals: 18,
    },
  },
  4689: {
    // IOTEX
    chainId: '0x1251', // 4689
    rpcUrls: [config.web3.blockchain.node[4689].endpoint],
    chainName: 'IoTeX Testnet',
    nativeCurrency: {
      name: 'IOTX',
      symbol: 'IOTX',
      decimals: 18,
    },
  },
  4690: {
    // IOTEX_TESTNET
    chainId: '0x1252', // 4690
    rpcUrls: [config.web3.blockchain.node[4690].endpoint],
    chainName: 'IoTeX Testnet',
    nativeCurrency: {
      name: 'IOTX',
      symbol: 'IOTX',
      decimals: 18,
    },
  },
  65: {
    // OKC_TESTNET
    chainId: '0x41', // 65
    rpcUrls: [config.web3.blockchain.node[65].endpoint],
    chainName: 'OKC Testnet',
    nativeCurrency: {
      name: 'OKT',
      symbol: 'OKT',
      decimals: 18,
    },
    blockExplorerUrls: ['https://www.oklink.com/okc-test/'],
  },
  66: {
    // OKC
    chainId: '0x42', // 66
    rpcUrls: [config.web3.blockchain.node[66].endpoint],
    chainName: 'OKC Testnet',
    nativeCurrency: {
      name: 'OKT',
      symbol: 'OKT',
      decimals: 18,
    },
    blockExplorerUrls: ['https://www.oklink.com/okc-test/'],
  },
  322: {
    //KCC TESTNET
    chainId: '0x142', // 322
    rpcUrls: [config.web3.blockchain.node[322].endpoint],
    chainName: 'KCC Testnet',
    nativeCurrency: {
      name: 'tKCS',
      symbol: 'tKCS',
      decimals: 18,
    },
  },
  321: {
    //KCC
    chainId: '0x141', // 322
    rpcUrls: [config.web3.blockchain.node[321].endpoint],
    chainName: 'KCC Mainnet',
    nativeCurrency: {
      name: 'KCS',
      symbol: 'KCS',
      decimals: 18,
    },
  },
  100: {
    //GNOSIS
    chainId: '0x64', // 100
    rpcUrls: [config.web3.blockchain.node[100].endpoint],
    chainName: 'GNOSIS',
    nativeCurrency: {
      name: 'XDAI',
      symbol: 'XDAI',
      decimals: 18,
    },
  },
  10200: {
    // GNOSIS_CHIADO
    chainId: '0x27d8', // 97
    rpcUrls: [config.web3.blockchain.node[10200].endpoint],
    chainName: 'GNOSIS_CHIADO',
    nativeCurrency: {
      name: 'xDAI',
      symbol: 'xDAI',
      decimals: 18,
    },
  },
  1284: {
    // MOONBEAM
    chainId: '0x504', // 100
    rpcUrls: [config.web3.blockchain.node[1284].endpoint],
    chainName: 'Moonbeam',
    nativeCurrency: {
      name: 'GLMR',
      symbol: 'GLMR',
      decimals: 18,
    },
  },
  1287: {
    // MOONBASE_ALPHA
    chainId: '0x507', // 97
    rpcUrls: [config.web3.blockchain.node[1287].endpoint],
    chainName: 'MOONBASE_ALPHA',
    nativeCurrency: {
      name: 'DEV',
      symbol: 'DEV',
      decimals: 18,
    },
  },
  1285: {
    // MOONRIVER
    chainId: '0x505', // 1285
    rpcUrls: [config.web3.blockchain.node[1285].endpoint],
    chainName: 'MOONRIVER',
    nativeCurrency: {
      name: 'MOVR',
      symbol: 'MOVR',
      decimals: 18,
    },
  },
  42220: {
    //CELO
    chainId: '0xa4ec', // 1285
    rpcUrls: [config.web3.blockchain.node[42220].endpoint],
    chainName: 'Celo',
    nativeCurrency: {
      name: 'CELO',
      symbol: 'CELO',
      decimals: 18,
    },
  },
  44787: {
    // CELO_ALFAJORES
    chainId: '0xaef3', // 44787
    rpcUrls: [config.web3.blockchain.node[44787].endpoint],
    chainName: 'Celo Alfajores Testnet',
    nativeCurrency: {
      name: 'CELO',
      symbol: 'CELO',
      decimals: 18,
    },
  },
  324: {
    //ZKSYNC_ERA
    chainId: '0x144', // 324
    rpcUrls: [config.web3.blockchain.node[324].endpoint],
    chainName: 'zkSync Era Mainnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  280: {
    // ZKSYNC_ERA_TESTNET
    chainId: '0x118', // 97
    rpcUrls: [config.web3.blockchain.node[280].endpoint],
    chainName: 'zkSync Era Testnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  1101: {
    // POLYGON_ZKEVM
    chainId: '0x44d', // 1101
    rpcUrls: [config.web3.blockchain.node[1101].endpoint],
    chainName: 'Polygon zkEVM',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  1442: {
    // POLYGON_ZKEVM_TESTNET
    chainId: '0x5a2', // 97
    rpcUrls: [config.web3.blockchain.node[1442].endpoint],
    chainName: 'Polygon zkEVM Testnet',
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
  },
  1666600000: {
    // HARMONY
    chainId: '0x63564c40', // 1666600000
    rpcUrls: [config.web3.blockchain.node[1666600000].endpoint],
    chainName: '1666600000',
    nativeCurrency: {
      name: 'ONE',
      symbol: 'ONE',
      decimals: 18,
    },
  },
  1666700000: {
    // HARMONEY_TESTNET
    chainId: '0x6357d2e0', // 1666700000
    rpcUrls: [config.web3.blockchain.node[1666700000].endpoint],
    chainName: 'HARMONEY_TESTNET',
    nativeCurrency: {
      name: 'ONE',
      symbol: 'ONE',
      decimals: 18,
    },
  },
  114: {
    // Flare Coton 2 Testnet
    chainId: '0x72', // 114
    rpcUrls: [config.web3.blockchain.node[114].endpoint],
    chainName: 'Flare Coton 2 Testnet',
    nativeCurrency: {
      name: 'C2FLR',
      symbol: 'C2FLR',
      decimals: 18,
    },
  },
};
