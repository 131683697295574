import { AxiosInstance } from 'axios';
import { IPaymentOrder } from './order.interface';
import { errorHandler } from '../../errorHandler';
import config from '@/config';

interface IPaymentOrderInput {
  orderId: string;
}

export const getPayment =
  (instance: AxiosInstance) =>
  async (input: IPaymentOrderInput): Promise<IPaymentOrder> => {
    const { orderId } = input;
    try {
      const response = await instance.get<IPaymentOrder>(
        `${config.api.router.order.getPayment}${orderId}`,
      );
      return response.data;
    } catch (e) {
      throw errorHandler(e);
    }
  };
