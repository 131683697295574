import { Icon } from '@iconify/react';
import { NavLink, useLocation } from 'react-router-dom';
// import { useAuth0 } from '@auth0/auth0-react';
import { GlobalStateContext } from '@/utils/context';
import { useAuth } from '@/utils/auth/hook';
import { useContext, useEffect } from 'react';
import Logo from '../common/Logo';
import config, { DeploymentEnvironment } from '@/config';

interface ILeftNav {
  isAuthenticated?: boolean;

  setResellerCode: (code: string) => void;
}
interface INavBtnProps {
  path?: string;
  icon?: string;
  title: string;
  display?: boolean;
  callback?: () => void;
}
function NavBtn(props: INavBtnProps) {
  const { path = '', icon, title, display, callback } = props;

  if (!display) {
    return <></>;
  }
  return (
    <NavLink
      to={path}
      className={({ isActive }) =>
        `nav-option flex cursor-pointer items-center gap-6 rounded-lg duration-200 sm:text-gray-500 md:text-white ${
          isActive && path !== '' ? 'on' : ''
        }`
      }
      onClick={() => {
        if (callback) {
          callback();
        }
      }}
      end
    >
      {icon && <Icon icon={icon} className="h-6 w-6" />}
      <div className="relative">{title}</div>
    </NavLink>
  );
}

function LeftNav(props: ILeftNav) {
  const { setResellerCode } = props;
  const {
    isShowNav,
    setIsShowNav,
    setIsShowCartWithoutAuth,
    setIsShowCart,
    isShowCartWithoutAuth,
    isShowCart,
  } = useContext(GlobalStateContext);
  const { isAuthenticated } = useAuth();
  const { search } = useLocation();
  const { login, logout } = useAuth();

  useEffect(() => {
    let code: string | null = window.location.href;
    try {
      code = code.substring(code.indexOf('?resellerCode=') + 14, code.length);
    } catch (e) {
      code = null;
    }
    if (code !== null && code.length === 8) {
      setResellerCode(code);
    }
  }, [setResellerCode]);

  return (
    <div
      data-show={isShowNav}
      className="leftNav-outer left-0 top-0 w-full shrink-0 overflow-hidden duration-200 md:h-full md:w-auto md:p-6 md:pr-2"
    >
      <div className="leftNav l-nav-bg navbar m-0 flex h-full w-full justify-between overflow-hidden overflow-y-auto p-4 pb-3  md:rounded-2xl md:py-8 md:pb-3 lg:w-72">
        <div className="flex h-full flex-row justify-between md:flex-col">
          <a className="z-[500] md:z-[0]" href="https://www.edns.domains/">
            <Logo isMainLogo />
          </a>
          <div className="flex gap-6 md:hidden">
            <div
              onClick={() =>
                isAuthenticated
                  ? setIsShowCart(!isShowCart)
                  : setIsShowCartWithoutAuth(!isShowCartWithoutAuth)
              }
              className="z-[500] md:hidden"
            >
              <Icon
                className="h-6 w-auto cursor-pointer text-white"
                icon="prime:shopping-cart"
              />
            </div>
            <div
              onClick={() => setIsShowNav(!isShowNav)}
              className="z-[500] cursor-pointer md:hidden"
            >
              <Icon
                className="z-[500] h-6 w-auto cursor-pointer text-white"
                icon="charm:menu-hamburger"
              />
            </div>
          </div>
          <div
            data-show={isShowNav}
            onClick={() => setIsShowNav(false)}
            className="leftNav-inner text-md flex h-full w-full flex-auto flex-col justify-between border-b bg-white text-gray-600 md:relative md:mt-2 md:h-auto md:w-auto md:gap-2 md:border-b-0 md:bg-transparent"
          >
            <div className="flex flex-col gap-4">
              <NavBtn
                path={`/account${search}`}
                title="Dashboard"
                icon="material-symbols:dashboard"
                display={isAuthenticated}
              />
              <NavBtn
                path={`/${search}`}
                title="Search Domains"
                icon="material-symbols:search"
                display
              />
              <NavBtn
                path={`/premium`}
                title="Premium Domain"
                icon="material-symbols:star"
                display={
                  isAuthenticated &&
                  config.env !== DeploymentEnvironment.PRODUCTION
                }
              />
              <NavBtn
                path={`/mydomain${search}`}
                title="My Domains"
                icon="mdi:webpack"
                display={isAuthenticated}
              />
              <NavBtn
                path={`/order${search}`}
                title="Orders"
                icon="material-symbols:view-list-sharp"
                display={isAuthenticated}
              />

              <NavBtn
                path={`/cart${search}`}
                title="Cart"
                icon="eva:shopping-cart-fill"
                display={false}
              />
            </div>
            <div className="-translate-y-20 md:translate-y-0">
              <div className="relative flex flex-col justify-between gap-5">
                <NavBtn
                  title="Sign out"
                  icon="material-symbols:logout-rounded"
                  display={isAuthenticated}
                  callback={() => {
                    window.localStorage.removeItem('token');
                    logout();
                    // redirect to home page
                    // window.location.reload();
                  }}
                />
                <NavBtn
                  title="Sign in"
                  icon="material-symbols:logout-rounded"
                  display={!isAuthenticated}
                  callback={() => {
                    login();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftNav;
