import React, {
  Dispatch,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import {
  AssociatedCryptoWalletType,
  createCryptoWallet,
} from '@/api/omni/CryptoWallet/createCryptoWallet';
import { BaseProvider } from '@ethersproject/providers/src.ts/base-provider';
import {
  BlockchainEco,
  CryptoWalletType,
  ICryptoWallet,
} from '@/api/omni/CryptoWallet/cryptoWallet.interface';
import { IBlockchain } from '@/api/omni/Blockchain/blockchain.interface';
import { IDomain } from '@/api/omni/Domain/domain.interface';
import { IOrder } from '@/api/omni/Order/order.interface';
import { IProduct } from '@/api/omni/Product/product.interface';
import { ISearchDomainDto } from '@/Pages/search';
import { ITld } from '@/api/omni/Tld/tld.interface';
import { IUser } from '@/api/omni/User/user.interface';
import { createOrder } from '@/api/omni/Order/createOrder';
import { ethers } from 'ethers';
import { getUser } from '@/api/omni/User/getUser';
import { isValidChain } from '@/utils/getAllContract';
import { listBlockChain } from '@/api/omni/Blockchain/listBlockChain';
import { listDomains } from '@/api/omni/Domain/list';
import { listOrders } from '@/api/omni/Order/listOrder';
import { listProducts } from '@/api/omni/Product/listProducts';
import { listTlds } from '@/api/omni/Tld/listTld';
import { listWallets } from '@/api/omni/CryptoWallet/listWallets';
import { useAuth } from '@/utils/auth/hook';
import { useAxios } from '../axios/AxiosProvider';

export interface ICacheDomain {
  domain: ISearchDomainDto;
  blockchain: IBlockchain;
  duration: number;
}

export interface IGlobalStateContext {
  isShowNav: boolean;
  setIsShowNav: Dispatch<SetStateAction<boolean>>;
  isShowCart: boolean;
  isShowCartWithoutAuth: boolean;
  setIsShowCart: Dispatch<SetStateAction<boolean>>;
  setIsShowCartWithoutAuth: Dispatch<SetStateAction<boolean>>;
  cacheDomain: ICacheDomain[];
  insertCacheDomain: (
    domain: ISearchDomainDto,
    blockchain: IBlockchain,
  ) => Promise<void>;
  updateDurtionCacheDomain: (
    domain: ICacheDomain,
    durtion: number,
  ) => Promise<void>;
  tlds: ITld[]; // -> v2
  isFetchWallets: boolean;
  cryptoWallets: ICryptoWallet[];
  user: IUser | undefined;
  ethereumAddressesLength: number;
  domains: IDomain[];
  isLoadingDomains: boolean;
  orders: IOrder[];
  createNewOrder: () => Promise<IOrder | undefined>;
  isLoadingOrders: boolean;
  products: IProduct[];
  blockChains: IBlockchain[]; // -> v2
  removeCacheDomain: (domain: ICacheDomain) => Promise<void>;
  updateUser: () => Promise<void>;
  updateCryptoWallet: () => Promise<void>;
  updateDomains: () => Promise<void>;
  updateOrders: () => Promise<void>;
  isBillingAddressFinishSetup: boolean;
  isEthereumAddressesFinishSetup: boolean;
  isManagedWallet: boolean;
  getProvider: (chainId: number) => Promise<BaseProvider>;
  addCryptoWallet: (
    type: CryptoWalletType,
  ) => (
    address?: string,
    message?: string,
    signature?: string,
  ) => Promise<void>;
}

export const GlobalStateContext = createContext<IGlobalStateContext>({
  isShowNav: false,
  setIsShowNav: () => {},
  isShowCart: false,
  insertCacheDomain: (domain: ISearchDomainDto, blockchain: IBlockchain) =>
    Promise.resolve(),
  updateDurtionCacheDomain: (domain: ICacheDomain, durtion: number) =>
    Promise.resolve(),
  removeCacheDomain: (domain: ICacheDomain) => Promise.resolve(),
  isShowCartWithoutAuth: false,
  setIsShowCart: () => {},
  setIsShowCartWithoutAuth: () => {},
  cacheDomain: [],
  tlds: [],
  isFetchWallets: false,
  cryptoWallets: [],
  user: undefined,
  products: [],
  getProvider: async () => {
    return new ethers.providers.JsonRpcProvider();
  },
  ethereumAddressesLength: -1,
  domains: [],
  isLoadingDomains: false,
  orders: [],
  createNewOrder: async () => undefined,
  isLoadingOrders: false,
  blockChains: [],
  updateUser: async () => {},
  updateCryptoWallet: async () => {},
  updateDomains: async () => {},
  updateOrders: async () => {},
  isBillingAddressFinishSetup: false,
  isEthereumAddressesFinishSetup: false,
  isManagedWallet: false,
  addCryptoWallet: () => async () => {},
});
//

export default function GlobalStateProvider(
  props: React.PropsWithChildren<any>,
) {
  const { children } = props;
  const { isAuthenticated } = useAuth();
  const { instance } = useAxios();

  //TODO change to using Map
  const [cacheDomain, setCacheDomain] = useState<ICacheDomain[]>([]);
  const [isShowNav, setIsShowNav] = useState<boolean>(false);
  const [isShowCart, setIsShowCart] = useState<boolean>(false);
  const [isShowCartWithoutAuth, setIsShowCartWithoutAuth] =
    useState<boolean>(false);
  const [blockChains, setBlockChains] = useState<IBlockchain[]>([]);
  const [tlds, setTlds] = useState<ITld[]>([]);
  const [user, setUser] = useState<IUser | undefined>(undefined);
  const [ethereumAddressesLength, setEthereumAddressesLength] =
    useState<number>(-1);
  const [domains, setDomains] = useState<IDomain[]>([]);
  const [isLoadingDomains, setIsLoadingDomains] = useState<boolean>(false);
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [isLoadingOrders, setIsLoadingOrders] = useState<boolean>(false);
  const [isBillingAddressFinishSetup, setIsBillingAddressFinishSetup] =
    useState<boolean>(false);
  const [isEthereumAddressesFinishSetup, setIsEthereumAddressesFinish] =
    useState<boolean>(false);
  const [isManagedWallet, setIsManagedWallet] = useState<boolean>(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const providers = {};

  const [cryptoWallets, setCryptoWallets] = useState<ICryptoWallet[]>([]);
  const [isFetchWallets, setIsFetchWallets] = useState<boolean>(false);
  const [products, setProducts] = useState<IProduct[]>([]);

  useEffect(() => {
    if (cacheDomain.length > 0) {
      localStorage.setItem('cacheDomain', JSON.stringify(cacheDomain));
    }
  }, [cacheDomain]);
  useEffect(() => {
    const _cacheDomainJson = localStorage.getItem('cacheDomain');
    if (_cacheDomainJson) {
      setCacheDomain(JSON.parse(_cacheDomainJson));
    }
  }, []);
  const updateTlds = async () => {
    const _tlds = await listTlds(instance)();
    // console.log(_tlds);
    setTlds(_tlds);
  };

  const updateBlockChains = async () => {
    const blockchain = await listBlockChain(instance)();
    if (!blockchain.data) {
      return;
    }
    const filteredArray = blockchain.data.reduce(
      (filteredBlockchain: IBlockchain[], _blockchain: IBlockchain) => {
        if (isValidChain(+_blockchain.chainId)) {
          filteredBlockchain.push(_blockchain);
        }
        return filteredBlockchain;
      },
      [],
    );
    setBlockChains(filteredArray);
  };

  const insertCacheDomain = async (
    domain: ISearchDomainDto,
    blockchain: IBlockchain,
  ) => {
    const _cacheDomainIndex = cacheDomain.findIndex(
      (x) =>
        x.domain.domain === domain.domain &&
        x.domain.tld.name === domain.tld.name,
    );
    if (_cacheDomainIndex === -1) {
      const _data: ICacheDomain = { domain, blockchain, duration: 1 };
      setCacheDomain([...cacheDomain, _data]);
    } else {
      cacheDomain[_cacheDomainIndex].blockchain = blockchain;
    }

    setIsShowCartWithoutAuth(true);
  };
  const removeCacheDomain = async (domain: ICacheDomain) => {
    const _cacheDomainIndex = cacheDomain.findIndex(
      (x) =>
        x.domain.domain === domain.domain.domain &&
        x.domain.tld.name === domain.domain.tld.name,
    );
    const _cacheDomains = [...cacheDomain];

    _cacheDomains.splice(_cacheDomainIndex, 1);
    setCacheDomain(_cacheDomains);
  };

  const updateDurtionCacheDomain = async (
    domain: ICacheDomain,
    durtion: number,
  ) => {
    const _cacheDomainIndex = cacheDomain.findIndex(
      (x) =>
        x.domain.domain === domain.domain.domain &&
        x.domain.tld.name === domain.domain.tld.name,
    );
    cacheDomain[_cacheDomainIndex].duration = durtion;

    setCacheDomain(cacheDomain);
  };

  const updateProducts = async () => {
    const _products = await listProducts(instance)();
    setProducts(_products);
  };

  const updateUser = useCallback(async () => {
    const _user = await getUser(instance)();
    setIsBillingAddressFinishSetup(true);
    setUser(_user);
  }, [instance]);

  const updateCryptoWallet = useCallback(async () => {
    setIsFetchWallets(true);
    const wallets = await listWallets(instance)();
    setEthereumAddressesLength(wallets.length);
    setCryptoWallets(wallets);

    const _managedWallet = wallets.find(
      (x) => x.type === CryptoWalletType.MANAGED,
    );
    setIsManagedWallet(!!_managedWallet);
    setIsFetchWallets(false);
  }, [instance]);

  const addCryptoWallet = useCallback(
    (type: CryptoWalletType) =>
      async (address?: string, message?: string, signature?: string) => {
        setIsFetchWallets(true);
        const payload =
          type === CryptoWalletType.ASSOCIATED
            ? {
                eco: BlockchainEco.ETHEREUM,
                wallet: {
                  associated: {
                    address,
                    type: AssociatedCryptoWalletType.FROM_USER,
                    signature,
                    message,
                  },
                },
              }
            : { eco: BlockchainEco.ETHEREUM };
        await createCryptoWallet(instance)(type)(payload);
        await updateCryptoWallet();
      },
    [instance, updateCryptoWallet],
  );

  const getProvider = useCallback(
    async (chainId: number): Promise<BaseProvider> => {
      if (!Object.keys(providers).find((x) => x === `${chainId}`)) {
        const blockchain = blockChains.find((x) => +x.chainId === chainId);
        if (!blockchain) {
          throw new Error(`Block Chain RPC Not Found. ${chainId}`);
        }
        providers[chainId] = new ethers.providers.JsonRpcProvider(
          blockchain.rpc.public.http,
        );
      }
      console.log(providers[chainId]);
      return providers[chainId];
    },
    [blockChains, providers],
  );

  const updateDomains = useCallback(async () => {
    setIsLoadingDomains(true);
    const _domains: IDomain[] = await listDomains(instance)();
    setDomains(_domains);
    setIsLoadingDomains(false);
  }, []);

  const updateOrders = useCallback(async () => {
    setIsLoadingOrders(true);
    const _orders = await listOrders(instance)();
    setOrders(_.orderBy(_orders, 'createdAt', 'desc'));
    setIsLoadingOrders(false);
  }, []);

  const createNewOrder = useCallback(async () => {
    const _order = await createOrder(instance)();
    await updateOrders();

    // console.log(_order);
    return _order;
  }, [updateOrders]);

  useEffect(() => {
    if (isShowCart) {
      setIsShowNav(false);
    }
  }, [isShowCart]);

  useEffect(() => {
    console.log(isShowNav);
    if (isShowNav) {
      setIsShowCart(false);
    }
    console.log(isShowNav);
  }, [isShowNav]);

  // useEffect(() => {
  //   console.log(isShowCartWithoutAuth);
  //   if (isShowCartWithoutAuth) {
  //     setIsShowCartWithoutAuth(false);
  //   }
  //   console.log(isShowCartWithoutAuth);
  // }, [isShowCartWithoutAuth]);

  useEffect(() => {
    setIsEthereumAddressesFinish(!!cryptoWallets.length);
  }, [cryptoWallets]);

  // Init
  useEffect(() => {
    updateBlockChains();
    updateTlds();
    updateProducts();
  }, []);

  // When is authenticated, then it would be fetch the data
  useEffect(() => {
    if (isAuthenticated) {
      updateUser();
      updateCryptoWallet();
      updateOrders();
      updateDomains();
    }
  }, [
    isAuthenticated,
    updateCryptoWallet,
    updateDomains,
    updateOrders,
    updateUser,
  ]);

  const context = useMemo<IGlobalStateContext>(
    () => ({
      isShowNav,
      setIsShowNav,
      cacheDomain,
      isShowCart,
      insertCacheDomain,
      setIsShowCartWithoutAuth,
      setIsShowCart,
      removeCacheDomain,
      tlds,
      user,
      blockChains,
      products,
      ethereumAddressesLength,
      domains,
      isLoadingDomains,
      orders,
      createNewOrder,
      isLoadingOrders,
      updateUser,
      updateCryptoWallet,
      updateDomains,
      updateOrders,
      isBillingAddressFinishSetup,
      isEthereumAddressesFinishSetup,
      isManagedWallet,
      getProvider,
      isShowCartWithoutAuth,
      isFetchWallets,
      cryptoWallets,
      addCryptoWallet,
      updateDurtionCacheDomain,
    }),
    [
      isShowNav,
      cacheDomain,
      isShowCart,
      insertCacheDomain,
      removeCacheDomain,
      tlds,
      user,
      blockChains,
      products,
      ethereumAddressesLength,
      domains,
      isLoadingDomains,
      orders,
      createNewOrder,
      isLoadingOrders,
      updateUser,
      updateCryptoWallet,
      updateDomains,
      updateOrders,
      isBillingAddressFinishSetup,
      isEthereumAddressesFinishSetup,
      isManagedWallet,
      getProvider,
      isShowCartWithoutAuth,
      isFetchWallets,
      cryptoWallets,
      addCryptoWallet,
      updateDurtionCacheDomain,
    ],
  );

  return (
    <GlobalStateContext.Provider value={context}>
      {children}
    </GlobalStateContext.Provider>
  );
}
