interface Props {
  page: number;
  pages: number;
  setPage: (page: number) => void;
}

function OrderLayout(props: Props) {
  const { page, pages, setPage } = props;
  return (
    <li
      className={`hidden w-[37px] cursor-pointer rounded-md p-2 px-3 text-center text-white duration-200 hover:bg-[#a52ba1] md:block ${
        page === pages ? 'bg-[#a822a4]' : 'bg-[#93278f]'
      }`}
      data-num={pages}
      onClick={() => {
        setPage(pages * 15);
      }}
    >
      {pages + 1}
    </li>
  );
}

export default OrderLayout;
