import '../App.scss';
import discord from '../static/discord.webp';
import fb from '../static/fb.webp';
import linkdin from '../static/linkdin.webp';
import logo from '../static/EDNS_white.png';
import medium from '../static/medium.webp';
import telegram from '../static/telegram.webp';
import twitter from '../static/twitter.webp';
import youtube from '../static/youtube.webp';

function Footer() {
  return (
    <footer className="w-full bg-gray-800 px-12 py-24 text-white md:py-12 2xl:px-0">
      <div className="xl:md-32 b v mx-auto flex max-w-4xl flex-col justify-between gap-14 md:flex-row">
        <div className="flex w-full flex-col md:w-72">
          <img className="w-52 md:w-56" src={logo} alt="EDNS"></img>
          <span className="text-md mt-3 w-full text-left text-white md:text-lg">
            Decentralized naming service for wallets, websites, and more
          </span>
        </div>
        <div className="flex-1 text-left">
          <span className="text-xl font-bold md:text-2xl">
            Join us on social media
          </span>
          <span className="mt-3 flex flex-wrap justify-start gap-3 md:justify-between">
            <a
              target="_blank"
              href="https://www.facebook.com/ednsdomains"
              rel="noreferrer"
            >
              <img alt="facebook" src={fb} className="icon w-7 md:w-10"></img>
            </a>
            <a
              target="_blank"
              href="https://medium.com/@edns.domains"
              rel="noreferrer"
            >
              <img alt="medium" src={medium} className="icon w-7 md:w-10"></img>
            </a>
            <a
              target="_blank"
              href="https://twitter.com/ednsdomains"
              rel="noreferrer"
            >
              <img
                alt="twitter"
                src={twitter}
                className="icon w-7 md:w-10"
              ></img>
            </a>
            <a
              target="_blank"
              href="https://t.me/edns_community"
              rel="noreferrer"
            >
              <img
                alt="telegram"
                src={telegram}
                className="icon w-7 md:w-10"
              ></img>
            </a>
            <a
              target="_blank"
              href="https://discord.com/invite/XesptqUUmm"
              rel="noreferrer"
            >
              <img
                alt="discord"
                src={discord}
                className="icon w-7 md:w-10"
              ></img>
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UCG-v-5UlRKCyPYsJts2zxfA"
              rel="noreferrer"
            >
              <img
                alt="youtube"
                src={youtube}
                className="icon w-7 md:w-10"
              ></img>
            </a>
            {/* <a target="_blank" href="https://www.youtube.com/">
              <img
                alt="instagram"
                src={instagram}
                className="icon w-7 md:w-10"
              ></img>
            </a> */}
            <a
              target="_blank"
              href="https://www.linkedin.com/company/ednsdomains/"
              rel="noreferrer"
            >
              <img
                alt="linkdin"
                src={linkdin}
                className="icon w-7 md:w-10"
              ></img>
            </a>
          </span>
          <div className="mb-2 mt-16 md:mb-0 md:ml-5">
            Subscribe to our update!
          </div>
          <form>
            <div className="relative w-full">
              <input
                className="mt-2 w-full rounded-full px-8 py-3 text-black outline-none"
                placeholder="e.g. name@example.com"
              ></input>
              <button className="right-2 top-[14px] mt-3 w-full rounded-full bg-blue-500 px-5 py-4 text-sm font-bold text-white duration-200 ease-in-out hover:bg-blue-500 hover:text-white md:absolute md:mt-0  md:w-auto md:bg-transparent md:py-2 md:text-blue-400">
                Subscribe
              </button>
            </div>
          </form>
          <div className="mt-24 flex w-full flex-col items-center justify-between gap-10 text-xs md:mt-14 md:flex-row md:gap-5">
            <span className="text-center md:text-left">© 2022 by EDNS.</span>
            <a
              target="_blank"
              href="https://www.edns.domains/privacy-policy"
              rel="noreferrer"
            >
              <button className="duration-200 hover:scale-125 hover:underline">
                Privacy Policy
              </button>
            </a>
            <a
              target="_blank"
              href="https://www.edns.domains/terms-of-use"
              rel="noreferrer"
            >
              <button className="duration-200 hover:scale-125 hover:underline">
                Terms of Use
              </button>
            </a>
            <a
              target="_blank"
              href="https://www.edns.domains/_files/ugd/761ef8_6f2302653e4840b9bea21a4f69d0b409.pdf"
              rel="noreferrer"
            >
              <button className="duration-200 hover:scale-125 hover:underline">
                White Paper
              </button>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
