import {
  AssociatedCryptoWalletType,
  createAssociatedWallet,
  createManagedWallet,
} from '@/api/omni/CryptoWallet/createCryptoWallet';
import {
  BlockchainEco,
  ICryptoWallet,
} from '@/api/omni/CryptoWallet/cryptoWallet.interface';
import { CartContext, GlobalStateContext, ICacheDomain } from '@/utils/context';

import {
  DomainPurchaseMethod,
  IAddDomainToCartInput,
  addDomainToCart,
} from '@/api/omni/Cart/addDomainToCart';
import { Icon } from '@iconify/react';
import { Web3Functions } from './web3/Web3';
import { getChainIcon } from '@/components/web3/getChainIcon';
import { useAuth } from '@/utils/auth/hook';
import { useAxios } from '@/utils/axios/AxiosProvider';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import config, { DeploymentEnvironment } from '@/config';

const BlindingWallet = () => {
  const { isAuthenticated } = useAuth();
  const { instance } = useAxios();
  const navigate = useNavigate();
  const {
    ethereumAddressesLength,
    updateCryptoWallet,
    updateUser,
    cryptoWallets,
    isManagedWallet,
    getProvider,
    products,
    user,
    setIsShowCart,
  } = useContext(GlobalStateContext);
  const { refreshCart } = useContext(CartContext);

  const [isSelect, setIsSelect] = useState<boolean>(false);
  const [read, setRead] = useState<boolean>(false);
  const [isUnderstand, setIsUnderstand] = useState<boolean>(false);
  const [done, setDone] = useState(false);
  const [doneAddDomain, setDoneAddDomain] = useState(false);
  const [resultDomain, setResultDomain] = useState<ICacheDomain[] | undefined>(
    undefined,
  );

  const createEthereumAddress = async (
    address: string,
    message: string,
    signature: string,
  ) => {
    const isEthereumAddressAlreadyExist = cryptoWallets?.find(
      (_address) => _address.address === address,
    );
    if (!isEthereumAddressAlreadyExist) {
      await createAssociatedWallet(instance)({
        eco: BlockchainEco.ETHEREUM,
        wallet: {
          associated: {
            address,
            type: AssociatedCryptoWalletType.FROM_USER,
            signature,
            message,
          },
        },
      });

      await updateCryptoWallet();
    }
  };
  const handleAddWalletClick = async () => {
    const chainId = config.env === DeploymentEnvironment.PRODUCTION ? 137 : 5;
    const useWeb3Function = new Web3Functions(
      chainId,
      await getProvider(chainId),
    );
    try {
      const result = await useWeb3Function.signMessage();
      if (result) {
        const { address, signature, message } = result;
        await createEthereumAddress(address, message, signature);
      }
    } catch (e) {
      console.error({ e });
    }
  };

  const handleCreateManagedWallet = async () => {
    await createManagedWallet(instance)({
      eco: BlockchainEco.ETHEREUM,
    });
    await updateUser();
  };

  useEffect(() => {
    const _domain = localStorage.getItem('cacheDomain');
    if (_domain) {
      setResultDomain(JSON.parse(_domain));
    }
  }, []);

  function hiddenscreenBlob() {
    return ethereumAddressesLength === 0 || !!resultDomain;
  }
  if (!isAuthenticated) {
    return null;
  }
  const addDomainToShoppingCart = async (_input: ICacheDomain) => {
    try {
      let walletId = cryptoWallets[0].id;
      if (user && cryptoWallets.length > 0) {
        if (
          user.default_address !== null &&
          user.default_address !== undefined
        ) {
          const wallet = cryptoWallets.find(
            (wallet: ICryptoWallet) => wallet.address === user.default_address,
          );
          if (wallet) {
            walletId = wallet.id;
          }
        }
      }
      const _domainProduct = products.find((p) => p.type === 'domain');
      const input: IAddDomainToCartInput = {
        productId: `${_domainProduct!.id}`,
        domain: {
          method: DomainPurchaseMethod.REGISTER,
          name: _input.domain.domain,
          duration: _input.duration,
          tldId: _input.domain.tld.name,
          blockchainId: _input.blockchain!.id, // selected BLockchainId
          walletId: walletId,
        },
      };
      await addDomainToCart(instance)(input);
      setDoneAddDomain(true);

      // navigate('/cart');
      // navigate(0);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <div
        data-toggle={hiddenscreenBlob()}
        className="selectWallet-wrapper fixed left-0 top-0 z-[700] bg-black/70"
      ></div>

      <div
        data-toggle={resultDomain && ethereumAddressesLength > 0}
        data-done={doneAddDomain}
        className="selectWallet-con z-[701] rounded-xl p-6 "
        style={{
          display:
            resultDomain && ethereumAddressesLength > 0 ? 'block' : 'none',
        }}
      >
        <div
          data-done={doneAddDomain}
          className="done-con flex h-full w-full flex-col items-center gap-8"
          style={{
            display: doneAddDomain ? 'flex' : 'none',
          }}
        >
          <Icon
            icon="charm:circle-tick"
            className="h-auto w-[120px] text-green-500"
          />
          <div className="flex flex-col items-center text-center ">
            <h3 className="text-xl font-medium">All done !</h3>
            <h3 className="mt-3 text-lg font-medium text-gray-500">
              Domain Added to Your Cart!
            </h3>
          </div>
        </div>
        <div
          data-done={doneAddDomain}
          className="wallet-con flex flex-col gap-5"
        >
          {resultDomain?.map((x) => (
            <div className="bg-card flex min-w-[250px] items-center justify-between rounded-lg border-2 p-5 py-5 font-medium text-[#93278f] duration-150">
              <div className="flex-wap flex flex-1 items-center gap-5">
                {resultDomain &&
                  getChainIcon(+x!.domain.tld.blockchains[0]!.chainId)}
                <span className="text-lg text-slate-50">
                  {x?.domain.domain}.{x?.domain.tld?.name}
                </span>
              </div>
              <span className="text-lg text-slate-50">
                USD {x.domain?.price} / Year
              </span>
            </div>
          ))}

          <div className="flex flex-1 flex-wrap gap-5">
            <button
              className="min-w-[250px] flex-1 rounded-lg border-2 border-[#93278f] p-3 py-5 font-medium text-[#93278f] duration-150 hover:bg-[#93278f] hover:text-white"
              onClick={() => {
                localStorage.removeItem('cacheDomain');
                setResultDomain(undefined);
              }}
            >
              Cancel
            </button>
            <button
              className="min-w-[250px] flex-1 rounded-lg border-2 border-[#93278f] p-3 py-5 font-medium text-[#93278f] duration-150 hover:bg-[#93278f] hover:text-white"
              onClick={async () => {
                const promise = [
                  resultDomain?.map((x) => addDomainToShoppingCart(x!)),
                ];
                await Promise.all(promise);
                localStorage.removeItem('cacheDomain');
                setDoneAddDomain(true);
                setTimeout(async () => {
                  await refreshCart();
                }, 2000);
                setTimeout(() => {
                  setIsShowCart(true);
                  setResultDomain(undefined);
                }, 2000);
              }}
            >
              Add
            </button>
          </div>
        </div>
      </div>
      <div
        data-toggle={ethereumAddressesLength === 0}
        data-done={done}
        className="selectWallet-con z-[701] rounded-xl p-6"
        style={{
          display: ethereumAddressesLength === 0 ? 'block' : 'none',
        }}
      >
        <div
          data-done={done}
          style={{ display: done ? 'flex' : 'none' }}
          className="done-con flex h-full w-full flex-col items-center gap-8"
        >
          <Icon
            icon="charm:circle-tick"
            className="h-auto w-[120px] text-green-500"
          />
          <div className="text-center">
            <h3 className="text-xl font-medium">All done !</h3>
            <h3 className="mt-3 text-lg font-medium text-gray-500">
              Welcome to EDNS
            </h3>
          </div>
        </div>
        <div data-done={done} className="wallet-con flex flex-col gap-5">
          <h2 className="text-xl font-medium">Select Wallet Type</h2>
          <div className="flex flex-1 flex-wrap gap-5">
            <button
              onClick={() => {
                handleAddWalletClick();
                setRead(false);
                setIsUnderstand(false);
                setIsSelect(false);
              }}
              className="min-w-[250px] flex-1 rounded-lg border-2 border-[#93278f] p-3 py-5 font-medium text-[#93278f] duration-150 hover:bg-[#93278f] hover:text-white"
            >
              Bind Ethereum Wallet
            </button>
            {!isManagedWallet ? (
              <button
                onClick={() => {
                  setIsSelect(true);
                  if (isUnderstand) {
                    handleCreateManagedWallet();
                    navigate('/account');
                  } else {
                    setRead(true);
                  }
                }}
                className={`min-w-[250px] flex-1 rounded-lg border-2 border-[#93278f] p-3 py-5 font-medium  duration-150 hover:bg-[#93278f] hover:text-white ${
                  isSelect
                    ? `bg-[#93278f] text-white`
                    : `bg-white text-[#93278f]`
                }`}
              >
                Create Managed Wallet
              </button>
            ) : (
              <></>
            )}
            {!isManagedWallet && read && (
              <div className="text-xs text-black">
                Caution: The managed wallet is not intended to be used for
                transactions. If the user intends to sell their domain on any
                secondary market, the user must transfer their domain to a
                crypto wallet. Any gas fee incurred in this transfer is to be
                borne by the user. <br />{' '}
                <div className="font-center flex w-full flex-col gap-5 px-0 pt-5 text-center text-black">
                  <span className="flex items-center justify-center gap-3">
                    I understand:{' '}
                    <input
                      type="checkbox"
                      onChange={() => {
                        setIsUnderstand((prev) => !prev);
                      }}
                    />
                  </span>
                  <button
                    onClick={() => {
                      if (isUnderstand) {
                        handleCreateManagedWallet();
                        setDone(true);
                        setTimeout(() => {
                          navigate('/account');
                          navigate(0);
                        }, 2000);
                      }
                    }}
                    className="min-w-[250px] flex-1 cursor-pointer rounded-lg border-2 border-[#93278f] p-3 py-5 font-medium text-[#93278f] duration-150 hover:bg-[#93278f] hover:text-white"
                    disabled={!isUnderstand}
                  >
                    Comfirm Create
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { BlindingWallet };
