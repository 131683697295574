import { ChangeEvent, ReactNode, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';

interface ICheckbox {
  name: string;
  value?: string | number | readonly string[] | undefined;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  children?: ReactNode;
  required?: boolean;
}
function MyCheckbox(props: ICheckbox) {
  const { name, children, required } = props;
  const [checked, setChecked] = useState<boolean>(false);

  const handleChange = () => {
    setChecked(!checked);
  };
  return (
    <div className="flex items-center ">
      <FormControl>
        <FormControlLabel
          control={
            <Checkbox
              required={required}
              checked={checked}
              onChange={handleChange}
              name={name}
              color="secondary"
            />
          }
          label={children}
          className="ml-2 text-sm text-gray-900 dark:text-gray-400"
        />
      </FormControl>
    </div>
  );
}
export { MyCheckbox };
