interface ICheckoutMethodTab {
  children: string;
  isSelected?: boolean;
  onClick?: () => void;
}

function capitalizeFirstLetter(str: string): string {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const CheckoutMethodTab = (props: ICheckoutMethodTab) => {
  const { children, isSelected = false, onClick } = props;
  return (
    <div
      className={`cursor-pointer text-xl ${
        isSelected && 'border-b-4 border-violet-950 text-edns '
      }`}
      onClick={onClick}
    >
      <span>
        {capitalizeFirstLetter(children.toLowerCase())}
        {' Currency'}
      </span>
    </div>
  );
};

export { CheckoutMethodTab };
