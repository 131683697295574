import { Link } from 'react-router-dom';
import maintenance from '@/static/maintenance.jpg';

const MissingPage = () => {
  return (
    <div className="grid h-screen place-items-center" data-theme="light">
      <div className="flex flex-col gap-3 text-center">
        <img
          className="mx-auto w-[50%] min-w-[250px]"
          src={maintenance}
          alt="missing"
        ></img>
        <h1 className="text-medium mt-3 text-xl">404</h1>
        <Link to="/" className="text-sm text-gray-500">
          Click here to return EDNS website
        </Link>
      </div>
    </div>
  );
};

export default MissingPage;
