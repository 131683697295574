/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Signer, utils, Contract, ContractFactory, Overrides } from "ethers";
import { Provider, TransactionRequest } from "@ethersproject/providers";
import type { RegistryInit, RegistryInitInterface } from "../RegistryInit";

const _abi = [
  {
    inputs: [],
    name: "init",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
];

const _bytecode =
  "0x608060405234801561001057600080fd5b50610280806100206000396000f3fe608060405234801561001057600080fd5b506004361061002b5760003560e01c8063e1c7392a14610030575b600080fd5b61003861003a565b005b610042610150565b7f73eb587317bc3da9a2594a66138a7026cba8d02e0ed74cd310bde160c932a0d98054600160ff1991821681179092557ff0c9af5748a041c692c372f372dbfb0b6f5fba68c2d448e041ba6c031449f3f180548216831790557f0fe476a928892d5c9b6dd27a1724ef5282fa447e6f90619e4c2f783e4a91954d80548216831790557f8276298219093d163343f2d880fc06df0c5cffedaa0c2ca732aa572fa19c1e3d80548216831790557fb73005c118b5f4636fc5d5f3c35e7dd5bd9764a470c590de3a135a1bea2d226a80548216831790553360009081527ff31a9df2f3f8d6c73a0d00e70ef3aa1d0b11d0de90fd97e177398d19f466a5426020526040902080549091169091179055565b7fc8fcad8db84d3cc18b4c41d551ea0ee66dd599cde068d998e57d5e09332c1320547fc8fcad8db84d3cc18b4c41d551ea0ee66dd599cde068d998e57d5e09332c131c906001600160a01b031633146101dc5760405162461bcd60e51b815260206004820152600a60248201526927a7262cafa7aba722a960b11b604482015260640160405180910390fd5b6301ffc9a760e01b600090815260039091016020526040808220805460ff1990811660019081179092556307e4c70760e21b845282842080548216831790556348e2b09360e01b845282842080548216831790556307f5828d60e41b8452919092208054909116909117905556fea2646970667358221220166e3a1f05805591e853b0df2ce31aebc1e92ea2c57b321a43a9527d9b02329864736f6c63430008110033";

export class RegistryInit__factory extends ContractFactory {
  constructor(
    ...args: [signer: Signer] | ConstructorParameters<typeof ContractFactory>
  ) {
    if (args.length === 1) {
      super(_abi, _bytecode, args[0]);
    } else {
      super(...args);
    }
  }

  deploy(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): Promise<RegistryInit> {
    return super.deploy(overrides || {}) as Promise<RegistryInit>;
  }
  getDeployTransaction(
    overrides?: Overrides & { from?: string | Promise<string> }
  ): TransactionRequest {
    return super.getDeployTransaction(overrides || {});
  }
  attach(address: string): RegistryInit {
    return super.attach(address) as RegistryInit;
  }
  connect(signer: Signer): RegistryInit__factory {
    return super.connect(signer) as RegistryInit__factory;
  }
  static readonly bytecode = _bytecode;
  static readonly abi = _abi;
  static createInterface(): RegistryInitInterface {
    return new utils.Interface(_abi) as RegistryInitInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): RegistryInit {
    return new Contract(address, _abi, signerOrProvider) as RegistryInit;
  }
}
