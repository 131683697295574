import { AxiosInstance } from 'axios';
import { IPremiumDomainBalance } from '@/api/omni/PremiumDomain/interface';
import config from '@/config';

export const getPremiumDomainBalance =
  (instance: AxiosInstance) =>
  async (domain: string): Promise<IPremiumDomainBalance> => {
    const url = new URL(
      `${config.api.router.premiumDomain.balance}${domain}`,
      config.api.endpoint,
    );
    const result = await instance.get<IPremiumDomainBalance>(url.href);

    return result.data;
  };
