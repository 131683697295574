import { AxiosInstance } from 'axios';
import { ICryptoWallet } from './cryptoWallet.interface';
import { errorHandler } from '../../errorHandler';
import config from '@/config';

export const removeCryptoWallet =
  (instance: AxiosInstance) =>
  async (walletId: string): Promise<ICryptoWallet[]> => {
    try {
      const result = await instance.delete<ICryptoWallet[]>(
        `${config.api.router.cryptoWallet.remove}${walletId}`,
      );
      return result.data;
    } catch (e) {
      throw errorHandler(e);
    }
  };
