import { AxiosInstance } from 'axios';
import { IOutSearchPremiumDomain } from '@/api/omni/Domain/search';
import config from '@/config';

export const searchPremiumDomain =
  (instance: AxiosInstance) =>
  async (domain: string): Promise<IOutSearchPremiumDomain> => {
    const url = new URL(
      `${config.api.router.premiumDomain.lookup}${domain}`,
      config.api.endpoint,
    );
    const result = await instance.get<IOutSearchPremiumDomain>(url.href);

    return result.data;
  };
