import { AxiosInstance } from 'axios';
import { IDomain } from './domain.interface';
import config from '@/config';

export const listDomains =
  (instance: AxiosInstance) => async (): Promise<IDomain[]> => {
    const domains = await instance.get<IDomain[]>(
      config.api.router.domain.list,
    );
    return domains.data;
  };
