import {
  CartContext,
  GlobalStateContext,
  MyDomainContext,
  SelectDomainState,
} from '@/utils/context';
import { DateTime } from 'luxon';
import {
  DomainPurchaseMethod,
  IAddDomainToCartInput,
  addDomainToCart,
} from '@/api/omni/Cart/addDomainToCart';
import { IDomain } from '@/api/omni/Domain/domain.interface';
import { Icon } from '@iconify/react';
import { ListBtn, OptionBtn } from './MenuBtn';
import { Menu } from '@mui/material';
import { Web3Functions } from '../web3/Web3';
import { useAxios } from '@/utils/axios/AxiosProvider';
import { useContext, useState } from 'react';
import { uuid } from '@walletconnect/utils';
import toast from 'react-hot-toast';

interface IDomainMenuProps {
  reserveDomain?: boolean;
  domain: IDomain;
  listView?: boolean;
}
const DomainMenu = (props: IDomainMenuProps) => {
  const { reserveDomain, domain, listView } = props;
  const { instance } = useAxios();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { products, setIsShowCart, updateDomains } =
    useContext(GlobalStateContext);
  const { shoppingCart, refreshCart } = useContext(CartContext);
  const { setSelectedDomain, setSelectDomainState } =
    useContext(MyDomainContext);
  const isOpen = Boolean(anchorEl);

  const isExpired =
    DateTime.now().toMillis() >
    DateTime.fromISO(domain?.expiry?.toString() || '1970-01-01').toMillis();

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMigration = async () => {
    if (domain?.status === 'migration') {
      const id = uuid();
      try {
        toast.loading('Doing Migration ...', { id: id });
        const useWeb3 = new Web3Functions(+domain.blockchains[0].chainId);
        await useWeb3.migration({ domain });
        await updateDomains();
        toast.remove(id);
        toast.success(`Domain ${domain.fqdn} Migrated.`);
      } catch (e) {
        toast.remove(id);
        toast.error((e as Error).message);
      }
    }
  };
  const handleSetDefault = async () => {
    if (!domain) {
      return;
    }
    const useWeb3 = new Web3Functions(
      +domain.blockchains[0].chainId,
      undefined,
      '@',
      domain.name,
      domain.tld.name,
    );
    await useWeb3.setReverse({ _ownerAddress: domain.owner.address });
    // if (callback === undefined) return;
    // callback(domain);
  };

  const handleOnClickOption = (state: SelectDomainState) => {
    handleClose();
    setSelectDomainState(state);
    setSelectedDomain(domain);
  };

  const handleRenewDomain = async () => {
    const _product = products.find((p) => p.type === 'domain');
    if (!_product) {
      throw new Error('Product Not Found, Type:Domain');
    }
    // check cart include domain or not
    if (shoppingCart?.products.find((p) => p.domain.name === domain.name)) {
      console.log('is already existing');
      return;
    }
    const _blockchain =
      domain.blockchains.length > 0
        ? domain.blockchains[0].id
        : `ethereum::mainnet::polygon`;
    const input: IAddDomainToCartInput = {
      productId: `${_product!.id}`,
      domain: {
        method: DomainPurchaseMethod.RENEWAL,
        name: domain.name,
        duration: 1,
        tldId: domain.tld.name,
        blockchainId: _blockchain,
        walletId: domain.owner.id,
      },
    };
    await addDomainToCart(instance)(input);

    await refreshCart();
    setIsShowCart(true);
    handleClose();
  };

  const menuList: any[] = [
    {
      isShown: domain.status === 'migration' && !isExpired,
      callback: async () => {
        await handleMigration();
        handleClose();
      },
      icon: 'eos-icons:database-migration',
      label: 'Migrate',
    },
    {
      isShown: domain.status !== 'migration' && !reserveDomain && !isExpired,
      callback: async () => {
        handleClose();
        await handleSetDefault();
      },
      icon: 'mdi:cursor-default-click',
      label: 'Set as default',
    },
    {
      isShown: domain.status !== 'migration' && !isExpired && false,
      callback: async () => {
        handleClose();
      },
      icon: 'material-symbols:sell',
      label: 'List for sale',
    },
    {
      isShown: domain.status !== 'migration' && !isExpired,
      callback: async () => {
        handleOnClickOption(SelectDomainState.Transfer);
      },
      icon: 'bi:send-fill',
      label: 'Transfer',
    },
    {
      isShown:
        domain.status !== 'migration' &&
        domain.tld.type === 'universal' &&
        !isExpired &&
        domain.type !== 'premium',
      callback: async () => {
        handleOnClickOption(SelectDomainState.SwapChain);
      },
      icon: 'eva:swap-fill',
      label: 'Swap Chain',
    },
    {
      isShown:
        domain.status !== 'migration' ||
        (domain.status === 'migration' && isExpired),
      callback: async () => await handleRenewDomain(),
      icon: 'wpf:renew-subscription',
      label: 'Renewal',
    },
    {
      isShown: domain.type === 'premium',
      callback: async () => handleOnClickOption(SelectDomainState.Mortgage),
      icon: 'ri:token-swap-line',
      label: 'Mortgage',
    },
  ];

  if (!domain) {
    return <></>;
  } else if (listView) {
    return (
      <div className="flex items-center gap-x-4">
        {menuList.map((menu) => (
          <ListBtn
            key={menu.label}
            isShown={menu.isShown}
            callback={menu.callback}
            icon={menu.icon}
          >
            {menu.label}
          </ListBtn>
        ))}
      </div>
    );
  }

  return (
    <>
      <div
        className=" right-4 top-0 z-[100] flex cursor-pointer"
        onClick={handleOpenMenu}
      >
        <Icon
          className={`option h-8 w-auto cursor-pointer `}
          icon="iwwa:option"
        />
      </div>

      <Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
        {menuList.map((menu) => (
          <OptionBtn
            key={menu.label}
            isShown={menu.isShown}
            callback={menu.callback}
            icon={menu.icon}
          >
            {menu.label}
          </OptionBtn>
        ))}
      </Menu>
    </>
  );
};

export { DomainMenu };
