import { AxiosInstance } from 'axios';
import { errorHandler } from '@/api/errorHandler';
import config from '@/config';

export const applyPromoCode =
  (instance: AxiosInstance) => async (code: string) => {
    try {
      // data.append('code', code);
      await instance.post(config.api.router.cart.applyPromoCode, {
        code,
      });
    } catch (e) {
      throw errorHandler(e);
    }
  };
