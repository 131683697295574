import { AxiosInstance } from 'axios';
import { errorHandler } from '../../errorHandler';
import config from '@/config';

interface ICoingate {
  id: string;
  status: string;
  url: string;
  reference: string;
}
export const createCoingatePayment =
  (instance: AxiosInstance) =>
  async (orderId: string): Promise<ICoingate> => {
    try {
      const res = await instance.post<ICoingate>(
        `${config.api.router.payment.coingate}${orderId}/coingate`,
        {},
      );
      return res.data;
    } catch (e) {
      throw errorHandler(e);
    }
  };
