import { ReactNode } from 'react';

interface IMessageError {
  title?: string;
  children?: ReactNode;
}

function MessageError(props: IMessageError) {
  const { title, children } = props;
  return (
    <>
      {(title || children) && (
        <div className="rounded-lg bg-red-100 p-4 dark:bg-red-200" role="alert">
          {title && (
            <div className="flex items-center">
              <h3 className="text-lg font-medium text-red-700 dark:text-red-800">
                {title}
              </h3>
            </div>
          )}
          {children && (
            <div className="my-2 text-sm text-red-700 dark:text-red-800">
              {children}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export { MessageError };
