import { Button } from '../common/Button';
import {
  DNSHintsMapping,
  DNSPlaceholderMapping,
  DNSRecordTypeOptions,
  TextRecordPlaceholder,
  TextRecordTypeOptions,
} from '@/data';
import { FC, SyntheticEvent, useCallback, useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { ModalLayout } from '../common/ModalLayout';
import { SectionLayout } from '../common/SectionLayout';
import { SectionTitle } from '../common/SectionTitle';
import { SetRecordType } from '@/components/web3/Web3.interface';
import { TabPanel } from '../common/TabPanel';
import { Web3Functions } from '../web3/Web3';
import { formatsByName } from '@ensdomains/address-encoder';
import { useMyDomain } from '@/utils/context';
import config, { DeploymentEnvironment } from '@/config';

interface IProfileSetRecordProps {
  useWeb3?: Web3Functions;
  isOpenAddRecord;
  setIsOpenAddRecord;
}
export const ProfileSetRecord: FC<IProfileSetRecordProps> = ({
  useWeb3,
  isOpenAddRecord,
  setIsOpenAddRecord,
}) => {
  const { domain } = useMyDomain();
  if (!domain) throw new Error('Domain not found');

  const [dnsTTL, setDNSTTL] = useState(0);
  const [selectSubDomain, setSubDomain] = useState('');
  const [selectAddressRecordType, setSelectAddressRecordType] =
    useState<string>('');
  const [tabValue, setTabValue] = useState<SetRecordType>(
    SetRecordType.Address,
  );
  const [recordValue, setRecordValue] = useState<string>('');

  const [selectTextRecordType, setSelectTextRecordType] = useState<string>(
    TextRecordTypeOptions[0].value,
  );
  const [selectDNSRecordType, setSelectDNSRecordType] = useState<string>(
    DNSRecordTypeOptions[0].value,
  );

  const AddressRecordTypeOptions =
    Object.keys(formatsByName).filter((c) => !c.match(/_LEGACY/)) || [];

  const handleChange = (event: SyntheticEvent, newValue: SetRecordType) => {
    setTabValue(newValue);
    setSubDomain('');
  };

  // Add record to blockchain
  const handleAddRecord = useCallback(async () => {
    console.log({ useWeb3 });
    if (!useWeb3) return;

    useWeb3.updatehost(selectSubDomain === '' ? undefined : selectSubDomain);
    const result = await useWeb3.setRecord({
      _ownerAddress: domain.owner.address!,
      type: tabValue,
      text: {
        type: selectTextRecordType,
        text: recordValue,
      },
      address: {
        coinType: formatsByName[selectAddressRecordType]?.coinType || 0,
        address: recordValue,
      },
    });
    if (result) {
      setIsOpenAddRecord(false);
    }
  }, [
    useWeb3,
    selectSubDomain,
    domain.owner.address,
    tabValue,
    selectTextRecordType,
    recordValue,
    selectAddressRecordType,
    setIsOpenAddRecord,
  ]);

  return (
    <ModalLayout isOpen={isOpenAddRecord} onClose={setIsOpenAddRecord}>
      <SectionLayout className="space-y-4 bg-white">
        <SectionTitle title={'Add Domain Records'} />
        <Tabs
          value={tabValue}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label={SetRecordType.Address} value={SetRecordType.Address} />
          <Tab label={SetRecordType.Text} value={SetRecordType.Text} />
          <Tab
            label={SetRecordType.TypedAddress}
            value={SetRecordType.TypedAddress}
          />
          <Tab
            label={SetRecordType.TypedText}
            value={SetRecordType.TypedText}
          />
          {config.env === DeploymentEnvironment.DEVELOPMENT && (
            <Tab label={SetRecordType.DNS} value={SetRecordType.DNS} />
          )}
        </Tabs>
        <TabPanel value={tabValue} index={SetRecordType.TypedAddress}>
          <div className="flex flex-col space-x-4">
            <div className="mb-5 flex w-full justify-between">
              <div className="flex-1">
                <div className="mb-2 flex flex-row items-center">
                  <FormControl sx={{ maxWidth: 500 }}>
                    <TextField
                      label="Subdomain"
                      fullWidth
                      value={selectSubDomain}
                      onChange={(e) => setSubDomain(e.target.value)}
                    />
                  </FormControl>
                  <div className="pl-5 text-lg">{domain.fqdn}</div>
                </div>
                <span className="text-zinc-500">
                  Keep blank to create a record for the root domain.
                </span>
              </div>

              <FormControl sx={{ flex: '1 1' }}>
                <InputLabel id="text-record-select-label">
                  Record Type
                </InputLabel>
                <Select
                  labelId="text-record-select-label"
                  label="Record Type"
                  value={selectAddressRecordType}
                  onChange={(e) => setSelectAddressRecordType(e.target.value)}
                  MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                >
                  {AddressRecordTypeOptions.map((type) => (
                    <MenuItem key={type} value={formatsByName[type].coinType}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {/*Row Two*/}
            <div style={{ margin: '0' }} className="flex flex-col">
              <span>Value</span>
              <textarea
                onChange={(e) => setRecordValue(e.target.value)}
                rows={4}
                className="border-2 p-1"
                placeholder={`Address`}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={SetRecordType.TypedText}>
          <div className="flex flex-col space-x-4">
            <div className="mb-5 flex w-full justify-between">
              <div className="flex-1">
                <div className="mb-2 flex flex-row items-center">
                  <FormControl sx={{ maxWidth: 500 }}>
                    <TextField
                      label="Subdomain"
                      fullWidth
                      value={selectSubDomain}
                      onChange={(e) => setSubDomain(e.target.value)}
                    />
                  </FormControl>
                  <div className="pl-5 text-lg">{domain.fqdn}</div>
                </div>
                <span className="text-zinc-500">
                  Keep blank to create a record for the root domain.
                </span>
              </div>

              <FormControl sx={{ flex: '1 1' }}>
                <InputLabel id="text-record-select-label">
                  Record Type
                </InputLabel>
                <Select
                  labelId="text-record-select-label"
                  label="Record Type"
                  value={selectTextRecordType}
                  onChange={(e) => setSelectTextRecordType(e.target.value)}
                  MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                >
                  {Object.keys(TextRecordTypeOptions).map((key) => (
                    <MenuItem
                      key={key}
                      value={TextRecordTypeOptions[key].value}
                    >
                      {TextRecordTypeOptions[key].label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {/*Row Two*/}
            <div style={{ margin: '0' }} className="flex flex-col">
              <span>Value</span>
              <textarea
                rows={4}
                onChange={(e) => setRecordValue(e.target.value)}
                className="border-2 p-1"
                placeholder={TextRecordPlaceholder[selectTextRecordType]}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={SetRecordType.Address}>
          <div className="flex space-x-4">
            <div className="flex w-full flex-col">
              <div className="flex-1">
                <div className="mb-2 flex flex-row items-center">
                  <FormControl sx={{ maxWidth: 500 }}>
                    <TextField
                      label="Subdomain"
                      fullWidth
                      value={selectSubDomain}
                      onChange={(e) => setSubDomain(e.target.value)}
                    />
                  </FormControl>
                  <div className="pl-5 text-lg">{domain.fqdn}</div>
                </div>
                <span className="text-zinc-500">
                  Keep blank to create a record for the root domain.
                </span>
              </div>
              <div className="mb-5"></div>
              <div style={{ margin: '0' }} className="flex flex-col">
                <span>Value</span>
                <textarea
                  rows={4}
                  onChange={(e) => setRecordValue(e.target.value)}
                  className="border-2 p-1"
                  placeholder={'Default Address'}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={SetRecordType.Text}>
          <div className="flex space-x-4">
            <div className="flex w-full flex-col">
              <div className="flex-1">
                <div className="mb-2 flex flex-row items-center">
                  <FormControl sx={{ maxWidth: 500 }}>
                    <TextField
                      label="Subdomain"
                      fullWidth
                      value={selectSubDomain}
                      onChange={(e) => setSubDomain(e.target.value)}
                    />
                  </FormControl>
                  <div className="pl-5 text-lg">{domain.fqdn}</div>
                </div>
                <span className="text-zinc-500">
                  Keep blank to create a record for the root domain.
                </span>
              </div>
              <div className="mb-5"></div>
              <div style={{ margin: '0' }} className="flex flex-col">
                <span>Value</span>
                <textarea
                  rows={4}
                  onChange={(e) => setRecordValue(e.target.value)}
                  className="border-2 p-1"
                  placeholder={'Default Text'}
                />
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel value={tabValue} index={SetRecordType.DNS}>
          <div className="flex flex-col space-x-4">
            <div className="mb-5 flex w-full justify-between">
              <div className="flex-1">
                <div className="mb-2 flex flex-row items-center">
                  <FormControl sx={{ maxWidth: 500 }}>
                    <TextField
                      label="Subdomain"
                      fullWidth
                      value={selectSubDomain}
                      onChange={(e) => setSubDomain(e.target.value)}
                    />
                  </FormControl>
                  <div className="pl-5 text-lg">{domain.fqdn}</div>
                </div>
                <span className="text-zinc-500">
                  Keep blank to create a record for the root domain.
                </span>
              </div>

              <FormControl sx={{ flex: '1 1' }}>
                <InputLabel id="text-record-select-label">
                  Record Type
                </InputLabel>
                <Select
                  labelId="text-record-select-label"
                  label="Record Type"
                  value={selectDNSRecordType}
                  onChange={(e) => setSelectDNSRecordType(e.target.value)}
                  MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                >
                  {Object.keys(DNSRecordTypeOptions).map((key) => (
                    <MenuItem key={key} value={DNSRecordTypeOptions[key].value}>
                      {DNSRecordTypeOptions[key].label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>

            {/*Row Two*/}
            <div style={{ margin: '0' }} className="flex flex-col">
              <span>Value</span>
              <textarea
                rows={4}
                onChange={(e) => setRecordValue(e.target.value)}
                className="border-2 p-1"
                placeholder={DNSPlaceholderMapping[selectDNSRecordType]}
              />
              <span className="text-zinc-500">
                {DNSHintsMapping[selectDNSRecordType]}
              </span>
            </div>
            <div className={'mb-5'}></div>
            {/*  Row 3*/}
            <div style={{ margin: '0' }} className="flex flex-col">
              <div className="flex flex-row items-center">
                <TextField
                  label="TTL"
                  value={dnsTTL}
                  onChange={(e) => setDNSTTL(+e.target.value)}
                />
                <button
                  className=" border-2"
                  style={{
                    height: '56px',
                    width: '64px',
                    marginLeft: '1rem',
                  }}
                  onClick={() => {
                    setDNSTTL(60);
                  }}
                >
                  1m
                </button>
                <button
                  className=" border-2"
                  style={{
                    height: '56px',
                    width: '64px',
                    marginLeft: '1rem',
                  }}
                  onClick={() => {
                    setDNSTTL(3600);
                  }}
                >
                  1h
                </button>
                <button
                  className=" border-2"
                  style={{
                    height: '56px',
                    width: '64px',
                    marginLeft: '1rem',
                  }}
                  onClick={() => {
                    setDNSTTL(dnsTTL + 86400);
                  }}
                >
                  +1d
                </button>
              </div>

              <span className="text-zinc-500">
                Recommended values: 60 to 172800 (two days)
              </span>
            </div>
          </div>
        </TabPanel>
        <div className="flex justify-end">
          <Button variant="contained" color="primary" onClick={handleAddRecord}>
            Add
          </Button>
        </div>
      </SectionLayout>
    </ModalLayout>
  );
};
