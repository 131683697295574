import '@/App.scss';
import { CartContext, GlobalStateContext } from '@/utils/context';
import { CheckoutMethodTab } from '@/components/checkout/payments/CheckoutMethodTab';
import { CryptoPayment } from '@/components/checkout/payments/CryptoPayment';
import { FiatPayment } from '@/components/checkout/payments/FiatPayment';
import { IOrder } from '@/api/omni/Order/order.interface';
import { Icon } from '@iconify/react';
import { cancelOrder } from '@/api/omni/Order/cancelOrder';
import { getOrder } from '@/api/omni/Order/getOrder';
import { useAxios } from '@/utils/axios/AxiosProvider';
import { useLocation, useNavigate } from 'react-router-dom';
import Countdown, { zeroPad } from 'react-countdown';
import Nav from '@/components/Nav/Nav';
import React, { useContext, useEffect, useState } from 'react';

enum PAYMENT_TYPE {
  FIAT = 'FIAT',
  CRYPTO = 'CRYPTO',
}

const renderer = ({ minutes, seconds }: any) => {
  return (
    <span>
      {zeroPad(minutes)} : {zeroPad(seconds)}
    </span>
  );
};
export interface nowPaymentRes {
  crypto?: {
    _payment: {
      id: string;
      gateway: string;
      status: string;
      currency: string;
      requestContextId: string;
      responseContextId: string;
      callbackContextId: string;
      errorContextId: string;
      createdAt: string;
      updatedAt: string;
      expiredAt: string;
    };
    currency: string;
    amount: string;
    address: string;
  };
}

const CheckoutPage = () => {
  const { instance } = useAxios();
  const sw: boolean[] = [];
  const [order, setOrder] = useState<IOrder>();
  const { updateOrders } = useContext(GlobalStateContext);
  const { refreshCart } = useContext(CartContext);
  const [isCancelingOrder, setIsCancelingOrder] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isloading, setisLoading] = useState(true);
  const [paymentType, setPaymentType] = useState(PAYMENT_TYPE.FIAT);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const id = useQuery().get('id');

  // Get user information
  useEffect(() => {
    async function exec() {
      if (id) {
        let _order: IOrder | undefined = order;
        if (!_order) {
          _order = await getOrder(instance)(id);
          if (_order.status !== 'pending_payment')
            if (_order.status === 'canceled') {
              navigate(`/payment/canceled/${_order.id}`);
            } else {
              navigate(`/payment/success/${_order.id}`);
            }
          setOrder(_order);
        }
      }
    }
    exec().then(() => {
      setisLoading(false);
    });
    if (!id) {
      navigate('/');
    }
  }, [id, instance, navigate, order]);

  useEffect(() => {
    document
      .querySelectorAll<HTMLElement>('.bar')
      .forEach(function (bar, index) {
        sw[index] = false;
        bar.querySelectorAll<HTMLElement>('.down').forEach(function (down) {
          down.addEventListener('click', () => {
            sw[index] = !sw[index];
            if (sw[index]) {
              document
                .querySelectorAll<HTMLElement>('.bar')
                .forEach(function (bars, index) {
                  sw[index] = false;
                  bars.style.height = '95px';
                  bars
                    .querySelectorAll<HTMLElement>('.down')
                    .forEach(function (downs) {
                      downs.style.transform = 'rotate(0deg)';
                    });
                });
              down.style.transform = 'rotate(180deg)';
              sw[index] = true;
            } else {
              bar.style.height = '95px';
              down.style.transform = 'rotate(0deg)';
              sw[index] = false;
            }
          });
        });
      });
  }, [sw]);

  if (isloading) {
    return (
      <>
        <Nav title="checkout" />
        <div className="h-full w-full flex-[7] overflow-y-scroll md:pt-5">
          <section className="flex h-fit flex-col justify-center gap-8 px-6 py-10 pb-32 pt-0 text-center text-5xl font-bold md:h-full md:px-10 md:pb-0 md:pt-6 lg:flex-row">
            <div className=" flex h-fit flex-[3] flex-col gap-16 rounded-lg border bg-white p-10 px-6 md:p-16 md:px-16"></div>
          </section>
        </div>
      </>
    );
  }

  return (
    <>
      <Nav title="Checkout" />

      <div className="h-full w-full flex-[7] overflow-y-scroll md:pt-5">
        <section className="flex h-fit flex-col justify-center gap-8 px-6 py-10 pb-32 pt-0 text-center text-5xl font-bold  md:px-10 md:pb-0 md:pt-6 lg:flex-row">
          <div className=" flex h-fit flex-[3] flex-col rounded-lg border bg-white p-10 px-6 md:p-16 md:px-16 md:pt-8">
            {/*Checkout Method Type*/}
            <div className="flex justify-center">
              <div className="flex space-x-6 py-2 font-bold text-gray-500">
                {Object.keys(PAYMENT_TYPE).map((k) => (
                  <CheckoutMethodTab
                    key={k}
                    isSelected={paymentType === PAYMENT_TYPE[k]}
                    onClick={() => setPaymentType(PAYMENT_TYPE[k])}
                  >
                    {k}
                  </CheckoutMethodTab>
                ))}
              </div>
            </div>
            <div className="relative min-h-[400px]  py-8">
              <CryptoPayment
                order={order!}
                isShow={paymentType === PAYMENT_TYPE.CRYPTO}
              ></CryptoPayment>
              <FiatPayment
                order={order!}
                isShow={paymentType === PAYMENT_TYPE.FIAT}
              />
            </div>
          </div>
          {/*Order Details Box*/}
          <div className="flex flex-[1] flex-col gap-8">
            <div
              className={`fixed bottom-6 left-[50%] z-50 flex w-full max-w-7xl translate-x-[-50%] rounded-md px-8 text-base md:border lg:relative lg:bottom-0 lg:z-0 lg:px-0`}
            >
              <div className="box-shadow flex h-full w-full items-center justify-between rounded-md border bg-white p-5 text-sm font-medium lg:border-none">
                <span className="flex items-center gap-2">
                  <Icon
                    icon="fluent:timer-24-regular"
                    className="h-6 w-auto text-green-500"
                  />
                  Time Left
                </span>
                <span className="font-normal lg:font-medium">
                  {order?.expiredAt && (
                    <Countdown
                      date={new Date(order.expiredAt).getTime()}
                      renderer={renderer}
                    />
                  )}
                </span>
              </div>
            </div>
            <div className="cart-right box-shadow relative flex max-h-[18rem] flex-col justify-between gap-5 rounded-md border bg-white px-6 py-6 text-gray-700">
              <span className="block w-full border-b-2 border-gray-500 pb-3 text-left text-xl font-medium">
                Order Summary
              </span>
              <div className="flex-[1] flex-col text-sm">
                <div className="flex justify-between py-2 font-medium">
                  <span>Subtotal</span>
                  <span className="font-medium">
                    ${order?.subtotal} <span className="text-xs">USD</span>
                  </span>
                </div>
              </div>
              <div className="flex items-end justify-between pb-2 pt-2 text-2xl font-medium">
                <span>Total</span>
                <span>
                  ${order?.total} <span className="text-xs">USD</span>
                </span>
              </div>
            </div>
            <button
              onClick={() => {
                async function exec() {
                  if (id && !isCancelingOrder) {
                    setIsCancelingOrder(true);
                    // await cancelOrder(accessToken, id);
                    await cancelOrder(instance)(id);
                    setIsCancelingOrder(false);
                    await refreshCart();
                    await updateOrders();
                    navigate('/cart');
                  }
                }
                exec();
              }}
              className="mt-2 text-sm font-medium text-blue-500 duration-200 hover:underline"
            >
              Cancel Payment
            </button>
          </div>
          {/*Order Details Box */}
        </section>
      </div>

      {/* <Footer /> */}
    </>
  );
};

export default CheckoutPage;
