import { AttributeType, ProfileAttribute } from './ProfileAttribute';
import {
  Chip,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import { Fragment } from 'react';
import { HashIcon } from '../common/HashIcon';
import { ProfileDateTime } from './ProfileDateTime';
import { SectionLayout } from '../common/SectionLayout';
import { SectionTitle } from '../common/SectionTitle';
import { Web3Functions } from '../web3/Web3';
import { useMyDomain } from '@/utils/context';

interface IProfileTabMoreProps {
  useWeb3?: Web3Functions;
}

const ProfileTabMore = (props: IProfileTabMoreProps) => {
  const { useWeb3 } = props;

  const { domain } = useMyDomain();
  if (domain === undefined) throw new Error('Domain Not Found');

  const { expiry, registeredAt, fqdn, owner, tokenId } = domain;

  return (
    <>
      <SectionLayout className="bg-white">
        <div className="grid grid-cols-6">
          <ProfileDateTime
            title="Registered"
            datetime={registeredAt?.toString()}
          />
          <ProfileDateTime title="Expires" datetime={expiry?.toString()} />
        </div>
      </SectionLayout>
      <SectionLayout className="bg-white">
        <div className="space-y-4">
          <SectionTitle title="Ownership" size="2xl" />
          <List
            sx={{ width: '100%', bgcolor: 'background.paper' }}
            disablePadding
          >
            <Divider component="li" />
            <ListItem alignItems="flex-start" disablePadding>
              <ListItemButton>
                <ListItemAvatar>
                  <HashIcon text={owner.address} size="sm" />
                </ListItemAvatar>
                <ListItemText
                  primary={fqdn}
                  secondary={
                    <Fragment>
                      <Typography className="overflow-hidden" component="span">
                        <p className="truncate">{owner.address}</p>
                      </Typography>
                    </Fragment>
                  }
                />
                <Chip label="Owner" variant="outlined" className="w-16" />
              </ListItemButton>
            </ListItem>
            <Divider component="li" />
          </List>
        </div>
      </SectionLayout>
      <SectionLayout className="bg-white">
        <div className="space-y-4">
          <SectionTitle title="Token" size="2xl" />
          <Divider />
          <div className="space-y-2">
            <ProfileAttribute title="Hex" type={AttributeType.Copy} isFull>
              {`0x${Number(tokenId).toString(16)}`}
            </ProfileAttribute>
            <ProfileAttribute title="Decimal" type={AttributeType.Copy} isFull>
              {tokenId!.toString()}
            </ProfileAttribute>
          </div>
          <Divider />
          <ProfileAttribute title="Wrapper" type={AttributeType.Copy} isFull>
            {useWeb3?.getContractAddress('DefaultWrapper')}
          </ProfileAttribute>
        </div>
      </SectionLayout>
      <SectionLayout className="bg-white">
        <div className="space-y-4">
          <SectionTitle title="Resolver" size="2xl" />

          <ProfileAttribute title="Wrapper" type={AttributeType.Copy} isFull>
            {useWeb3?.getContractAddress('PublicResolver')}
          </ProfileAttribute>
        </div>
      </SectionLayout>
    </>
  );
};

export { ProfileTabMore };
