import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ICart, ICartProduct } from '@/api/omni/Cart/cart.interface';
import { IPromoCode } from '@/api/omni/PromoCode/promoCode.interface';
import { applyPromoCode } from '@/api/omni/Cart/applyPromoCode';
import { deletePromoCode } from '@/api/omni/Cart/deletePromoCode';
import { getCart } from '@/api/omni/Cart/getCart';
import { removeCartProduct } from '@/api/omni/Cart/removeCartProduct';
import { useAuth } from '../auth/hook';
import { useAxios } from '../axios/AxiosProvider';
import _ from 'lodash';

interface ICartContext {
  isLoadingCart: boolean;
  shoppingCart?: ICart;
  setShoppingCart: Dispatch<SetStateAction<ICart | undefined>>;
  subtotal?: number;
  total?: number;
  products?: ICartProduct[];
  productsCount: number;
  promoCodes?: IPromoCode[];
  refreshCart: () => void;
  deleteCartProduct: (id: string) => void;
  handleApplyPromoCode: (code: string) => void;
  handleDeletePromoCode: (code: string) => void;
}

const CartContext = createContext<ICartContext>({
  isLoadingCart: false,
  shoppingCart: undefined,
  setShoppingCart: () => {},
  subtotal: undefined,
  total: undefined,
  products: undefined,
  productsCount: 0,
  promoCodes: undefined,
  refreshCart: () => {},
  deleteCartProduct: () => {},
  handleApplyPromoCode: () => {},
  handleDeletePromoCode: () => {},
});

function CartProvider({ children }: { children: ReactNode }) {
  const [isLoadingCart, setIsLoadingCart] = useState<boolean>(false);
  const [shoppingCart, setShoppingCart] = useState<ICart>();
  const [subtotal, setSubtotal] = useState<number>();
  const [total, setTotal] = useState<number>();
  const [products, setProducts] = useState<ICartProduct[]>();
  const [productsCount, setProductsCount] = useState<number>(0);
  const [promoCodes, setPromoCodes] = useState<IPromoCode[]>();

  const { isAuthenticated } = useAuth();
  const { instance } = useAxios();

  // fetch cart information
  const refreshCart = useCallback(async () => {
    if (!isAuthenticated) return;
    const _shoppingCart = await getCart(instance)();
    setShoppingCart(_shoppingCart);
  }, [instance, isAuthenticated]);

  // delete cart product
  const deleteCartProduct = useCallback(
    async (productId: string) => {
      await removeCartProduct(instance)(`${productId}`);
      await refreshCart();
    },
    [instance, refreshCart],
  );

  // apply promo code
  const handleApplyPromoCode = useCallback(
    async (code: string) => {
      await applyPromoCode(instance)(code);
    },
    [instance],
  );

  // delete promo code
  const handleDeletePromoCode = useCallback(
    async (code: string) => {
      await deletePromoCode(instance)(code);
    },
    [instance],
  );

  useEffect(() => {
    refreshCart();
  }, [refreshCart]);

  // update cart product
  useEffect(() => {
    const exec = async () => {
      setIsLoadingCart(true);
      await refreshCart();
      setIsLoadingCart(false);
    };
    exec();
  }, [refreshCart]);

  useEffect(() => {
    if (shoppingCart) {
      setSubtotal(shoppingCart.subtotal);
      setTotal(shoppingCart.total);
      setProducts(_.sortBy(shoppingCart.products, 'id'));
      setPromoCodes(shoppingCart.promoCodes);
    }
  }, [shoppingCart]);

  useEffect(() => {
    if (products) {
      setProductsCount(products.length);
    } else {
      setProductsCount(0);
    }
  }, [products]);

  const value = useMemo<ICartContext>(
    () => ({
      isLoadingCart,
      shoppingCart,
      setShoppingCart,
      subtotal,
      total,
      products,
      productsCount,
      promoCodes,
      refreshCart,
      deleteCartProduct,
      handleApplyPromoCode,
      handleDeletePromoCode,
    }),
    [
      isLoadingCart,
      shoppingCart,
      subtotal,
      total,
      products,
      productsCount,
      promoCodes,
      refreshCart,
      deleteCartProduct,
      handleApplyPromoCode,
      handleDeletePromoCode,
    ],
  );
  return <CartContext.Provider value={value}>{children}</CartContext.Provider>;
}

export { CartContext, CartProvider };
