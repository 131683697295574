import { Attribute } from './Attribute';
import { CrossChainProvider } from '@/components/web3/Web3.interface';
import { Icon } from '@iconify/react';

interface IBridgeProps {
  name: string;
  onClick?: (e: CrossChainProvider) => void;
  disable?: boolean;
  selected?: boolean;
}

function Bridge(props: IBridgeProps) {
  const { name, disable, selected, onClick } = props;
  const textColor = disable ? 'text-gray-400' : 'text-edns';
  return (
    <div className={textColor}>
      <div
        className={`flex items-center justify-between ${
          selected && 'rounded-2xl bg-edns text-white'
        }`}
        onClick={() => onClick && !disable && onClick(CrossChainProvider[name])}
      >
        <div
          className={`${!disable && 'cursor-pointer'} p-2  text-lg font-bold`}
        >
          {name}
        </div>
        <div className="px-2">
          {selected && (
            <Icon
              icon="teenyicons:tick-circle-solid"
              className={` h-6 w-6 p-1 text-white`}
            />
          )}
          {disable && <div className={textColor}>Coming Soon</div>}
        </div>
      </div>
      <div className="w-full border"></div>
      <div className="flex hidden flex-col">
        <Attribute title="Arrival Time" color={textColor}>
          --
        </Attribute>
        <Attribute title="Fee" color={textColor}>
          --
        </Attribute>
      </div>
    </div>
  );
}

export { Bridge };
