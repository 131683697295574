import { AxiosInstance } from 'axios';
import { IPremiumDomain } from '@/api/omni/PremiumDomain/interface';
import { errorHandler } from '@/api/errorHandler';
import config from '@/config';

export const premiumDomainListRequest =
  (instance: AxiosInstance) => async (): Promise<IPremiumDomain[]> => {
    try {
      const res = await instance.get(`${config.api.router.premiumDomain.list}`);
      return res.data;
    } catch (e) {
      throw errorHandler(e);
    }
  };
