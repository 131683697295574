import { AxiosContext } from '@/utils/axios/AxiosProvider';
import { AxiosInstance } from 'axios';
import { CountryOptions } from '@/components/checkout/payments/countryOptions';
import {
  IPayment,
  IPaymentMain,
} from '@/components/checkout/payments/paymentProvider.interface';
import { createCheckoutPayment } from '@/api/omni/Payment-gateway/checkout';
import { createMixPayment } from '@/api/omni/Payment-gateway/mixpay';
import CheckOutDotComSvg from '@/static/payment/checkout_dot_com.svg';
import PaymentCard from '@/components/checkout/payments/paymentCard';
import React, { useContext, useState } from 'react';
import Select from 'react-select';
import UnionPaySvg from '@/static/payment/union-pay.svg';
import i18nIsoCountries from 'i18n-iso-countries';

const handleCreateMixPay =
  (instance: AxiosInstance) => async (orderId: string) => {
    // const link = await createCoingatePay(accessToken, order?.id);
    const link = await createMixPayment(instance)(orderId);
    window.location.href = link.url;
  };
const handleCreateUnionPay =
  (instance: AxiosInstance) => async (orderId: string) => {
    console.log('UnionPayDisable');
  };

const FiatPayment = (props: IPaymentMain) => {
  const { instance } = useContext(AxiosContext);
  const [country, setCountry] = useState<string | undefined>();

  const renderContry = () => {
    const option: { value: string; label: string }[] = CountryOptions.map(
      (x) => x.value,
    );
    return (
      <div>
        <div className=" flex flex-[1] flex-col gap-5">
          <span>Please select the country</span>
          <div className="h-16">
            <div className="h-full w-full rounded-md outline-blue-500">
              <Select
                options={option}
                isSearchable
                name="color"
                defaultValue={option[0]}
                value={
                  country
                    ? {
                        label: i18nIsoCountries.getName(country, 'en'),
                        value: country,
                      }
                    : option[0]
                }
                className="h-full"
                onChange={(_option) => {
                  setCountry(_option?.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const HandlerCreateCheckout = (instance) => async (orderId) => {
    if (country) {
      // const res = await createCheckoutPay(accessToken, order?.id, country, countryCheck);
      const res = await createCheckoutPayment(instance)(orderId, {
        country,
        rememberCountrySetting: false,
      });
      window.location.href = res.url;
    }
  };
  const CryptoPaymet: IPayment = {
    Coingate: {
      header: 'Credit Card',
      point: ['Powered By Checkout.com', 'Accepted Credit Cards'],
      disable: false,
      children: renderContry(),
      icon: <img src={CheckOutDotComSvg} alt="coingate" />,
      onCreate: HandlerCreateCheckout,
    },
    MixPay: {
      header: 'Union Pay',
      point: ['Powered By Union Pay.', 'Accepted Union Pay'],
      disable: true,
      icon: <img src={UnionPaySvg} alt="mixpay" />,
      onCreate: handleCreateUnionPay,
    },
  };

  if (!props.isShow) {
    return <></>;
  }
  return (
    <div className="flex h-full  flex-col  justify-center gap-8 lg:flex-row">
      {Object.values(CryptoPaymet).map((payment) => (
        <PaymentCard
          amount={props.order.total}
          name={payment.header}
          point={payment.point}
          isSelected={true}
          icon={payment.icon}
          children={payment.children ? payment.children : <></>}
          onCreate={payment.onCreate(instance)}
          orderId={props.order.id}
          disable={payment.disable}
        ></PaymentCard>
      ))}
    </div>
  );
};

export { FiatPayment };
