import {
  AXIOS_INIT_STATE,
  AxiosCountReducer,
  AxiosState,
  IAxiosState,
} from './AxiosCountReducer';
import { ReqLoadingProgress } from './interceptors/requesets/ReqLoadingProgress';
import { ReqToken } from './interceptors/requesets/ReqToken';
import { RequestError } from './interceptors/requesets/RequestError';
import { ResLoadingProcess } from './interceptors/responses/ResLoadingProcess';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import axios, { AxiosInstance } from 'axios';
import config from '@/config';

interface IAxiosContext {
  instance: AxiosInstance;
  resolverGdn: AxiosInstance;
  loadingCount: IAxiosState;
}
const axiosConfig = {
  baseURL: config.api.endpoint,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 50000,
};
const resolverGdnConfig = {
  baseURL: config.api.resolverGdn.endPoint,
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 50000,
};

const instance = axios.create(axiosConfig);
const resolverGdn = axios.create(resolverGdnConfig);

const AxiosContext = createContext<IAxiosContext>({
  instance,
  resolverGdn,
  loadingCount: AXIOS_INIT_STATE,
});

const AxiosProvider = ({ children }) => {
  const [loadingCount, dispatchLoadingCount] = useReducer(
    AxiosCountReducer,
    AXIOS_INIT_STATE,
  );

  const handleReqCallback = () => {
    dispatchLoadingCount({ type: AxiosState.REQUEST });
  };

  const handleResCallback = () => {
    dispatchLoadingCount({ type: AxiosState.RESPONSE });
  };

  useEffect(() => {
    if (
      loadingCount.totalCount > 0 &&
      loadingCount.totalCount === loadingCount.count
    ) {
      // wait 0.5s
      setTimeout(() => {
        dispatchLoadingCount({ type: AxiosState.END });
      }, 500);
    }
  }, [loadingCount]);

  useEffect(() => {
    instance.interceptors.request.use(
      ReqToken,
      RequestError(handleResCallback),
    );
    instance.interceptors.request.use(
      ReqLoadingProgress(handleReqCallback),
      RequestError(handleResCallback),
    );

    instance.interceptors.response.use(
      ResLoadingProcess(handleResCallback),
      RequestError(handleResCallback),
    );

    resolverGdn.interceptors.request.use(
      ReqLoadingProgress(handleReqCallback),
      RequestError(handleResCallback),
    );

    resolverGdn.interceptors.response.use(
      ResLoadingProcess(handleResCallback),
      RequestError(handleResCallback),
    );
  }, []);

  const value = useMemo(() => {
    return {
      instance,
      resolverGdn,
      loadingCount,
    };
  }, [loadingCount]);
  return (
    <AxiosContext.Provider value={value}>{children}</AxiosContext.Provider>
  );
};

const useAxios = () => {
  return useContext(AxiosContext);
};

export { AxiosContext, AxiosProvider, useAxios };
