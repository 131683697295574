import '@/App.scss';
import { motion } from 'framer-motion';
import Footer from '@/components/Footer';
import Nav from '@/components/Nav/Nav';
import bg from '@/static/bg.webp';

const AboutPage = () => {
  return (
    <>
      <Nav />
      <section
        className="relative min-h-[250px] bg-cover bg-no-repeat lg:min-h-[300px]"
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div className="absolute bottom-8 h-auto w-full text-center text-5xl text-white md:font-bold lg:text-5xl">
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ ease: 'easeOut', delay: 0.1, duration: 0.6 }}
            viewport={{ once: true }}
            className="mx-auto px-8 text-left font-medium lg:max-w-7xl lg:px-14"
          >
            About us
          </motion.div>
        </div>
      </section>
      <section className="py-40 text-center text-2xl md:py-[20rem] md:text-8xl">
        Content
      </section>
      <Footer />
    </>
  );
};

export default AboutPage;
