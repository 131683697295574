import { IDomain } from '@/api/omni/Domain/domain.interface';
import { JSX, useContext, useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { getDomainDetails } from '@/api/omni/Domain/detail';
import { useParams } from 'react-router';
import Nav from '@/components/Nav/Nav';

import { ProfileTab } from '@/components/Profile/ProfileTab';

import { GlobalStateContext } from '@/utils/context/GlobalStateContext';
import {
  IAllRecords,
  IGetResolverInput,
  IResolverGdn,
  ISubDomain,
} from '@/api/resolver/interface';
import { MyDomainProvider } from '@/utils/context';
import { Network } from '@/utils/enums';
import { ProfileTabMain } from '@/components/Profile/ProfileTabMain';
import { ProfileTabMore } from '@/components/Profile/ProfileTabMore';
import { ProfileTabSubdomains } from '@/components/Profile/ProfileTabSubdomains';
import { Web3Functions } from '@/components/web3/Web3';
import { extractFqdn } from '@/utils/scripts/extractFqdn';
import { getAllRecords } from '@/api/resolver/getAllRecords';
import { getSubDomain } from '@/api/resolver/getSubDomain';
import { useAxios } from '@/utils/axios/AxiosProvider';
import { useSearchParams } from 'react-router-dom';
import config, { DeploymentEnvironment } from '@/config';

enum TabType {
  Profile = 'profile',
  // Records = 'records',
  Subdomains = 'subdomains',
  More = 'more',
}
interface IEmit {
  [tab: string]: JSX.Element;
}

const DEFAULT_EMIT: IEmit = {
  [TabType.Profile]: <></>,
  // [TabType.Records]: <></>,
  [TabType.Subdomains]: <></>,
  [TabType.More]: <></>,
};

const DomainPage = () => {
  // get domain from url
  const { fqdn = '' } = useParams();
  let [searchParams, setSearchParms] = useSearchParams();
  const tab = searchParams.get('tab') || TabType.Profile;
  const { host = '@', domain, tld } = extractFqdn(fqdn);
  const { instance, resolverGdn } = useAxios();

  const [allRecords, setAllRecords] = useState<IResolverGdn<IAllRecords>>();
  const [subDomains, setSubDomains] = useState<IResolverGdn<ISubDomain>>();
  const [useWeb3, setUseWeb3] = useState<Web3Functions>();
  const { getProvider } = useContext(GlobalStateContext);
  const [domainDetails, setDomainDetails] = useState<IDomain>();
  const [emitComponent, setEmitComponent] = useState<IEmit>(DEFAULT_EMIT);

  // handle tab change event
  const handleOnChangeTab = (tab: TabType): void => {
    setSearchParms({ tab: tab });
  };

  const handleEmit = (tab, component: JSX.Element = <></>) => {
    setEmitComponent((e) => ({ ...e, [tab]: component }));
  };

  // Base on the tab selected, get the corresponding profile tab
  useEffect(() => {}, [tab]);

  // Fetch data from backend when set fqdn
  useEffect(() => {
    const exec = async () => {
      const domain = await getDomainDetails(instance)(fqdn);
      setDomainDetails(domain);
    };
    exec();
  }, [fqdn, instance]);

  useEffect(() => {
    const exec = async () => {
      if (domainDetails) {
        const getResolverPayload: IGetResolverInput = {
          fqdn,
          net:
            config.env !== DeploymentEnvironment.DEVELOPMENT
              ? Network.MAINNET
              : Network.TESTNET,
          chain_id: Number(domainDetails.blockchains[0].chainId),
        };
        const records = await getAllRecords(resolverGdn)(getResolverPayload);
        const subDomains = await getSubDomain(resolverGdn)(getResolverPayload);

        setSubDomains(subDomains);
        setAllRecords(records);
      }
    };
    exec();
  }, [fqdn, domainDetails, resolverGdn]);

  // Set useWeb3 on init
  useEffect(() => {
    const exec = async () => {
      if (useWeb3 || !domainDetails) return;
      const chainId = +domainDetails!.blockchains[0]?.chainId;
      const _useWeb3 = new Web3Functions(
        chainId,
        await getProvider(chainId),
        host,
        domain,
        tld,
      );
      console.log(_useWeb3);
      setUseWeb3(_useWeb3);
    };
    exec();
  }, [domainDetails, getProvider, domain, tld, useWeb3, host]);

  if (!useWeb3 || !domainDetails) {
    return <>initing rpc</>;
  }
  return (
    <>
      <MyDomainProvider domain={domainDetails}>
        <Nav title="Domain Info" />
        <Toaster />
        <div className="flex w-full flex-col space-y-4 overflow-x-hidden overflow-y-scroll px-6 pb-6 max-md:max-w-[100dvw] md:px-8 md:pb-8">
          <div>
            <div>
              <div className="text-3xl font-bold">{fqdn}</div>
            </div>

            <div></div>
          </div>

          <div className="flex flex-wrap justify-between gap-2 max-md:ml-[-1.5rem] max-md:w-[100dvw]">
            <div className="flex space-x-6 overflow-auto py-2 font-medium text-gray-500 max-md:pl-[1.5rem] max-md:pr-[2rem]">
              {Object.keys(TabType).map((key) => (
                <ProfileTab
                  key={key}
                  isSelected={tab === TabType[key]}
                  onClick={() => handleOnChangeTab(TabType[key])}
                >
                  {key}
                </ProfileTab>
              ))}
            </div>
            <div className="max-md:ml-[1.5rem]">{emitComponent[tab]}</div>
          </div>
          {tab === TabType.Profile && domainDetails !== undefined && (
            <ProfileTabMain fqdn={fqdn} tld={tld} useWeb3={useWeb3} />
          )}
          {/* {tab === TabType.Records && domainDetails !== undefined && (
            <ProfileTabRecords
              fqdn={fqdn}
              allRecords={allRecords?.data}
              emit={(e) => handleEmit(TabType.Records, e)}
            />
          )} */}
          {tab === TabType.Subdomains && domainDetails !== undefined && (
            <ProfileTabSubdomains
              subDomains={subDomains}
              emit={(e) => handleEmit(TabType.Subdomains, e)}
              useWeb3={useWeb3}
            />
          )}
          {tab === TabType.More && domainDetails !== undefined && (
            <ProfileTabMore />
          )}
        </div>
      </MyDomainProvider>
    </>
  );
};

export default DomainPage;
