import { ReactNode } from 'react';

interface IProfileSubSectionProps {
  title: string;
  children?: ReactNode | ReactNode[];
}
const ProfileSubSection = (props: IProfileSubSectionProps) => {
  const { title, children } = props;

  return (
    <div className="space-y-2">
      <div className="font-bold text-gray-500">{title}</div>
      <div className="flex flex-wrap gap-2">{children}</div>
    </div>
  );
};

export { ProfileSubSection };
