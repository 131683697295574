import { AxiosInstance } from 'axios';
import { IProduct } from './product.interface';
import config from '@/config';

export const listProducts =
  (instance: AxiosInstance) => async (): Promise<IProduct[]> => {
    const products = await instance.get<IProduct[]>(
      config.api.router.product.list,
    );
    return products.data;
  };
