import { AxiosInstance } from 'axios';
import { errorHandler } from '../../errorHandler';
import config from '@/config';

interface IRevealManagedWalletPrivateKey {
  walletId: string;
  accessToken?: string;
}

export const revealManagedWalletPrivateKey =
  (instance: AxiosInstance) =>
  async (input: IRevealManagedWalletPrivateKey): Promise<string> => {
    try {
      const res = await instance.get<{ privateKey: string }>(
        `${config.api.router.cryptoWallet.revealManagedWalletPrivateKey}${input.walletId}`,
      );
      return res.data.privateKey;
    } catch (e) {
      throw errorHandler(e);
    }
  };
