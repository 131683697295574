import { useContext, useEffect } from 'react';
// import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@/components/common/Button';
import { Chip, InputAdornment, TextField } from '@mui/material';
import { GlobalStateContext } from '@/utils/context/GlobalStateContext';
import { HashIcon } from '@/components/common/HashIcon';
import { SectionLayout } from '@/components/common/SectionLayout';
import { SectionTitle } from '@/components/common/SectionTitle';
import Nav from '@/components/Nav/Nav';
import config from '@/config';
import i18nIsoCountries from 'i18n-iso-countries';

const SettingPage = () => {
  const { user } = useContext(GlobalStateContext);

  const { email } = user || { email: '' };

  useEffect(() => {
    const _countryOptions = new Set<{ value: string; label: string }>();
    const names = i18nIsoCountries.getNames('en');
    for (const code in names) {
      _countryOptions.add({ label: names[code], value: code });
    }
  }, []);

  const handleChangePasswordClick = async () => {
    window.location.href = `${config.auth0.url}/auth/realms/${config.auth0.realm}/account/#/`;
  };

  return (
    <>
      <Nav title="Settings" />
      <div className="flex flex-1 flex-col overflow-y-auto p-10 px-6 pt-0">
        <div className="flex flex-1 flex-col gap-8 md:min-w-[550px]">
          <div className="flex flex-1 flex-col gap-5">
            <SectionLayout className="bg-white">
              <div className="space-y-4">
                <SectionTitle title={'Account Setting'} />
                <div className="flex gap-6">
                  <HashIcon text={user?.username || ''} size="xl" />
                  <div className="flex items-center">
                    <div className="flex flex-col">
                      <div className="text-lg font-medium">
                        {user?.default_address || user?.username}
                      </div>

                      <Chip label={user?.username} variant="outlined" />
                    </div>
                  </div>
                </div>

                <TextField
                  variant="outlined"
                  value={email}
                  disabled
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Email</InputAdornment>
                    ),
                  }}
                />

                <Button onClick={handleChangePasswordClick}>
                  Change Password
                </Button>
              </div>
            </SectionLayout>
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingPage;
