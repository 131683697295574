import { AxiosInstance } from 'axios';
import { ICart } from './cart.interface';
import { errorHandler } from '../../errorHandler';
import config from '@/config';

export enum DomainPurchaseMethod {
  REGISTER = 'register',
  RENEWAL = 'renewal',
}

export interface IAddDomainToCartInput {
  productId: string;
  domain: {
    method: DomainPurchaseMethod;
    name: string;
    duration: number;
    tldId: string;
    blockchainId: string;
    walletId: string;
  };
}
export const addDomainToCart =
  (instance: AxiosInstance) =>
  async (input: IAddDomainToCartInput): Promise<ICart> => {
    try {
      const result = await instance.post(
        config.api.router.cart.addDomain,
        input,
      );
      return result.data;
    } catch (e) {
      throw errorHandler(e);
    }
  };
