import { IOrder } from './order.interface';

import { AxiosInstance } from 'axios';
import { errorHandler } from '../../errorHandler';
import config from '@/config';

interface IListOrder {
  orders: IOrder[];
}
export const listOrders =
  (instance: AxiosInstance) => async (): Promise<IOrder[]> => {
    try {
      const response = await instance.get<IListOrder>(
        config.api.router.order.listOrder,
      );
      return response.data.orders;
    } catch (e) {
      throw errorHandler(e);
    }
  };
