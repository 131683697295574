import { IBlockchain } from '@/api/omni/Blockchain/blockchain.interface';
// eslint-disable-next-line import/no-unresolved
import { ISearchDomainDto } from '../../Pages/search';

import { Icon } from '@iconify/react';
import { SectionTitle } from '@/components/common/SectionTitle';
import { Skeleton, Tooltip } from '@mui/material';
import { getChainIcon } from '@/components/web3/getChainIcon';
import token from '@/static/token.png';

interface IResultDomainSectionProps {
  domain?: ISearchDomainDto | undefined;
  blockchain?: IBlockchain | undefined;
  isPremiumDomain?: boolean | undefined;
  isLoading: boolean;
  token?:
    | {
        deposit: number;
        bonus: number;
      }
    | undefined;
}
const ResultDomainSection = (props: IResultDomainSectionProps) => {
  const { domain, blockchain, isLoading } = props;
  if (isLoading) {
    return (
      <div className="z-50 flex w-full gap-5">
        <div className="box-shadow relative flex w-full flex-1 flex-col overflow-hidden rounded-3xl border bg-white">
          <div className="flex flex-col gap-6 p-6 pt-6 md:gap-8 md:p-8">
            <div className="flex items-center space-x-4">
              <Skeleton height={52} width={'250px'} />
            </div>

            <div className="flex flex-col items-center justify-between gap-4 sm:flex-row md:gap-0">
              <div className="flex w-full flex-col gap-2 md:w-fit md:justify-center">
                <span className="flex gap-6 text-xl font-medium md:gap-8 md:text-3xl">
                  <div className="flex flex-1 items-center gap-6 overflow-hidden">
                    <div className="flex items-center justify-end space-x-2">
                      <Skeleton height={36} width={'500px'} />
                    </div>
                  </div>
                </span>
              </div>

              <div className="text-3xl font-semibold">
                <Skeleton height={36} width={'160px'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="z-50 flex w-full gap-5">
      <div className="box-shadow relative flex w-full flex-1 flex-col overflow-hidden rounded-3xl border bg-white">
        <div className="flex flex-col">
          <div className="flex items-center space-x-4 border-b p-6 py-5">
            <SectionTitle
              size="xl"
              title={
                props.isPremiumDomain === true
                  ? 'Register Premium Domain'
                  : 'Register Domain'
              }
            />

            {blockchain && (
              <div className="hidden h-10 w-10 sm:block">
                {getChainIcon(+blockchain?.chainId)}
              </div>
            )}
          </div>
          <div className="flex flex-row flex-wrap items-center justify-between gap-4 p-6 py-8 md:gap-0">
            <div className="flex w-full flex-col gap-2 md:w-fit md:justify-center">
              <span className="flex gap-6 text-xl font-medium md:gap-8 md:text-3xl">
                <div className="flex flex-1 items-center gap-6 overflow-hidden">
                  {/* <div className="flex items-center justify-end space-x-2">
                    <Availability
                      isAvailable={domain?.available}
                      size={IconSize.BASE}
                    />
                  </div> */}
                  <div className="flex text-2xl md:text-3xl">
                    <span className="truncate font-normal">
                      {domain?.domain}
                    </span>
                    <Tooltip title={blockchain?.name || ''} arrow>
                      <span className="font-semibold text-purple-500">
                        .{domain?.tld.name}
                      </span>
                    </Tooltip>
                  </div>
                </div>
              </span>
            </div>

            <div className="flex shrink-0 flex-nowrap items-end gap-1 text-3xl font-semibold">
              USD {domain?.price}
              <span className="text-lg font-normal text-gray-300">/ year</span>
            </div>
          </div>
          {props.token && (
            <div className="flex items-center gap-4 p-6 pt-0">
              {/*TODO Token UIUX*/}
              <div className="grid aspect-square w-12 place-items-center overflow-hidden rounded-full border-2 p-1">
                <img src={token} className="w-8" alt="" />
              </div>
              <div className="flex flex-col font-medium">
                <div>
                  Deposit Token{' '}
                  <p className="float-right ml-2">{props.token.deposit}</p>
                </div>
                <Icon
                  icon="icon-park-solid:down-two"
                  className="mx-auto text-purple-500"
                />
                <div>
                  Bonus Token{' '}
                  <p className="float-right ml-2">{props.token.bonus}</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { ResultDomainSection };
