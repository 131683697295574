import { IBlockchain } from '@/api/omni/Blockchain/blockchain.interface';
import { Icon } from '@iconify/react';
import { getChainIcon } from '@/components/web3/getChainIcon';

interface IBlockchainButtonProps {
  blockchain: IBlockchain;
  setSelected: (blockchain: IBlockchain) => void;
  selected?: IBlockchain | undefined;
  disable?: boolean;
}

function BlockchainButton(props: IBlockchainButtonProps) {
  const { blockchain, setSelected, selected } = props;

  if (props.disable) {
    return (
      <div
        className={`flex flex-col items-center justify-center gap-2 overflow-hidden rounded-xl p-4 grayscale`}
        aria-label="centered"
      >
        <div className="relative overflow-hidden">
          <div className="flex h-16 w-16 items-center rounded-full bg-gray-200 p-3.5">
            {getChainIcon(+blockchain.chainId)}
          </div>
          <Icon
            icon="fontisto:locked"
            className={`absolute left-[50%] top-[50%] block h-7 w-7 p-1 text-[#bb29bb]`}
          />
        </div>
        <div
          className={`${
            selected?.id === blockchain.id && 'text-[#bb29bb]'
          } max-w-full truncate`}
        >
          {blockchain.name}
        </div>
      </div>
    );
  }

  return (
    <div
      className={`flex flex-col items-center justify-center gap-2 overflow-hidden rounded-xl p-4 hover:grayscale-0 ${
        selected?.id === blockchain.id ? 'grayscale-0' : 'grayscale'
      }`}
      onClick={(b) => {
        setSelected(blockchain);
      }}
      aria-label="centered"
    >
      <div className="relative overflow-hidden">
        <div className="flex h-16 w-16 items-center rounded-full bg-gray-200 p-3.5">
          {getChainIcon(+blockchain.chainId)}
        </div>
        <Icon
          icon="teenyicons:tick-circle-solid"
          className={`absolute left-[50%] top-[50%] h-7 w-7 p-1 text-[#bb29bb] ${
            selected?.id === blockchain.id ? 'block' : 'hidden'
          }`}
        />
      </div>
      <div
        className={`${
          selected?.id === blockchain.id && 'text-[#bb29bb]'
        } max-w-full truncate`}
      >
        {blockchain.name}
      </div>
    </div>
  );
}

export { BlockchainButton };
