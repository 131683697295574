import '@/App.scss';
import { BlockchainButton } from '@/components/Blockchain/BlockchainButton';
import { CartContext } from '@/utils/context/CartContext';
import {
  DomainPurchaseMethod,
  IAddDomainToCartInput,
  addDomainToCart,
} from '@/api/omni/Cart/addDomainToCart';
import {
  DomainSearchType,
  IOutSearchDomain,
  ISearchDomain,
  searchDomain,
} from '@/api/omni/Domain/search';
import { GlobalStateContext } from '@/utils/context/GlobalStateContext';
import { IBlockchain } from '@/api/omni/Blockchain/blockchain.interface';
import { ICryptoWallet } from '@/api/omni/CryptoWallet/cryptoWallet.interface';
import { IProduct } from '@/api/omni/Product/product.interface';
import { ITld } from '@/api/omni/Tld/tld.interface';
import { Icon } from '@iconify/react';
import { ResultDomainSection } from '../../components/Search/ResultDomainSection';
import { SectionTitle } from '@/components/common/SectionTitle';
import { Tab, Tabs, Tooltip } from '@mui/material';
import { TabPanel } from '@/components/common/TabPanel';
import { TldButtonGroup } from '@/components/Blockchain/TldButtonGroup';
import { motion } from 'framer-motion';
import { useAuth } from '@/utils/auth/hook';
import { useAxios } from '@/utils/axios/AxiosProvider';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Nav from '../../components/Nav/Nav';

export interface ISearchDomainDto {
  domain: string;
  tld: ITld;
  // blockchain_id: IBlockchain;
  price: number;
  available?: DomainSearchType;
}
interface IDomainList {
  recommend: ISearchDomainDto[];
}

export interface IUserResult {
  id: string;
  provider: string;
  status: string;
  username: string;
  email: string;
  defaultEthereumAddress: null;
  ethereumAddresses: [];
  billingAddress: {};
  shoppingCart: {};
  role: string;
  languagePreference: string;
  createdAt: string;
}

enum TLDTYPE {
  CLASSICAL = 'Classical',
  UNIVERSAL = 'Universal',
}

function SearchPage() {
  const { instance } = useAxios();

  const {
    tlds,
    insertCacheDomain,
    products,
    cryptoWallets,
    blockChains,
    setIsShowCart,
    user,
  } = useContext(GlobalStateContext);

  const { isAuthenticated } = useAuth();
  const { products: cartProducts, refreshCart } = useContext(CartContext);
  const [domainList, setDomainList] = useState<IDomainList | undefined>(
    undefined,
  );
  const [isAddingDomainToShoppingCart, setIsAddingDomainToShoppingCart] =
    useState<boolean>(false);
  const [isAddedDomainToShoppingCart, setIsAddedDomainToShoppingCart] =
    useState<boolean>(false);

  const [cartReload, setCartReload] = useState<boolean>(false);
  const [add, setAdd] = useState<boolean>(false);
  const regex = new RegExp(
    /^[a-z0-9\-_\u00a9\u00ae\u2000-\u3300\ud83c\ud000-\udfff\ud83d\ud000-\udfff\ud83e\ud000-\udfff]{5,128}$/,
  );
  const { login } = useAuth();
  const urlParams = useParams();
  const fqdn = urlParams.fqdn?.toLowerCase();
  const navigate = useNavigate();
  const [domainProduct, setDomainProduct] = useState<IProduct | undefined>(
    undefined,
  );
  const [selectedTldType, setSelectTldType] = useState<TLDTYPE>(
    TLDTYPE.CLASSICAL,
  );
  const [resultDomain, setResultDomain] = useState<
    ISearchDomainDto | undefined
  >(undefined);
  const [selectBlockChain, setSelectBlockChain] = useState<
    IBlockchain | undefined
  >(undefined);
  const [domain, setDomain] = useState<string>();
  const [tld, setTld] = useState<string>();
  const [lookupDomain, setLookupDomain] = useState<IOutSearchDomain>();
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const domainDataConverter = useCallback(
    (res: ISearchDomain): ISearchDomainDto => {
      const tld = tlds.find((x) => x.id === res.tld_id);
      if (!tld) {
        throw new Error(`Tld : ${res.tld_id} not found`);
      }
      const domains: ISearchDomainDto = {
        domain: res.name,
        tld,
        // blockchain_id: blockChain,
        price: res.price,
        available: res.available,
      };
      return domains;
    },
    [tlds],
  );

  const domainListDataConverter = useCallback(
    (res: IOutSearchDomain): IDomainList => {
      const output: IDomainList = {
        recommend: res.recommend.map((d) => {
          return domainDataConverter(d);
        }),
      };
      return output;
    },
    [domainDataConverter],
  );

  // check domain whether is already in shopping cart
  const isAlreadyInShoppingCart = () => {
    if (cartProducts && resultDomain) {
      const newFqdn = `${resultDomain.domain}.${resultDomain.tld.name}`;
      return !!cartProducts.find(
        (product) =>
          `${product.domain?.name}.${product.domain?.tld.name}` === newFqdn,
      );
    }

    return false;
  };

  // add domain into shopping cart
  const addDomainToShoppingCart = async (_input: ISearchDomainDto) => {
    let walletId = cryptoWallets[0].id;
    if (user && cryptoWallets.length > 0) {
      if (user.default_address !== null && user.default_address !== undefined) {
        const wallet = cryptoWallets.find(
          (wallet: ICryptoWallet) => wallet.address === user.default_address,
        );
        if (wallet) {
          walletId = wallet.id;
        }
      }
    }
    const input: IAddDomainToCartInput = {
      productId: `${domainProduct!.id}`,
      domain: {
        method: DomainPurchaseMethod.REGISTER,
        name: _input.domain,
        duration: 1,
        tldId: _input.tld.name,
        blockchainId: selectBlockChain!.id, // selected BLockchainId
        walletId: walletId,
      },
    };
    await addDomainToCart(instance)(input);
  };

  const handleAlignmentButtonSide = (
    newAlignment: ISearchDomainDto | string,
  ) => {
    if (typeof newAlignment === 'string') {
      if (newAlignment === 'showAll') {
        // setSelectBlockChain(undefined);
      }
    } else {
      setResultDomain(newAlignment);
      // setSelectBlockChain(undefined);
    }
  };

  // add select domain into shopping cart
  const handleAddToCart = async () => {
    if (isAuthenticated) {
      setAdd(true);
      setIsAddingDomainToShoppingCart(true);

      try {
        await addDomainToShoppingCart(resultDomain!);
        await refreshCart();
        setIsShowCart(true);
        // window.innerWidth > 768 && setIsShowCart(true);
      } catch (e) {
        console.log(e);
      } finally {
        setIsAddingDomainToShoppingCart(false);
      }
    } else {
      if (resultDomain) {
        // localStorage.setItem('domain_cache', JSON.stringify(resultDomain));
        // localStorage.setItem(
        //   'blockchain_cache',
        //   JSON.stringify(selectBlockChain),
        // );
        insertCacheDomain(resultDomain, selectBlockChain!);
      }
      // login();
    }
  };

  useEffect(() => {
    setDomainProduct(products?.find((p) => p.type === 'domain'));
  }, [products]);

  useEffect(() => {
    if (urlParams.fqdn) {
      const [_name] = urlParams.fqdn.split('.');
      if (!regex.test(_name)) navigate('/');
    } else {
      navigate('/');
    }
  }, [navigate, regex, urlParams]);

  // split fqdn into domain and tld
  useEffect(() => {
    if (fqdn && fqdn !== '') {
      const [_domain, _tld] = fqdn.split('.');
      if (domain !== _domain) {
        setDomain(_domain);
      }
      if (tld !== _tld) {
        setTld(_tld);
      }
    }
  }, [fqdn, domain, tld]);

  // fetch suggest domain by domain name
  useEffect(() => {
    async function exec() {
      try {
        if (domain) {
          setIsSearching(true);
          const _lookupDomain = await searchDomain(instance)(domain);
          setLookupDomain(_lookupDomain);
          setIsSearching(false);
        }
      } catch (e) {
        setDomainList(undefined);
      }
    }
    exec();
  }, [domain, instance]);

  // convert lookup domain to domain list format
  useEffect(() => {
    if (lookupDomain) {
      const _domainList = domainListDataConverter(lookupDomain);
      setDomainList(_domainList);
    }
  }, [lookupDomain, domainListDataConverter]);

  // update result domain on domainlist updated
  useEffect(() => {
    if (domainList && domainList.recommend.length > 0) {
      const resultDomain = domainList.recommend.find((x) => x.tld.name === tld);
      setResultDomain(resultDomain);
    }
  }, [domainList, tld]);

  // update tld tab
  useEffect(() => {
    if (resultDomain) {
      if (resultDomain.tld.type === 'universal') {
        setSelectTldType(TLDTYPE.UNIVERSAL);
      }
      if (resultDomain.tld.type === 'classical') {
        setSelectTldType(TLDTYPE.CLASSICAL);
      }

      setSelectBlockChain(resultDomain.tld.blockchains[0]);
    }
  }, [resultDomain]);

  // Update useParams on result domain change
  useMemo(() => {
    if (resultDomain && !isSearching) {
      const newFqdn = `${resultDomain.domain}.${resultDomain.tld.name}`;
      // params.set('fqdn', `${resultDomain.domain}.${resultDomain.tld.name}`);
      // navigate(`/search/${newFqdn}`);
    }
  }, [resultDomain, isSearching]);

  return (
    <>
      <Nav
        reload={cartReload}
        setreload={setCartReload}
        cartCount={add}
        setCartCount={setAdd}
        title="Domains"
        setIsAddedDomainToShoppingCart={setIsAddedDomainToShoppingCart}
      />

      <section className="relative flex h-full w-full flex-col space-y-8 overflow-y-scroll px-6 pb-6 md:px-6 md:py-4 md:pt-0">
        {/* Result Domain Selection Start */}

        <ResultDomainSection
          domain={resultDomain}
          blockchain={selectBlockChain}
          isLoading={isSearching}
        />

        {/* TLD Selection */}
        {resultDomain && !isSearching && (
          <div className="box-shadow !mt-5 rounded-3xl border bg-white">
            <div className="flex h-[81px] items-center border-b p-6">
              <SectionTitle title="TLD" size="xl" />
            </div>

            <Tabs
              value={selectedTldType}
              className="p-6 pt-2"
              onChange={(e, v) => setSelectTldType(v)}
              aria-label="basic tabs example"
            >
              {/*<Tab*/}
              {/*  label={*/}
              {/*    <Tooltip title="Classical TLD for specific chain only" arrow>*/}
              {/*      <span>{TLDTYPE.CLASSICAL}</span>*/}
              {/*    </Tooltip>*/}
              {/*  }*/}
              {/*  value={TLDTYPE.CLASSICAL}*/}
              {/*/>*/}

              <Tab
                label={
                  <Tooltip
                    title="Universal TLD allows you to change the domain to any chain"
                    arrow
                  >
                    <span>{TLDTYPE.UNIVERSAL}</span>
                  </Tooltip>
                }
                value={TLDTYPE.UNIVERSAL}
              />
            </Tabs>
            <motion.div
              initial={{ x: -20, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ ease: 'easeOut', duration: 0.5 }}
              viewport={{ once: true }}
              className="relative flex w-full flex-1 flex-col gap-8 rounded-3xl bg-white p-6 pt-0 md:gap-10"
            >
              {domainList?.recommend && (
                <>
                  <TabPanel value={selectedTldType} index={TLDTYPE.CLASSICAL}>
                    <TldButtonGroup
                      resultDomain={resultDomain}
                      recommendList={domainList.recommend.filter(
                        (x) => x.tld.type === 'classical',
                      )}
                      callback={handleAlignmentButtonSide}
                    />
                  </TabPanel>
                  <TabPanel value={selectedTldType} index={TLDTYPE.UNIVERSAL}>
                    <TldButtonGroup
                      resultDomain={resultDomain}
                      recommendList={domainList.recommend.filter(
                        (x) => x.tld.type === 'universal',
                      )}
                      callback={handleAlignmentButtonSide}
                    />
                  </TabPanel>
                </>
              )}
            </motion.div>
          </div>
        )}

        {/* Blockchain Selection */}
        {resultDomain &&
          !isSearching &&
          resultDomain.available === DomainSearchType.AVAILABLE && (
            <motion.div
              whileInView={
                resultDomain.available === DomainSearchType.AVAILABLE
                  ? { opacity: 1 }
                  : { opacity: 0 }
              }
              viewport={{ once: true }}
              // transition={{ delay: 0.5 }}
              className="!mt-5 mb-10 flex h-fit w-full flex-1 flex-col opacity-0"
            >
              <div className="rounded-3xl border border-[#e2e9f2] bg-white">
                <div className="flex h-[81px] items-center border-b p-6 py-4">
                  <SectionTitle title="Chains" size="xl" />
                </div>
                <div className="flex flex-col gap-4 p-6">
                  <div className="flex flex-col space-y-4">
                    <div className="grid grid-cols-2 items-center sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 2xl:grid-cols-8">
                      {resultDomain.tld.blockchains.map((blockchain) => (
                        <BlockchainButton
                          blockchain={blockchain}
                          setSelected={(e) => setSelectBlockChain(e)}
                          selected={selectBlockChain}
                          key={blockchain.id}
                        />
                      ))}
                    </div>
                  </div>
                  {selectBlockChain && (
                    <div className="flex justify-end">
                      <button
                        data-on
                        className={`gradient-box gradient-box-hover flex w-full justify-center rounded-md px-12 py-4 text-xl text-white 
                          ${
                            resultDomain.available
                              ? 'opacity-100'
                              : 'opacity-50'
                          }
                          `}
                        onClick={handleAddToCart}
                        disabled={isAlreadyInShoppingCart()}
                      >
                        <motion.div className="text-lg font-semibold">
                          {!isAddingDomainToShoppingCart &&
                            !isAlreadyInShoppingCart() &&
                            'Add to cart'}
                          {isAddingDomainToShoppingCart && (
                            <Icon
                              icon="eos-icons:loading"
                              className="h-7 w-auto"
                            />
                          )}
                          {isAlreadyInShoppingCart() && (
                            <Icon icon="charm:tick" className="h-7 w-auto" />
                          )}
                        </motion.div>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </motion.div>
          )}
      </section>
    </>
  );
}

export default SearchPage;
