import { AxiosRequestConfig } from 'axios';

export const ReqToken = (config: AxiosRequestConfig) => {
  const token = localStorage.getItem('token');
  if (token) {
    if (config?.headers) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
  }
  return {
    ...config,
  };
};
