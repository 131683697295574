import { IDomain } from '@/utils/interface/domain.interface';
import { IEthereumAddress } from '@/utils/interface/ethereum-address.interface';
import { ITld } from '@/utils/interface/tld.interface';

export enum OrderStatus {
  CREATED = 'created',
  PENDING_PAYMENT = 'pending_payment',
  PAID = 'paid',
  IN_QUEUE = 'in_queue',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  EXPIRED = 'expired',
  CANCELED = 'canceled',
  ERROR = 'error',
}

export enum PaymentMethod {
  CREDIT_CARD = 'credit_card',
  UNION_PAY = 'union_pay',
  CRYPTO = 'crypto',
}

export interface IOrderDomain {
  id: number;
  type: string;
  status?: OrderStatus;
  tld: ITld;
  name: string;
  fqdn: string;
  owner: IEthereumAddress;
  durations: number;
  domain: IDomain;
  price: number;
  createdAt: string;
  updatedAt: string;
}

export interface ICheckoutPaymentLink {
  id: number;
  pid: string;
  link: string;
}

export interface INowPaymentsPaymentLink {
  id: number;
  pid: string;
  link: string;
}

export interface ICoingatePaymentLink {
  id: number;
  pid: string;
  link: string;
}
export interface IUnionPaymentLink {
  id: number;
  pid: string;
  link: string;
}

export interface IPayments {
  id: string;
  nowPaymentsPaymentLink: INowPaymentsPaymentLink;
  checkoutPaymentLink: ICheckoutPaymentLink;
  coingatePaymentLink: ICoingatePaymentLink;
  unionPaymentLink: IUnionPaymentLink;
}

export interface IOrder {
  id: string;
  status: string;
  domains: IOrderDomain[];
  successNowPayments?: INowPaymentsPaymentLink;
  successCheckout?: ICheckoutPaymentLink;
  successCoingate?: ICoingatePaymentLink;
  payments?: IPayments;
  amount: number;
  createdAt: string;
  updatedAt: string;
  expiredAt: string;
}

export interface IGetOrders {
  orders: IOrder[];
}

export interface IPaymentOrder {
  orderId: string;
  paymentId: string;
  amount: number;
  method: PaymentMethod;
  createdAt: string;
}
