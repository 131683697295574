import {
  ChangeEvent,
  FormEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Icon } from '@iconify/react';
import { ethers } from 'ethers';
import { motion } from 'framer-motion';
// import { IBillingAddress, IDomain, IOrder } from '@/utils/interface';
import { Skeleton } from '@mui/material';
// import { useAuth0 } from '@auth0/auth0-react';
import {
  AssociatedCryptoWalletType,
  createAssociatedWallet,
} from '@/api/omni/CryptoWallet/createCryptoWallet';
import {
  BlockchainEco,
  CryptoWalletType,
  ICryptoWallet,
} from '@/api/omni/CryptoWallet/cryptoWallet.interface';
import { GlobalStateContext } from '@/utils/context/GlobalStateContext';
import { IDomain } from '@/api/omni/Domain/domain.interface';
import { Toaster, toast } from 'react-hot-toast';
import { Web3Functions } from '@/components/web3/Web3';
import { Web3ReactProvider } from '@web3-react/core';
import { deleteEthereumAddress } from '@/api/delete-ethereum-address';
import { getChainIcon } from '@/components/web3/getChainIcon';
import { setDefaultWallet } from '@/api/omni/CryptoWallet/setDefaultWallet';
import { useAuth } from '@/utils/auth/hook';
import { useAxios } from '@/utils/axios/AxiosProvider';
import EthereumAddressView from '@/components/Account/EthereumAddressView';
import Nav from '@/components/Nav/Nav';
import _ from 'lodash';
import config, { DeploymentEnvironment } from '@/config';
import i18nIsoCountries from 'i18n-iso-countries';

i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const ani = {
  hide: {
    opacity: 0,
    zIndex: '-1',
    transition: { zIndex: { delay: 0.35 } },
    duration: 0.2,
  },
  show: { opacity: 1, zIndex: '100', transition: { duration: 0.2 } },
};

function TransferOrSell() {
  const { isAuthenticated } = useAuth();
  const { instance } = useAxios();

  const { updateUser, updateDomains } = useContext(GlobalStateContext);

  const [ethereumAddresses, setEthereumAddresses] = useState<
    ICryptoWallet[] | undefined
  >(undefined);

  const [isEthereumAddressHasBeenSetup, setIsEthereumAddressHasBeenSetup] =
    useState<boolean | undefined>(undefined);
  const [
    isDuplicatedBindingEthereumAddress,
    setIsDuplicatedBindingEthereumAddress,
  ] = useState<boolean | undefined>(undefined);
  const scrolls = useRef<HTMLDivElement>(null);
  const [customAddWalletErrorMessage, setCustomAddWalletErrorMessage] =
    useState<string | undefined>(undefined);
  const footer = useRef<HTMLDivElement>(null);
  const [_domains, setDomains] = useState<IDomain[]>([]);
  const [selectWallet, setSelectWallet] = useState<string>('');
  const [selectedDomain, setSelectedDomain] = useState<string[]>([]);
  const [selectedToken, setSelectedToken] = useState<string[]>([]);
  const [isUsingOwnerAddress, setIsUsingOwnerAddress] = useState<
    boolean | undefined
  >(undefined);
  const [isUsingCorrectNetwork, setIsUsingCorrectNetwork] = useState<
    boolean | undefined
  >(undefined);
  const [isValidRecipantAddress, setIsValidReciputeAddress] = useState<
    boolean | undefined
  >(undefined);
  const [recipantAddress, setRecipantAddress] = useState<string | undefined>(
    undefined,
  );
  const [transferDomain, setTransferDomain] = useState(false);
  const [change, setChange] = useState<number>(-1);
  const [managedAddress, setManagedAddress] = useState<string>('');
  const [transferAction, setTransferAction] = useState<boolean>(false);
  const { cryptoWallets, updateCryptoWallet, getProvider, domains } =
    useContext(GlobalStateContext);

  function handleSelectDomain(fqdn: string) {
    if (selectedDomain.includes(fqdn)) {
      setSelectedDomain(selectedDomain.filter((e) => e !== fqdn));
    } else {
      setSelectedDomain((oldArray) => [...oldArray, fqdn]);
    }
  }

  function handleSelectToken(fqdn: string) {
    if (selectedToken.includes(fqdn)) {
      setSelectedToken(selectedToken.filter((e) => e !== fqdn));
    } else {
      setSelectedToken((oldArray) => [...oldArray, fqdn]);
    }
  }

  useEffect(() => {
    async function exec() {
      // const _user = await getUser(accessToken);
      try {
        const managedWallet = cryptoWallets.find(
          (x) => x.type === CryptoWalletType.MANAGED,
        );
        if (managedWallet) setManagedAddress(managedWallet.address);
      } catch (e) {
        console.error(e);
      }
    }

    exec();
  }, [cryptoWallets]);

  useEffect(() => {
    async function exec() {
      if (cryptoWallets.length > 0) {
        setEthereumAddresses(_.orderBy(cryptoWallets, 'updatedAt', 'desc'));
        setDomains(domains);
      }
    }
    exec();
  }, [cryptoWallets, domains, transferAction]);

  function setWallet(ethereumAddress: string) {
    // console.log(ethereumAddress)
    setSelectWallet(ethereumAddress);
  }
  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // setIsConnectingToWeb3Wallet(true);
  };
  const handleRecipantAddressChange = (e: ChangeEvent<HTMLInputElement>) => {
    const _recipantAddress = e.target.value;
    setRecipantAddress(_recipantAddress);
    setIsValidReciputeAddress(ethers.utils.isAddress(_recipantAddress));
  };

  useEffect(() => {
    if (isAuthenticated && ethereumAddresses) {
      setIsEthereumAddressHasBeenSetup(ethereumAddresses.length > 0);
    }
  }, [ethereumAddresses, isAuthenticated]);

  const createEthereumAddress = async (
    address: string,
    message: string,
    signature: string,
  ) => {
    const isEthereumAddressAlreadyExist = ethereumAddresses?.find(
      (_address) => _address.address === address,
    );
    if (!isEthereumAddressAlreadyExist) {
      await createAssociatedWallet(instance)({
        eco: BlockchainEco.ETHEREUM,
        wallet: {
          associated: {
            address,
            type: AssociatedCryptoWalletType.FROM_USER,
            signature,
            message,
          },
        },
      });
      // await Promise.all([getDefaultEthereumAddress(accessToken), getEthereumAddresses(accessToken)]);
      await updateCryptoWallet();
      setEthereumAddresses(cryptoWallets);
      // setDefaultEthereumAddress(_defaultEthereumaddress);
    } else {
      setIsDuplicatedBindingEthereumAddress(true);
    }
  };

  const handleAddWalletClick = async () => {
    try {
      const chainId = config.env === DeploymentEnvironment.PRODUCTION ? 137 : 5;
      const useWeb3Function = new Web3Functions(
        chainId,
        await getProvider(chainId),
      );
      const result = await useWeb3Function.signMessage();
      if (result) {
        const { address, signature, message } = result;
        await createEthereumAddress(address, message, signature);
      }
    } catch (e) {
      console.error({ e });
      if (e instanceof Error) {
        if (e.message === 'No Binance Chain Wallet found')
          setCustomAddWalletErrorMessage(e.message);
      }
    }
  };

  const handleDeleteEthereumAddressClick = async (address: string) => {
    await deleteEthereumAddress(address);
    // const [_defaultEthereumaddress, _ethereumAddresses] = await Promise.all([getDefaultEthereumAddress(accessToken), getEthereumAddresses(accessToken)]);
    await updateCryptoWallet();
    setEthereumAddresses(cryptoWallets);
    // setDefaultEthereumAddress(_defaultEthereumaddress);
  };

  async function handleTransfer() {
    if (recipantAddress && isValidRecipantAddress) {
      const selectDomain = selectedDomain.map((x) => {
        return domains.filter((domain) => domain.fqdn === x);
      });
      for (let i = 0; i < selectDomain.length; i += 1) {
        const useWeb3Function = new Web3Functions(
          +selectDomain[i][0].blockchains[0].chainId,
          undefined,
          '@',
          selectDomain[i][0].name,
          selectDomain[i][0].tld.name,
        );
        await useWeb3Function.transferDomain({
          tokenId: selectDomain[i][0].tokenId,
          newOwner: 'address',
          ownerAddress: selectDomain[i][0].owner.address,
        });
      }
      await updateDomains();
      setTransferAction(!transferAction);
    }
  }
  const handleSetDefault = async (address: string) => {
    await setDefaultWallet(instance)(address);
    await updateUser();
  };

  const EthereumAddressesView = ethereumAddresses?.map((ethereumAddress, i) => {
    return (
      <EthereumAddressView
        key={ethereumAddress.address}
        index={i + 1}
        ethereumAddress={ethereumAddress}
        handleClick={setWallet}
        // handleSetAsDefaultEthereumAddressClick={handleSetAsDefaultEthereumAddressClick}
        handleDeleteEthereumAddressClick={handleDeleteEthereumAddressClick}
        hoverChangeBg
        change={change}
        setChange={setChange}
        setSelected={setSelectedDomain}
        type={ethereumAddress.type}
        handleSetDefaultWallet={handleSetDefault}
      />
    );
  });

  useEffect(() => {
    if (scrolls.current) {
      if (scrolls.current.scrollWidth > scrolls.current.clientWidth) {
        scrolls.current.classList.add('cursor-grab');
      } else {
        scrolls.current.classList.remove('cursor-grab');
      }
    }

    window.addEventListener('resize', () => {
      // check horizontal overflow
      if (scrolls.current) {
        if (scrolls.current.scrollWidth > scrolls.current.clientWidth) {
          scrolls.current.classList.add('cursor-grab');
        } else {
          scrolls.current.classList.remove('cursor-grab');
        }
      }
    });
  }, []);

  if (scrolls.current) {
    if (scrolls.current.scrollWidth > scrolls.current.clientWidth) {
      scrolls.current.classList.add('cursor-grab');
    } else {
      // console.log(scrolls.current.scrollWidth, scrolls.current.clientWidth);
      scrolls.current.classList.remove('cursor-grab');
    }
  }

  let pos = { top: 0, left: 0, x: 0, y: 0 };
  let ele;

  const mouseMoveHandler = function (e) {
    if (scrolls.current) {
      ele = scrolls.current;
    }
    // How far the mouse has been moved
    const dx = e.clientX - pos.x;
    const dy = e.clientY - pos.y;

    // Scroll the element
    ele.scrollTop = pos.top - dy;
    ele.scrollLeft = pos.left - dx;
  };
  const mouseUpHandler = function () {
    if (scrolls.current) {
      ele = scrolls.current;
    }
    document.removeEventListener('mousemove', mouseMoveHandler);
    document.removeEventListener('mouseup', mouseUpHandler);

    ele.style.cursor = 'default';
    ele.style.removeProperty('user-select');
  };
  const mouseDownHandler = function (e) {
    if (scrolls.current) {
      ele = scrolls.current;
    }
    ele.style.cursor = 'grab';
    ele.style.userSelect = 'none';
    pos = {
      // The current scroll
      left: ele.scrollLeft,
      top: ele.scrollTop,
      // Get the current mouse position
      x: e.clientX,
      y: e.clientY,
    };

    document.addEventListener('mousemove', mouseMoveHandler);
    document.addEventListener('mouseup', mouseUpHandler);
  };

  useEffect(() => {
    const a = document.querySelector(':root') as HTMLElement;
    let ethernetAddressesLength;
    if (ethereumAddresses) {
      ethereumAddresses?.length >= 5
        ? (ethernetAddressesLength = '5')
        : (ethernetAddressesLength = (
            ethereumAddresses?.length + 1
          ).toString());
    }
    if (ethernetAddressesLength) {
      // console.log(ethernetAddressesLength);
      a.style.setProperty('--card-quantity', ethernetAddressesLength);
    }
  }, [ethereumAddresses]);

  useEffect(() => {
    const r = document.querySelector(':root') as HTMLElement;
    if (footer.current) {
      r.style.setProperty('--footer-h', `${footer.current.offsetHeight}px`);
    }
  });

  function renderDomain(
    name: string,
    tld: string,
    tokenId: string,
    chainID: number,
  ) {
    return (
      <div
        key={tokenId}
        className={`${
          selectedDomain.includes(`${name}.${tld}`) === true
            ? 'bg-purple-700 text-white'
            : 'bg-white '
        } transfer-domain-con flex cursor-pointer flex-col items-center justify-center gap-2 rounded-xl border p-10 py-6 duration-200 hover:bg-purple-700 hover:text-white md:max-w-[325px]`}
        onClick={(_) => {
          handleSelectDomain(`${name}.${tld}`);
          handleSelectToken(`${tokenId}`);
        }}
      >
        {getChainIcon(chainID)}

        <text className="mt-2 text-lg">{name}</text>
        <div className="font-medium">.{tld}</div>
      </div>
    );
  }

  function checkWalletOwnDomain() {
    let newList = domains;
    newList = newList.filter((x) => x.owner.address === selectWallet);
    console.log(newList);
    if (newList.length > 0) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    window.onclick = function (event) {
      // console.log(event.target);
      if (!event.target.closest('.transfer-bg') && transferDomain) {
        setTransferDomain(false);
      }
    };
  });

  return (
    <>
      <Nav title="Transfer / Sell" />
      <div className="flex flex-1 flex-col overflow-y-auto p-10 px-6 pb-0 md:px-10">
        <div className="account-r-con flex w-full flex-1 flex-col gap-2 lg:gap-8">
          {customAddWalletErrorMessage && (
            <div
              className="relative rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
              role="alert"
            >
              <span className="block sm:inline">
                {customAddWalletErrorMessage}
              </span>
              <span
                className="absolute bottom-0 right-0 top-0 px-4 py-3"
                onClick={() => setCustomAddWalletErrorMessage(undefined)}
              >
                <svg
                  className="h-6 w-6 fill-current text-red-500"
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
            </div>
          )}
          {isDuplicatedBindingEthereumAddress && (
            <div
              className="relative rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
              role="alert"
            >
              <strong className="font-bold">Duplicated Address!</strong>
              <span className="block sm:inline">
                {' '}
                You have already bind this address into your account.
              </span>
              <span
                className="absolute bottom-0 right-0 top-0 px-4 py-3"
                onClick={() => setIsDuplicatedBindingEthereumAddress(false)}
              >
                <svg
                  className="h-6 w-6 fill-current text-red-500"
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
            </div>
          )}
          <div className="text-xl ">Select the wallet</div>
          <Toaster />
          <div>
            <div
              ref={scrolls}
              onMouseDown={mouseDownHandler}
              className="wallet-bar z-[0] flex min-h-[100px] w-full shrink-0 gap-5 overflow-x-auto rounded-3xl border bg-white p-10 "
            >
              {isEthereumAddressHasBeenSetup === false &&
                managedAddress === '' && (
                  <div className="flex min-h-[220px] shrink-0 items-center justify-center rounded-2xl border bg-red-500 px-5 py-5 font-medium text-white">
                    Please add your wallet address
                  </div>
                )}
              {ethereumAddresses ? (
                <>
                  <motion.button
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    transition={{ ease: 'easeOut', duration: 0.3 }}
                    viewport={{ once: true }}
                    className="box-shadow gradient-box gradient-box-rounded-2xl flex min-h-[200px] w-full max-w-[446px] shrink-0 items-center justify-center gap-5 rounded-2xl bg-white px-7 py-3 text-lg font-medium text-blue-500 duration-200"
                    onClick={() => {
                      handleAddWalletClick();
                      // setIsConnectingToWeb3Wallet(true);
                      // setIsDuplicatedBindingEthereumAddress(false);
                    }}
                  >
                    <Icon
                      icon="ant-design:plus-outlined"
                      className="h-8 w-auto"
                    />
                    Add Wallet
                  </motion.button>
                  {EthereumAddressesView}
                </>
              ) : (
                <Skeleton
                  // style={{ border: '1px solid rgb(229, 231, 235)' }}
                  width="350px"
                  height={210}
                  // borderRadius="1.25rem"
                  // baseColor="white"
                  // highlightColor="#f1f1f1"
                />
              )}
            </div>
          </div>

          <div
            className="flex-1"
            style={selectWallet ? { display: 'flex' } : { display: 'none' }}
          >
            <section className="mb-10 flex w-full flex-1 flex-col gap-5 overflow-hidden">
              <div>
                <span className="text-lg">Domains</span>
              </div>
              <div className="flex flex-1 flex-col justify-between rounded-xl border p-10">
                <div
                  className=""
                  style={
                    checkWalletOwnDomain() === true
                      ? { display: 'none' }
                      : { display: 'flex' }
                  }
                >
                  <span className="text-lg">No Domain in this Wallet</span>
                </div>
                <div className="domains-grid-con w-full ">
                  {_domains.map((x) => {
                    if (x.owner.address === selectWallet) {
                      return renderDomain(
                        x.name,
                        x.tld.name,
                        x.tokenId,
                        +x.blockchains[0]!.chainId,
                      );
                    }
                    return null;
                  })}
                </div>
                <div className="mt-5 text-right">
                  <button
                    data-show={selectedDomain.length > 1}
                    className="btn-select mx-4 w-28 rounded-full border-2 border-[#93278f] px-2 py-2 text-[#93278f] duration-200 hover:bg-[#93278f] hover:text-white"
                    onClick={(_) => {
                      selectedToken.forEach((i) => {
                        window.open(
                          // `https://opensea.io/assets/matic/${getCont}/${i}`,
                          `_blank${i}`,
                        );
                      });
                    }}
                  >
                    Sell
                  </button>
                  <button
                    className="w-28 rounded-full border-2 border-[#93278f] px-2 py-2 text-[#93278f] duration-200 hover:bg-[#93278f] hover:text-white"
                    onClick={(_) => setTransferDomain(true)}
                    data-show={transferDomain}
                  >
                    Transfer
                  </button>
                </div>
              </div>
              <div
                data-show={transferDomain}
                className="bg-bound absolute left-0 top-0 z-[410] h-full w-full bg-gray-500/20"
              >
                <div
                  className="info transfer-bg border"
                  data-show={transferDomain}
                >
                  <div className="box-shadow flex max-w-2xl flex-col gap-5 p-10">
                    {isUsingCorrectNetwork === false && (
                      <div
                        id="alert-additional-content-2"
                        className="mb-4 rounded-lg bg-red-100 p-4 dark:bg-red-200"
                        role="alert"
                      >
                        <div className="flex items-center">
                          <h3 className="text-lg font-medium text-red-700 dark:text-red-800">
                            Incorrect network
                          </h3>
                        </div>
                        <div className="mb-4 mt-2 text-sm text-red-700 dark:text-red-800">
                          <p>
                            Please switch your blockchain network to Polygon
                            Mainnet
                          </p>
                        </div>
                      </div>
                      // {/* </div>
                      //     <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
                      //       <p className="font-bold">Incorrect network</p>
                      //       <p>Please switch your blockchain network to Polygon Mainnet</p>
                      //     </div> */}
                    )}
                    {isUsingOwnerAddress === false && (
                      // <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
                      //   <p className="font-bold">Incorrect owner address</p>
                      //   <p>Please switch your wallet address to the domain owner address</p>
                      //   <p>
                      //     {domains.map((x) => {
                      //       <i>The current domain owner address: {x.owner.address} </i>;
                      //     })}
                      //   </p>
                      //   <p>
                      //     <i>Your current using wallet address: {account}</i>
                      //   </p>
                      // </div>
                      <div
                        id="alert-additional-content-2"
                        className="mb-4 rounded-lg bg-red-100 p-4 dark:bg-red-200"
                        role="alert"
                      >
                        <div className="flex items-center">
                          <h3 className="text-lg font-medium text-red-700 dark:text-red-800">
                            Incorrect owner address
                          </h3>
                        </div>
                        <div className="mb-4 mt-2 text-sm text-red-700 dark:text-red-800">
                          <p>
                            Please switch your wallet address to the domain
                            owner address
                          </p>
                          <p>
                            {domains.map((x) => {
                              return (
                                <i>
                                  The current domain owner address:{' '}
                                  {x.owner.address}{' '}
                                </i>
                              );
                            })}
                          </p>
                          <p>
                            <i>
                              Your current using wallet address: {'account'}
                            </i>
                          </p>
                        </div>
                      </div>
                    )}
                    <p className="text-2xl font-medium">
                      Transfer Domain To Other Address
                    </p>
                    <span>
                      You must have the private key in order to manage your
                      domain. if you transfer the domain to an exchange or any
                      other account where you do not control the private key,
                      you will not be able to access your domain. Not your keys,
                      not your domain.
                    </span>
                    <form onSubmit={(e) => handleFormSubmit(e)}>
                      <div className="flex w-full flex-col gap-2">
                        <span className="font-bold">Recipient address</span>
                        <input
                          id="newOwnerInput"
                          onChange={(e) => handleRecipantAddressChange(e)}
                          className="w-full rounded-md border bg-white px-5 py-3 outline-none duration-200 focus:outline-[#93278f]"
                          placeholder="Enter recipient address"
                          required
                        ></input>
                      </div>
                      {isValidRecipantAddress === false && (
                        <div
                          className="my-4 rounded-lg bg-red-100 p-4 text-sm text-red-700 dark:bg-red-200 dark:text-red-800"
                          role="alert"
                        >
                          <span className="font-medium">
                            Please enter a valid Ethereum address.
                          </span>
                        </div>
                      )}
                      <div className="mt-5 w-full border"></div>

                      <div className="flex w-full flex-col gap-5 py-5">
                        <div className="flex items-center ">
                          <input
                            id="cb1"
                            type="checkbox"
                            value=""
                            className="focus:ring-3 h-4 w-4 shrink-0 rounded border border-gray-300 bg-gray-50 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                            required
                          />

                          <label className="ml-2 text-sm text-gray-900 dark:text-gray-400">
                            I understand that I am transferring ownership of
                            this domain and this action is irreversible
                          </label>
                        </div>
                        <div className="flex items-center ">
                          <input
                            id="cb2"
                            type="checkbox"
                            value=""
                            className="focus:ring-3 h-4 w-4 shrink-0 rounded border border-gray-300 bg-gray-50 focus:ring-blue-300 dark:border-gray-600 dark:bg-gray-700 dark:ring-offset-gray-800 dark:focus:ring-blue-600"
                            required
                          />

                          <label className="ml-2 text-sm text-gray-900 dark:text-gray-400">
                            I'm not transferring to an exchange address
                          </label>
                        </div>
                      </div>
                      <div className="w-full border"></div>
                      <div className="mt-5 text-right">
                        <p id="errormsg" style={{ color: 'red' }}></p>
                        <button
                          className="mt-2 w-full rounded-xl border-2 border-[#93278f] px-8 py-4 font-medium text-[#93278f] duration-200 hover:bg-[#93278f] hover:text-white"
                          onClick={async (_) => {
                            toast.promise(handleTransfer(), {
                              loading: 'Loading',
                              success: 'Success.',
                              error: 'Error, Please Check the wallet account',
                            });
                          }}
                          type="submit"
                          // onClick={(x) => setTransferDomain(false)}
                        >
                          Transfer Domain
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  );
}

// export default withAuthenticationRequired(TransferOrSell, {
//   onRedirecting: () => <div className="load-center"></div>,
// });

function TransferOrSellPage() {
  function getLibrary(provider) {
    const _provider = new ethers.providers.Web3Provider(provider);
    _provider.pollingInterval = 12000;
    return _provider;
  }
  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <TransferOrSell />
    </Web3ReactProvider>
  );
}
export default TransferOrSellPage;
