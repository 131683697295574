import { CartContext } from '@/utils/context/CartContext';
import { GlobalStateContext } from '@/utils/context';
import { IUser } from '@/api/omni/User/user.interface';
import { Skeleton } from '@mui/material';
import { useContext } from 'react';
import CartProduct from './CartProduct';

interface CartItemsProps {
  user: IUser | undefined;
}

function CartItems(props: CartItemsProps) {
  const { products, isLoadingCart: isLoading } = useContext(CartContext);
  const { setIsShowCart } = useContext(GlobalStateContext);
  const { user } = props;

  return (
    <div className="relative flex flex-col">
      {!isLoading ? (
        <>
          {user &&
            products &&
            products.map((product, i) => (
              <CartProduct
                key={`${product.id}`}
                product={product}
                user={user}
                index={i}
                length={products.length - 1}
              />
            ))}
        </>
      ) : (
        <div className="flex flex-col bg-white before:gap-5">
          <div className="relative flex justify-between gap-4 py-2">
            <div className="flex-[4]">
              <Skeleton height={20} />
            </div>
            <div className="flex-[8]"></div>
            <div className="flex-[1]">
              <Skeleton height={20} />
            </div>
          </div>
          <div className="relative flex justify-between gap-4 py-2">
            <div className="flex-[4]">
              <Skeleton height={30} />
            </div>
            <div className="flex-[8]"></div>
            <div className="flex-[1]">
              <Skeleton height={30} />
            </div>
          </div>
          <div className="relative flex justify-between gap-4 py-2">
            <div className="flex-[2]">
              <Skeleton height={45} />
            </div>
            <div className="flex-[9]">
              <Skeleton height={45} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default CartItems;
