import { GlobalStateContext, MyDomainContext } from '@/utils/context';
import { IBlockchain } from '@/api/omni/Blockchain/blockchain.interface';
import { useContext, useEffect, useState } from 'react';

const FilterBlockchain = () => {
  const { domains } = useContext(GlobalStateContext);
  const { selectedBlockchainId, selectedWallet, setSelectedBlockchainId } =
    useContext(MyDomainContext);
  const [pageBlockchains, setPageBlockchains] = useState<IBlockchain[]>([]);

  const handleSelectChain = (chain?: IBlockchain) => {
    setSelectedBlockchainId(+(chain?.chainId ?? 0));
  };

  useEffect(() => {
    const _domain = domains.filter((d) => d.owner.id === selectedWallet?.id);
    const _blockchains: IBlockchain[] = [];
    _domain.forEach((d) => {
      d.blockchains.forEach((b) => {
        if (!_blockchains.find((_b) => _b.id === b.id)) {
          _blockchains.push(b);
        }
      });
    });
    setPageBlockchains(_blockchains);
  }, [domains, selectedWallet]);

  return <></>; //disable filter ui
  return (
    <>
      <div className="flex gap-4 font-semibold">
        <div className="text-lg">Chains Filter:</div>
        <div className="flex gap-2">
          <div
            onClick={() => handleSelectChain()}
            className={`cursor-pointer text-lg  ${
              !selectedBlockchainId
                ? 'text-edns hover:text-edns-hover'
                : 'hover:text-slate-700'
            }`}
          >
            All
          </div>
          {pageBlockchains.map((b) => (
            <div
              onClick={() => handleSelectChain(b)}
              key={b.id}
              className={`cursor-pointer text-lg  ${
                selectedBlockchainId === +b.chainId
                  ? 'text-edns hover:text-edns-hover'
                  : 'hover:text-slate-700'
              }`}
            >
              {b.name}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export { FilterBlockchain };
