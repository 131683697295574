function NFooter() {
  return (
    <div className="hidden shrink-0  justify-between gap-5 px-10 pb-6 text-xs text-black md:flex md:text-sm">
      <div>©2023 EDNS. All rights reserved.</div>
      <div className="flex justify-end gap-5">
        <a
          target="_blank"
          href="https://www.edns.domains/privacy-policy"
          rel="noreferrer"
        >
          <button className="duration-200 hover:underline">
            Privacy Policy
          </button>
        </a>
        <a
          target="_blank"
          href="https://www.edns.domains/terms-of-use"
          rel="noreferrer"
        >
          <button className="duration-200 hover:underline">Terms of Use</button>
        </a>
      </div>
    </div>
  );
}

export default NFooter;
