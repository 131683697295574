import '@/App.scss';
import { GlobalStateContext } from '@/utils/context/GlobalStateContext';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { domainNameCount } from '@/components/DomainFIlter/domainNameCount';
import { motion } from 'framer-motion';
import Nav from '@/components/Nav/Nav';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Select from 'react-select';
import _ from 'lodash';

import { HomeLogo } from '@/components/Home/HomeLogo';

const regex = new RegExp(
  /^[a-z0-9\-_\u00a9\u00ae\u2000-\u3300\ud83c\ud000-\udfff\ud83d\ud000-\udfff\ud83e\ud000-\udfff]{5,128}$/,
);

type OptionType = {
  value: any;
  label: any;
};

const HomePage = () => {
  const { tlds } = useContext(GlobalStateContext);
  const input = useRef<HTMLInputElement>(null);
  const [i, setI] = useState('');
  const [error, setError] = useState(false);
  const navigate = useNavigate();
  const [site, setSite] = useState(false);
  const { search } = useLocation();

  const [tldOptions, setTldOptions] = useState<OptionType[]>([]);
  const [selectedTldOption, setSelectedTldOption] = useState<
    OptionType | undefined
  >(undefined);

  const location = useLocation();
  // const { isAuthenticated } = useAuth0();
  useEffect(() => {
    if (!tlds) {
      return;
    }
    const _tldOptions = tlds.map((_tld) => ({
      value: _tld.name,
      label: `.${_tld.name}`,
    }));
    setTldOptions(_tldOptions);
    // const metaTld = tlds.find((_tld) => _tld.name === 'meta');
    // if (metaTld) setSelectedTldOption({ value: metaTld.name, label: `.${metaTld.name}` });
    setSelectedTldOption(_tldOptions[0]);
  }, [tlds]);

  useEffect(() => {
    if (input.current) {
      input.current.addEventListener('change', () => {
        if (input.current) setI(_.toLower(input.current.value.toLowerCase()));
        // console.log(i);
      });
    }
    if (location.search !== '' && !site) {
      setSite(true);
      setTimeout(() => {
        // console.log(window.location.href);
        const url = window.location.href;
        const _url = url.split(`${window.location.origin}`)[1];
        navigate(_url);
      }, 1000);
    }
    // exec();
  }, [input, location, navigate, site]);

  const customStyles = {
    control: (base) => ({
      ...base,
      width: '100%',
      height: '100%',
      border: '2px solid #f7f8fc',
      outline: 'none',
      padding: '0.375rem 0',
      borderRadius: '0.5rem',
    }),
    menu: (base) => ({
      ...base,
      height: '120px',
      zIndex: '1000',
    }),
    menuList: (base) => ({
      ...base,
      height: '100%',
    }),
  };

  const handleChange = (options) => {
    setSelectedTldOption(options);
  };

  return (
    <>
      <Nav home />
      <div className="grid flex-1 place-items-center overflow-y-auto py-10">
        <div className="flex w-full flex-col items-center px-4 md:mx-auto xl:px-0">
          {/* <BtnCart /> */}
          <HomeLogo />
          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ ease: 'easeOut', duration: 0.5, delay: 0.2 }}
            className="relative flex h-fit w-full flex-col flex-nowrap gap-5 rounded-2xl border-2 border-[#f7f8fc] bg-white/80 p-6 text-xl backdrop-blur-sm md:h-fit md:w-[60vw] md:max-w-[calc(100vw-332px-2.5rem)] md:flex-row md:gap-5"
          >
            <div className="relative h-full w-full">
              <input
                ref={input}
                onChange={(e) => {
                  console.log(domainNameCount(e.currentTarget.value));
                  setError(!regex.test(e.currentTarget.value));
                  setError(!(domainNameCount(e.currentTarget.value) >= 5));
                }}
                className="sm:text-md relative h-full w-full rounded-lg border-2 border-[#f7f8fc] px-10 py-3.5 text-sm outline-transparent transition-all duration-200 focus:outline-blue-400 2xl:px-12"
                type="text"
                placeholder="Input the domain name"
              ></input>
              <svg
                className="absolute left-3 top-[16px] h-auto w-5 fill-current text-3xl text-gray-300 2xl:left-5 2xl:top-[18px]"
                x="0px"
                y="0px"
                viewBox="0 0 56.966 56.966"
              >
                <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
              </svg>
            </div>
            <div className="search-con z-[80] flex h-full items-center rounded-md text-base outline-none">
              {tldOptions && (
                <Select
                  options={tldOptions}
                  value={selectedTldOption}
                  styles={customStyles}
                  onChange={handleChange}
                  className="h-full w-full text-center "
                ></Select>
              )}
            </div>

            <button
              onClick={() => {
                if (regex.test(i)) {
                  setError(false);
                  if (search) {
                    console.log(search);
                    navigate(
                      `/search/${i}.${selectedTldOption?.value}${search}`,
                    );
                  } else {
                    navigate(`/search/${i}.${selectedTldOption?.value}`);
                  }
                } else {
                  setError(true);
                }
              }}
              className="gradient-box sm:text-md z-[60] flex h-[52px] w-full items-center justify-center rounded-lg bg-transparent py-3.5 text-sm text-black duration-100 sm:w-fit sm:px-10 sm:py-0 sm:text-base"
            >
              Search
            </button>

            <div
              className={`error-msg absolute !left-0 right-0 top-[95%] mt-4 flex h-fit !w-full justify-end rounded-lg bg-red-300 px-5 py-4 text-xs text-red-700 md:absolute md:top-[100%] md:w-auto md:px-10 md:py-3 md:text-sm ${
                (error || selectedTldOption?.value === 'iotex') &&
                'show-msg z-[700]'
              }`}
            >
              <ul className="w-[95%] list-disc self-end sm:w-full">
                {error && (
                  <li className="self-end">
                    Sorry, only letters (a-z), numbers (0-9), underscore (_),
                    hyphen (-) and Emoji😚 are allowed.
                  </li>
                )}
                {error && (
                  <li className="mt-2 sm:mt-0">
                    Your domain must be between 5-128 characters long.
                  </li>
                )}
                {selectedTldOption?.value === 'iotex' && (
                  <li className="self-end">
                    .iotex is only deployed on the IoTeX chain, and not the
                    Polygon chain. .iotex will not expand to support omni-chain
                    functionality.
                  </li>
                )}
                {/* <li>Domain must be between 5 and 128 characters. </li> */}
                {/* <li>Special character ( other than - and _ ) are not allowed to enter in the domain.</li> */}
              </ul>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            viewport={{ once: true }}
            transition={{ ease: 'easeOut', duration: 1, delay: 0.3 }}
            className="md:text-md z-[50] mt-24 flex flex-col gap-2 text-center text-sm text-gray-600 md:gap-5"
          >
            Want to create your own Top Level Domain?
            <Link
              to="#"
              onClick={(e) => {
                window.location.href = 'mailto:info@edns.domains';
                e.preventDefault();
              }}
              className="bg-gradient-to-r from-[#31bdff] to-[#f934d0] bg-clip-text text-sm text-transparent duration-200 md:text-lg"
            >
              <b>Contact Us</b>
            </Link>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
