import Keycloak from 'keycloak-js';
import config from '@/config';

const keycloak = new Keycloak({
  // url: "http://localhost:8080/auth",
  // url: "https://auth.dev.dedrive.io/auth",
  url: `${config.auth0.url}/auth`,
  realm: `${config.auth0.realm}`,
  clientId: `${config.auth0.client}`,
});

export default keycloak;
