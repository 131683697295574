import { CartContext } from '@/utils/context';
import { IOrder } from '@/api/omni/Order/order.interface';
import { Icon } from '@iconify/react';
import { motion } from 'framer-motion';
import { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface CheckoutButtonProps {
  disabled?: boolean;
  onCheckout: () => Promise<IOrder | undefined>;
}

function CheckoutButton(props: CheckoutButtonProps) {
  const { disabled = false, onCheckout } = props;
  const btnRef = useRef<HTMLButtonElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { refreshCart } = useContext(CartContext);
  const checkoutAndCreateOrder = async () => {
    setIsLoading(true);

    const order = await onCheckout();
    await refreshCart();
    setIsLoading(false);
    if (order) {
      if (order.total === 0) {
        navigate(`/payment/success/${order.id}`);
      } else {
        navigate(`/checkout?id=${order.id}`);
      }
    }
  };

  return (
    <button
      ref={btnRef}
      data-on
      className="gradient-box mt-1 flex w-full items-center justify-center gap-2 rounded-lg py-3 text-lg font-semibold text-white opacity-100"
      onClick={() => {
        if (btnRef.current) {
          btnRef.current.disabled = true;
        }
        checkoutAndCreateOrder();
        // setI(true);
      }}
      disabled={disabled}
    >
      <motion.span animate={{ display: isLoading ? 'none' : 'block' }}>
        Checkout
      </motion.span>
      <motion.div animate={{ display: isLoading ? 'block' : 'none' }}>
        <Icon icon="eos-icons:loading" className="h-4 w-auto" />
      </motion.div>
    </button>
  );
}
export default CheckoutButton;
