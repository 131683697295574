import { AxiosError } from 'axios';

export const RequestError = (callback?) => (error: AxiosError) => {
  if (callback) {
    callback();
  }
  if (error.response) {
    console.error(error.response.data);
  }
  return Promise.reject(error);
};
