import { CrossChain } from '@/components/CrossChain/CrossChain';
import { DomainList } from '@/components/DomainList/DomainList';
import {
  DomainPurchaseMethod,
  IAddDomainToCartInput,
  addDomainToCart,
} from '@/api/omni/Cart/addDomainToCart';
import { GlobalStateContext } from '@/utils/context/GlobalStateContext';
import { IDomain } from '@/api/omni/Domain/domain.interface';
import {
  MyDomainContext,
  MyDomainProvider,
} from '@/utils/context/MyDomainContext';
import { Toaster } from 'react-hot-toast';
import { TransferDomain } from '@/components/DomainList/TransferDomain';
import { WalletList } from '@/components/DomainList/WalletList';
import { useAxios } from '@/utils/axios/AxiosProvider';
import { useContext, useEffect } from 'react';
import Nav from '@/components/Nav/Nav';
import _ from 'lodash';
import i18nIsoCountries from 'i18n-iso-countries';

i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));

const MyDomainPage = () => {
  const { cryptoWallets, products, setIsShowNav } =
    useContext(GlobalStateContext);
  const { instance } = useAxios();
  const {
    setEthereumAddresses,
    isDuplicatedBindingEthereumAddress,
    setIsDuplicatedBindingEthereumAddress,
    customAddWalletErrorMessage,
    setCustomAddWalletErrorMessage,
  } = useContext(MyDomainContext);

  useEffect(() => {
    async function exec() {
      const wallet = _.orderBy(cryptoWallets, 'updatedAt', 'desc');
      setEthereumAddresses(wallet);
    }
    exec();
  }, [cryptoWallets, setEthereumAddresses]);

  const renewCallback = async (domain: IDomain) => {
    const _product = products.find((p) => p.type === 'domain');
    if (!_product) {
      throw new Error('Product Not Found, Type:Domain');
    }
    const input: IAddDomainToCartInput = {
      productId: `${_product!.id}`,
      domain: {
        method: DomainPurchaseMethod.RENEWAL,
        name: domain.name,
        duration: 1,
        tldId: domain.tld.name,
        blockchainId: domain.blockchains.pop()!.id,
        walletId: domain.owner.id,
      },
    };
    await addDomainToCart(instance)(input);
    setIsShowNav(true);
  };

  return (
    <MyDomainProvider>
      <Toaster />

      <Nav title="My Domains" />

      <div className="flex flex-1 flex-col overflow-y-auto p-6 px-0 pb-0 max-md:pt-0">
        <div className="account-r-con flex w-full flex-1 flex-col gap-8">
          {customAddWalletErrorMessage && (
            <div
              className="relative rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
              role="alert"
            >
              <span className="block sm:inline">
                {customAddWalletErrorMessage}
              </span>
              <span
                className="absolute bottom-0 right-0 top-0 px-4 py-3"
                onClick={() => setCustomAddWalletErrorMessage(undefined)}
              >
                <svg
                  className="h-6 w-6 fill-current text-red-500"
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
            </div>
          )}
          {isDuplicatedBindingEthereumAddress && (
            <div
              className="relative rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
              role="alert"
            >
              <strong className="font-bold">Duplicated Address!</strong>
              <span className="block sm:inline">
                You have already bind this address into your account.
              </span>
              <span
                className="absolute bottom-0 right-0 top-0 px-4 py-3"
                onClick={() => setIsDuplicatedBindingEthereumAddress(false)}
              >
                <svg
                  className="h-6 w-6 fill-current text-red-500"
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
            </div>
          )}
          <div className="px-6 md:px-10">
            <WalletList />
          </div>

          <div className="flex-1 px-6 md:px-10">
            <DomainList />
          </div>
          <TransferDomain />
          <CrossChain />
        </div>
      </div>
    </MyDomainProvider>
  );
};

export default MyDomainPage;
