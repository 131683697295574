import {
  CryptoWalletType,
  ICryptoWallet,
} from '@/api/omni/CryptoWallet/cryptoWallet.interface';
import { Icon } from '@iconify/react';
import { Menu, MenuItem } from '@mui/material';
import { motion } from 'framer-motion';
import Logo from '../common/Logo';
import React, { useState } from 'react';

export interface IEthereumAddressViewProps {
  index: any;
  ethereumAddress: ICryptoWallet;
  hoverChangeBg?: boolean;
  isDefaultEthereumAddress?: boolean;
  // handleSetAsDefaultEthereumAddressClick(address: string): Promise<void>;
  handleSetDefaultWallet(address: string): Promise<void>;
  handleDeleteEthereumAddressClick(walletId: string): Promise<void>;
  handleClick?: any;
  change?: number;
  setChange?: (change: number) => void;
  selected?: string | undefined;
  setSelected?: (selected: string[]) => void;
  type?: string;
  setReveal?: any;
  handleReveal?: (walletId: string) => void;
}

interface IWalletButtonProps {
  isRounded: boolean;
  children?: React.ReactNode;
  onClick?: () => void;
}
interface IWalletIconProps {
  className?: string;
  type: CryptoWalletType;
}
function WalletIcon(props: IWalletIconProps) {
  const { className, type } = props;

  if (type === CryptoWalletType.ASSOCIATED) {
    return <Icon icon="logos:metamask-icon" className={className} />;
  }
  if (type === CryptoWalletType.MANAGED) {
    return <Logo className={className} />;
  }
  return <></>;
}

function WalletButton(props: IWalletButtonProps) {
  const { isRounded, children, onClick } = props;
  return (
    <MenuItem
      className={`w-full cursor-pointer p-0 font-medium shadow-md duration-200 `}
      onClick={onClick}
    >
      {children}
    </MenuItem>
  );
}

function WalletMenu(props: IEthereumAddressViewProps) {
  const {
    ethereumAddress,
    isDefaultEthereumAddress = false,
    type = '',
    handleReveal,
    handleDeleteEthereumAddressClick,
    handleSetDefaultWallet,
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const isOpenMenu = Boolean(anchorEl!);

  const handleOpenMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <div
        className="absolute right-4 top-6 z-[10] flex cursor-pointer"
        onClick={handleOpenMenu}
      >
        <Icon
          className="option h-10 w-auto cursor-pointer text-white"
          icon="iwwa:option"
        />
      </div>

      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleMenuClose}
        className="e absolute right-0 top-[135%] z-50 w-[10rem]"
      >
        {type !== 'managed' && (
          <WalletButton
            isRounded={isDefaultEthereumAddress}
            onClick={() => {
              handleDeleteEthereumAddressClick(ethereumAddress.id);
              handleMenuClose();
            }}
          >
            Unlink Wallet
          </WalletButton>
        )}
        {type === 'managed' && (
          <WalletButton
            isRounded={isDefaultEthereumAddress}
            onClick={() => {
              handleReveal && handleReveal(ethereumAddress.managed!.id);
              handleMenuClose();
            }}
          >
            Reveal Key
          </WalletButton>
        )}
        {!isDefaultEthereumAddress && (
          <WalletButton
            isRounded={isDefaultEthereumAddress}
            onClick={() => {
              handleSetDefaultWallet(ethereumAddress.address);
              handleMenuClose();
            }}
          >
            Set Default
          </WalletButton>
        )}
      </Menu>
    </>
  );
}

function EthereumAddressView(props: IEthereumAddressViewProps) {
  const {
    index,
    ethereumAddress,
    hoverChangeBg = false,
    isDefaultEthereumAddress = false,
    type = '',
    handleClick,
    setChange,
    selected = '',
    setSelected,
    change,
    handleSetDefaultWallet,
  } = props;

  const [isfetching, setIsFetching] = useState(false);

  const _handleSetDefaultWallet = async (address: string) => {
    setIsFetching(true);
    await handleSetDefaultWallet(address);
    setIsFetching(false);
  };
  return (
    <div className="relative">
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{
          delay: index * 0.15,
          ease: 'easeOut',
          duration: 0.3,
        }}
        viewport={{ once: true }}
        className={`box-shadow bg-card relative flex h-full w-fit shrink-0 overflow-hidden rounded-2xl px-1 py-1 duration-200
        ${
          hoverChangeBg
            ? ` cursor-pointer hover:bg-blue-700 hover:text-white ${
                change === index && 'bg-blue-700 text-white'
              }`
            : 'text-blue-800'
        }
      `}
        onClick={() => {
          if (handleClick) handleClick(ethereumAddress.address);
          if (setChange) setChange(index);
          setSelected && setSelected([]);
        }}
      >
        {isfetching ? (
          <div
            className={`flex max-h-[250px] flex-col justify-between gap-2 rounded-xl
               px-7 text-white`}
          >
            <div className="grid h-full place-items-center">
              <Icon
                icon="eos-icons:loading"
                className="h-24 w-full self-center text-gray-300"
              />
            </div>
          </div>
        ) : (
          <div
            className={`flex flex-col justify-between space-y-4 px-4 py-4 ${
              ethereumAddress.address !== selected
                ? 'bg-white text-blue-700'
                : 'text-white'
            } rounded-xl`}
          >
            <div className="z-1 flex items-end gap-8">
              <div>
                <WalletIcon
                  type={ethereumAddress.type}
                  className="h-[36px] w-auto shrink-0"
                />

                <p className=" flex-[1] text-base font-medium ">
                  {isDefaultEthereumAddress && 'Default'}
                </p>
              </div>
            </div>
            <div className="z-1">
              {type === 'managed' && (
                <div className="text-base font-medium ">Managed Wallet</div>
              )}
              <span className="z-50 truncate text-center text-sm font-medium lg:text-left">
                {ethereumAddress.address.slice(0, 14)}......
                {ethereumAddress.address.slice(-16)}
              </span>
            </div>
          </div>
        )}
      </motion.div>
      <WalletMenu {...props} handleSetDefaultWallet={_handleSetDefaultWallet} />
    </div>
  );
}

export default EthereumAddressView;
