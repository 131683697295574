import { AxiosContext } from '@/utils/axios/AxiosProvider';
import { FaInfoCircle } from 'react-icons/fa';
import { FiSearch } from 'react-icons/fi';
import { IPremiumDomain } from '@/api/omni/PremiumDomain/interface';
import { Pagination } from '@mui/material';
// import { faker } from '@faker-js/faker';
import { Premium_domain_row } from '@/Pages/premiumDomain/List/component/premium_domain_row';
import { premiumDomainListRequest } from '@/api/omni/PremiumDomain/list';
import React, { useContext, useEffect, useState } from 'react';

interface CheckboxProps {
  checkAll: boolean;
  setCheckAll: (checkAll: boolean) => void;
  setCheckedList: (checkedList: any) => void;
  index: number;
  checkedList: number[];
  dummyDomain: IPremiumDomain[];
}

interface CheckAllBoxProps {
  checkAll: boolean;
  setCheckAll: (checkAll: boolean) => void;
  isCheckAll: boolean;
  checkedList: number[];
  dummyDomain: IPremiumDomain[];
  setCheckedList: (checkedList: any) => void;
}

// const Checkbox = ({ setCheckedList, index, checkedList }: CheckboxProps) => {
//   const checked = checkedList.includes(index);
//
//   const handleCheck = () => {
//     if (checked) {
//       setCheckedList(checkedList.filter((x) => x !== index));
//     } else {
//       setCheckedList([...checkedList, index]);
//     }
//   };
//
//   return (
//     <div
//       onClick={handleCheck}
//       data-checked={checked}
//       className={`grid h-5 w-5 shrink-0 place-items-center rounded-md border-2 transition-colors ${
//         checked ? 'bg-[#e5e7eb]/40' : ''
//       }`}
//     >
//       {checked && <ImCheckmark className="mt-[-1px] w-3" />}
//     </div>
//   );
// };
//
// const CheckAllBox = ({
//   isCheckAll,
//   dummyDomain,
//   setCheckedList,
// }: CheckAllBoxProps) => {
//   const handleCheckAll = () => {
//     setCheckedList(isCheckAll ? [] : dummyDomain.map((_, index) => index));
//   };
//
//   return (
//     <div
//       onClick={handleCheckAll}
//       data-checked={isCheckAll}
//       className="grid h-5 w-5 shrink-0 place-items-center rounded-md border-2 transition-colors data-[checked=true]:bg-[#e5e7eb]/40"
//     >
//       {isCheckAll && <ImCheckmark className="mt-[-1px] w-3" />}
//     </div>
//   );
// };

interface SearchBarProps {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
}

const SearchBar = (props: SearchBarProps) => {
  return (
    <div
      onInput={(event) => {
        props.setSearchTerm(event.currentTarget.innerText);
      }}
      contentEditable
      className="min-w-[200px] outline-none"
    ></div>
  );
};

const PremiumDomainTable = () => {
  const [_dummyDomain, setDummyDomain] = useState<IPremiumDomain[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [checkedList, setCheckedList] = useState<number[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const pageSize = 20;
  const { instance } = useContext(AxiosContext);

  useEffect(() => {
    const exc = async () => {
      setDummyDomain(await premiumDomainListRequest(instance)());
    };
    exc();
  }, []);

  useEffect(() => {
    if (_dummyDomain.length === 0) {
      return;
    }
    console.log(_dummyDomain);
    if (
      _dummyDomain.filter((x) => x.name.includes(searchTerm)).length /
        pageSize <
      page
    ) {
      setPage(
        _dummyDomain.filter((x) => x.name.includes(searchTerm)).length /
          pageSize <
          1
          ? 1
          : _dummyDomain.filter((x) => x.name.includes(searchTerm)).length /
              pageSize,
      );
    }
  }, [searchTerm, page, _dummyDomain]);

  return (
    <div className="flex flex-col overflow-hidden">
      <div className="px-6">
        <div className="flex w-fit items-center gap-4 rounded-lg bg-white p-2 px-3">
          <FiSearch className="text-[#9010d9]" />
          <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        </div>
      </div>
      <section className="overflow-x-auto overflow-y-hidden rounded-xl px-6 py-4 text-sm max-md:pb-6 md:text-base">
        <div className="flex h-full min-w-[670px] flex-col rounded-xl bg-white shadow-sm">
          <div className="flex w-full border-b border-[#f5f5f5] text-[#6c6d6c]">
            <div className="flex w-[30%] min-w-[140px] items-center gap-4 p-5 font-medium">
              {/* <CheckAllBox
              checkAll={checkAll}
              setCheckAll={setCheckAll}
              isCheckAll={checkedList.length === dummyDomain.length}
              checkedList={checkedList}
              dummyDomain={dummyDomain}
              setCheckedList={setCheckedList}
            /> */}
              <p>Name</p>
            </div>
            <p className="flex w-[25%] items-center p-5 font-medium">Price</p>
            <p className="flex w-[25%] items-center gap-2 p-5 font-medium">
              Bonus Token
              <div className="group relative">
                <FaInfoCircle className="cursor-pointer text-[#70767b]" />
                <p className="pointer-events-none absolute left-[50%] top-[23px] translate-x-[-50%] rounded-md border bg-white p-2 py-1 font-normal opacity-0 shadow-md transition-all group-hover:pointer-events-auto group-hover:opacity-100">
                  Description
                </p>
              </div>
            </p>
            <p className="flex w-[30%] items-center gap-2 p-5 font-medium">
              Deposit Token
              <div className="group relative">
                <FaInfoCircle className="cursor-pointer text-[#70767b]" />
                <p className="pointer-events-none absolute left-[50%] top-[23px] translate-x-[-50%] rounded-md border bg-white p-2 py-1 font-normal opacity-0 shadow-md transition-all group-hover:pointer-events-auto group-hover:opacity-100">
                  Description
                </p>
              </div>
            </p>
          </div>
          <div className="h-full w-full overflow-y-auto ">
            {_dummyDomain
              .filter((x) => x.name.includes(searchTerm))
              .sort((a, b) => {
                return b.available - a.available;
              })
              .slice((page - 1) * pageSize, page * pageSize)
              .map((x, index) => (
                <Premium_domain_row
                  name={x.name}
                  status={x.status}
                  price={x.price}
                  bonusToken={x.bonusToken}
                  depositToken={x.depositToken}
                  available={x.available}
                  tld={x.tld}
                ></Premium_domain_row>
              ))}
          </div>
        </div>
      </section>

      <Pagination
        count={Math.ceil(
          _dummyDomain.filter((x) => x.name.includes(searchTerm)).length /
            pageSize,
        )}
        variant="outlined"
        shape="rounded"
        className="mx-auto max-md:pb-6"
        onChange={(event, page) => setPage(page)}
      />
    </div>
  );
};

export { PremiumDomainTable };
