import Nav from '@/components/Nav/Nav';

import { PremiumDomainTable } from '@/Pages/premiumDomain/List/component/PremiumDomainTable';
import React from 'react';

const PremiumDomainPage = () => {
  return (
    <>
      <Nav title="Premium" />
      <div className="flex w-full flex-col space-y-4 overflow-x-hidden overflow-y-hidden max-md:max-w-[100dvw]">
        <PremiumDomainTable />
      </div>
    </>
  );
};

export default PremiumDomainPage;
