import { Availability } from '../common/Availability';
import { DomainSearchType } from '@/api/omni/Domain/search';
import { ISearchDomainDto } from '../../Pages/search';

interface ITldBButtonProps {
  name: string;
  tldName: string;
  price: number;
  isAvailable?: DomainSearchType;
  onClick?: (d: any) => void;
}

function TldButton(props: ITldBButtonProps) {
  const { name, tldName, price, isAvailable, onClick } = props;
  return (
    <button
      className={`flex flex-1 items-center justify-between gap-1 px-5 lowercase sm:w-auto ${
        isAvailable !== DomainSearchType.AVAILABLE ? ' opacity-50' : ''
      }`}
      onClick={onClick}
      disabled={isAvailable !== DomainSearchType.AVAILABLE}
    >
      <div className="flex text-lg">
        <div className="sm:hidden">{name}</div>
        <div className="font-semibold">.{tldName}</div>
      </div>
      <div className="flex justify-center space-x-2">
        <Availability isAvailable={isAvailable} />
        <div className="font-medium">{` $${price}`}</div>
      </div>
    </button>
  );
}

interface ITldBButtonGroupProps {
  resultDomain?: ISearchDomainDto;
  recommendList: ISearchDomainDto[];
  callback: (d: any) => void;
}

function TldButtonGroup(props: ITldBButtonGroupProps) {
  const { resultDomain, recommendList, callback } = props;
  return (
    <div
      className="grid grid-cols-1 gap-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6"
      aria-label="text alignment"
    >
      {recommendList.map((d, index) => (
        <div
          onClick={() => callback(d)}
          className={`tldCard flex cursor-pointer items-center justify-center rounded-lg border py-3 shadow-md transition-colors${
            resultDomain?.tld.name === d.tld.name
              ? 'border border-purple-500 bg-purple-200/10'
              : ''
          }${!d.available ? ' opacity-10' : ''}`}
          // value={d.tld.name}
          aria-label="centered"
          key={`${d.tld.name}`}
        >
          <TldButton
            onClick={() => callback(d)}
            name={resultDomain?.domain || ''}
            tldName={d.tld.name}
            isAvailable={d.available}
            price={d.price}
          />
        </div>
      ))}
    </div>
  );
}

export { TldButtonGroup, TldButton };
