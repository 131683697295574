/* Autogenerated file. Do not edit manually. */
/* tslint:disable */
/* eslint-disable */

import { Contract, Signer, utils } from "ethers";
import { Provider } from "@ethersproject/providers";
import type { IBaseProvider, IBaseProviderInterface } from "../IBaseProvider";

const _abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes32",
        name: "ref",
        type: "bytes32",
      },
      {
        indexed: true,
        internalType: "bytes",
        name: "sender",
        type: "bytes",
      },
      {
        indexed: true,
        internalType: "bytes",
        name: "srcChainId",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "bytes",
        name: "payload",
        type: "bytes",
      },
    ],
    name: "MessageReceived",
    type: "event",
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: "bytes",
        name: "sender",
        type: "bytes",
      },
      {
        indexed: true,
        internalType: "bytes",
        name: "receiver",
        type: "bytes",
      },
      {
        indexed: true,
        internalType: "bytes",
        name: "dstChainId",
        type: "bytes",
      },
      {
        indexed: false,
        internalType: "bytes",
        name: "payload",
        type: "bytes",
      },
    ],
    name: "MessageSent",
    type: "event",
  },
  {
    inputs: [
      {
        internalType: "bytes",
        name: "_payload",
        type: "bytes",
      },
    ],
    name: "receive_",
    outputs: [],
    stateMutability: "nonpayable",
    type: "function",
  },
  {
    inputs: [
      {
        internalType: "address payable",
        name: "_sender",
        type: "address",
      },
      {
        internalType: "enum Chain",
        name: "_dstChain",
        type: "uint8",
      },
      {
        internalType: "bytes",
        name: "_payload",
        type: "bytes",
      },
    ],
    name: "send_",
    outputs: [],
    stateMutability: "payable",
    type: "function",
  },
];

export class IBaseProvider__factory {
  static readonly abi = _abi;
  static createInterface(): IBaseProviderInterface {
    return new utils.Interface(_abi) as IBaseProviderInterface;
  }
  static connect(
    address: string,
    signerOrProvider: Signer | Provider
  ): IBaseProvider {
    return new Contract(address, _abi, signerOrProvider) as IBaseProvider;
  }
}
