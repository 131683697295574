import axios from 'axios';
import config from '@/config';

export async function bindReseller(resellerID: string): Promise<boolean> {
  const url = new URL(`/user/reseller`, `${config.api.endpoint}`);
  const response = await axios.post(
    url.href,
    {
      resellerId: resellerID,
    },
    {
      // headers: { Authorization: `Bearer ${accessToken}` },
    },
  );
  return response.data;
}
