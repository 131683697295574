import 'react-loading-skeleton/dist/skeleton.css';
import { AxiosContext } from '@/utils/axios/AxiosProvider';
import { IPremiumDomainBalance } from '@/api/omni/PremiumDomain/interface';
import { MyDomainContext, SelectDomainState } from '@/utils/context';
import { Web3Functions } from '@/components/web3/Web3';
import { getChainIcon } from '@/components/web3/getChainIcon';
import { getPremiumDomainBalance } from '@/api/omni/PremiumDomain/balance';
import React, { useContext, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import ednsToken from '@/static/ednsToken.png';
import toast, { Toaster } from 'react-hot-toast';
enum MortgageTab {
  OVERVIEW,
  DEPOSIT,
  WITHDRAW,
}

const loadingSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <circle cx="12" cy="2" r="0" fill="#0057ff">
        <animate
          attributeName="r"
          begin="0"
          calcMode="spline"
          dur="1s"
          keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
          repeatCount="indefinite"
          values="0;2;0;0"
        />
      </circle>
      <circle cx="12" cy="2" r="0" fill="#0057ff" transform="rotate(45 12 12)">
        <animate
          attributeName="r"
          begin="0.125s"
          calcMode="spline"
          dur="1s"
          keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
          repeatCount="indefinite"
          values="0;2;0;0"
        />
      </circle>
      <circle cx="12" cy="2" r="0" fill="#0057ff" transform="rotate(90 12 12)">
        <animate
          attributeName="r"
          begin="0.25s"
          calcMode="spline"
          dur="1s"
          keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
          repeatCount="indefinite"
          values="0;2;0;0"
        />
      </circle>
      <circle cx="12" cy="2" r="0" fill="#0057ff" transform="rotate(135 12 12)">
        <animate
          attributeName="r"
          begin="0.375s"
          calcMode="spline"
          dur="1s"
          keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
          repeatCount="indefinite"
          values="0;2;0;0"
        />
      </circle>
      <circle cx="12" cy="2" r="0" fill="#0057ff" transform="rotate(180 12 12)">
        <animate
          attributeName="r"
          begin="0.5s"
          calcMode="spline"
          dur="1s"
          keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
          repeatCount="indefinite"
          values="0;2;0;0"
        />
      </circle>
      <circle cx="12" cy="2" r="0" fill="#0057ff" transform="rotate(225 12 12)">
        <animate
          attributeName="r"
          begin="0.625s"
          calcMode="spline"
          dur="1s"
          keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
          repeatCount="indefinite"
          values="0;2;0;0"
        />
      </circle>
      <circle cx="12" cy="2" r="0" fill="#0057ff" transform="rotate(270 12 12)">
        <animate
          attributeName="r"
          begin="0.75s"
          calcMode="spline"
          dur="1s"
          keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
          repeatCount="indefinite"
          values="0;2;0;0"
        />
      </circle>
      <circle cx="12" cy="2" r="0" fill="#0057ff" transform="rotate(315 12 12)">
        <animate
          attributeName="r"
          begin="0.875s"
          calcMode="spline"
          dur="1s"
          keySplines="0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8;0.2 0.2 0.4 0.8"
          repeatCount="indefinite"
          values="0;2;0;0"
        />
      </circle>
    </svg>
  );
};
const ednsIcon = () => {
  return <img style={{ width: '24px', height: '24px' }} src={ednsToken} />;
};
const MortgageBox = (props: { useWeb3?: Web3Functions }) => {
  const {
    selectedDomain: domain,
    selectDomainState,
    isOpenMortgage,
    setIsOpenMortgage,
    setSelectedDomain,
    setSelectDomainState,
  } = useContext(MyDomainContext);

  const { instance } = useContext(AxiosContext);

  const [balance, setBalance] = useState<IPremiumDomainBalance | null>(null);
  const [tab, setTab] = useState<MortgageTab>(MortgageTab.OVERVIEW);
  const [loading, setLoading] = useState(true);
  const [depositInput, setDepositInput] = useState<string | undefined>(
    undefined,
  );
  const [withdrawInput, setWithdrawInput] = useState<string | undefined>(
    undefined,
  );
  useEffect(() => {
    if (
      domain &&
      selectDomainState === SelectDomainState.Mortgage &&
      props.useWeb3 !== undefined
    ) {
      getBalance();
      setIsOpenMortgage(true);
    }
  }, [domain, setIsOpenMortgage, selectDomainState, props.useWeb3]);

  useEffect(() => {
    if (balance !== null) {
      setLoading(false);
    }
  }, [balance]);
  const getBalance = async () => {
    const balance = await getPremiumDomainBalance(instance)(domain!.fqdn);
    setBalance(balance);
    setDepositInput(`${balance.requirement}`);
  };
  if (!isOpenMortgage) return <> </>;

  const handleClose = () => {
    setSelectedDomain(undefined);
    setSelectDomainState(SelectDomainState.None);
    setIsOpenMortgage(false);
  };

  const getTokenAddress = () => {
    const tokenAddress = props.useWeb3?.getTokenContractAddress();
    const output =
      tokenAddress?.slice(0, 6) +
      '...' +
      tokenAddress?.slice(tokenAddress?.length - 6, tokenAddress?.length);
    return output;
  };

  const renderTab = () => {
    switch (tab) {
      case MortgageTab.OVERVIEW:
        return (
          <div className="flex justify-between">
            {/*<SkeletonTheme baseColor="#202020" highlightColor="#444">*/}
            <div className="flex flex-1 flex-col">
              <div>Require Token</div>
              <div className="flex items-center gap-1">
                {ednsIcon()}
                {loading ? (
                  <Skeleton width={'2rem'} />
                ) : (
                  balance?.requirement
                )}{' '}
                EDNS
              </div>
            </div>
            <div className="flex flex-1 flex-col">
              <div>Current Stake</div>
              <div className="flex items-center  gap-1">
                {ednsIcon()}
                {loading ? <Skeleton width={'2rem'} /> : balance?.balance} EDNS
              </div>
            </div>
            {/*</SkeletonTheme>*/}
          </div>
        );
      case MortgageTab.WITHDRAW:
        return (
          <div className="flex flex-col gap-4">
            <div className="flex justify-between">
              <div className="flex flex-1 flex-col">
                <div>Require Token</div>
                <div className="flex items-center gap-1">
                  {ednsIcon()}
                  {loading ? (
                    <Skeleton width={'2rem'} />
                  ) : (
                    balance?.requirement
                  )}{' '}
                  EDNS
                </div>
              </div>
              <div className="flex flex-1 flex-col">
                <div>Current Stake</div>
                <div className="flex items-center  gap-1">
                  {ednsIcon()}
                  {loading ? (
                    <Skeleton width={'2rem'} />
                  ) : (
                    balance?.balance
                  )}{' '}
                  EDNS
                </div>
              </div>
            </div>
            <div className="flex flex-1 flex-col gap-2">
              <div className="flex justify-between">
                Amount
                <button
                  onClick={() => {
                    setWithdrawInput(`${balance!.balance}`);
                  }}
                >
                  Max
                </button>
              </div>
              <div className="flex flex-1">
                <input
                  type="number"
                  value={withdrawInput}
                  onChange={(e) => setWithdrawInput(e.target.value)}
                  className="flex-1 border-2 p-2"
                />
                <button
                  className="bg-[#e5eeff] px-2 text-[#0057ff]"
                  disabled={loading}
                  onClick={async () => {
                    let toastId = toast.loading('Loading...');
                    let removedload = false;
                    try {
                      if (!loading) {
                        setLoading(true);

                        await props.useWeb3!.withDrawPremiumDomain({
                          amount: `${depositInput}`,
                          fqdn: domain!.fqdn,
                          owner: domain!.owner.address,
                        });
                        await getBalance();
                        toast.dismiss(toastId);
                        toast.success('Withdraw Success.');
                        setLoading(false);
                      }
                    } catch (e) {
                      toast.success('Withdraw Fail.');
                      if (!removedload) {
                        toast.dismiss(toastId);
                      }
                    }
                  }}
                >
                  {loading ? loadingSvg() : 'Withdraw'}
                </button>
              </div>
            </div>
          </div>
        );
      case MortgageTab.DEPOSIT:
        return (
          <div className="flex flex-col gap-4">
            <div className="flex justify-between">
              <div className="flex flex-1 flex-col">
                <div>Require Token</div>
                <div className="flex items-center gap-1">
                  {ednsIcon()}
                  {loading ? (
                    <Skeleton width={'2rem'} />
                  ) : (
                    balance?.requirement
                  )}{' '}
                  EDNS
                </div>
              </div>
              <div className="flex flex-1 flex-col">
                <div>Current Stake</div>
                <div className="flex items-center  gap-1">
                  {ednsIcon()}
                  {loading ? (
                    <Skeleton width={'2rem'} />
                  ) : (
                    balance?.balance
                  )}{' '}
                  EDNS
                </div>
              </div>
            </div>
            <div className="flex flex-1 flex-col gap-2">
              <div className="flex justify-between">Amount</div>
              <div className="flex flex-1">
                <input
                  type="number"
                  value={depositInput}
                  onChange={(e) => setDepositInput(e.target.value)}
                  className="flex-1 border-2 p-2"
                />
                <button
                  className="bg-[#e5eeff] px-2 text-[#0057ff]"
                  disabled={loading}
                  onClick={async () => {
                    let toastId = toast.loading('Loading...');
                    let removedload = false;
                    try {
                      if (!loading) {
                        setLoading(true);
                        await props.useWeb3!.depositPremiumDomain({
                          amount: `${depositInput}`,
                          fqdn: domain!.fqdn,
                          owner: domain!.owner.address,
                        });
                        await getBalance();
                        toast.remove(toastId);
                        toast.success('Deposit Success');
                        setLoading(false);
                      }
                    } catch (e) {
                      toast.success('Deposit Fail.');
                      if (!removedload) {
                        toast.dismiss(toastId);
                      }
                    }
                  }}
                >
                  {loading ? loadingSvg() : 'Deposit'}
                </button>
              </div>
            </div>
          </div>
        );
      default:
        return <div></div>;
    }
  };
  return (
    <div
      className="absolute left-0 top-0 z-[410] flex h-full w-full items-center justify-center bg-gray-500/20"
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          handleClose();
        }
      }}
    >
      <Toaster />
      <div className="transfer-bg wallet-bar max-h-full w-fit overflow-y-scroll rounded-xl border bg-white">
        <div className="box-shadow flex w-[700px] max-w-2xl flex-col gap-4 p-10">
          <div>
            <div className="flex items-end justify-between">
              <div className="text-2xl font-medium">Deposit EDNS</div>
              <div className="text-[#7e7e7e]">{''}</div>
            </div>
            <div className="flex justify-between">
              <div className="flex items-center">
                <span className="text-[#7e7e7e]">
                  {/*Be advise, in order to use Premium Domain and it's services, you*/}
                  {/*will need to deposit the required amount of EDNS Token in the*/}
                  {/*Mortgage contract. However if you decided to withdrawal your*/}
                  {/*Token, the selected domain and services will be deactivated.*/}
                  Deposit $EDNS to active Your Domain.
                </span>
              </div>
              <div>
                <div className="bold flex items-center gap-2">
                  {/*{ednsIcon()}*/}

                  {/*{`0%`}*/}
                </div>
              </div>
            </div>
          </div>
          <div className="flex gap-2">
            <button
              className={`bold flex-1 rounded-lg py-2 ${
                tab === MortgageTab.OVERVIEW
                  ? 'bg-[#e5eeff] text-[#0057ff]'
                  : 'bg-[#eeeff0] text-[#000000]'
              }`}
              onClick={() => {
                setTab(MortgageTab.OVERVIEW);
              }}
            >
              Overview
            </button>
            <button
              className={`bold flex-1 rounded-lg py-2 ${
                tab === MortgageTab.DEPOSIT
                  ? 'bg-[#e5eeff] text-[#0057ff]'
                  : 'bg-[#eeeff0] text-[#000000]'
              }`}
              onClick={() => {
                setTab(MortgageTab.DEPOSIT);
              }}
            >
              Deposit
            </button>
            <button
              className={`bold flex-1 rounded-lg py-2 ${
                tab === MortgageTab.WITHDRAW
                  ? 'bg-[#e5eeff] text-[#0057ff]'
                  : 'bg-[#eeeff0] text-[#000000]'
              }`}
              onClick={() => {
                setTab(MortgageTab.WITHDRAW);
              }}
            >
              Withdraw
            </button>
          </div>
          <hr />
          {renderTab()}
          <hr />
          <div className="flex flex-col gap-2">
            <div className="text-xl">Token Contract</div>
            <div className="flex justify-between">
              <div className="flex items-center gap-2">
                {getChainIcon(+domain!.blockchains[0].chainId, 'svg')}
                {domain?.blockchains[0].name} : {getTokenAddress()}
                <button
                  onClick={async () => {
                    await navigator.clipboard.writeText(
                      props.useWeb3!.getTokenContractAddress(),
                    );
                    toast.success('Copied!');
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                  >
                    <g
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    >
                      <rect width="14" height="14" x="8" y="8" rx="2" ry="2" />
                      <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2" />
                    </g>
                  </svg>
                </button>
              </div>
              <button
                onClick={() => {
                  try {
                    props.useWeb3?.addTokenToWallet();
                  } catch (e) {
                    console.log(e);
                  }
                }}
                className="rounded-lg bg-[radial-gradient(ellipse_at_bottom_left,_var(--tw-gradient-stops))] from-purple-600 via-fuchsia-700 to-orange-400 to-purple-800 p-2 text-white"
              >
                Add To Wallet
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MortgageBox;
