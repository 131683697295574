import { Icon } from '@iconify/react';
import { ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
// import { IUser } from '@/utils/interface/user.interface';
import { Chip, CircularProgress } from '@mui/material';
import {
  GlobalStateContext,
  ICacheDomain,
} from '@/utils/context/GlobalStateContext';
import { ITldPrice } from '@/api/omni/Tld/tld.interface';
import { Toaster } from 'react-hot-toast';
import { getChainIcon } from '@/components/web3/getChainIcon';

interface IProps {
  domain: ICacheDomain;
  index: number;
  popup?: boolean;
  length?: number;
}

interface UpdateCountProps {
  callback?: () => void;
  enable: boolean;
  children?: ReactNode;
}
function UpdateCount(props: UpdateCountProps) {
  const { callback, enable, children } = props;
  return (
    <button
      onClick={callback}
      className={`grid aspect-square h-8 w-8 cursor-pointer place-items-center rounded-full border bg-white ${
        enable ? 'active:bg-gray-200' : 'disabled:opacity-50'
      }`}
      disabled={!enable}
    >
      {children}
    </button>
  );
}

function CacheCartProduct(props: IProps) {
  const { domain, index, length } = props;
  const item = useRef<HTMLDivElement>(null);

  const { cryptoWallets, updateDurtionCacheDomain, removeCacheDomain } =
    useContext(GlobalStateContext);

  const [isDomainUpdating, setIsDomainUpdating] = useState<boolean>(false);
  const [isUpdateDecrease, setIsUpdateDecrease] = useState<boolean>(true);
  const [isUpdateIncrease, setIsUpdateIncrease] = useState<boolean>(true);
  const [isDomainDeleting, setIsDomainDeleting] = useState<boolean>(false);

  const [quantity, setQuantity] = useState<number>(domain.duration);

  useEffect(() => {
    setQuantity(domain.duration!);
  }, [domain]);

  useEffect(() => {
    const r = document.querySelector(':root') as HTMLElement;
    if (item.current) {
      r.style.setProperty('--cart-h', `${item.current.clientHeight}px`);
    }
  });

  const getTldPrice = () => {
    const domainLenght = domain.domain.domain.length;
    const priceList: ITldPrice = domain!.domain.tld.price.register;
    switch (domainLenght) {
      case 1: {
        return priceList.oneLetter;
      }
      case 2: {
        return priceList.twoLetter;
      }
      case 3: {
        return priceList.threeLetter;
      }
      case 4: {
        return priceList.fourLetter;
      }
      default: {
        return priceList.fiveLetter;
      }
    }
  };

  const increaseValue = async () => {
    setIsDomainUpdating(true);
    if (quantity >= 5) {
      setIsUpdateIncrease(false);
    } else {
      const _quantity = quantity + 1;
      setQuantity(_quantity);
      //TODO increaseValue
      await updateDurtionCacheDomain(domain, _quantity);
      // const newCart = await updateCartAmount(instance)({
      //   id: product.id,
      //   type,
      //   value: _quantity,
      // });
      // setShoppingCart(newCart);
      setIsUpdateDecrease(true);
    }
    setIsDomainUpdating(false);
  };

  const decreaseValue = async () => {
    setIsDomainUpdating(true);
    if (quantity > 1) {
      const _quantity = quantity - 1;
      setQuantity(_quantity);
      setIsUpdateIncrease(true);

      // const type = product.type === 'domain' ? product.type : 'product';
      //TODO decreaseValue
      await updateDurtionCacheDomain(domain, _quantity);
      // const newCart = await updateCartAmount(instance)({
      //   id: product.id,
      //   type,
      //   value: _quantity,
      // });
      // setShoppingCart(newCart);
    } else if (quantity > 0) {
      const _quantity = quantity - 1;
      setQuantity(_quantity);
    }
    setIsDomainUpdating(false);
  };

  // if (product.type === 'domain') {
  return (
    <div
      key={index}
      ref={item}
      className={`cart-con flex flex-col pb-12 ${
        !(index === length) && 'border-b'
      } bg-white before:gap-5`}
    >
      <Toaster />
      {/* {domain.status === 'AVAILABLE' ? null : <div className="mt-5 rounded-md bg-red-500 px-5 py-5 font-medium text-red-200">Domain not available</div>} */}
      <div className="relative flex flex-col justify-between gap-4 py-12 pb-4">
        <div className="flex max-w-full flex-[2] items-center justify-between  text-base lg:text-lg">
          <div className="flex gap-x-2">
            <div className="w-fit">
              <Chip
                label={'REGISTER'}
                variant="outlined"
                size="small"
                color="primary"
              />
            </div>
            <p className=" text-base font-bold">
              {domain.domain.domain}.{domain.domain.tld.name}
            </p>
            <div className="flex h-6 w-6 items-center rounded-full p-1.5">
              {getChainIcon(+domain?.blockchain.chainId)}
            </div>
          </div>

          <div
            className={`flex min-w-fit shrink-0 items-center truncate text-xl text-[#393859] ${
              quantity >= 2 && 'line-through'
            }`}
          >
            ${getTldPrice()}
          </div>
        </div>
        <hr className=" border-dashed" />
        <div className="flex justify-between">
          <div className="relative flex w-fit">
            <div className="flex min-w-fit items-center gap-2 rounded-full border bg-[#fdf8ff] p-2 py-1">
              <UpdateCount
                callback={() => decreaseValue()}
                enable={isUpdateDecrease && !isDomainUpdating}
              >
                -
              </UpdateCount>

              <p className="h-full w-fit shrink-0 py-2 text-center">{`${quantity} year(s)`}</p>

              <UpdateCount
                callback={() => increaseValue()}
                enable={isUpdateIncrease && !isDomainUpdating}
              >
                +
              </UpdateCount>
            </div>
          </div>
          <div className="flex items-center justify-end text-left md:justify-start">
            <motion.span
              className="h-full w-full items-center justify-end lg:justify-start"
              animate={
                isDomainUpdating ? { display: 'flex' } : { display: 'none' }
              }
            >
              <Icon
                icon="eos-icons:loading"
                className="h-6 w-auto text-gray-300"
              />
            </motion.span>
            <motion.span
              className={`text-right text-xl font-medium md:text-left ${
                quantity <= 0 && 'line-through'
              }`}
              animate={
                isDomainUpdating ? { display: 'none' } : { display: 'block' }
              }
            >
              ${domain.duration * getTldPrice()}
            </motion.span>
          </div>
        </div>
        <hr className=" border-dashed" />
      </div>
      <button
        onClick={async () => {
          // deleteDomain();
          await removeCacheDomain(domain);
          // TODO delet Domain
        }}
        data-show={quantity === 0}
        className="btn-delete w-full items-center rounded-md bg-red-500"
        disabled={isDomainDeleting}
      >
        {!isDomainDeleting ? (
          <Icon
            className="mx-auto h-auto w-5 text-white"
            icon="ant-design:delete-outlined"
          />
        ) : (
          <CircularProgress size="2rem" color="info" />
        )}
      </button>
    </div>
  );
  // }
  // Product
  // return (
  //   <div
  //     key={index}
  //     ref={item}
  //     className={`cart-con flex flex-col ${
  //       !(index === length) && 'border-b'
  //     } bg-white before:gap-5`}
  //   >
  //     Product
  //   </div>
  // );
}

export default CacheCartProduct;
