import { JSX } from 'react';
import { Tooltip } from '@mui/material';
import arbitrum from 'chain-icons/svg/arbitrum.svg';
import avalanche from 'chain-icons/svg/avalanche.svg';
import bnb from 'chain-icons/svg/binance-coin.svg';
import cele from 'chain-icons/svg/celo.svg';
import flare from '@/static/chainSvg/flare-flr-logo.svg';
import ftm from 'chain-icons/svg/fantom.svg';
import goerliSVG from 'chain-icons/svg/ethereum-classic.svg';
import harmony from 'chain-icons/svg/harmony.svg';
import iotex from 'chain-icons/svg/iotex.svg';
import moonbeam from '@/static/chainSvg/mainbrand-1.svg';
import moonriver from '@/static/chainSvg/Moonriver.svg';
import okc from 'chain-icons/svg/okb.svg';
import optimism from 'chain-icons/svg/optimism.svg';
import polygonSVG from 'chain-icons/svg/polygon.svg';
import xDAISVG from 'chain-icons/svg/xdai.svg';

const getChainIcon = (
  chainId: number,
  type: 'name' | 'image' | 'svg' = 'image',
): JSX.Element => {
  let svg;
  let name;
  switch (chainId) {
    case 5: {
      svg = goerliSVG;
      name = 'Goerli';
      break;
    }
    case 137: {
      svg = polygonSVG;
      name = 'POLYGON';
      break;
    }
    case 80001: {
      svg = polygonSVG;
      name = 'POLYGON';
      break;
    }
    case 100: {
      svg = xDAISVG;
      name = `Gnosis`;
      break;
    }
    case 10200: {
      svg = xDAISVG;
      name = `Gnosis`;
      break;
    }
    case 1101: {
      svg = polygonSVG;
      name = 'Polygon zkEVM';
      break;
    }
    case 1442: {
      svg = polygonSVG;
      name = 'Polygon zkEVM';
      break;
    }
    case 1666600000: {
      svg = harmony;
      name = 'Harmony One';
      break;
    }
    case 1666700000: {
      svg = harmony;
      name = 'Harmony One';
      break;
    }
    case 250: {
      svg = ftm;
      name = 'Fantom';
      break;
    }
    case 4002: {
      svg = ftm;
      name = 'Fantom';
      break;
    }
    case 56: {
      svg = bnb;
      name = 'BNB';
      break;
    }
    case 97: {
      svg = bnb;
      name = 'BNB';
      break;
    }
    case 1284: {
      svg = moonbeam;
      name = 'Moonbeam';
      break;
    }
    case 1287: {
      svg = moonbeam;
      name = 'Moonbeam';
      break;
    }
    case 43114: {
      svg = avalanche;
      name = 'Avalanche C-Chain';
      break;
    }
    case 43113: {
      svg = avalanche;
      name = 'Avalanche C-Chain';
      break;
    }
    case 1285: {
      svg = moonriver;
      name = 'Moonriver';
      break;
    }
    // case
    case 10: {
      svg = optimism;
      name = 'Optimism';
      break;
    }
    case 420: {
      svg = optimism;
      name = 'Optimism';
      break;
    }
    case 42161: {
      svg = arbitrum;
      name = 'Arbitrum';
      break;
    }
    case 421613: {
      svg = arbitrum;
      name = 'Arbitrum';
      break;
    }
    case 42220: {
      svg = cele;
      name = 'Celo';
      break;
    }
    case 44787: {
      svg = cele;
      name = 'Celo';
      break;
    }
    case 66: {
      svg = okc;
      name = 'OKC';
      break;
    }
    case 65: {
      svg = okc;
      name = 'OKC';
      break;
    }
    case 4689: {
      svg = iotex;
      name = 'iotex';
      break;
    }
    case 114: {
      svg = flare;
      name = 'flare';
      break;
    }

    default:
      break;
  }

  if (type === 'image') {
    return (
      <div>
        <Tooltip title={name}>
          <img src={svg} width={36} height={50} alt={name} />
        </Tooltip>
      </div>
    );
  }

  if (type === 'svg') {
    return <img src={svg} width={16} height={16} alt={name} />;
  }
  return <>{name}</>;
};

export { getChainIcon };
