import { AxiosInstance } from 'axios';
import { ITld } from './tld.interface';
import config from '@/config';

interface IListTld {
  count: number;
  limit: number;
  skip: number;
  data: ITld[];
}
export const listTlds =
  (instance: AxiosInstance) => async (): Promise<ITld[]> => {
    const tlds = await instance.get<IListTld>(config.api.router.tld.list);
    return tlds.data.data;
  };

export const getTldById =
  (instance: AxiosInstance) =>
  async (id: string): Promise<ITld> => {
    const tld = await instance.get<ITld>(`${config.api.router.tld.get}${id}`);
    return tld.data;
  };
