import ContractJson from '../static/contract/contracts.json';

export interface IGetContractsData {
  chainId: number;
  addresses: {
    'Registry.Diamond': string | null;
    'Registry.Init': string | null;
    'Registry.DiamondCutFacet': string | null;
    'Registry.DiamondLoupeFacet': string | null;
    'Registry.AccessControlFacet': string | null;
    'Registry.TldRecordFacet': string | null;
    'Registry.DomainRecordFacet': string | null;
    'Registry.HostRecordFacet': string | null;
    'Registry.BaseRegistryFacet': string | null;
    'PublicResolver': string | null;
    'Registrar': string | null;
    'ClassicalRegistrarController': string | null;
    'UniversalRegistrarController': string | null;
    'OmniRegistrarController': string | null;
    'Root': string | null;
    'DefaultWrapper': string | null;
    'Token': string | null;
    'Bridge': string | null;
    'Synchronizer': string | null;
    'Portal': string | null;
    'LayerZeroProvider': string | null;
    'MigrationManager': string | null;
    'Mortgage': string | null;
  };
}
export const getAllContractsData = (): IGetContractsData[] => {
  // return JSON.parse(
  //   fs.readFileSync(
  //     path.join(process.cwd(), 'src/static/contract/contracts.json'),
  //     { encoding: 'utf8' },
  //   ),
  // );
  return ContractJson as IGetContractsData[];
};

export const getContractsData = (
  chainId: number,
): IGetContractsData | undefined => {
  const data = getAllContractsData();
  return data.find((c) => c.chainId === chainId);
};

export const isValidChain = (chainId: number): boolean => {
  let checking = true;
  try {
    const contract = getContractsData(chainId);
    if (!contract) {
      checking = false;
    } else {
      const resolver = contract?.addresses.PublicResolver;
      if (!resolver) {
        checking = false;
      }
    }
  } catch (e) {
    checking = false;
  }
  return checking;
};
