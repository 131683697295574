import { AxiosInstance } from 'axios';
import { ICryptoWallet } from './cryptoWallet.interface';
import { errorHandler } from '../../errorHandler';
import config from '@/config';

export const listWallets =
  (instance: AxiosInstance) => async (): Promise<ICryptoWallet[]> => {
    try {
      const wallets = await instance.get<ICryptoWallet[]>(
        config.api.router.cryptoWallet.list,
      );
      return wallets.data;
    } catch (e) {
      throw errorHandler(e);
    }
  };
